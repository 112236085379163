import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ConfirmSignUp from './ConfirmSignUp';
import PasswordResetRequired from './PasswordResetRequired';
import PasswordReset from './PasswordReset';
import ConfirmPasswordReset from './ConfirmPasswordReset';
import Logo from 'images/YorkIE_Fuel-mint.svg';
import Messaging from './marketing/MarketingMessage';
import Footer from 'containers/Footer';
import NewsSettings from './NewsSettings';
import ReferralAccount from './ReferralAccount';

import './CustomSignIn.css';

const UpdateSettings = (key, value, clear = false) => {
  const storageKey = '_signup_settings';

  let notifications = '{}';
  if (!clear) {
    notifications = localStorage.getItem(storageKey);
  }

  notifications = JSON.parse(notifications);
  let updated = { ...notifications, [key]: value };
  localStorage.setItem(storageKey, JSON.stringify(updated));
};

const Authenticator = () => {
  const [authState, setAuthState] = useState('signIn');
  const [feedback, setFeedback] = useState('');
  const [username, setUsername] = useState('');
  const [workflow, setWorkflow] = useState('');
  const [referral, setReferral] = useState(null);
  const [userData, setUserData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  // check for referral
  const { code } = useParams();

  useEffect(() => {
    document.title = `Login ${process.env.REACT_APP_SITE_TITLE_SUFFIX}`;
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const plan = searchParams.get('plan');
    const queryCode = searchParams.get('code');
    if (code) {
      setAuthState('referral');
      setWorkflow('referral');
      return;
    }
    if (location.pathname.includes('signup')) {
      setAuthState('signUp');
    }

    if (plan) UpdateSettings('plan', plan, true);
    if (plan === 'news') {
      setAuthState('news');
    }

    if (queryCode) {
      setWorkflow('referral');
      return;
    }
    setWorkflow(plan);
  }, [location, code]);

  const changeWorkflow = plan => {
    const searchParams = new URLSearchParams(location.search);
    const queryCode = searchParams.get('code');
    if (queryCode) {
      setWorkflow('referral');
      return;
    }
    setWorkflow(plan);
  };

  const changeState = (state, msg, un, ud) => {
    setFeedback(msg);
    setUsername(un);
    setAuthState(state);
    setUserData(ud);
  };

  return (
    <div className="authenticontainer">
      <div className="marketing">
        <div className="marketing-logo"></div>
        <div className="marketing-message">
          <Messaging workflow={workflow} setReferral={setReferral} />
        </div>
      </div>
      <div className="user-form">
        <div className="marketing-logo">
          <img
            alt="large fuel logo"
            src={Logo}
            style={{ width: '300px', display: 'block', cursor: 'pointer', marginLeft: '-7px', marginTop: '-6px' }}
            onClick={() => history.push('/')}
          />
        </div>
        <div className="user-content">
          {authState === 'news' && (
            <NewsSettings changeState={changeState} changeWorkflow={changeWorkflow} updateSettings={UpdateSettings} />
          )}
          {authState === 'signIn' && (
            <SignIn changeState={changeState} changeWorkflow={changeWorkflow} feedback={feedback} username={username} />
          )}
          {authState === 'signUp' && <SignUp changeState={changeState} changeWorkflow={changeWorkflow} />}
          {authState === 'confirmSignUp' && (
            <ConfirmSignUp
              changeState={changeState}
              feedback={'Check your email for a confirmation code'}
              username={username}
            />
          )}
          {authState === 'passwordReset' && (
            <PasswordReset changeState={changeState} feedback={feedback} username={username} />
          )}
          {authState === 'confirmReset' && (
            <ConfirmPasswordReset changeState={changeState} feedback={feedback} username={username} />
          )}
          {authState === 'newPasswordRequired' && (
            <PasswordResetRequired changeState={changeState} feedback={feedback} user={userData} />
          )}
          {authState === 'referral' && <ReferralAccount referral={referral} />}
        </div>
        <Footer className="auth-footer" />
      </div>
    </div>
  );
};

export default Authenticator;
export { UpdateSettings };
