import React from 'react';
import { Link } from 'react-router-dom';
import { Popover, Space, Tooltip, Typography } from 'antd';
import { LinkOutlined, EnvironmentOutlined, CalendarOutlined, TeamOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import LocationPopover from 'components/company/PopoverLocation';
import AvatarFailover from 'components/AvatarFailover';
import { logoURL } from 'helpers/Organizations';
import './Overview.css';

const MinOverview = props => {
  const organization = props?.organization;
  let organizationUrl = '#';
  if (organization) {
    organizationUrl = organization.homepage_url;
    if (organizationUrl && !organizationUrl.startsWith('http')) {
      organizationUrl = `http://${organizationUrl}`;
    }
  }
  return (
    <Space direction="vertical" className="company-quick" style={{ maxWidth: '100%' }}>
      {organization?.homepage_url && (
        <div className="line-row">
          <Tooltip title="Company Website">
            <LinkOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
          <a target="_blank" rel="noopener noreferrer" href={organizationUrl}>
            {organization?.homepage_url ? organization?.homepage_url.split('?')[0] : ''}
          </a>
        </div>
      )}
      {organization?.city && organization?.country_code && (
        <div className="line-row">
          <Tooltip title="Company Headquarters">
            <EnvironmentOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
          <Popover
            content={
              <LocationPopover
                city={organization?.city}
                state={organization?.state_code}
                country={organization?.country_code}
              />
            }
            placement={'topLeft'}
          >
            <div>
              <span style={{ borderBottom: '1px dotted' }}>
                {organization?.city}, {organization?.state_code} {organization?.country_code}
              </span>
            </div>
          </Popover>
        </div>
      )}
      {organization?.founded_on && (
        <div className="line-row">
          <Tooltip title="Founded">
            <CalendarOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
          {organization?.founded_on !== null && organization?.founded_on !== '' && organization?.founded_on ? (
            <Moment date={organization?.founded_on} format="MMMM, YYYY" />
          ) : (
            '-'
          )}
        </div>
      )}

      {organization?.employee_count && (
        <div className="line-row">
          <Tooltip title="# of Employees">
            <TeamOutlined style={{ fontSize: '20px' }} />
          </Tooltip>
          {organization?.employee_count !== null &&
          organization?.employee_count &&
          organization?.employee_count !== '' &&
          organization?.employee_count !== 'unknown'
            ? organization?.employee_count
            : '-'}
        </div>
      )}
    </Space>
  );
};

const CompanyHeader = ({ org, showDomain }) => {
  const orgUuid = org.uuid || org.id;
  return (
    <Link data-id={'company-' + org.name} to={`/research/companies/${orgUuid}`}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: '10px',
        }}
      >
        <div style={{ marginRight: '8px', borderRadius: '4px' }}>
          <AvatarFailover
            key={`avatar-${orgUuid}`}
            src={logoURL(orgUuid)}
            name={org.name}
            shape="square"
            size={'medium'}
          />
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ width: '100%' }}>
            <Typography.Text style={{ fontSize: '16px' }}>
              <Link to={`/research/companies/${orgUuid}`} style={{ color: '#000' }}>
                {org.name}
              </Link>
            </Typography.Text>
            {showDomain && org.domain && (
              <Typography.Text type="secondary" style={{ fontSize: '95%', float: 'right' }}>
                {org.domain}
              </Typography.Text>
            )}
          </div>
          <Typography.Paragraph
            style={{ margin: 0 }}
            type="secondary"
            ellipsis={{ rows: 1, expandable: false }}
            size={18}
          >
            {org.short_description}
          </Typography.Paragraph>
        </div>
      </div>
    </Link>
  );
};

export { MinOverview, CompanyHeader };
