import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input, Button, Row, Col, Alert, message } from 'antd';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { EyeInvisibleOutlined, EyeTwoTone, ArrowRightOutlined } from '@ant-design/icons';
import GoogleSignin from './GoogleSignin';

const SignIn = ({ changeState, changeWorkflow, feedback, username }) => {
  useEffect(() => {
    if (
      window.location.href.includes('confirmationcode') &&
      window.location.href.includes('/login') &&
      feedback === ''
    ) {
      changeState(
        window.location.href.includes('resetpassword') ? 'confirmReset' : 'confirmSignUp',
        null,
        JSON.parse(localStorage.getItem('userName')) || new URL(window.location.href).searchParams.get('email')
      );
    }
    // eslint-disable-next-line
  }, [changeState]);

  const [email, setEmail] = useState(username || '');
  const [loading, setLoading] = useState(false);
  const [signLoading, setSignLoading] = useState(false);
  const [err, setErr] = useState('');
  const history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let redirect = params.get('redirect');
  useEffect(() => {
    if (redirect != null && redirect.includes('error_description')) {
      signInWithGoogle();
    }
    // eslint-disable-next-line
  }, []);

  const handleLogin = async e => {
    setLoading(true);
    setErr('');
    try {
      let result = await Auth.signIn(e.username.toLocaleLowerCase().trim(), e.password);
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        changeState('newPasswordRequired', null, result.username, result);
        return;
      }
      const storedRedirectUrl = JSON.parse(localStorage.getItem('redirectUrl'));
      if (!redirect && storedRedirectUrl) {
        localStorage.removeItem('redirectUrl');
        history.push(storedRedirectUrl);
      }
      setLoading(false);
      // changeState('signIn');
    } catch (error) {
      console.log('error signing in:', error);
      if (error.code === 'UserNotConfirmedException') {
        try {
          const res = await Auth.resendSignUp(e.username);
          if (res.hasOwnProperty('error')) {
            message.error({ content: res.error, duration: 5 });
          } else {
            changeState('confirmSignUp', null, e.username);
          }
        } catch (err) {
          console.log('error resending code: ', err);
        }
      } else if (error.message.toLowerCase().includes('user does not exist')) {
        setErr('User does not exist, You may have previously logged in with Google');
      } else if (
        error.message.includes(
          `Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern`
        )
      ) {
        setErr('Invalid email address');
      } else {
        setErr(error.message);
      }
      setLoading(false);
    }
  };

  const next = () => {
    changeState('signUp');
    changeWorkflow('free');
    history.push(
      `/signup${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''}${
        window.location.hash ? window.location.hash : ''
      }`
    );
  };

  function signInWithGoogle() {
    setSignLoading(true);
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: JSON.stringify({ backURL: new URLSearchParams(window.location.search).get('redirect') }),
    });
  }

  return (
    <Row>
      <Col xs={24} md={24} lg={16} xl={16} xxl={9} style={{ maxWidth: '600px' }}>
        <h4 className="mute-title" style={{ marginBottom: '30px' }}>
          Log In
        </h4>

        {feedback && <Alert style={{ marginBottom: '10px' }} type="info" showIcon={false} message={feedback} banner />}
        {err && <Alert style={{ marginBottom: '10px' }} type="error" showIcon={false} message={err} banner />}

        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="normal_login"
          className="login-form"
          onFinish={e => handleLogin(e)}
        >
          <Form.Item
            label="Email"
            initialValue={email}
            name="username"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input
              autoFocus
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="xl"
              size="large"
              id="username"
              key="username"
              name="username"
              placeholder="Email"
              disabled={loading}
              tabIndex={1}
              data-id="login-email"
            />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label={
              <Row>
                <Col xs={12}>Password</Col>
                <Col xs={12} style={{ textAlign: 'end' }}>
                  <Button
                    type="link"
                    size="small"
                    onClick={() => changeState('passwordReset')}
                    disabled={loading}
                    style={{ paddingRight: '0px' }}
                    tabIndex={4}
                  >
                    Forgot your password?
                  </Button>
                </Col>
              </Row>
            }
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password
              className="xl"
              size="large"
              id="password"
              key="password"
              name="password"
              type="password"
              value="password"
              placeholder="Password"
              disabled={loading}
              onCopy={event => {
                event.preventDefault();
                return false;
              }}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              tabIndex={2}
              data-id="login-password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              id="submit-login-btn"
              type="primary"
              size="large"
              htmlType="submit"
              block
              loading={loading}
              tabIndex={3}
              data-id="login-btn"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
        <GoogleSignin loading={signLoading} onClick={signInWithGoogle} />
        <Divider plain></Divider>
        <Button type="link" size="large" block onClick={next} disabled={loading} tabIndex={5}>
          Create New Account <ArrowRightOutlined />
        </Button>
      </Col>
    </Row>
  );
};

export default SignIn;
