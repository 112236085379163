import React from 'react';

const Dots = ({ color = 'rgba(255,255,255,.05)', size = '20' }) => {
  return (
    <svg width="100%" height="100%">
      <pattern
        id="pattern-circles"
        x="0"
        y="0"
        width={size}
        height={size}
        patternUnits="userSpaceOnUse"
        patternContentUnits="userSpaceOnUse"
      >
        <circle id="pattern-circle" cx="10" cy="10" r="3" fill={color} />
      </pattern>

      <rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#pattern-circles)" />
    </svg>
  );
};

export default Dots;
