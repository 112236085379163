import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
const FuelMenu = ({ lists, navClick, isLoggedIn }) => {
  const items = useMemo(() => {
    if (isLoggedIn) {
      let arr = [
        {
          key: 'sub1',
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          label: <Link to={`/research/watchlists/updates`}>Research</Link>,
          children: [
            {
              key: '/research/watchlists',
              label: (
                <Link data-id="watchlists-link" to={`/research/watchlists`}>
                  Watchlists
                </Link>
              ),
            },
            {
              key: '/research/companies/saved-searches',
              label: (
                <Link data-id="savedSearches-link" to={`/research/companies/saved-searches`}>
                  Saved Searches
                </Link>
              ),
            },
            {
              key: '/research/companies',
              label: <Link to={`/research/companies`}>Companies</Link>,
            },
            {
              key: `/markets`,
              label: (
                <Link data-id="market-link" to={`/markets`}>
                  Markets
                </Link>
              ),
            },
            {
              key: '/research/reports',
              label: <Link to={`/research/reports`}>Reports</Link>,
            },
          ],
        },
        {
          key: 'sub2',
          label: (
            <Link data-id="advisory-menu" to="/advisory">
              Strategy
            </Link>
          ),
          children: [
            { key: '/advisory/library/market', label: <Link to="/advisory/library/product">Product</Link> },
            {
              key: '/advisory/library/finance',
              label: <Link to="/advisory/library/finance">Finance</Link>,
            },
            { key: '/advisory/library/sales', label: <Link to="/advisory/library/sales">Sales</Link> },
            {
              key: '/advisory/library/marketing',
              label: <Link to="/advisory/library/marketing">Marketing</Link>,
            },
            {
              key: '/advisory/chat/live',
              label: <Link to="/advisory/chat/live">Advisory Chat</Link>,
            },
          ],
        },
        {
          key: 'tools-key',
          label: (
            <Link to="/tools" onClick={() => navClick()}>
              Execution
            </Link>
          ),
          children: [
            {
              key: '/tools/blog',
              label: <Link to="/tools/blog">Blog Draft</Link>,
            },
            {
              key: '/tools/ebook',
              label: <Link to="/tools/ebook">Ebook Draft</Link>,
            },
            {
              key: '/tools/messaging',
              label: <Link to="/tools/messaging">Messaging Hierarchy</Link>,
            },
            {
              key: '/tools/prfaq',
              label: <Link to="/tools/prfaq">PR/FAQ</Link>,
            },
            {
              key: '/tools/strategic-growth',
              label: <Link to="/tools/strategic-growth">Strategic Growth Plan</Link>,
            },
            {
              key: '/tools/pitch',
              label: <Link to="/tools/pitch">Pitch Analyzer</Link>,
            },
            {
              key: '/tools/social',
              label: <Link to="/tools/social">Social Posts</Link>,
            },
            { key: '/tools/investors', label: <Link to="/tools/investors">Investors Research</Link> },
            {
              key: '/tools/podcasts',
              label: <Link to="/tools/podcasts">Podcasts Research</Link>,
            },
          ],
        },
      ];
      return arr;
    } else {
      let arr = [
        {
          key: 'sub1',
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          label: <a style={{ pointerEvents: 'none' }}>Research</a>,
          children: [
            {
              key: `/markets`,
              label: (
                <Link data-id="market-link" to={`/markets`}>
                  Markets
                </Link>
              ),
            },
            {
              key: '/research/reports',
              label: <Link to={`/research/reports`}>Reports</Link>,
            },
          ],
        },
        {
          key: 'sub2',
          label: (
            <Link data-id="advisory-menu" to="/advisory">
              Strategy
            </Link>
          ),
          children: [
            { key: '/advisory/library/market', label: <Link to="/advisory/library/product">Product</Link> },
            {
              key: '/advisory/library/finance',
              label: <Link to="/advisory/library/finance">Finance</Link>,
            },
            { key: '/advisory/library/sales', label: <Link to="/advisory/library/sales">Sales</Link> },
            {
              key: '/advisory/library/marketing',
              label: <Link to="/advisory/library/marketing">Marketing</Link>,
            },
          ],
        },
        {
          key: '/plans',
          label: <Link to={`/plans`}>Pricing</Link>,
        },
      ];
      return arr;
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);
  return <Menu items={items} mode="inline" onClick={navClick} />;
};

export default FuelMenu;
