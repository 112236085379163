import { API } from '@aws-amplify/api';

// Get all article details
const getArticleDetails = async (articleId, queryEarned = false) => {
  let url = `/api-public/news/none/article/${articleId}${queryEarned ? '?queryEarned=true' : ''}`;

  try {
    let result = await API.get('fuelapi', url);

    return result.length > 0 ? result[0] : {};
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch article' };
  }
};

const scrapeEarnedContent = async (articleURL, companyId, companyName, source) => {
  let url = `/api/companies/${companyId}/earnedContent`;

  try {
    let result = await API.put('fuelapi', url, { body: { url: articleURL, companyName, source } });

    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to queue article' };
  }
};

const scrapeOwnedContent = async (articleURL, companyId, companyName, source) => {
  let url = `/api/companies/${companyId}/ownedContent`;

  try {
    let result = await API.put('fuelapi', url, { body: { url: articleURL, companyName, source } });

    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to queue article' };
  }
};

const deleteEarnedContent = async (articleId, companyId, companyName) => {
  let url = `/api/companies/${companyId}/earnedContent/${articleId}`;

  try {
    let result = await API.del('fuelapi', url);

    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to queue article' };
  }
};

const sdk = {
  getArticleDetails,
  scrapeEarnedContent,
  deleteEarnedContent,
  scrapeOwnedContent,
};

export default sdk;
