import { create } from 'zustand';
import prefs from 'sdk/Preferences';
import sdk from 'sdk/Accounts';

const FUEL_FREE = 'fuel-free';
const FUEL_INDIVIDUAL_PLUS = 'fuel-individual-plus';
const FUEL_INDIVIDUAL = 'fuel-individual';
const FUEL_TEAMS = 'fuel-teams';

const GROUP_UNLIMITED = 'unlimited';
const GROUP_INTERNAL = 'internal';
const GROUP_ADMIN = 'admin';
const GROUP_MESSENGERS = 'messengers';
const GROUP_CHANNEL_CREATE = 'channels-create';
const GROUP_IDEAGEN = 'idea-gen';
const GROUP_BETA = 'beta';

const PREFERENCES = {
  PREF_ACTIVE_ACCOUNT: 'active_account',
  PREF_DASHBOARD_SETTINGS: 'dashboard_settings',
  PREF_COMPANY_POPOVER: 'company_popover',
  PREF_WATCHLIST_PAGE: 'watchlist_page',
  PREF_WATCHLIST_EMAIL: 'watchlist_notifications',
};

const usePersonalAccount = create((set, get) => ({
  user: null,
  cognito: null,
  loading: true,
  account: null,
  groups: [],
  err: null,
  PREFERENCES: PREFERENCES,
  loadingPrefs: true,
  preferences: null,
  initialStoreSetup: user => {
    const userEmail = user?.attributes?.email;
    if (userEmail) {
      set({ user: user });
      get().fetchAccount(user);
      get().fetchPreferences();
    } else {
      set({ account: null, groups: [] });
    }
  },
  fetchAccount: async creator => {
    let result = await sdk.getPersonalAccount(creator.attributes.email);
    set({ groups: creator.groups });
    console.log('groups: ', creator.groups);
    if (!result.errors) set({ account: result.items && result.items.length === 0 ? {} : result.items[0] });
    set({ err: result.errors, cognito: creator, loading: false });
  },
  fetchPreferences: async () => {
    const userEmail = get().user.attributes.email;
    set({ loadingPrefs: true });
    try {
      let presult = await prefs.getUserPreferences(userEmail);
      let items = presult.items
        ? presult.items.reduce((obj, item) => {
            obj[item.key] = { ...item, details: JSON.parse(item.details) };
            return obj;
          }, {})
        : {};
      if (!presult.errors) set({ preferences: items });
    } catch (e) {
      console.log('failed to load personal preferences');
    }
    set({ loadingPrefs: false });
  },
  reload: async client => {
    const user = client || get().user;
    await get().fetchAccount(user);
  },
  hasGroup: grp => {
    return get().groups.includes(grp);
  },
  checkAccountPaid: () => {
    const account = get().account;
    return account && account.plan && account.plan.id !== FUEL_FREE;
  },
  hasIndividualPlan: () => {
    return get().hasGroup(FUEL_INDIVIDUAL_PLUS) || get().hasGroup(FUEL_INDIVIDUAL);
  },
  hasPaidContent: () => {
    return (
      get().checkAccountPaid() ||
      get().hasGroup(FUEL_TEAMS) ||
      get().hasGroup(FUEL_INDIVIDUAL_PLUS) ||
      get().hasGroup(FUEL_INDIVIDUAL) ||
      get().hasGroup(GROUP_UNLIMITED) ||
      get().hasGroup(GROUP_ADMIN) ||
      get().hasGroup(GROUP_INTERNAL)
    );
  },
  hasMessengersGroup: () => {
    return get().hasGroup(GROUP_MESSENGERS);
  },
  hasCreateChannelsGroup: () => {
    return get().hasGroup(GROUP_CHANNEL_CREATE);
  },
  hasUnlimitedGroup: () => {
    return get().hasGroup(GROUP_UNLIMITED);
  },
  hasAdminGroup: () => {
    return get().hasGroup(GROUP_ADMIN);
  },
  hasInternalGroup: () => {
    return get().hasGroup(GROUP_INTERNAL);
  },
  hasIdeaGenGroup: () => {
    return (
      get().hasGroup(GROUP_INTERNAL) ||
      get().hasGroup(GROUP_UNLIMITED) ||
      get().hasGroup(GROUP_ADMIN) ||
      get().hasGroup(GROUP_IDEAGEN)
    );
  },
  hasBetaGroup: () => {
    return get().hasGroup(GROUP_BETA);
  },
  isLoggedIn: () => {
    return !!get().cognito;
  },
  updatePreference: async (key, value, details) => {
    set({ loadingPrefs: true });
    let currentPref = get().preferences[key] || { owner: get().user.attributes.email, key };
    delete currentPref['createdAt'];
    delete currentPref['updatedAt'];
    currentPref['value'] = value;
    currentPref['details'] = JSON.stringify(details ? details : {});
    try {
      let result = await prefs.upsertPreference(currentPref);
      if (!result.errors) {
        set({ preferences: { ...get().preferences, [key]: { ...currentPref, details } } });
      } else {
        console.log('failed to update preference', result.errors);
      }
    } catch (e) {
      console.log('failed to update preference', e);
    }
    set({ loadingPrefs: false });
  },
  hasAccessToAdvancedTool: () => {
    //prevent access to social Ideas advanced tool
    return (
      get().hasGroup(FUEL_INDIVIDUAL_PLUS) ||
      get().hasGroup(FUEL_TEAMS) ||
      get().hasGroup(GROUP_UNLIMITED) ||
      get().hasGroup(GROUP_ADMIN) ||
      get().hasGroup(GROUP_INTERNAL)
    );
  },
}));

export default usePersonalAccount;
