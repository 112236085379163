import { API, graphqlOperation } from '@aws-amplify/api';
import { listPlans } from 'graphql/queries';
import Auth from '@aws-amplify/auth';

const refreshToken = async callback => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
    if (err) throw err;
    callback();
  });
};

const getVisibleOrgPlans = async () => {
  try {
    let plans = await API.graphql(
      graphqlOperation(listPlans, { filter: { listed: { eq: true }, planType: { eq: 'ORGANIZATION' } } })
    );
    if (plans.errors) {
      return plans;
    }
    let result = { items: plans.data.listPlans.items.sort((a, b) => (a.order > b.order ? 1 : -1)) };
    return result;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch plans'] };
  }
};

const getVisiblePlans = async () => {
  try {
    let plans = await API.graphql(graphqlOperation(listPlans, { filter: { listed: { eq: true } } }));
    if (plans.errors) {
      return plans;
    }
    let result = { items: plans.data.listPlans.items.sort((a, b) => (a.order > b.order ? 1 : -1)) };
    return result;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch plans'] };
  }
};

// Get or create free personal account
const createPersonalAccount = async referralId => {
  /*  
  const stripePriceId = req.query['stripePriceId'];
  const fuelPriceId = req.query['fuelPriceId'];
  const priceQty = req.query['priceQty'];
  const accountId = req.query['accountId']; 
  */
  let url = `/api/billing/create?referralId=${referralId}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch account' };
  }
};

// Get Stripe checkout session for an organization account
const getOrgCheckoutSession = async (
  stripePriceId,
  fuelPriceId,
  priceQty,
  accountId,
  referralId,
  referralAccountId
) => {
  /*  
  const stripePriceId = req.query['stripePriceId'];
  const fuelPriceId = req.query['fuelPriceId'];
  const priceQty = req.query['priceQty'];
  const accountId = req.query['accountId']; 
  */
  let url = `/api/billing/checkout/org?${
    referralId ? `referralId=${referralId}&referralAccountId=${referralAccountId}&` : ''
  }stripePriceId=${stripePriceId}&fuelPriceId=${fuelPriceId}&priceQty=${priceQty}&accountId=${accountId}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch session' };
  }
};

// Get Stripe checkout session
const getCheckoutSession = async (stripePriceId, fuelPriceId, referralId) => {
  let url = `/api/billing/checkout?${
    referralId ? `referralId=${referralId}&` : ''
  }stripePriceId=${stripePriceId}&fuelPriceId=${fuelPriceId}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch session' };
  }
};

// Create Stripe portal session to manage billing
const getPortalSession = async () => {
  let url = `/api/billing/portal`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch session' };
  }
};

// Create Stripe portal session to manage billing for an organization account
const getOrgPortalSession = async accountId => {
  let url = `/api/billing/portal/org?accountId=${accountId}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch session' };
  }
};

const sdk = {
  getCheckoutSession,
  getOrgCheckoutSession,
  getVisiblePlans,
  getPortalSession,
  getVisibleOrgPlans,
  refreshToken,
  getOrgPortalSession,
  createPersonalAccount,
};

export default sdk;
