import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import Icon, { FacebookFilled, LinkedinFilled } from '@ant-design/icons';
import useVersion from 'store/Version';
import Content from 'containers/Content';
import Logo from 'images/YorkIE_Fuel-light-mint.svg';
import './Footer.css';
import TwitterLogoGray from 'images/twitter_bg_white_color_black.svg';

const TwitterGrayIcon = () => {
  return <img src={TwitterLogoGray} alt="X (formerly Twitter)" style={{ width: '12.5px' }} />;
};

const updater = {
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  borderBottom: '1px dashed',
  padding: '0',
};

const Footer = () => {
  const hasUpdate = useVersion(state => state.updateAvailable);

  return (
    <div className="footer hide-on-print">
      <Content>
        <div className="footer-content">
          <div className="logo">
            <Space direction="vertical">
              <Link to={`/`}>
                <img className="fuel-logo" alt="large fuel logo" src={Logo} style={{ width: '224px' }} />
              </Link>
              <div className="social-links">
                <Space size="large">
                  <a href="https://www.facebook.com/yorkgrowth" target="_blank" rel="noreferrer noopener">
                    <FacebookFilled />
                  </a>
                  <a href="https://twitter.com/yorkgrowth" target="_blank" rel="noreferrer noopener">
                    <Icon component={TwitterGrayIcon} />
                  </a>
                  <a href="https://www.linkedin.com/company/york-ie" target="_blank" rel="noreferrer noopener">
                    <LinkedinFilled />
                  </a>
                </Space>
              </div>
            </Space>
          </div>
          <div className="made-by">
            <div>
              <div className="msg">
                Made with &hearts; by{' '}
                <a href="https://york.ie" target="_blank" rel="noreferrer noopener">
                  York IE&trade;
                </a>
              </div>
              <div className="tm">
                Fuel&trade; - &copy; {new Date().getFullYear()}
                {process.env.REACT_APP_VERSION ? <> \\ v{process.env.REACT_APP_VERSION}</> : ''}
                {hasUpdate && (
                  <>
                    {' '}
                    -{' '}
                    <button className="button-link" style={updater} onClick={() => window.location.reload()}>
                      Update Now
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="resources">
            <div>
              <ul>
                <li>Resources</li>
                <li>
                  <Link to="/plans">Plans</Link>
                </li>
                <li>
                  <Link to="/plans#faq">FAQ</Link>
                </li>
                <li>
                  <a href="https://knowledge.york.ie" target="_blank" rel="noreferrer noopener">
                    Help
                  </a>
                </li>
                <li>
                  <a href="https://knowledge.york.ie/mobile-install" target="_blank" rel="noreferrer noopener">
                    Mobile App
                  </a>
                </li>
                <li>
                  <a
                    href="https://knowledge.york.ie/york-ie-fuel-platform-release-notes"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Release Notes
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>Company</li>
                <li>
                  <a href="https://york.ie/what-we-do/" target="_blank" rel="noreferrer noopener">
                    About
                  </a>
                </li>
                <li>
                  <a href="/support" target="_blank" rel="noreferrer noopener">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="https://york.ie/legal/terms-conditions/" target="_blank" rel="noreferrer noopener">
                    Terms
                  </a>
                </li>
                <li>
                  <a href="https://york.ie/legal/privacy-policy/" target="_blank" rel="noreferrer noopener">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default Footer;
