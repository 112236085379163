import React, { useState, useEffect } from 'react';

import Welcome from './Welcome';
import NewsIntro from './NewsIntro';
import Referrals from './Referrals';

let timeoutTrack = null;
const Messaging = ({ workflow, setReferral }) => {
  const [unload, setUnload] = useState(false);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (current) {
      setUnload(true);
      clearTimeout(timeoutTrack);
      timeoutTrack = setTimeout(() => {
        setCurrent(workflow);
        setUnload(false);
      }, 500);
    } else {
      setCurrent(workflow);
    }
  }, [workflow, current]);

  const render = () => {
    switch (current) {
      case 'news':
        return <NewsIntro workflow={current} isVisible={!unload} />;
      case 'referral':
        return <Referrals workflow={current} isVisible={!unload} setReferral={setReferral} />;
      default:
        return <Welcome workflow={current} isVisible={!unload} />;
    }
  };

  return render();
};

export default Messaging;
