import React from 'react';
import { withRouter } from 'react-router-dom';
import Amplify, { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

import config from 'aws-exports';
import Splash from 'components/Splash';
import Updater from 'Updater';
import Stats, { events } from 'Stats';

// v2
import AppV2 from 'AppV2';
import ErrorBoundary from 'ErrorBoundary';

// Scripts
import GoogleTagManager from 'components/google-tag-manager';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const isStage = window.location.hostname === 'stage.fuel.york.ie';

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn, stageRedirectSignIn] = config.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut, stageRedirectSignOut] = config.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...config,

  // update oauth sso based on environment
  oauth: {
    ...config.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : isStage ? stageRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : isStage ? stageRedirectSignOut : productionRedirectSignOut,
  },
};
console.log('updatedAwsConfig', updatedAwsConfig);
Amplify.configure(updatedAwsConfig);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: {
        isLoggedIn: false,
        loading: true,
      },
      redirect: '',
      user: {},
      updateAvailable: false,
      installingUpdate: false,
      waitingServiceWorker: null,
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      Stats.pageView();
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'signUp') {
        console.log('user signed up');
        var _hsq = (window._hsq = window._hsq || []);
        const userEmail = data?.user?.username;
        if (userEmail) {
          console.log('set signup and referrer');
          _hsq.push([
            'identify',
            {
              email: userEmail,
              fuel_signup_date: new Date().toISOString(),
              fuel_signup_referral: document?.referrer ?? 'unknown',
              fuel_version_number: process.env.REACT_APP_VERSION,
            },
          ]);
        }
        Stats.customEvent(events.USER_SIGNUP, { email: userEmail });
      }
      if (event === 'customOAuthState' && typeof data === 'string') {
        try {
          const { backURL } = JSON.parse(data);
          if (backURL) {
            window.location = window.location.origin + backURL;
          }
        } catch {}
      }
    });
    this.handleUserSignIn();

    // new relic
    Stats.pageView();
  }

  webExtensionCallback = async () => {
    const isLoggedIn = this.state.authState.isLoggedIn;
    const extensionId = process.env.REACT_EXTENSION_ID || 'ppkafkcbmoppgdjeoeeagbeoiibdgemd';
    const payload = {
      intent: isLoggedIn ? 'login' : 'logout',
      data: {
        session: isLoggedIn ? await Auth.currentSession() : null,
      },
    };
    console.log('sending message to extension if installed...');
    if (window?.chrome?.runtime) {
      window.chrome.runtime.sendMessage(extensionId, payload, function(response) {
        console.log('extension response =>', response);
      });
    } else {
      console.warn('invalid chrome runtime environment');
    }
  };

  handleUserSignIn = redirect => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then(user => {
        console.log('user', user);
        const grps =
          'cognito:groups' in user.signInUserSession.accessToken.payload
            ? user.signInUserSession.accessToken.payload['cognito:groups']
            : [];

        // push data layer variables for GA
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user.username,
          fuel_user_id: user.username,
          fuel_version: process.env.REACT_APP_VERSION,
        });

        Amplify.configure({
          // Set API to use id token. This will ensure email is passed within the token
          API: {
            graphql_headers: async () => ({
              Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
            }),
          },
        });

        // set hubspot identity
        const userEmail = user.attributes['email'];
        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          'identify',
          {
            email: userEmail,
            fuel_version_number: process.env.REACT_APP_VERSION,
            fuel_verified: 'true',
            fuel_teams_user: grps.includes('fuel-teams'),
          },
        ]);

        // set grafana identity
        // set user metadata, to be included with every event. All properties optional
        if (window.faro && window.location.hostname !== 'localhost') {
          try {
            let attributes = {};
            if (grps.includes('fuel-teams')) {
              attributes['plus_user'] = 'true';
            }
            if (grps.includes('fuel-plus')) {
              attributes['plus_user'] = 'true';
            }
            if (grps.includes('fuel-trial')) {
              attributes['trial_user'] = 'true';
            }
            if (grps.includes('admin')) {
              attributes['admin_user'] = 'true';
            }
            if (grps.includes('unlimited')) {
              attributes['unlimited_user'] = 'true';
            }
            if (grps.includes('internal')) {
              attributes['internal_user'] = 'true';
            }
            if (grps.includes('beta')) {
              attributes['beta_user'] = 'true';
            }

            window.faro.api.setUser({
              id: user.username,
              attributes: attributes,
            });
          } catch (e) {
            console.log('error setting faro user', e);
          }
        }

        // set local state
        this.setState({
          authState: { isLoggedIn: true, loading: false },
          redirect: redirect,
          user: {
            username: user.username,
            attributes: user.attributes,
            groups: grps,
            signInUserSession: user.signInUserSession,
          },
        });

        this.webExtensionCallback();
      })
      .catch(err => {
        this.setState({
          authState: { isLoggedIn: false, loading: false },
        });
        console.log('User not signed in', err);
      });
  };

  handleUserSignOut = async () => {
    try {
      await Auth.signOut({
        global: true,
      });

      this.setState({
        authState: { isLoggedIn: false, loading: false },
        user: {},
      });
      this.webExtensionCallback();
      this.props.history.push('/');
    } catch (e) {
      console.log(e);
    }
  };

  handleUpdateAvailable = isUpdateAvailable => {
    if (isUpdateAvailable) {
      if ('serviceWorker' in navigator) {
        console.log('doReload - serviceWorker', navigator.serviceWorker);
        navigator.serviceWorker.ready
          .then(registration => {
            console.log('doReload - registration', registration);
            registration.unregister().then(() => {
              window.location.reload();
            });
          })
          .catch(() => window.location.reload());
      } else {
        console.log('doReload - no service worker in navigator, reloading');
        window.location.reload();
      }
    }
  };

  render() {
    var authProps = {
      isLoggedIn: this.state.authState.isLoggedIn,
      loadingAuth: this.state.authState.loading,
      user: this.state.user,
      onUserSignIn: this.handleUserSignIn,
      onUserSignOut: this.handleUserSignOut,
      redirect: this.state.redirect,
    };

    if (authProps.loadingAuth) {
      return <Splash />;
    }

    return (
      <ErrorBoundary>
        <Updater updateAvailable={this.handleUpdateAvailable} />
        <AppV2 redirect={this.state.redirect} authProps={authProps} />
        <GoogleTagManager gtmId="GTM-TNHNZ2B" />
      </ErrorBoundary>
    );
  }
}

export default withRouter(App);
