import { useEffect } from 'react';
let timeout = null;

const useFocusEl = (ref, field, visibleFilterField, key) => {
  useEffect(() => {
    if (ref?.current && field === visibleFilterField) {
      if (timeout) timeout = null;
      timeout = setTimeout(() => {
        ref.current.focus();
      }, 200);
    }
  }, [ref, visibleFilterField, field, key]);
  return null;
};

export default useFocusEl;
