import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { accountLogoUrl } from 'utils';
import sdk from 'sdk/Accounts';
import Fade from 'components/utility/Fade';
import Welcome from './Welcome';

const { Title } = Typography;

const Referrals = ({ isVisible, workflow, setReferral }) => {
  const [loading, setLoading] = useState(true);
  const [referral, setReferralObj] = useState(null);
  const location = useLocation();
  const { code } = useParams();

  const fetchReferral = async rc => {
    setLoading(true);
    let result = await sdk.getReferralByCode(rc);
    result.account.limits = result?.account?.limits ? JSON.parse(result?.account?.limits) : {};
    setReferralObj(result);
    setReferral(result);
    setLoading(false);
  };

  useEffect(() => {
    if (!code) {
      const searchParams = new URLSearchParams(location.search);
      const queryCode = searchParams.get('code');
      fetchReferral(queryCode);
    } else {
      fetchReferral(code);
    }
    // eslint-disable-next-line
  }, [code]);

  return (
    <div style={{ display: 'flex', gap: '100px', flexDirection: 'column', maxWidth: '500px' }}>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '250px',
            fontSize: '24px',
            color: '#EEE',
          }}
        >
          <LoadingOutlined />
        </div>
      )}
      {!loading && (
        <Fade>
          <div className="referral-info" style={{ display: 'flex', gap: '50px', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '15px', color: '#EEE' }}>
              {referral?.account?.logo && (
                <div
                  style={{
                    height: '96px',
                    width: '96px',
                    flexShrink: 0,
                    padding: '10px',
                    backgroundColor: '#FFF',
                    borderRadius: '4px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    color: '#EEE',
                  }}
                >
                  <img
                    src={accountLogoUrl(referral?.account?.logo)}
                    alt={referral?.account?.name}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              )}
              <div>
                <h3 style={{ color: '#EEE' }}>{referral?.account?.name}</h3>
                <Title
                  level={5}
                  className="mute-title fuel-title-text"
                  style={{ color: 'var(--brand)', position: 'relative' }}
                >
                  {referral?.referralType || 'Ambassador Referral'}
                </Title>
              </div>
            </div>
            <div
              style={{
                color: '#EEE',
                fontSize: '20px',
                padding: '20px',
                backgroundColor: 'rgba(255,255,255,.05)',
                borderRadius: '4px',
                borderLeft: '5px solid var(--brand)',
              }}
            >
              {referral?.description}
            </div>
          </div>
        </Fade>
      )}
      <Welcome isVisible={isVisible} />
    </div>
  );
};

export default Referrals;
