import React from 'react';
import { Spin, Space } from 'antd';

import './Splash.css';

const Splash = ({ styles }) => {
  return (
    <div className="splash" style={styles}>
      <Space size="middle">
        <Spin size="large" />
        Play the long game.
      </Space>
    </div>
  );
};

export default Splash;
