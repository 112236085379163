/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "fuelapi",
            "endpoint": "https://uxwkft4m2g.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4oucdmtnizgf7fpyaosx4ckyre.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-s6niiwljg5fqpgxiruo65bz3fi",
    "aws_cognito_identity_pool_id": "us-east-2:fdea6edb-4b76-4fdd-9484-e81cb45419ad",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_KzRH5iGRO",
    "aws_user_pools_web_client_id": "1m7967s8d88qben511lguhnmne",
    "oauth": {
        "domain": "go-sso-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://fuel.york.ie/,https://stage.fuel.york.ie/",
        "redirectSignOut": "http://localhost:3000/,https://fuel.york.ie/,https://stage.fuel.york.ie/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "fuel-front-end-20191029134026-hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d2nlndpide5w5l.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "admin-data-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "email-settings-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "slackauth-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "slackdata-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "slacktoken-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "user-settings-prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "articlesend-prod",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
