import { API, graphqlOperation } from '@aws-amplify/api';
import {
  createChannelInvites as createChannelInvitesGQL,
  updateChannelInvites,
  deleteChannelInvites,
} from 'graphql/mutations';
import { channelsByUser, channelMembers } from 'graphql/queries';

const createChannelInvites = async (owner, channelID, inviteID) => {
  try {
    let newInvite = await API.graphql(
      graphqlOperation(createChannelInvitesGQL, {
        input: { owner, channelID, inviteID },
      })
    );

    if (newInvite.errors) {
      return newInvite;
    }
    return newInvite.data.createChannelInvites;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed creating channel invite'] };
  }
};

const deleteInvite = async inviteID => {
  try {
    let deleted = await API.graphql(
      graphqlOperation(deleteChannelInvites, {
        input: { id: inviteID },
      })
    );

    deleted = deleted.data.deleteChannelInvites;
    return deleted;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to delete channel invite'] };
  }
};

const updateInvite = async (inviteID, quebicUserID) => {
  try {
    let updated = await API.graphql(
      graphqlOperation(updateChannelInvites, {
        input: { id: inviteID, quebicUserID: quebicUserID },
      })
    );

    updated = updated.data.updateChannelInvites;
    return updated;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to update channel invite'] };
  }
};

const fetchInvites = async userSub => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(
        graphqlOperation(channelsByUser, {
          nextToken,
          owner: userSub,
        })
      );
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.channelsByUser.items)));
      nextToken = list.data.channelsByUser.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch channels by user'] };
  }
};

const fetchChannelMembers = async channelID => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(
        graphqlOperation(channelMembers, {
          nextToken,
          channelID,
        })
      );
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.channelMembers.items)));
      nextToken = list.data.channelMembers.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch channels by channelID'] };
  }
};

/*
  verb: putObject or getObject
*/
const getPresignedURL = async (verb, channelId, fileNameOrKey, fileType) => {
  //app.get('/api/messages/:channelId/attachments/putObject', messages.getPresignedPutObject);
  //app.get('/api/messages/:channelId/attachments/getObject', messages.getPresignedGetObject);
  let url = `/api/messages/${channelId}/attachments/${verb}`;

  let params = { fileNameOrKey, fileType };
  try {
    let result = await API.get('fuelapi', url, { queryStringParameters: params });
    return result;
  } catch (e) {
    console.log('error getting object URL', e);
    return { error: 'error getting object URL' };
  }
};

const notifyUsers = async users => {
  try {
    // dedupe
    let filteredUsers = [...new Set(users)];
    let result = await API.post('fuelapi', '/api/messengers/notify', { body: { users: filteredUsers } });
    return result;
  } catch (e) {
    console.log('error sending notification', e);
    return { error: 'error sending notifications' };
  }
};

const sdk = {
  createChannelInvites,
  fetchInvites,
  fetchChannelMembers,
  getPresignedURL,
  deleteInvite,
  updateInvite,
  notifyUsers,
};
export default sdk;
