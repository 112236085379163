import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Typography, Button, Space, Select, Switch, notification, message } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { CheckOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import sdk from 'sdk/Accounts';
import listSdk from 'sdk/Watchlists';
import Dots from 'pages/user/marketing/Dots';
import { shallow } from 'zustand/shallow';
import useAccounts from 'store/Accounts';
import usePersonalAccount from 'store/PersonalAccount';
import Search from 'components/Search';
import { CompanyTag } from 'components/general/Tag';
import Stats from 'Stats';
import './FuelModal.css';

const watchlistContexts = [
  'Competitors',
  'Customers',
  'Leads',
  'Strategic Business Development',
  'Investment Portfolio',
  'Investors',
  'Location',
];

const { Title, Paragraph, Text } = Typography;

const NewModal = ({ loading, children }) => {
  return (
    <div className="fuel-modal">
      <div className="fuel-modal-msg">
        <Title level={4} className="mute-title fuel-title-text" style={{ color: 'var(--brand)', position: 'relative' }}>
          <div
            className="pattern"
            style={{ height: '160px', width: '100px', position: 'absolute', left: '-20px', top: '-20px' }}
          >
            <Dots />
          </div>
          Watchlists
        </Title>
        <Space direction="vertical" size="small">
          <Paragraph className="font-16" style={{ color: '#FFF' }}>
            You can use watchlists to keep track of companies that matter to you:
          </Paragraph>

          <Space direction="vertical" size="small" style={{ padding: '10px 30px' }}>
            {watchlistContexts.map((m, ms) => (
              <Space key={`uses-${ms}`} align="start">
                <CheckOutlined style={{ color: 'var(--brand)', fontSize: '16px' }} />{' '}
                <Text style={{ color: '#FFF' }}>{m}</Text>
              </Space>
            ))}
          </Space>
        </Space>
      </div>
      <div className="fuel-modal-content">{children}</div>
    </div>
  );
};

const NewList = ({
  show,
  setShow,
  overrideAccount,
  setPrivacy = false,
  companies,
  name,
  addToExistingWatchlist = false,
  watchlistId,
}) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({
    account_privacy: true,
    items: companies ? companies.slice(0, 500) : [],
    name: name || '',
    company: {},
  });
  const [activeTeams, setActiveTeams] = useState([]);
  const { activeAccount, teams, isOwner, loadingTeams, refreshWatchlists } = useAccounts(
    state => ({
      activeAccount: state.activeAccount,
      teams: state.teams,
      isOwner: state.isOwner,
      loadingTeams: state.loadingTeams,
      refreshWatchlists: state.refreshWatchlists,
    }),
    shallow
  );
  const { hasAdminGroup, account: personalAccount, groups } = usePersonalAccount(state => ({
    hasAdminGroup: state.hasAdminGroup,
    account: state.account,
    groups: state.groups,
  }));
  const history = useHistory();
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    setList({ ...list, name: name, items: companies ? companies.slice(0, 500) : [] });
    // eslint-disable-next-line
  }, [name, companies]);

  useEffect(() => {
    getTeams();

    // eslint-disable-next-line
  }, [activeAccount]);

  useEffect(() => {
    setList({ ...list, account_privacy: setPrivacy });
    // eslint-disable-next-line
  }, [setPrivacy]);

  const getTeams = async () => {
    if (activeAccount?.account?.id) {
      let result = await sdk.getTeams(activeAccount.account.id);
      if (!result.errors) {
        setActiveTeams(result.items);
      } else {
        // set error
      }
    } else {
      setActiveTeams([]);
    }
  };

  const onCancel = () => {
    setList({});
    setShow(null);
  };

  const addCompanyToExistingWatchlist = async formValues => {
    setLoading(true);

    let result = await listSdk.getList(watchlistId);
    if (result.error || !result.items) {
      message.error('Failed to update Watchlist');
      setLoading(false);
      return;
    }

    //new companies to add
    const existingOrgs = result.items.map(c => c.uuid);
    const newCompanies = [
      ...list.items
        .filter(company => !existingOrgs.includes(company.uuid))
        .map(company => (company.added ? company : { ...company, added: new Date().toISOString() })),
    ];
    const newItems = result.items.concat(newCompanies);
    const limitExceeded = listSdk.checkCompaniesPerWatchlistLimit(
      activeAccount,
      groups,
      personalAccount,
      newItems.length
    );
    if (limitExceeded) {
      setLoading(false);
      setShow(false);
      return;
    }
    let updated = await listSdk.updateList({
      id: watchlistId,
      account_privacy: formValues.account_privacy ? 'PRIVATE' : 'PUBLIC',
      name: formValues.name,
      items: [...newItems],
      accountID: activeAccount?.account?.id,
      scope: formValues?.scope,
      company: JSON.stringify(formValues.company),
    });

    if (updated.error) {
      message.error('Failed to update Watchlist');
      setLoading(false);
      return;
    }
    history.push(`/research/watchlists/${watchlistId}`);
    setLoading(false);
    setShow(false);
  };

  const onFinish = async values => {
    setLoading(true);
    if (addToExistingWatchlist) {
      addCompanyToExistingWatchlist(values);
    } else {
      await createList({ ...values, accountID: overrideAccount ? null : activeAccount?.account?.id });
    }
    setLoading(false);
  };

  const createList = async input => {
    // return;
    setLoading(true);
    try {
      //{ name, items, accountID }
      let newList = { ...input, ...list };
      console.log('newList: ', newList);
      // return;
      let result = await listSdk.createList(newList);
      if (result.error || result.errors) {
        if (result.type && result.type === 'limit') {
          notification.warning({
            key: 'notification-upgrade',
            message: `Limits Exceeded`,
            description: (
              <div>
                {result.error} {!!input.accountID ? 'Upgrade Account.' : 'Upgrade your personal account.'}
                <br />
                <br />
                <Button
                  onClick={() => {
                    history.push(!!input.accountID ? '/accounts/upgrade' : '/account#billing');
                    notification.destroy('notification-upgrade');
                  }}
                >
                  {!!input.accountID ? 'Upgrade' : 'Manage Plans'}
                </Button>
              </div>
            ),
            placement: 'topRight',
            duration: 20,
          });
          Stats.pageClick('fuel.watchlistLimit');
        } else {
          message.error(`Failed to create Watchlist. ${result?.error || ''}`);
        }
        setLoading(false);
        setShow(false);
        return;
      }
      Stats.pageClick('fuel.watchlistCreate');
      refreshWatchlists();
      history.push(`/research/watchlists/${result.list.id}`);
    } catch (e) {
      console.log('exception', e);
      message.error(`Failed to create Watchlist. ${e?.data?.error || ''}`);
    }
    setLoading(false);
    setShow(false);
  };

  const addItem = option => {
    const items = list.items || [];
    const item = {
      name: option.label.props['data-value'],
      type: 'organization',
      uuid: option.value,
      added: new Date().toISOString(),
    };
    if (items.findIndex(item => item.uuid === option.value) > -1) {
      // do nothing
    } else {
      let update = { ...list, items: [...items, item] };
      setList(update);
    }
  };

  const handleSelect = async item => {
    const company = { id: item.data.uuid, name: item.data.organization.name };
    setList({ ...list, company: company });
  };

  const removeItem = uuid => {
    let items = list.items || [];
    let update = { ...list, items: [...items.filter(i => i.uuid !== uuid)] };
    setList(update);
  };

  return (
    <Modal
      open={show}
      width={'986px'}
      footer={null}
      onCancel={onCancel}
      modalRender={modal => <NewModal>{modal}</NewModal>}
      maskClosable={false}
      style={{
        top: 40,
      }}
      title={
        addToExistingWatchlist ? (
          <span>Adding companies to {name}</span>
        ) : (
          <span>
            New Watchlist -{' '}
            <Text style={{ color: 'var(--brand)' }}>
              {!overrideAccount && !!activeAccount ? activeAccount.account.name : 'Personal Account'}
            </Text>
          </span>
        )
      }
    >
      <Form
        layout="vertical"
        onValuesChange={e => {
          let update = { ...list, ...e };
          setList(update);
        }}
        fields={[
          { name: 'name', value: list.name || '' },
          { name: 'companies', value: list.items || [] },
          { name: 'account_privacy', value: list.account_privacy },
        ]}
        requiredMark={false}
        onFinish={onFinish}
      >
        <div>
          <Form.Item label={<Text style={{ fontSize: '16px' }}>Name</Text>} name="name">
            <Input data-id="watchlist-name-input" placeholder="Watchlist name (default: Untitled)" size="large" />
          </Form.Item>
          <Form.Item label={<Text style={{ fontSize: '16px' }}>Company</Text>} name="company">
            <Search
              prefix={true}
              handleSelect={handleSelect}
              bordered={true}
              style={{ width: '100%' }}
              size="large"
              placeholder="the company this watchlist scope is for"
              resetOnSelection={false}
              doNotResetOnBlur={true}
              handleSearch={value => setList({ ...list, company: { ...list.company, name: value } })}
              data-id="blog-company-search-input"
              searchValue={list.company?.name}
              doReset={!!!list.company?.name ? true : false}
              notFoundContent={
                <div style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.45)' }}>
                  Not Found! Click{' '}
                  <Link to="/add-company" target="_blank" rel="noopener noreferrer">
                    Here
                  </Link>{' '}
                  to add it
                </div>
              }
            />
          </Form.Item>
          <Form.Item label={<Text style={{ fontSize: '16px' }}>Scope</Text>} name="scope">
            <Select
              size="large"
              showSearch
              placeholder="The context for which this watchlist is being created"
              value={list?.scope ?? undefined}
              optionFilterProp="children"
              onChange={value => setList({ ...list, scope: value })}
              options={watchlistContexts.map(item => ({ value: item, label: item }))}
            />
          </Form.Item>
          {!overrideAccount && activeTeams.length > 0 && (
            <Form.Item label={<Text style={{ fontSize: '16px' }}>Teams</Text>} name="teamIDs">
              <Select
                style={{ width: '100%', padding: '1px 4px' }}
                notFoundContent={null}
                mode="multiple"
                size="large"
                placeholder={`Select a team from ${activeAccount?.account?.name}.`}
                data-id="watchlist-team-select-input"
              >
                {!loadingTeams &&
                  activeTeams
                    .filter(t => isOwner || teams.includes(t.id))
                    .map((t, i) => (
                      <Select.Option key={t.id} value={t.id} data-id={`watchlist-team-name-${i}`}>
                        {t.name}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          )}

          {!overrideAccount && !!activeAccount && hasAdminGroup() && (
            <Form.Item
              name="account_privacy"
              tooltip="Set account privacy so only you can see and edit this Watchlist"
              label={<Text style={{ fontSize: '16px' }}>Private Watchlist</Text>}
              valuePropName="checked"
            >
              <Switch size="large" />
            </Form.Item>
          )}
          <Form.Item label={<Text style={{ fontSize: '16px' }}>Companies</Text>} name="items">
            <Search
              handleSelect={addItem}
              bordered={true}
              style={{ width: '100%' }}
              size="large"
              placeholder="Add a few companies to your list."
              resetOnSelection={true}
              data-id="watchlist-company-search-input"
            />
          </Form.Item>
          <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', overflowY: 'auto', maxHeight: '400px' }}>
              {list.items &&
                list.items
                  .slice(0, showAll ? list.items.length : 8)
                  .map((i, id) => (
                    <CompanyTag
                      key={`ctag-${i.uuid}`}
                      onDismiss={() => removeItem(i.uuid)}
                      dismissable={true}
                      id={i.uuid}
                      url={i.logo_url}
                      name={i.name}
                    />
                  ))}
            </div>
            {list.items && list.items.length > 8 && (
              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  onClick={() => setShowAll(!showAll)}
                  type="text"
                  style={{ fontWeight: '500', color: 'var(--secondary)', textTransform: 'uppercase' }}
                >
                  {!showAll ? (
                    <>
                      {list.items.length - 8} more <ArrowDownOutlined />
                    </>
                  ) : (
                    <>
                      Hide <ArrowUpOutlined />
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div>
          <Form.Item style={{ margin: '0px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ minWidth: '200px' }}>
                {list && list.items && list.items.length > 0 && (
                  <div style={{ fontSize: '14px', color: 'rgba(0,0,0,.65)' }}>{list.items.length} companies</div>
                )}
              </div>
              <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button
                  data-companies-length={list?.items?.length}
                  data-id="create-watchlist-submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  size="large"
                >
                  {addToExistingWatchlist ? 'Add Companies' : 'Create Watchlist'}
                </Button>
                <Button type="text" onClick={onCancel} disabled={loading} size="large">
                  Cancel
                </Button>
              </Space>
            </div>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default NewList;
