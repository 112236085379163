import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result, Image, Alert } from 'antd';
import Stats from 'Stats';

const doReload = () => {
  if ('serviceWorker' in navigator) {
    console.log('doReload - serviceWorker', navigator.serviceWorker);
    navigator.serviceWorker.ready
      .then(registration => {
        console.log('doReload - registration', registration);
        registration.unregister().then(() => {
          window.location.reload();
        });
      })
      .catch(() => window.location.reload());
  } else {
    console.log('doReload - no service worker in navigator, reloading');
    window.location.reload();
  }
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    try {
      if (this.props.onError) {
        this.props.onError();
      }
      console.error(error);
      Stats.error(error);
    } catch (e) {}
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      if (this.props.showNothing) {
        return null;
      }
      return this.props.justText ? (
        <Alert
          type="warning"
          message={
            this.props.text ? (
              this.props.text
            ) : (
              <>
                Something went wrong...
                <Button
                  style={{ padding: '0px 3px', color: 'var(--accent)', underline: 'dotted' }}
                  type="link"
                  onClick={doReload}
                >
                  reload the page
                </Button>
                or{' '}
                <Link style={{ color: 'var(--accent)' }} to="/support">
                  contact support
                </Link>
                .
              </>
            )
          }
        />
      ) : (
        <Result
          icon={
            this.props.hideImage ? (
              <></>
            ) : (
              <Image
                preview={false}
                style={{ maxWidth: '300px' }}
                src={`${process.env.REACT_APP_IMAGE_URL}/to_the_moon.svg`}
              />
            )
          }
          title="Something went wrong"
          subTitle="An unexpected error occurred loading this page. Please try again or contact support."
          extra={[
            <Button type="primary" onClick={doReload}>
              Reload
            </Button>,
            <Link style={{ paddingLeft: '10px' }} to="/support">
              Contact Support
            </Link>,
          ]}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
export { doReload };
