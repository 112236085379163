import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { LoadingOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import sdk from 'sdk/Categories';
import { abbreviateNumber } from 'utils';
const { REACT_APP_SITE_ROLLUPS_URL } = process.env;
const axios = require('axios');

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, obj) =>
      (!!obj?.id && (
        <Link style={{ fontWeight: '500' }} to={'/markets/' + obj.id.substring(4)}>
          {text}
        </Link>
      ): ''),
  },
  {
    title: 'No. Of Companies',
    dataIndex: 'companyCount',
    key: 'companyCount',
  },
  {
    title: 'Total Funding',
    dataIndex: 'totalFunding',
    key: 'totalFunding',
    render: (text, obj) => `$${text}`,
  },
];

const SearchMarkets = ({ search, countCallback = c => {} }) => {
  const [loading, setLoading] = useState(true);
  const query = search;
  const [searchResults, setResults] = useState([]);
  const [errorText, setError] = useState('');

  const getTotalFunding = history => {
    const funding = Object.keys(history)
      .map(year => {
        let investmentTypes = Object.keys(history[year]);
        let yearTotal = 0;

        for (let ii = 0; ii < investmentTypes.length; ii++) {
          const tp = investmentTypes[ii];
          const element = history[year][tp];

          if (tp !== 'rounds') {
            yearTotal += element;
          }
        }

        return { year, total: yearTotal };
      })
      .reduce((total, yearData) => total + yearData.total, 0);

    return abbreviateNumber(funding);
  };

  let performSearch = async () => {
    setLoading(true);

    let result = await sdk.searchCategories(query);
    Promise.all(
      result.categories.map(async category => {
        const { data: marketDetail } = await axios.get(`${REACT_APP_SITE_ROLLUPS_URL}${category.id}.json`);
        return {
          ...category,
          companyCount: marketDetail.count,
          totalFunding: getTotalFunding(marketDetail.funding_history),
        };
      })
    ).then(res => {
      setResults(res);
      countCallback(result.categories.length);
      setError('');
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = `${query} ${process.env.REACT_APP_SITE_TITLE_SUFFIX}`;
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Table
      bordered
      columns={columns}
      loading={loading}
      dataSource={searchResults.map((market, i) => {
        return {
          ...market,
          key: i,
        };
      })}
      size="large"
      scroll={{ x: 700 }}
      pagination={{
        defaultPageSize: 10,
        hideOnSinglePage: true,
        showTotal: total => <Typography.Text type="secondary">{searchResults.length} Results</Typography.Text>,
        size: 'large',
      }}
      title={() => (
        <>
          {loading && <LoadingOutlined />}
          {!loading && !errorText && <CheckCircleOutlined />}
          {!errorText && (
            <span style={{ margin: '0px 6px' }}>
              {loading && <>Searching for </>}
              {!loading && (
                <>
                  {searchResults.length > 0 ? 'Showing ' : ''}
                  <i>{searchResults.length}</i> results for{' '}
                </>
              )}
              <i style={{ color: 'var(--blue)' }}>{search}</i>
            </span>
          )}
          {!loading && errorText && (
            <>
              <ExclamationCircleOutlined />
              <span style={{ margin: '0px 6px' }}>
                Error while searching for
                <i style={{ color: 'var(--blue)' }}>{search}</i>
              </span>
            </>
          )}
        </>
      )}
    />
  );
};

export default withRouter(SearchMarkets);
