// This file provides a common set of helpers to retrieve news.

import { API } from '@aws-amplify/api';

const getConfig = async () => {
  var url = `/api-public/news/config`;
  let result = await API.get('fuelapi', url)
    .then(async data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });
  return result;
};

const getCuratedArticle = async articleId => {
  // /news/:category/curated
  var url = `/api-public/news/curated/${articleId}`;
  let result = await API.get('fuelapi', url)
    .then(async data => {
      return data.length > 0 ? data[0] : data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });
  return result;
};

///news/curated/featured

const getFeaturedNews = async (offset, limit) => {
  // /news/:category/curated
  var url = `/api-public/news/curated/featured?_limit=${limit}`;
  let result = await API.get('fuelapi', url)
    .then(async data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });
  return result;
};

// Get news

const getMarketNews = async marketId => {
  var url = `/api-public/markets/${marketId}/news`;
  let result = await API.get('fuelapi', url)
    .then(async data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });
  return result;
};

const getNewsKeywords = async () => {
  var url = `/api-public/news/keywords`;
  let result = await API.get('fuelapi', url)
    .then(async data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });
  return result;
};

const getNewsArticles = async () => {
  var url = `/api-public/news/articles`;
  let result = await API.get('fuelapi', url)
    .then(async data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });
  return result;
};

const searchNewsTerm = async (term, company_uuid) => {
  var url = `/api-public/news/articles/search`;
  let boolQuery = {
    must: {
      multi_match: {
        query: term,
        fields: ['title^10', 'description^5', 'summary_blurbs'],
        operator: 'and',
      },
    },
    filter: { range: { published_at: { gte: 'now-6M/d' } } },
  };

  if (!!company_uuid) {
    boolQuery.must_not = {
      term: {
        company_uuid: company_uuid,
      },
    };
  }

  let result = await API.post('fuelapi', url, {
    body: {
      query: {
        size: 25,
        query: {
          bool: boolQuery,
        },
        sort: { published_at: 'desc' },
      },
    },
  })
    .then(async data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });

  return result;
};

const searchArticlesES = async (filters, offset, limit, sort) => {
  let url = `/api/search/articles`;

  try {
    let result = await API.post('fuelapi', url, { body: { query: { filters, offset, limit, sort } } });
    return result;
  } catch (e) {
    console.log('error searching articles', e);
    return { error: 'Failed to search for articles' };
  }
};

const searchFundingNews = async () => {
  var url = `/api-public/news/articles/search`;
  let result = await API.post('fuelapi', url, {
    body: {
      query: {
        query: {
          bool: {
            should: [
              {
                multi_match: {
                  query: 'raised funding',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: 'funding round',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: 'series a',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: 'series b',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: 'series c',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: 'series d',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: 'seed funding',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: 'seed round',
                  fields: ['title^10', 'description^5', 'summary_blurbs'],
                  operator: 'and',
                },
              },
              {
                term: {
                  keyword: {
                    value: 'series a',
                    boost: 10,
                  },
                },
              },
              {
                term: {
                  keyword: {
                    value: 'series b',
                    boost: 10,
                  },
                },
              },
              {
                term: {
                  keyword: {
                    value: 'series c',
                    boost: 10,
                  },
                },
              },
              {
                term: {
                  keyword: {
                    value: 'series d',
                    boost: 10,
                  },
                },
              },
              {
                term: {
                  keyword: {
                    value: 'funding round',
                    boost: 100,
                  },
                },
              },
            ],
            minimum_should_match: 1,
            filter: {
              range: {
                published_at: {
                  gte: 'now-1d',
                },
              },
            },
          },
        },
        limit: 20,
      },
    },
  })
    .then(async data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: e };
    });

  return result;
};

const sdk = {
  getConfig,
  getCuratedArticle,
  getFeaturedNews,
  getMarketNews,
  getNewsKeywords,
  getNewsArticles,
  searchFundingNews,
  searchNewsTerm,
  searchArticlesES,
};
export default sdk;
