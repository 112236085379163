import React from 'react';

const FuelFooter = ({ className = '' }) => {
  return (
    <div className={className} style={{ textAlign: 'left', backgroundColor: '#FFF', color: '#AFAFAF' }}>
      York IE Fuel&trade; - &copy; {new Date().getFullYear()}
      {process.env.REACT_APP_VERSION ? <> \\ v{process.env.REACT_APP_VERSION}</> : ''}
    </div>
  );
};

export default FuelFooter;
