import { create } from 'zustand';
import Client from 'client';

const useRecentlyViewed = create((set, get) => ({
  recentlyViewedCompanies: Client.recentLinks().get(),

  // Update the recently viewed companies in localStorage and pass along the new list
  updateRecentlyViewed: updatedList => {
    Client.recentLinks().update(updatedList);
    set({ recentlyViewedCompanies: Client.recentLinks().get() });
  },

  // Create the recently viewed companies in localStorage
  saveRecentlyViewed: list => {
    Client.recentLinks().save(list);
    set({ recentlyViewedCompanies: Client.recentLinks().get() });
  },

  // Clear all recently viewed companies from localStorage and pass along an empty obj
  clearRecentlyViewed: () => {
    Client.recentLinks().clear();
    set({ recentlyViewedCompanies: {} });
  },
}));

export default useRecentlyViewed;
