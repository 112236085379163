import { useEffect, useState } from 'react';
import { API } from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

import { message } from 'antd';
import Splash from 'components/Splash';

export const SlackAuth = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const main = async () => {
      const urlParams = new URLSearchParams(window.location.search);

      let tokens = await Auth.currentSession();
      tokens.slack_user_id = urlParams.get('slack_user_id');
      let channelId = urlParams.get('channel');
      let teamId = urlParams.get('team');
      API.post('fuelapi', '/slackauth', { body: tokens })
        .then(() => {
          setLoading(false);
          window.open(`https://slack.com/app_redirect?channel=${channelId}&team=${teamId}`, '_self');
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
          message.error('Something went wrong');
        });
    };
    main();
  }, []);

  if (loading) {
    return <Splash />;
  }

  return <h3 style={{ textAlign: 'center' }}>You can close this window</h3>;
};
