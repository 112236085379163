import React, { useState, useEffect } from 'react';
import { Button, Drawer, Input, Popover } from 'antd';
import { DownOutlined, LeftOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import useMessages from 'store/Messages';
import { shallow } from 'zustand/shallow';
import SearchUsers from './SearchUsers';
import usePersonalAccount from 'store/PersonalAccount';
import { ShareAvatar } from 'components/users/ShareAvatars';
import ErrorBoundary from 'ErrorBoundary';
import sdk from 'sdk/Messages';
import accounts from 'sdk/Accounts';
import './Home.css';

const NewChannel = ({ show, close, create, loading }) => {
  const [name, setName] = useState('');
  const [people, setPeople] = useState([]);

  const cancel = () => {
    setName('');
    setPeople([]);
    close();
  };

  const onDeselect = v => {
    setPeople(people.filter(p => p.value !== v));
  };

  const onSelect = (v, opt) => {
    console.log('opt', opt);
    setPeople([...people, { label: opt.fullName, value: opt.value }]);
  };

  const createChannel = async () => {
    console.log('people', people);
    await create(
      name,
      people.map(p => p.value)
    );
    setPeople([]);
    setName('');
  };

  return (
    <Drawer
      title={<div style={{ color: '#FFF' }}>New Private Channel</div>}
      placement={'left'}
      style={{ zIndex: 1040 }}
      onClose={cancel}
      open={show}
      key={`new-channel-drawer`}
      width="400px"
      bodyStyle={{ padding: '20px' }}
      headerStyle={{ backgroundColor: 'var(--charcoal-four)', textTransform: 'uppercase' }}
      closable={false}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button disabled={loading} type="text" onClick={cancel} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            onClick={() => {
              createChannel();
            }}
            style={{ marginRight: 8 }}
          >
            Create
          </Button>
        </div>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <div style={{ fontSize: '16px', color: 'rgba(0,0,0,.65)' }}>Channel Name</div>
          <Input
            placeholder="Channel name (default: Untitled)"
            size="large"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <div style={{ fontSize: '16px', color: 'rgba(0,0,0,.65)' }}>People</div>
          <SearchUsers
            placeholder="Invite Fuel users to channel"
            size="large"
            onSelect={onSelect}
            onDeselect={onDeselect}
            selected={people}
          />
        </div>
      </div>
    </Drawer>
  );
};

const MessageNavGroup = ({ active, setActive, label, items, actions, loading, unreads }) => {
  const [show, setShow] = useState(true);

  return (
    <div className="messages-nav-group">
      <div className="messages-nav-header" onClick={() => setShow(!show)}>
        <span className="messages-nav-text">{label}</span>
        {show ? <DownOutlined /> : <LeftOutlined />}
      </div>
      <div className={`messages-nav-items ${show ? '' : 'hidden'}`}>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
            <LoadingOutlined />
          </div>
        )}
        {!loading && (!items || items.length === 0) && (
          <div
            style={{ color: '#AAA', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}
          >
            No Channels
          </div>
        )}
        {!loading &&
          items &&
          items.length > 0 &&
          items.map(item => (
            <div
              onClick={() => setActive(item.key)}
              key={item.key}
              className={`messages-nav-item ${active === item.key ? 'active' : ''}`}
            >
              {item.nodes}
            </div>
          ))}

        {actions && (
          <div
            style={{ margin: '10px 0px', display: 'flex', gap: '4px', alignItems: 'center', justifyContent: 'center' }}
          >
            {actions}
          </div>
        )}
      </div>
    </div>
  );
};

const ChannelMembers = ({ channelId, callback = () => {} }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMembers = async () => {
    setLoading(true);
    try {
      let result = await sdk.fetchChannelMembers(channelId);
      let users = await accounts.getUsersByIDs(result.map(r => r.owner));
      callback(users);
      setMembers(users);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line
  }, [channelId]);

  return (
    <div>
      {loading && <LoadingOutlined />}
      {!loading && (
        <Popover
          placement={'right'}
          content={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '100%' }}>
              {members.map(m => (
                <div
                  key={`channel-member-${channelId}-${m.id}`}
                  style={{ display: 'grid', gridTemplateColumns: '24px minmax(0, 1fr)', gap: '8px', width: '100%' }}
                >
                  <ShareAvatar id={m?.id} url={m?.avatar} size="small" shape="square" />
                  <div style={{ borderBottom: '1px solid rgba(0,0,0,.05)', padding: '4px 0px' }}>
                    <div style={{ fontWeight: '500', fontSize: '14px', lineHeight: '14px' }}>{m?.fullName}</div>
                    <div style={{ fontSize: '13px', color: 'rgba(0,0,0,.65)' }}>{m?.email}</div>
                  </div>
                </div>
              ))}
            </div>
          }
        >
          <div
            style={{
              fontSize: '14px',
              color: 'rgba(0,0,0,.45)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <span>{members[0]?.fullName}</span>
            {members?.length > 1 && <span> + {members?.length - 1} more</span>}
          </div>
        </Popover>
      )}
    </div>
  );
};

const SearchableChannel = ({ channel, source, filter, unread }) => {
  const [members, setMembers] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    let cname = channel.display_name.toLowerCase();
    let display = false;
    if (!filter) {
      setShow(true);
      return;
    }
    if (cname.includes(filter)) {
      display = true;
    }
    try {
      for (const u of members) {
        let fullName = u?.fullName || '';
        let email = u?.email || '';
        if (fullName.toLowerCase().includes(filter) || email.toLowerCase().includes(filter)) {
          display = true;
          break;
        }
      }
    } catch (e) {
      console.log(e);
    }
    setShow(display);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <div
      key={`${channel.id}-text`}
      className={`messages-nav-item-wrap ${unread ? 'ping' : ''}`}
      style={{ display: show ? 'initial' : 'none' }}
    >
      <div className={`messages-nav-text`}>{channel.display_name}</div>
      {source !== 'navbar' && (
        <ErrorBoundary justText={true}>
          <ChannelMembers channelId={channel.id} callback={setMembers} />
        </ErrorBoundary>
      )}
    </div>
  );
};

const MessagesSidebar = ({ source = 'admin' }) => {
  const {
    channels,
    activeChannel,
    selectChannel,
    loading,
    createChannel,
    createInvite,
    refreshData,
    unreads,
  } = useMessages(
    state => ({
      channels: state.channels,
      activeChannel: state.activeChannel,
      selectChannel: state.selectChannel,
      loading: state.loading,
      createChannel: state.createChannel,
      createInvite: state.createInvite,
      refreshData: state.refreshData,
      unreads: state.unreads,
    }),
    shallow
  );
  const { cognito, hasCreateChannelsGroup } = usePersonalAccount(
    state => ({
      cognito: state.cognito,
      hasCreateChannelsGroup: state.hasCreateChannelsGroup,
    }),
    shallow
  );
  const [filter, setFilter] = useState('');
  const [showNew, setShowNew] = useState(false);
  const [newLoading, setNewLoading] = useState(false);

  useEffect(() => {
    if (!activeChannel && channels?.filter(c => c.is_private === true).length > 0) {
      selectChannel(channels.filter(c => c.is_private === true)[0].id);
    }
    // eslint-disable-next-line
  }, [channels, activeChannel]);

  const addChannel = async (name, people) => {
    if (name) {
      setNewLoading(true);
      let channel = await createChannel(name);
      // create invite code
      let invite = await createInvite(channel.id);
      // create invite records per user in appsync
      for (const p of people) {
        console.log('p', p);
        await sdk.createChannelInvites(p, channel.id, invite.id);
      }

      await refreshData();
      setShowNew(false);
      setNewLoading(false);
    }
  };

  return (
    <div className="admin-messages-sidebar">
      <div className="messages-header">
        <div>{cognito?.attributes?.name || cognito?.attributes?.email}</div>
        <div style={{ marginTop: '5px' }}>
          <Input
            size="large"
            bordered={false}
            placeholder="Search channels..."
            value={filter}
            className="channel-search"
            onChange={e => setFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="messages-nav">
        <MessageNavGroup
          loading={loading}
          active={activeChannel?.id}
          setActive={selectChannel}
          label="Notifications"
          key="notification-channels"
          items={
            channels &&
            channels
              .filter(c => c.is_private === true)
              .map(c => ({
                key: c.id,
                nodes: (
                  <SearchableChannel
                    unread={!!unreads[c.id]}
                    channel={c}
                    source={source}
                    filter={filter ? filter.toLowerCase() : ''}
                  />
                ),
              }))
          }
          actions={
            hasCreateChannelsGroup()
              ? [
                  <Button
                    onClick={() => setShowNew(true)}
                    type="text"
                    style={{ fontWeight: '500', color: 'var(--secondary)', textTransform: 'uppercase' }}
                    key={'create-channel-button'}
                  >
                    <PlusOutlined /> Create
                  </Button>,
                ]
              : []
          }
        />
      </div>
      <NewChannel loading={newLoading} show={showNew} close={() => setShowNew(false)} create={addChannel} />
    </div>
  );
};

export default MessagesSidebar;
