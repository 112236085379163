import React, { useState } from 'react';
import { Form, Input, Row, Col, Alert, Button } from 'antd';
import Auth from '@aws-amplify/auth';

const PasswordResetRequired = ({ changeState, feedback, user, challengeParams }) => {
  const [msg] = useState(feedback);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [password, setPassword] = useState('');
  const [, setConfirmPassword] = useState('');

  const confirmReset = async e => {
    setLoading(true);
    setErr('');
    try {
      let resetResult = await Auth.completeNewPassword(user, password, {});
      console.log('resetResult', resetResult);
      let loginResult = await Auth.signIn({ username: user.username, password: password });
      console.log('loginResult', loginResult);

      setLoading(false);
      //changeState('signIn', 'Sign in with new password', '');
    } catch (error) {
      console.log('error resetting password:', error);
      setErr(error.message);
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={24} md={24} lg={16} xl={16} xxl={9} style={{ maxWidth: '600px' }}>
        <h4 className="mute-title" style={{ marginBottom: '30px' }}>
          Password reset is required
        </h4>

        {msg && <Alert style={{ marginBottom: '10px' }} type="info" showIcon={false} message={msg} banner />}
        {err && <Alert style={{ marginBottom: '10px' }} type="error" showIcon={false} message={err} banner />}

        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="normal_reset"
          className="reset-form"
          onFinish={e => confirmReset(e)}
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (!value?.trim()) {
                    return callback('Please input your new password');
                  }
                  callback();
                },
              },
            ]}
          >
            <Input.Password
              className="xl"
              onChange={event => setPassword(event.target.value)}
              size="large"
              id="password"
              key="password"
              name="password"
              placeholder="Password"
              disabled={loading}
              tabIndex={2}
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (!value?.trim()) {
                    return callback('Confirm Password is required!');
                  }
                  if (value !== password) {
                    return callback('Confirm Password should be same as password!');
                  }
                  callback();
                },
              },
            ]}
          >
            <Input.Password
              className="xl"
              size="large"
              id="password"
              key="password"
              onChange={event => setConfirmPassword(event.target.value)}
              name="password"
              placeholder="Confirm Password"
              disabled={loading}
              tabIndex={2}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={loading}
                  tabIndex={5}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="fuel.user.resetPasswordRequiredComplete"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default PasswordResetRequired;
