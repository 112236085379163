import dayjs from 'dayjs';
import awsmobile from 'aws-exports';
import axios from 'axios';

const { REACT_APP_SITE_ROLLUPS_URL, REACT_APP_SITE_MARKET_URL } = process.env;

const fetchFeaturedMarkets = async () => {
  const resp = await axios.get(`${REACT_APP_SITE_MARKET_URL}/feature_markets.json`);
  return resp.data.results;
};
const fetchMarket = async categoryId => {
  const resp = await axios.get(`${REACT_APP_SITE_ROLLUPS_URL}${categoryId}.json`);
  return resp.data;
};
const fetchMarkets = async () => {
  const resp = await axios.get(`${REACT_APP_SITE_ROLLUPS_URL}fuel-market-rollup.json`);
  return resp.data;
};
const fetchCompanyRollup = async () => {
  const resp = await axios.get(`${REACT_APP_SITE_ROLLUPS_URL}company-landing.json`);
  return resp.data;
};

const accountLogoUrl = accountKey => {
  const imgUrl = accountKey
    ? 'https://' +
      awsmobile.aws_user_files_s3_bucket +
      '.s3.' +
      awsmobile.aws_user_files_s3_bucket_region +
      '.amazonaws.com/public/' +
      encodeURI(accountKey)
    : null;
  return imgUrl;
};

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

const getStaticUrl = (size, id) => {
  return `https://static.york.ie/resized/${size}/public/playbooks/${id}.png`;
};

const getStripePK = () => {
  return window.location.hostname === 'fuel.york.ie'
    ? process.env.REACT_APP_STRIPE_PK
    : process.env.REACT_APP_STRIPE_TEST_PK;
};

const getQuebicEnv = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') return 'nick';
  else if (hostname === 'stage.fuel.york.ie') return 'stage';
  else if (hostname === 'fuel.york.ie') return 'prod';
  return 'stage';
};

function dateFrom(str) {
  let d = new Date();
  let today = d.toISOString().split('T')[0];
  d.setDate(d.getDate() - 1);
  let yesterday = d.toISOString().split('T')[0];
  if (str === today) {
    return 'Today';
  } else if (str === yesterday) {
    return 'Yesterday';
  }
  return dayjs(str).format('dddd, MMMM Do');
}

const abbrs = ['', 'k', 'm', 'b', 't'];

const abbreviateNum = num => {
  if (num && num > -1) {
    let strNum = num + '';
    let t = Math.floor(strNum.length / 3);
    let r = strNum.length % 3;
    let prefix = '';

    if (r === 0) {
      t--;
      prefix = strNum.slice(0, 3);
    } else {
      prefix = strNum.slice(0, r);
    }
    return prefix + abbrs[t];
  } else {
    return '--';
  }
};

const abbreviate_number = (num, fixed) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return '0';
  } // terminate early
  if (typeof num !== 'number') {
    return '-';
  }

  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split('e'), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
};

const getFileSizeInExactFormat = bytes => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    bytes = bytes + ' bytes';
  } else if (bytes === 1) {
    bytes = bytes + ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
};

function jsonToCsv(items) {
  const header = Object.keys(items[0]);
  const headerString = header.join(',');
  const replacer = (key, value) => value ?? '';

  const rowItems = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

  const csv = [headerString, ...rowItems].join('\r\n');

  return csv;
}

const abbreviateNumber = value => {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};

const slugify = (str, separator = '-') => {
  return str
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);
};

function lightenDarkenColor(col, amt) {
  var usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  var b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  var g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

function isColorDark(hexColor) {
  // Convert hex color value to RGB
  var r = parseInt(hexColor.substring(1, 3), 16);
  var g = parseInt(hexColor.substring(3, 5), 16);
  var b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate perceived brightness
  var perceivedBrightness = Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b);

  if (perceivedBrightness > 128) {
    return false;
  } else {
    return true;
  }
}

//Haversine formula
function distanceBetweenLocationsInMiles(location1, location2) {
  const lat1 = location1.lat;
  const lon1 = location1.lon;
  const lat2 = location2.lat;
  const lon2 = location2.lon;
  const R = 3958.8; // Earth radius in miles

  const radianLat1 = (lat1 * Math.PI) / 180;
  const radianLon1 = (lon1 * Math.PI) / 180;
  const radianLat2 = (lat2 * Math.PI) / 180;
  const radianLon2 = (lon2 * Math.PI) / 180;

  const dLat = radianLat2 - radianLat1;
  const dLon = radianLon2 - radianLon1;

  const a = Math.sin(dLat / 2) ** 2 + Math.cos(radianLat1) * Math.cos(radianLat2) * Math.sin(dLon / 2) ** 2;

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in miles

  return distance;
}
const getMapBoxToken = () => {
  return window.location.hostname === 'fuel.york.ie'
    ? process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
    : process.env.REACT_APP_MAPBOX_ACCESS_TOKEN_TEST;
};

// filter comparison utility methods

const dateComparisonIsValid = (operator, dateStr1, dateStr2) => {
  let date1 = new Date(dateStr1);
  let date2 = new Date(dateStr2);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  date1 = date1.getTime();
  date2 = date2.getTime();

  switch (operator) {
    case 'lt':
      return date1 < date2;
    case 'lte':
      return date1 <= date2;
    case 'gt':
      return date1 > date2;
    case 'gte':
      return date1 >= date2;
    case 'eq':
      return date1 === date2;
    case 'ne':
      return date1 !== date2;
    default:
      return false;
  }
};

const numberComparisonIsValid = (operator, num1, num2) => {
  num1 = +num1;
  num2 = +num2;
  if (isNaN(num1) || isNaN(num2)) {
    console.log('invalid number inputs');
    return false;
  }
  switch (operator) {
    case 'lt':
      return num1 < num2;
    case 'lte':
      return num1 <= num2;
    case 'gt':
      return num1 > num2;
    case 'gte':
      return num1 >= num2;
    case 'eq':
      return num1 === num2;
    case 'ne':
      return num1 !== num2;
    default:
      return false;
  }
};

const tranformLocation = loc => {
  const obj = {};
  if (!!loc.country_code) {
    obj['country_code'] = loc.country_code.substring(0, 2).toLowerCase();
  } else {
    obj['country_code'] = null;
  }

  if (!!loc.state_code || !!loc.us_state) {
    let stateCode = loc.state_code || loc.us_state;
    obj['state_code'] = stateCode.toLowerCase();
  } else {
    obj['state_code'] = null;
  }

  if (!!loc.city || !!loc.name) {
    let cityName = loc.city || loc.name;
    obj['city'] = cityName.toLowerCase();
  } else {
    obj['city'] = null;
  }

  if (!!loc.region && loc.region !== 'NULL') {
    obj['region'] = loc.region.toLowerCase();
  } else {
    obj['region'] = null;
  }
  return obj;
};

const isLocationMatching = (locations, orgLocation) => {
  const transformedLocations = locations.map(loc => tranformLocation(loc));
  const orgLoc = tranformLocation(orgLocation);
  for (let loc of transformedLocations) {
    if (
      (!!!loc.country_code || loc.country_code === orgLoc.country_code) &&
      (!!!loc.state_code || loc.state_code === orgLoc.state_code) &&
      (!!!loc.city || loc.city === orgLoc.city) &&
      (!!!loc.region || loc.region === orgLoc.region)
    ) {
      return true;
    }
  }
  return false;
};

const downloadSVG = (svgChartRef, entityName, fontSize = '50px') => {
  const chartNode = svgChartRef.current;
  const width = chartNode?.getBBox()?.width;
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute('version', '1.1');
  svg.appendChild(chartNode.cloneNode(true));
  const chartWrapper = svg.querySelector('.chart-wrapper');
  if (chartWrapper) {
    const titleText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    titleText.setAttribute('class', 'chart-title');
    titleText.setAttribute('dx', (width / 2).toString());
    titleText.setAttribute('dy', '-100');
    titleText.setAttribute('text-anchor', 'middle');
    titleText.setAttribute('font-size', fontSize);
    titleText.setAttribute('fill', '#333232');
    titleText.textContent = `${entityName} - Funding History`;
    chartWrapper.appendChild(titleText);
  }

  const svgBlob = new Blob([new XMLSerializer().serializeToString(svg)], { type: 'image/svg+xml;charset=utf-8' });
  const svgUrl = URL.createObjectURL(svgBlob);
  const link = document.createElement('a');
  link.href = svgUrl;
  link.download = `${entityName} - Funding History.svg`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(svgUrl);
};

const sanitizeDomainText = text => {
  let formattedToDomain = text.trim();
  if (text.includes('://')) {
    formattedToDomain = text.split('://')[1];
  }
  if (formattedToDomain.includes('/')) {
    formattedToDomain = formattedToDomain.split('/')[0];
  }
  formattedToDomain = formattedToDomain.replace(/www./i, '');
  return formattedToDomain;
};

export {
  downloadBlob,
  getStaticUrl,
  getStripePK,
  dateFrom,
  abbreviateNum,
  abbreviate_number,
  accountLogoUrl,
  getQuebicEnv,
  getFileSizeInExactFormat,
  fetchFeaturedMarkets,
  fetchMarket,
  fetchMarkets,
  fetchCompanyRollup,
  jsonToCsv,
  abbreviateNumber,
  slugify,
  lightenDarkenColor,
  isColorDark,
  distanceBetweenLocationsInMiles,
  getMapBoxToken,
  dateComparisonIsValid,
  numberComparisonIsValid,
  tranformLocation,
  isLocationMatching,
  downloadSVG,
  sanitizeDomainText,
};
