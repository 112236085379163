export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!, $condition: ModelAccountConditionInput) {
    updateAccount(input: $input, condition: $condition) {
      id
      creator
      owners
      members
      name
      user_seats
      description
      logo
      limits
      referralID
      trialStart
      trialEnd
      createdAt
      updatedAt
      accountStatus
      planID
    }
  }
`;

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      creator
      owners
      members
      name
      description
      logo
      limits
      user_seats
      referralID
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      accountType
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
    }
  }
`;

export const accountReferrels = /* GraphQL */ `
  query ReferralsByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    referralsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralType
        code
        description
        planID
        uses
        active
        plan {
          id
          name
          limits
        }
      }
      nextToken
    }
  }
`;

export const updateReferral = /* GraphQL */ `
  mutation UpdateReferral($input: UpdateReferralInput!, $condition: ModelReferralConditionInput) {
    updateReferral(input: $input, condition: $condition) {
      id
      referralType
      code
      description
      active
      accountID
      planID
      uses
    }
  }
`;

export const getWatchlistItems = /* GraphQL */ `
  query GetWatchlist($id: ID!) {
    getWatchlist(id: $id) {
      id
      owner
      admins
      name
      items
    }
  }
`;

export const getWatchlist = /* GraphQL */ `
  query GetWatchlist($id: ID!) {
    getWatchlist(id: $id) {
      id
      owner
      viewers
      admins
      name
      company
      scope
      description
      items
      metadata
      datasource
      updatingData
      lastDataUpdate
      accountID
      columns
      createdAt
      updatedAt
      account_privacy
      account {
        id
        creator
        owners
        members
        name
        description
        logo
        limits
        createdAt
        updatedAt
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
      }
      teams {
        items {
          id
          team {
            id
            name
          }
        }
        nextToken
      }
      settings {
        items {
          id
          owner
          email
          follow
          funding_updates
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const updateWatchlistMinimal = /* GraphQL */ `
  mutation UpdateWatchlist($input: UpdateWatchlistInput!, $condition: ModelWatchlistConditionInput) {
    updateWatchlist(input: $input, condition: $condition) {
      id
      owner
      viewers
      admins
      name
      description
      items
      metadata
      datasource
      createdAt
      updatedAt
    }
  }
`;

export const updateWatchlist = /* GraphQL */ `
  mutation UpdateWatchlist($input: UpdateWatchlistInput!, $condition: ModelWatchlistConditionInput) {
    updateWatchlist(input: $input, condition: $condition) {
      id
      owner
      viewers
      admins
      name
      description
      items
      accountID
      columns
      createdAt
      updatedAt
      account_privacy
      account {
        id
        creator
        owners
        members
        name
        description
        logo
        limits
        createdAt
        updatedAt
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
      }
      teams {
        items {
          id
          team {
            id
            name
          }
        }
        nextToken
      }
      settings {
        items {
          id
          owner
          email
          follow
          funding_updates
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const teamsByAccount = /* GraphQL */ `
  query TeamsByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const teamsByAccountAndMember = /* GraphQL */ `
  query ByTeamMember($email: String!, $accountID: ID!) {
    byTeamMember(email: $email, accountID: { eq: $accountID }) {
      items {
        id
        teamID
        team {
          id
          name
        }
      }
    }
  }
`;

export const teamsByAccountAndMemberDetail = /* GraphQL */ `
  query ByTeamMember($email: String!, $accountID: ID!) {
    byTeamMember(email: $email, accountID: { eq: $accountID }) {
      items {
        id
        teamID
        team {
          id
          name
          description
          accountID
        }
      }
    }
  }
`;

export const byAccountMember = /* GraphQL */ `
  query ByAccountMember(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelAccountMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccountMember(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        email
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          logo
          accountStatus
          createdAt
          updatedAt
          limits
          referralID
          trialStart
          trialEnd
        }
      }
      nextToken
    }
  }
`;

export const listWatchlists = /* GraphQL */ `
  query ListWatchlists($filter: ModelWatchlistFilterInput, $limit: Int, $nextToken: String) {
    listWatchlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        items
      }
      nextToken
    }
  }
`;

export const listWatchlistDetails = /* GraphQL */ `
  query ListWatchlists($filter: ModelWatchlistFilterInput, $limit: Int, $nextToken: String) {
    listWatchlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        viewers
        admins
        name
        metadata
        items
        createdAt
        updatedAt
        accountID
        settings {
          nextToken
          items {
            id
            owner
            email
            follow
          }
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByOwner = /* GraphQL */ `
  query WatchlistsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        name
        description
        metadata
        columns
        createdAt
        updatedAt
        settings {
          items {
            id
            owner
            funding_updates
            follow
            email
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const watchlistsByOwnerAndAccount = /* GraphQL */ `
  query WatchlistsByOwnerAndAccount(
    $owner: String
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByOwnerAndAccount(
      owner: $owner
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        name
        description
        metadata
        accountID
        createdAt
        updatedAt
        account_privacy
        account {
          id
          creator
          owners
          members
          name
          description
          logo
          createdAt
          updatedAt
        }
        teams {
          items {
            id
            team {
              id
              name
            }
          }
          nextToken
        }
        settings {
          nextToken
          items {
            id
            owner
            email
            follow
            funding_updates
          }
        }
      }
      nextToken
    }
  }
`;

export const watchlistsByTeam = /* GraphQL */ `
  query WatchlistsByTeam(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByTeam(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        teamID
        watchlistID
        createdAt
        updatedAt
        watchlist {
          id
          owner
          viewers
          admins
          name
          description
          metadata
          accountID
          account_privacy
          account {
            id
            creator
            owners
            members
            name
            description
            logo
            createdAt
            updatedAt
          }
          teams {
            items {
              id
              team {
                id
                name
              }
            }
            nextToken
          }
          columns
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByAccount = /* GraphQL */ `
  query WatchlistsByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        name
        description
        items
        metadata
        accountID
        createdAt
        updatedAt
        account_privacy
        account {
          id
          creator
          owners
          members
          name
          description
          logo
          createdAt
          updatedAt
        }
        teams {
          items {
            id
            team {
              id
              name
            }
          }
          nextToken
        }
        settings {
          nextToken
          items {
            id
            owner
            email
            follow
            funding_updates
          }
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByAccountWithPreviewOnly = /* GraphQL */ `
  query WatchlistsByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        name
        description
        metadata
        accountID
        createdAt
        updatedAt
        account_privacy
        account {
          id
          creator
          owners
          members
          name
          description
          logo
          createdAt
          updatedAt
        }
        teams {
          items {
            id
            team {
              id
              name
            }
          }
          nextToken
        }
        settings {
          nextToken
          items {
            id
            owner
            email
            follow
            funding_updates
          }
        }
      }
      nextToken
    }
  }
`;

export const watchlistSharesByOwner = /* GraphQL */ `
  query WatchlistSharesByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistSharesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        list {
          id
          owner
          viewers
          admins
          name
          description
          metadata
          accountID
          account {
            id
            creator
            owners
            members
            name
            description
            logo
            createdAt
            updatedAt
          }
          teams {
            items {
              id
              team {
                id
                name
              }
            }
            nextToken
          }
          settings {
            nextToken
            items {
              id
              owner
              email
              follow
              funding_updates
            }
          }
          columns
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const createWatchlistSetting = /* GraphQL */ `
  mutation CreateWatchlistSetting(
    $input: CreateWatchlistSettingInput!
    $condition: ModelWatchlistSettingConditionInput
  ) {
    createWatchlistSetting(input: $input, condition: $condition) {
      id
      owner
      starred
      email
      follow
      funding_updates
      preferences
    }
  }
`;

export const updateWatchlistSetting = /* GraphQL */ `
  mutation UpdateWatchlistSetting(
    $input: UpdateWatchlistSettingInput!
    $condition: ModelWatchlistSettingConditionInput
  ) {
    updateWatchlistSetting(input: $input, condition: $condition) {
      id
      owner
      starred
      email
      follow
      funding_updates
      preferences
    }
  }
`;

export const listSettingsByOwner = /* GraphQL */ `
  query ListSettingsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        starred
        email
        follow
        funding_updates
        createdAt
        updatedAt
        watchlist {
          id
          owner
          viewers
          admins
          name
          description
          items
          metadata
          account_privacy
          accountID
          account {
            id
            creator
            owners
            members
            name
            description
            logo
            createdAt
            updatedAt
          }
          teams {
            items {
              id
              team {
                id
                name
              }
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSettingsByOwnerWithPreviewOnly = /* GraphQL */ `
  query ListSettingsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        starred
        email
        follow
        funding_updates
        createdAt
        updatedAt
        watchlist {
          id
          owner
          viewers
          admins
          name
          description
          metadata
          account_privacy
          accountID
          account {
            id
            creator
            owners
            members
            name
            description
            logo
            createdAt
            updatedAt
          }
          teams {
            items {
              id
              team {
                id
                name
              }
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const createWatchlist = /* GraphQL */ `
  mutation CreateWatchlist($input: CreateWatchlistInput!, $condition: ModelWatchlistConditionInput) {
    createWatchlist(input: $input, condition: $condition) {
      id
      name
      items
    }
  }
`;

export const createNotebook = /* GraphQL */ `
  mutation CreateNotebook($input: CreateNotebookInput!, $condition: ModelNotebookConditionInput) {
    createNotebook(input: $input, condition: $condition) {
      id
      name
      owner
      createdAt
      updatedAt
    }
  }
`;

export const listNotebooks = /* GraphQL */ `
  query ListNotebooks($filter: ModelNotebookFilterInput, $limit: Int, $nextToken: String) {
    listNotebooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        viewers
        admins
        clients
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listWatchlistNotebooks = /* GraphQL */ `
  query WatchlistNotebooks($id: ID!) {
    getWatchlist(id: $id) {
      notebooks {
        items {
          notebook {
            id
            name
          }
          id
          createdAt
        }
      }
    }
  }
`;

export const courseBySlug = /* GraphQL */ `
  query CourseBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseBySlug(slug: $slug, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category
        slug
        short_description
        description
        content
        featuredimage
        published
        featured
        bizAssessmentCategory
        createdAt
        updatedAt
        attachments {
          items {
            id
            title
            description
            filetype
            order
            name
            attachmentType
            fuelTool
          }
          nextToken
        }
        videos {
          items {
            id
            title
            previewimage
            description
            duration
            order
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const coursesByFeatured = /* GraphQL */ `
  query CoursesByFeatured(
    $featured: COURSE_FEATURED_STATE
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByFeatured(
      featured: $featured
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        category
        featured
        bizAssessmentCategory
        slug
        short_description
        content
        featuredimage
        order
        published
        createdAt
        updatedAt
        attachments {
          items {
            id
          }
          nextToken
        }
        videos {
          items {
            id
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const coursesByCategory = /* GraphQL */ `
  query CoursesByCategory(
    $category: COURSE_CATEGORY
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByCategory(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        category
        slug
        short_description
        content
        featuredimage
        published
        featured
        bizAssessmentCategory
        createdAt
        updatedAt
        attachments {
          items {
            id
          }
          nextToken
        }
        videos {
          items {
            id
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listCourses = /* GraphQL */ `
  query ListCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        name
        category
        slug
        short_description
        description
        content
        featuredimage
        published
        featured
        bizAssessmentCategory
        createdAt
        updatedAt
        attachments {
          items {
            id
          }
          nextToken
        }
        videos {
          items {
            id
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const createReferral = /* GraphQL */ `
  mutation CreateReferral($input: CreateReferralInput!, $condition: ModelReferralConditionInput) {
    createReferral(input: $input, condition: $condition) {
      id
      referralType
      code
      description
      active
      accountID
      planID
      uses
      createdAt
      updatedAt
    }
  }
`;

export const accountsByReferrer = /* GraphQL */ `
  query AccountsByReferrer(
    $referralID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByReferrer(
      referralID: $referralID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        name
        description
        logo
        limits
        referralID
        createdAt
        updatedAt
        accountStatus
      }
      nextToken
    }
  }
`;

export const WatchlistsByAccountAndPersonalAccount = `
 query WatchlistsByAccountAndPersonalAccount (
  $accountID: ID
  $owner: String
  $sortDirection: ModelSortDirection
  $byAccount_filter: ModelWatchlistFilterInput
  $byOwner_filter: ModelWatchlistFilterInput
  $limit: Int
  $byAccount_nextToken: String
  $byOwner_nextToken: String
) {
  watchlistsByAccount(
    accountID: $accountID
    sortDirection: $sortDirection
    filter: $byAccount_filter
    limit: $limit
    nextToken: $byAccount_nextToken
  ) {
    items {
      id
      owner
      viewers
      admins
      name
      description
      items
      metadata
      accountID
      createdAt
      updatedAt
      account_privacy
      account {
        id
        creator
        owners
        members
        name
        description
        logo
        createdAt
        updatedAt
      }
      teams {
        items {
          id
          team {
            id
            name
          }
        }
        nextToken
      }
      settings {
        nextToken
        items {
          id
          owner
          email
          follow
          funding_updates
        }
      }
    }
    nextToken
  }
  watchlistsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $byOwner_filter
    limit: $limit
    nextToken: $byOwner_nextToken
  ) {
    items {
      id
      owner
      viewers
      admins
      name
      description
      items
      metadata
      accountID
      createdAt
      updatedAt
      account_privacy
      account {
        id
        creator
        owners
        members
        name
        description
        logo
        createdAt
        updatedAt
      }
      teams {
        items {
          id
          team {
            id
            name
          }
        }
        nextToken
      }
      settings {
        nextToken
        items {
          id
          owner
          email
          follow
          funding_updates
        }
      }
    }
    nextToken
  }
}`;

export const WatchlistsByAccountAndPersonalAccountPreviewOnly = `
 query WatchlistsByAccountAndPersonalAccount (
  $accountID: ID
  $owner: String
  $sortDirection: ModelSortDirection
  $byAccount_filter: ModelWatchlistFilterInput
  $byOwner_filter: ModelWatchlistFilterInput
  $limit: Int
  $byAccount_nextToken: String
  $byOwner_nextToken: String
) {
  watchlistsByAccount(
    accountID: $accountID
    sortDirection: $sortDirection
    filter: $byAccount_filter
    limit: $limit
    nextToken: $byAccount_nextToken
  ) {
    items {
      id
      owner
      viewers
      admins
      name
      description
      metadata
      accountID
      createdAt
      updatedAt
      account_privacy
      account {
        id
        creator
        owners
        members
        name
        description
        logo
        createdAt
        updatedAt
      }
      teams {
        items {
          id
          team {
            id
            name
          }
        }
        nextToken
      }
      settings {
        nextToken
        items {
          id
          owner
          email
          follow
          funding_updates
        }
      }
    }
    nextToken
  }
  watchlistsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $byOwner_filter
    limit: $limit
    nextToken: $byOwner_nextToken
  ) {
    items {
      id
      owner
      viewers
      admins
      name
      description
      metadata
      accountID
      createdAt
      updatedAt
      account_privacy
      account {
        id
        creator
        owners
        members
        name
        description
        logo
        createdAt
        updatedAt
      }
      teams {
        items {
          id
          team {
            id
            name
          }
        }
        nextToken
      }
      settings {
        nextToken
        items {
          id
          owner
          email
          follow
          funding_updates
        }
      }
    }
    nextToken
  }
}`;
