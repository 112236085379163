import { create } from 'zustand';

const useVersion = create((set, get) => ({
  updateAvailable: false,
  setUpdateAvailable: (update = true) => {
    set({ updateAvailable: update });
  },
}));

export default useVersion;
