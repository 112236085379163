import { create } from 'zustand';
import { message } from 'antd';
import messagesSdk from 'sdk/Messages';
import accounts from 'sdk/Accounts';
import Q from 'integrations/quebic/sdk';
import { getQuebicEnv } from 'utils';

const env = getQuebicEnv();
const environments = {
  nick: { space_id: '274614524705779712', invite: 'AXZTaTT5Vn9J', provider: 'fuel-cognito-dev-id-token' },
  stage: { space_id: '274623098676846592', invite: 'Xk5cEtK2kiLO', provider: 'fuel-cognito-stage-id-token' },
  prod: { space_id: '336898119792861184', invite: 'RVaotF9MExPr', provider: 'fuel-cognito-prod-id-token' },
};
let ACTIVE_CHANNEL = null;
const SPACE_ID = environments[env].space_id;
const PROVIDER = environments[env].provider;
const INVITE_ID = environments[env].invite;
const sdk = new Q(SPACE_ID);

const useMessages = create((set, get) => ({
  init: false,
  user: null,
  space: null,
  messages: [],
  channels: null,
  loading: true,
  loadingMessages: true,
  unreads: {},
  channelUserMap: {},
  channelUsers: [],
  err: null,
  quebicID: null,
  activeChannel: null,
  initialStoreSetup: async user => {
    set({ user: user });
    const isLocal = window.location.hostname === 'localhost';
    if (!isLocal && user?.attributes?.email && !get().init) {
      get().authn(user);
    } else {
      console.log('dev environment, skipping quebic authentication...');
      set({ loading: false, loadingMessages: false });
    }
  },
  // login
  authn: async u => {
    sdk.addInitCallback(get().sessionInit);
    try {
      let idtoken = u?.signInUserSession?.idToken?.jwtToken;
      if (idtoken) {
        let result = await sdk.quebicOAUTHSession(PROVIDER, idtoken, u.attributes.sub, u.attributes.email);
        console.log('session result', result);
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      let message = 'An unexpected error occurred. Please contact support.';
      let link = null;
      if (e.message === 'not_found') {
        message = 'Quebic account was not found. Please contact support.';
      } else if (e.message === 'account_already_exists') {
        const redirect_uri = `${window.location.protocol}//${window.location.hostname}`;
        const user = get().user;
        const token = user.signInUserSession.idToken.jwtToken;
        const userEmail = user.attributes.email;
        link = (
          <a
            href={`https://qbasic.componentry.com/link-account.html?username=${userEmail}&provider=${PROVIDER}&token=${token}&redirect_uri=${redirect_uri}`}
            rel="noreferrer noopener"
            target="_blank"
          >
            Link your account
          </a>
        );
        message = <div>A Quebic account already exists for this email. {link}</div>;
      } else if (e.message === 'already_a_member') {
        message = 'Quebic account is already a member. Please contact support.';
      }

      const err = (
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '4px' }}>{message}</div>
          <div style={{ marginBottom: '4px' }}>
            Quebic provider:{' '}
            <span
              style={{
                fontSize: '14px',
                backgroundColor: '#EFEFEF',
                border: '1px solid #eee',
                borderRadius: '4px',
                padding: '3px 6px',
              }}
            >
              {PROVIDER}
            </span>
          </div>
          <div>
            Message:{' '}
            <span
              style={{
                fontSize: '14px',
                backgroundColor: '#EFEFEF',
                border: '1px solid #eee',
                borderRadius: '4px',
                padding: '3px 6px',
              }}
            >
              {e.message}
            </span>
          </div>
        </div>
      );
      set({ err: err, loading: false, loadingMessages: false });
    }

    window.qbc = sdk;
  },
  checkReadStates: (chls, readStates) => {
    // for each channel with a last_message_id, compare to readStates same channel_id and last_message_id
    // if there is a difference (or no value in readStates), channel needs to be ack'd / is unread

    let unread = {};
    const privateChannels = chls.filter(c => c.is_private && c.space_id === SPACE_ID);
    for (const { id, last_message_id } of privateChannels) {
      if (!!last_message_id) {
        const lastRead = readStates.find(r => r.id === id);
        if (!lastRead || lastRead.last_message_id !== last_message_id) {
          unread[id] = last_message_id;
        }
      }
    }
    set({ unreads: unread });
  },
  // callback when session is initialized
  sessionInit: data => {
    if (data) {
      console.log('session established', data);
      const { user, channels: initChannels, spaces: initSpaces, read_states } = data;
      sdk.addMessageCallback(get().onMessage);
      sdk.addDeleteMessageCallback(get().onMessageDeleted);
      set({ quebicID: user.id, init: true });
      get().refreshData(user.id, initSpaces, initChannels);
      get().checkReadStates(initChannels, read_states);
    } else {
      console.log('failed to establish session', data);
      set({ loading: false, loadingMessages: false });
    }
  },
  selectChannel: async channelId => {
    set({ loadingMessages: true });
    let ch = get().channels.find(c => c.id === channelId);
    // if (!ch) {
    //   ch = directMessages.channels.find(c => c.id === channelId);
    // }
    if (ch) {
      let msgs = await get().fetchChannelMessages(channelId);
      ACTIVE_CHANNEL = channelId;
      msgs = msgs.sort((a, b) => b.timestamp - a.timestamp);
      let dayMap = {};
      for (const m of msgs) {
        let day = m.timestamp.toISOString().split('T')[0];
        if (!dayMap[day]) {
          dayMap[day] = [];
        }
        dayMap[day].push(m);
      }

      let userMap = {};
      let chUsers = [];
      try {
        // fetch channel invites for user mappings
        let members = await messagesSdk.fetchChannelMembers(channelId);
        let users = await accounts.getUsersByIDs(members.map(r => r.owner));
        chUsers = users.map(u => u.email);
        for (const member of members) {
          if (member.quebicUserID) {
            userMap[member.quebicUserID] = { ...users.find(u => u.id === member.owner) };
          }
        }
      } catch (e) {
        console.warn('failed to load user map for channel', channelId, e);
      }

      // get latest message (first based on order above)
      if (msgs.length > 0) {
        let latest = msgs[0];
        // console.log('selectChannel latest msg', latest);
        // ack message, if needed
        // console.log('selectChannel channel', ch);
        const unreads = get().unreads;
        // console.log('selectChannel unreads', unreads);
        let unreadMsg = unreads[channelId];
        if (!!unreadMsg && !!latest) {
          let ackResponse = await sdk.client.message.ack(channelId, latest.id);
          // console.log('selectChannel ackResponse', ackResponse);
          if (!ackResponse) {
            let temp = { ...unreads };
            delete temp[channelId];
            set({ unreads: temp });
          }
        }
        // console.log('selectChannel msgs', msgs);
      }
      set({ channelUserMap: userMap, channelUsers: chUsers, messages: dayMap, activeChannel: ch });
    }
    set({ loadingMessages: false });
  },
  createMessage: async (content, embeds = [], notify) => {
    const activeChannel = get().activeChannel;
    if (activeChannel) {
      let msgResult = await sdk.client.message.create(activeChannel.id, { content, embeds });

      if (!!msgResult?.id && !!notify) {
        get().sendNotification(content, get().channelUsers);
      }
    }
  },
  sendNotification: async (content, users) => {
    console.log('send notification', content, users);
    //  send notification to users
    if (users.length > 0) {
      let currentUser = get().user?.attributes?.email;
      if (currentUser) {
        // do not notify current user
        users = users.filter(u => u !== currentUser);
      }
      let result = await messagesSdk.notifyUsers(users);
      if (!result.error) {
        message.success('Email notification sent.');
      }
    }
  },
  deleteMessage: async id => {
    const activeChannel = get().activeChannel;
    if (activeChannel) {
      await sdk.client.message.delete(activeChannel.id, id);
    }
  },
  createChannel: async name => {
    try {
      let spc = await sdk.client.space.createChannel(SPACE_ID, {
        display_name: name,
        description: name,
        default_access_role: 0,
        is_private: true,
      });
      return spc;
    } catch (e) {
      console.log('error createChannel', e);
    }
  },
  createInvite: async (channel_id, uses = -1) => {
    try {
      let invite = await sdk.client.invite.create(SPACE_ID, {
        channel_id,
        uses,
      });
      return invite;
    } catch (e) {
      console.log('error createInvite', e);
    }
  },
  deleteChannel: async chanId => {
    const activeChannel = get().activeChannel;
    if (chanId === activeChannel?.id) {
      set({ activeChannel: null });
    }
    try {
      let spc = await sdk.client.channel.delete(chanId);
      return spc;
    } catch (e) {
      console.log('error deleting channel', e);
    }
  },
  // fetch active channel messages
  fetchChannelMessages: async channelId => {
    //  const filter = {};
    //  if (before !== '' && after === '') {
    //    filter.before = before;
    //  } else if (after !== '') {
    //    filter.after = after;
    //  }
    let msgs = [];
    if (channelId) {
      msgs = await sdk.client.message.list(channelId, 100);
    }

    // ack latest message, if needed

    return msgs;
  },
  // const fetchDms = async () => {
  //   let data = await sdk.client.dm.list();
  //   let dms = {
  //     channels: [...data.channels],
  //     users: data.users.reduce((a, v) => ({ ...a, [v.id]: v }), {}),
  //   };
  //   return dms;
  // };

  // receive messages for active channel
  onMessage: async data => {
    if (data.channel_id === ACTIVE_CHANNEL) {
      set(state => {
        let updates = { ...state.messages };
        let day = data.timestamp.toISOString().split('T')[0];
        if (!updates[day]) {
          updates[day] = [];
        }
        updates[day].push(data);
        return { messages: updates };
      });
    }
  },
  // receive message deleted for active channel
  onMessageDeleted: async data => {
    if (data.channel_id === ACTIVE_CHANNEL) {
      // remove from channel map
      console.log('message delete', data);
      set(state => {
        let updates = { ...state.messages };
        let days = Object.keys(updates);
        for (const day of days) {
          updates[day] = updates[day].filter(m => m.id !== data.id);
        }
        return { messages: updates };
      });
    }
  },
  //fetch space
  fetchSpace: async () => {
    try {
      let spc = await sdk.client.space.get(SPACE_ID);
      return spc;
    } catch (e) {
      if (e.message === 'not_found') {
        // handle invite on first attempt failure
        let ir = await sdk.client.invite.join(INVITE_ID);
        if (ir) {
          console.log(ir);
          let spc = await sdk.client.space.get(SPACE_ID);
          return spc;
        } else {
          console.log('error accepting space invite', ir);
        }
      }
    }
  },
  //fetch channels
  fetchChannels: async () => {
    let chs = await sdk.client.space.channels(SPACE_ID);
    return chs;
  },
  // refresh all data
  refreshData: async (qid = get().quebicID, initSpaces, initChannels) => {
    set({ loading: true });
    try {
      let spc = initSpaces;
      let chs = initChannels;

      if (!spc) {
        console.log('fetch spaces');
        spc = await get().fetchSpace();
      } else {
        spc = spc.find(s => s.id === SPACE_ID);
      }

      if (!chs) {
        console.log('fetch channels');
        chs = await get().fetchChannels();
      } else {
        chs = chs.filter(c => c.space_id === SPACE_ID);
      }
      // let dms = await fetchDms();

      // fetch channel invites for this user and find discrepencies
      // invite user with any differences
      // call refreshData when completed
      const sub = get().user?.attributes?.sub;
      let refreshAgain = false;
      if (sub) {
        // console.log('have user sub', sub, 'fetching current invites');
        let invites = await messagesSdk.fetchInvites(sub);
        // console.log('invites', invites);
        for (const invite of invites) {
          let hasChannel = chs.find(c => c.id === invite.channelID);
          if (!hasChannel) {
            try {
              let inv = await sdk.client.invite.join(invite.inviteID);
              console.log('new invite result', inv);
              if (qid) {
                // update channel record with quebic user ID for mapping
                let inviteUpdate = await messagesSdk.updateInvite(invite.id, qid);
                console.log('inviteUpdate', inviteUpdate);
              }
              refreshAgain = true;
            } catch (e) {
              console.log('failed invite', 'refreshAgain', refreshAgain);
              if (e.message === 'not_found') {
                // channel was deleted, clear invite
                console.warn(`channel '${invite.channelID}' was not found, deleting invite ${invite.id}`);
                await messagesSdk.deleteInvite(invite.id);
              }
            }
          }
          if (hasChannel && !invite.quebicUserID) {
            console.warn('have channel / invite without userid, quebicID', hasChannel, qid);
            if (qid) {
              // update channel record with quebic user ID for mapping
              let inviteUpdate = await messagesSdk.updateInvite(invite.id, qid);
              console.log('inviteUpdate', inviteUpdate);
            }
          }
        }
      }

      if (refreshAgain) {
        set({ unreads: true });
        await get().refreshData();
        return;
      }

      if (chs.filter(c => c.is_private === true).length === 0) {
        set({ loadingMessages: false });
      }
      set({ space: spc, channels: chs });
      // setDirectMessages(dms);
    } catch (e) {
      console.log('error fetching quebic data', e);
      set({ loading: false });
    }
    set({ loading: false });
  },
}));

export default useMessages;
