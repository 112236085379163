import React from 'react';
import './AsideSection.css';

const AsideSection = ({ title, children }) => {
  return (
    <div className="aside-section">
      <div className="aside-header">{title}</div>
      {children}
    </div>
  );
};

export default AsideSection;
