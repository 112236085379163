// This file provides a common set of helpers to retrieve users.

import { API } from '@aws-amplify/api';

// Get Users
const getUsers = async params => {
  let result = await API.get('fuelapi', `/api/admin/users`, { queryStringParameters: params });
  return result;
};

export { getUsers };
