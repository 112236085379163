/*
Wrapper for stats reporting.

Currently this is just used to wrap new relic `noticeError`
*/

const events = {
  CLICK_ELEMENT: 'fuel.elementClick', // generic event for on click
  NAV_ROUTE_CHANGE: 'fuel.routeChange', // react router change event
  LIST_LIMIT: 'fuel.lists.limit', // user's limit has been reached for lists
  LIST_CREATE: 'fuel.lists.create', // user creates a new list
  PLAYBOOK_MATERIAL: 'fuel.playbooks.downloadMaterial', // user downloads playbook material
  PLAYBOOK_VIDEO: 'fuel.playbooks.playVideo', // user plays playbook video
  APP_CRASH: 'fuel.application.crash', // the UI crashed
  USER_SIGNUP: 'fuel.user.signup', // user signs up
};

class Stats {
  error(err, attr = {}) {
    if (typeof window.faro == 'object' && window.location.hostname !== 'localhost') {
      window.faro.api.pushError(new Error(events.APP_CRASH), {
        ...attr,
        pathname: window.location.pathname,
        host: window.location.hostname,
      });

      // push measurement
      window.faro.api.pushMeasurement({
        type: events.APP_CRASH,
        values: {
          count: 1,
        },
      });
    }
  }

  pageView(attr = {}) {
    if (typeof window.faro == 'object' && window.location.hostname !== 'localhost') {
      window.faro.api.pushEvent(events.NAV_ROUTE_CHANGE, {
        ...attr,
        pathname: window.location.pathname,
        host: window.location.hostname,
      });

      window.faro.api.pushMeasurement({
        type: events.NAV_ROUTE_CHANGE,
        values: {
          count: 1,
        },
      });
    }
  }

  pageClick(action = events.CLICK_ELEMENT, attr = {}) {
    if (typeof window.faro == 'object') {
      window.faro.api.pushEvent(action, {
        ...attr,
        pathname: window.location.pathname,
        host: window.location.hostname,
      });
    }
  }

  customEvent(action = events.CLICK_ELEMENT, attr = {}) {
    if (typeof window.faro == 'object' && window.location.hostname !== 'localhost') {
      window.faro.api.pushEvent(action, {
        ...attr,
        pathname: window.location.pathname,
        host: window.location.hostname,
      });

      window.faro.api.pushMeasurement({
        type: action,
        values: {
          count: 1,
        },
      });
    }
  }
}

const stats = new Stats();
export default stats;
export { events };
