import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Table, Card, Skeleton, Tag, Popover, Button, Input, Select } from 'antd';
import Moment from 'react-moment';
import { LoadingOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import CompanyPopoverOverview from 'components/company/PopoverOverview';
import AvatarFailover from 'components/AvatarFailover';
import { logoURL } from 'helpers/Organizations';
import { getSearchResult } from 'helpers/Search';

const { Option } = Select;

const columnsFilter = (onChange, filters) => [
  {
    dataIndex: 'logo',
    key: 'logo',
    width: '0px',
    fixed: 'left',
    render: text => text && <img src={text} height="24px" width="24px" alt={text} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '150px',
    fixed: 'left',
    children: [
      {
        title: <Input placeholder="filter name" name="name" value={filters.name} onChange={onChange} />,
        dataIndex: 'name',
        key: 'name',
        render: (text, obj) => (
          <Popover content={<CompanyPopoverOverview uuid={obj.id} />}>
            <Link to={'/research/companies/' + obj.id} style={{ borderBottom: '1px dotted' }}>
              <strong>{text}</strong>
            </Link>
          </Popover>
        ),
      },
    ],
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Homepage',
    dataIndex: 'homepage_url',
    key: 'homepage_url',
    width: '200px',
    ellipsis: true,
    children: [
      {
        title: <Input placeholder="filter homepage" name="homepage_url" onChange={onChange} />,
        dataIndex: 'homepage_url',
        key: 'homepage_url',
        render: text => (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ),
      },
    ],
    sorter: (a, b) => {
      if (!a.homepage_url) a.homepage_url = '';
      if (!b.homepage_url) b.homepage_url = '';
      return a.homepage_url.localeCompare(b.homepage_url);
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    width: '200px',
    children: [
      {
        title: <Input placeholder="filter location" name="location" onChange={onChange} />,
        dataIndex: 'location',
        key: 'location',
      },
    ],
    sorter: (a, b) => a.location.localeCompare(b.location),
    ellipsis: true,
  },
  {
    title: 'Founded',
    dataIndex: 'founded_on',
    key: 'founded_on',
    width: '150px',
    ellipsis: true,
    children: [
      {
        title: (
          <Input
            type="date"
            addonBefore={
              <Select defaultValue="<" suffixIcon={null} name="founded_on" onChange={onChange}>
                <Option value="<">{'<'}</Option>
                <Option value=">">{'>'}</Option>
                <Option value="=">{'='}</Option>
              </Select>
            }
            placeholder="filter founded"
            name="founded"
            onChange={onChange}
          />
        ),
        dataIndex: 'founded_on',
        key: 'founded_on',
        render: text => text && <Moment date={text} format="MMMM, YYYY" />,
      },
    ],
    sorter: (a, b) => a.founded_on - b.founded_on,
  },
  {
    title: 'Markets',
    dataIndex: 'markets',
    width: '200px',
    children: [
      {
        title: <Input placeholder="filter markets" name="markets" onChange={onChange} />,
        dataIndex: 'markets',
        key: 'markets',
        render: (markets, org) =>
          markets && (
            <>
              {markets.length > 0 && (
                <Tag key={`market-${markets[0].id}`}>
                  <Link
                    to={`/markets/${
                      markets[0].name && markets[0]?.name?.toLowerCase
                        ? markets[0].name
                            .toLowerCase()
                            .split(' ')
                            .join('-')
                        : markets[0].id
                    }`}
                  >
                    {markets[0].name}
                  </Link>
                </Tag>
              )}
              {markets.length > 1 && (
                <Popover
                  title={`${org.name} - Markets`}
                  content={
                    <div style={{ width: '150px' }}>
                      {markets.map(obj => {
                        return (
                          <Tag key={`market-${obj.id}`}>
                            <Link
                              to={`/markets/${obj.name
                                .toLowerCase()
                                .split(' ')
                                .join('-')}`}
                            >
                              {obj.name}
                            </Link>
                          </Tag>
                        );
                      })}
                    </div>
                  }
                >
                  <Button type="link" size="small">
                    <strong>...</strong>
                  </Button>
                </Popover>
              )}
            </>
          ),
      },
    ],
  },
];
const columns = [
  {
    dataIndex: 'id',
    key: 'id',
    width: '32px',
    fixed: 'left',
    render: (text, obj) =>
      obj.id &&
      obj.name &&
      !obj.isLoading && (
        <AvatarFailover
          key={`avatar-${obj.id}`}
          src={logoURL(obj.id)}
          backupSrc={obj.logo_url}
          name={obj.name}
          shape="square"
          size={'small'}
        />
      ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '150px',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text, obj) => (
      <Popover content={<CompanyPopoverOverview uuid={obj.id} />}>
        <Link to={'/research/companies/' + obj.id}>
          <strong>{text}</strong>
        </Link>
      </Popover>
    ),
  },
  {
    title: 'Homepage',
    dataIndex: 'homepage_url',
    key: 'homepage_url',
    width: '200px',
    ellipsis: true,
    sorter: (a, b) => {
      if (!a.homepage_url) a.homepage_url = '';
      if (!b.homepage_url) b.homepage_url = '';
      return a.homepage_url.localeCompare(b.homepage_url);
    },
    render: text => {
      let url = typeof text === 'string' ? text.split('?')[0] : '';
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    width: '200px',
    sorter: (a, b) => a.location.localeCompare(b.location),
    ellipsis: true,
  },
  {
    title: 'Founded',
    dataIndex: 'founded_on',
    key: 'founded_on',
    width: '150px',
    ellipsis: true,
    sorter: (a, b) => a.founded_on - b.founded_on,
    render: text => text && <Moment date={text} format="MMMM, YYYY" />,
  },
  {
    title: 'Markets',
    dataIndex: 'markets',
    width: '200px',
    render: (markets, org) =>
      markets && (
        <>
          {markets.length > 0 && (
            <Tag key={`market-${markets[0].id}`}>
              <Link to={`/markets/${markets[0].id}`}>{markets[0].name}</Link>
            </Tag>
          )}
          {markets.length > 1 && (
            <Popover
              title={`${org.name} - Markets`}
              content={
                <div style={{ width: '150px' }}>
                  {markets.map(obj => {
                    return (
                      <Tag key={`market-${obj.id}`}>
                        <Link to={`/markets/${obj.id}`}>{obj.name}</Link>
                      </Tag>
                    );
                  })}
                </div>
              }
            >
              <Button type="link" size="small">
                <strong>...</strong>
              </Button>
            </Popover>
          )}
        </>
      ),
  },
];
const data = [0, 1, 2, 3, 4].map(i => {
  return {
    id: `${i}-loader-key-company-search`,
    name: <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />,
    homepage_url: <Skeleton.Input style={{ width: 150 }} active={true} size={'small'} />,
    location: <Skeleton.Input style={{ width: 100 }} active={true} size={'small'} />,
    markets: [{ name: <Skeleton.Input style={{ width: 50 }} active={true} size={'small'} /> }],
    isLoading: true,
  };
});

let filterTimeout = null;
const filterOps = {
  '=': (a, b) => {
    return a === b;
  },
  '<': (a, b) => {
    return a < b;
  },
  '>': (a, b) => {
    return a > b;
  },
};

const SearchCompanies = ({ search, sf, filterable, countCallback = c => {} }) => {
  const query = search;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchResults, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filters, setFilters] = useState({});
  const [errorText, setError] = useState('');

  const updateResults = results => {
    setResults(results);
    setFilteredResults(results);
    countCallback(results.length);
  };

  let performSearch = async () => {
    let qry = query.replace(/https?:\/\//i, '');
    let result = await getSearchResult(qry);

    let results = [];
    if (result.companies && result.companies.length > 0) {
      results = result.companies.map(c => ({
        ...c,
        markets: c.categories,
        location: (c.state_code || c.city) + (c.country_code ? ', ' + c.country_code : ''),
      }));
    }
    updateResults(results);
    setError('');
    setLoading(false);
  };

  const applyFilters = filters => {
    let filteredResults = [...searchResults].filter(result => {
      // static check
      if (filters.name && result.name.toLowerCase().indexOf(filters.name.toLowerCase()) < 0) return false;
      if (filters.homepage_url && result.homepage_url.toLowerCase().indexOf(filters.homepage_url.toLowerCase()) < 0)
        return false;
      if (filters.location && result.location.toLowerCase().indexOf(filters.location.toLowerCase()) < 0) return false;
      if (
        (filters.markets &&
          result.category_list &&
          result.category_list.toLowerCase().indexOf(filters.markets.toLowerCase()) < 0) ||
        !result.category_list
      )
        return false;

      if (filters.founded && result.founded_on) {
        return filterOps[filters.founded_op || '<'](result.founded_on, new Date(filters.founded).getTime());
      } else if (filters.founded && !result.founded_on) {
        return false;
      }

      return true;
    });

    setFilteredResults(filteredResults);
  };

  const onFilter = event => {
    clearTimeout(filterTimeout);
    let newFilters = { ...filters };
    if (event.target) {
      newFilters[event.target.name] = event.target.value;
    } else {
      newFilters.founded_op = event;
    }
    setFilters(newFilters);
    filterTimeout = setTimeout(() => applyFilters(newFilters), 750);
  };

  useEffect(() => {
    document.title = `${query} ${process.env.REACT_APP_SITE_TITLE_SUFFIX}`;
    setLoading(true);
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      <Card bodyStyle={{ padding: '0px' }} style={{ marginTop: '15px' }}>
        <Table
          rowKey="id"
          columns={filterable ? columnsFilter(onFilter, filters) : columns}
          dataSource={
            loading
              ? data
              : filteredResults.map((org, i) => {
                  return {
                    ...org,
                    key: i,
                  };
                })
          }
          size="middle"
          scroll={{ x: 700 }}
          pagination={{
            current: page,
            pageSize: 8,
          }}
          onChange={paging => setPage(paging.current)}
          title={() => (
            <>
              {loading && <LoadingOutlined />}
              {!loading && !errorText && <CheckCircleOutlined />}
              {!errorText && (
                <span style={{ margin: '0px 6px' }}>
                  {loading && <>Searching for </>}
                  {!loading && (
                    <>
                      Showing <i>{searchResults.length}</i> results for{' '}
                    </>
                  )}
                  <i style={{ color: 'var(--blue)' }}>{search}</i>
                </span>
              )}
              {!loading && errorText && (
                <>
                  <ExclamationCircleOutlined />
                  <span style={{ margin: '0px 6px' }}>
                    Error while searching for
                    <i style={{ color: 'var(--blue)' }}>{search}</i>
                  </span>
                </>
              )}
            </>
          )}
        />
      </Card>
    </>
  );
};

export default withRouter(SearchCompanies);
