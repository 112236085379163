import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useAccounts from 'store/Accounts';
import { useLocalStorage } from 'hooks';
import ErrorBoundary from 'ErrorBoundary';

const wrapStyles = {
  fontSize: '12px',
  padding: '5px',
  textAlign: 'center',
  backgroundColor: 'var(--yellow)',
  alignItems: 'center',
};

const PlatformNotifications = () => {
  const history = useHistory();
  const today = new Date().toISOString().split('T')[0];
  const { upgradeNotification } = useAccounts(state => ({
    upgradeNotification: state.upgradeNotification,
  }));
  const [settings, setHideNotification] = useLocalStorage(`setting:upgrade_notification`, null);
  const { type, data } = upgradeNotification();

  const showBanner = type => {
    const setting = settings?.[type] ?? null;
    if (setting && setting?.lastUpdated === today) {
      return setting.value; // true or false
    }
    return true;
  };

  const onHideNotification = () => {
    const updatedSettings = {
      ...settings,
      [type]: {
        value: false,
        lastUpdated: today,
      },
    };
    setHideNotification(updatedSettings);
  };

  return (
    <ErrorBoundary justText={true}>
      {type !== 'NONE' && showBanner(type) ? (
        <div style={wrapStyles}>
          <div>
            <span style={{ marginRight: '10px' }}>
              {type === 'UPGRADE_TRIAL_ACCT'
                ? `You have ${data.days} day${data.days === 1 ? '' : 's'} left of your Fuel Pro trial`
                : type === 'UPGRADE_FREE_ACCT'
                ? `Elevate Your Experience with Fuel Plus!`
                : null}
            </span>
            <Button
              onClick={() => history.push(`/accounts/${data.account?.accountID}#billing`)}
              type="primary"
              size="small"
              style={{ fontSize: '12px' }}
            >
              Upgrade Now
            </Button>
            <Button
              onClick={onHideNotification}
              style={{ float: 'right', fontSize: '12px' }}
              type="text"
              size="small"
              icon={<CloseOutlined />}
            />
          </div>
        </div>
      ) : null}
    </ErrorBoundary>
  );
};

export default PlatformNotifications;
