import React from 'react';
import { Typography, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Fade from 'components/utility/Fade';
import Dots from './Dots';

const { Title, Paragraph, Text } = Typography;

const Welcome = ({ isVisible }) => {
  return (
    <div style={{ maxWidth: '450px' }}>
      <Fade animation="plain" isVisible={isVisible}>
        <Title level={4} className="mute-title fuel-title-text" style={{ color: 'var(--brand)', position: 'relative' }}>
          <div
            className="pattern"
            style={{ height: '300px', width: '200px', position: 'absolute', left: '-100px', top: '-100px' }}
          >
            <Dots />
          </div>
          Welcome To York IE Fuel
        </Title>
      </Fade>
      <Space direction="vertical" size="large">
        <Fade transformY="0" transformX="2" isVisible={isVisible}>
          <Paragraph className="font-16" style={{ color: '#FFF' }}>
            Your One-Stop Shop for Business Growth
            <br />
            With York IE Fuel, you can:
          </Paragraph>
        </Fade>
        <Fade delay="delay-1" transformY="10" isVisible={isVisible}>
          <Space align="start">
            <CheckOutlined style={{ color: 'var(--brand)', fontSize: '22px' }} />{' '}
            <Text className="font-16" style={{ color: '#FFF' }}>
              Conduct market research and competitive intelligence
            </Text>
          </Space>
        </Fade>

        <Fade delay="delay-2" transformY="10" isVisible={isVisible}>
          <Space align="start">
            <CheckOutlined style={{ color: 'var(--brand)', fontSize: '22px' }} />{' '}
            <Text className="font-16" style={{ color: '#FFF' }}>
              Find the right investors for your market and stage
            </Text>
          </Space>
        </Fade>

        <Fade delay="delay-3" transformY="10" isVisible={isVisible}>
          <Space align="start">
            <CheckOutlined style={{ color: 'var(--brand)', fontSize: '22px' }} />{' '}
            <Text className="font-16" style={{ color: '#FFF' }}>
              Use tools and templates to build your company
            </Text>
          </Space>
        </Fade>

        <Fade delay="delay-4" transformY="10" isVisible={isVisible}>
          <Space align="start">
            <CheckOutlined style={{ color: 'var(--brand)', fontSize: '22px' }} />{' '}
            <Text className="font-16" style={{ color: '#FFF' }}>
              Get help from expert advisors
            </Text>
          </Space>
        </Fade>
      </Space>
    </div>
  );
};

export default Welcome;
