import { Client, ClientEvents, SessionManager, PushEventType } from '@quebicapp/quebicjs';
let config = {
  api_endpoint: 'https://api.quebic.com',
  cdn_endpoint: 'https://cdn.quebic.com',
  mediaext_endpoint: 'https://mediaext.quebic.com',
  gateway_endpoint: 'wss://gateway.quebic.com',
  app_endpoint: 'https://app.quebic.com',
};
const CLIENT = new Client(config);

const SESSION = new SessionManager(CLIENT);

let client = CLIENT;
//let PATH = '/';

class QuebicSDK {
  qToken = null;
  appToken = null;
  client = CLIENT;
  session = SESSION;
  spaceId = null;

  // callbacks
  onMessageCreatedHandler = [];
  onMessageDeletedHandler = [];
  onReadyHandler = [];
  onStatusHandler = [];
  onNeedAuthHandler = [];
  onInitHandler = [];

  constructor(space_id, onInit) {
    if (!space_id) {
      throw new Error('QuebicSDK: space_id is required');
    }
    if (onInit) {
      this.addInitCallback(onInit);
    }
    this.spaceId = space_id;
  }

  addInitCallback = callback => {
    this.onInitHandler = callback;
  };

  addMessageCallback = callback => {
    this.onMessageCreatedHandler.push(callback);
  };

  addDeleteMessageCallback = callback => {
    this.onMessageDeletedHandler.push(callback);
  };

  addReadyCallback = callback => {
    this.onReadyHandler.push(callback);
  };

  addStatusCallback = callback => {
    this.onStatusHandler.push(callback);
  };

  addNeedAuthCallback = callback => {
    this.onNeedAuthHandler.push(callback);
  };

  onInit = user => {
    if (this.onInitHandler) {
      this.onInitHandler(user);
    }
  };

  handle_auth_error = () => {
    this.onNeedAuthHandler.forEach(handler => {
      if (handler !== undefined) {
        handler();
      }
    });
  };

  notifyOnStatus = id => {
    this.onStatusHandler.forEach(handler => {
      if (handler !== undefined) {
        try {
          handler(id);
        } catch (ex) {
          console.log(ex);
        }
      }
    });
  };

  onEvent = event => {
    switch (event.type) {
      case PushEventType.ChannelCreated: {
        // Channel Created
        console.log(`\n< event:ChannelCreated`, event);
        break;
      }
      case PushEventType.ChannelDeleted: {
        // Channel Deleted
        console.log(`\n< event:ChannelDeleted`, event);
        break;
      }
      case PushEventType.ChannelUpdated: {
        // Channel Updated
        console.log(`\n< event:ChannelUpdated`, event);
        break;
      }
      case PushEventType.MessageDeleted: {
        // Message Deleted
        console.log(`\n< event:MessageDeleted`, event);
        this.onMessageDeletedHandler.forEach(handler => {
          if (handler !== undefined) {
            handler(event.data);
          }
        });
        break;
      }
      case PushEventType.MessageCreated: {
        // Message created
        console.log(`\n< event:MessageCreated`, event);
        this.onMessageCreatedHandler.forEach(handler => {
          if (handler !== undefined) {
            handler(event.data);
          }
        });
        break;
      }
      case PushEventType.MessageUpdated: {
        // Message Updated
        console.log(`\n< event:MessageUpdated`, event);
        break;
      }
      case PushEventType.SpaceDeleted: {
        // Space Deleted
        console.log(`\n< event:SpaceDelete`, event);
        break;
      }
      case PushEventType.SpaceUpdated: {
        // Space updated
        console.log(`\n< event:SpaceUpdate`, event);
        break;
      }
      case PushEventType.ChannelJoin: {
        // Channel join
        console.log(`\n< event:ChannelJoin`, event);
        break;
      }
      case PushEventType.ChannelLeave: {
        // Channel leave
        console.log(`\n< event:ChannelLeave`, event);
        break;
      }
      case PushEventType.SpaceJoin: {
        // Space join
        console.log(`< event:SpaceJoin`, event);
        break;
      }
      case PushEventType.SpaceLeave: {
        // Space leave
        console.log(`\n< event:SpaceLeave`, event);
        break;
      }
      case PushEventType.MessageReactionAdd: {
        // Message reaction add
        console.log(`\n< event:MessageReactionAdd`, event);
        break;
      }
      case PushEventType.MessageReactionRemove: {
        // Message reaction remove
        console.log(`\n< event:MessageReactionRemove`, event);
        break;
      }
      case PushEventType.MessageReactionRemoveAll: {
        // Message reaction remove all
        console.log(`\n< event:MessageReactionRemoveAll`, event);
        break;
      }
      case PushEventType.UserStartedTyping: {
        // User typing
        console.log(`\n< event:Typing`, event);
        break;
      }
      default: {
        break;
      }
    }
  };

  onReady = event => {
    console.log('onReady', event);
    // pass user info
    this.onInit(event);
  };

  onReadySupplemental = event => {
    console.log('onReadySupplemental', event);
  };

  sessionError = () => {
    console.log('failed to establish session');
    this.onInit(null);
  };

  sessionCallback = qToken => {
    localStorage.setItem('qToken', JSON.stringify(qToken));
    this.qToken = qToken;

    client.on(ClientEvents.PushEvent, this.onEvent);
    client.on(ClientEvents.Ready, this.onReady);
    client.on(ClientEvents.ReadySupplemental, this.onReadySupplemental);
    client.on(ClientEvents.Error, event => console.log('ClientEvents.Error', event));
    //client.on(ClientEvents.MemberListUpdate, this.onMemberUpdate);
  };

  quebicSession = (qToken, appToken) => {
    this.appToken = appToken;
    return new Promise((resolve, reject) => {
      if (client.sessionToken !== null) {
        // have existing token (typically when debugging locally)
        this.sessionCallback(qToken);
        resolve(qToken);
      } else {
        ////		console.log(`calling session.login sub:${appToken.user.sub} username:${appToken.user.username}`);
        SESSION.login(qToken)
          .then(() => {
            this.sessionCallback(qToken);
            resolve(qToken);
          })
          .catch(ex => {
            reject(ex);
          });
      }
    });
  };

  quebicOAUTHSession = (provider, idToken, sub, email) => {
    this.appToken = { idToken, user: { sub, username: email } };

    return new Promise((resolve, reject) => {
      console.log(`ESTABLISH OAUTH ID_TOKEN session for provider:${provider} sub:${sub} email:${email}`);

      client.oauth
        .login(provider, 'token', idToken, '')
        .then(qToken => {
          this.quebicSession(qToken, this.appToken).then(result => {
            resolve(result);
          });
        })
        .catch(loginErr => {
          // Likely a 409 account already exists
          reject(loginErr);
        });
    });
  };
}

export default QuebicSDK;
