import { API } from '@aws-amplify/api';

const getTrendingKeywords = async () => {
  let url = `/api/keywords/trending`;
  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving trending keywords', e);
    return { error: 'Failed to fetch trending keywords' };
  }
};

const getKeywordArticles = async keywords => {
  const queryString = keywords.reduce((qs, keyword, index) => {
    return index < 1 ? qs + 'name=' + keyword : qs + '&name=' + keyword;
  }, '');

  let url = `/api-public/keywords/articles?${queryString}`;
  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving keyword articles', e);
    return { error: 'Failed to fetch keyword articles' };
  }
};

const getKeywordHistory = async keywords => {
  const queryString = keywords.reduce((qs, keyword, index) => {
    return index < 1 ? qs + 'name=' + keyword : qs + '&name=' + keyword;
  }, '');

  let url = `/api-public/keywords/history?${queryString}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving keyword history', e);
    return { error: 'Failed to fetch keyword history' };
  }
};

const sdk = {
  getKeywordArticles,
  getKeywordHistory,
  getTrendingKeywords,
};

export default sdk;
