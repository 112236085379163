import React from 'react';
import { useLocation } from 'react-router-dom';
import { Space, Tooltip } from 'antd';
import Icon, { FacebookFilled, LinkedinFilled, ShareAltOutlined } from '@ant-design/icons';
import Facebook from 'components/svgs/Facebook';
import Linkedin from 'components/svgs/Linkedin';
import ShareIcon from 'components/svgs/Share';
import { navigatorShare } from 'components/NavigatorShare';
import TwitterLightgreen from 'images/twitter_bg_lightgreen_color_white.svg';

const TwitterLogoComponent = () => {
  const { pathname } = useLocation();
  const page = pathname.includes('/news/article/') ? 'news-article' : 'other';

  return (
    <img
      src={TwitterLightgreen}
      alt="X (formerly Twitter)"
      style={{ width: page === 'news-article' ? '12.5px' : '14px' }}
    />
  );
};

const TwitterIcon = () => {
  return <Icon component={TwitterLogoComponent} />;
};

const twitterShare = (title, link, organizationName) => {
  return (
    'https://twitter.com/intent/tweet?text=' +
    encodeURIComponent(title + '\n\n' + link + organizationName + ' via @YorkGrowth')
  );
};

const linkedInShare = (title, link) => {
  return (
    'https://www.linkedin.com/shareArticle??mini=true&url=' +
    encodeURIComponent(link) +
    '&title' +
    encodeURIComponent(title) +
    '&source=@YorkGrowth'
  );
};

const facebookShare = link => {
  return 'https://facebook.com/sharer/sharer.php?u=' + encodeURIComponent(link);
};

const Share = ({ title, url, style, organizationName, org }) => {
  const parseTwitterHandle = url => {
    let orgName = organizationName ? ` By ${organizationName}` : ' ';
    if (!url) {
      return orgName;
    }
    let parsed = url
      .replace('https://', '')
      .replace('http://', '')
      .replace('twitter.com/', '')
      .split('?');

    return parsed.length > 0 ? ` By @${parsed[0]}` : orgName;
  };

  const source = parseTwitterHandle(org?.twitter_url);
  return (
    <Space style={style} className="article-detail-top-right-socials">
      <Tooltip title="Facebook">
        <a href={facebookShare(url)} className="light" target="_blank" rel="noopener noreferrer">
          <FacebookFilled />
        </a>
      </Tooltip>
      <Tooltip title="X (formerly Twitter)">
        <a href={twitterShare(title, url, source)} className="light" target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </a>
      </Tooltip>
      <Tooltip title="LinkedIn">
        <a href={linkedInShare(title, url)} className="light" target="_blank" rel="noopener noreferrer">
          <LinkedinFilled />
        </a>
      </Tooltip>
      <Tooltip title="Share">
        <span
          onClick={event => {
            event.preventDefault();
            navigatorShare(url, title);
          }}
          style={{ cursor: 'pointer' }}
          className="light"
          rel="noopener noreferrer"
        >
          <ShareAltOutlined />
        </span>
      </Tooltip>
    </Space>
  );
};

const ShareDark = ({ title, url, style, organizationName, org }) => {
  const parseTwitterHandle = url => {
    let orgName = organizationName ? ` By ${organizationName}` : ' ';
    if (!url) {
      return orgName;
    }
    let parsed = url
      .replace('https://', '')
      .replace('http://', '')
      .replace('twitter.com/', '')
      .split('?');

    return parsed.length > 0 ? ` By @${parsed[0]}` : orgName;
  };

  const source = parseTwitterHandle(org?.twitter_url);
  return (
    <Space style={style}>
      <Tooltip title="Facebook">
        <a href={facebookShare(url)} className="light" target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
      </Tooltip>
      <Tooltip title="X (formerly Twitter)">
        <a href={twitterShare(title, url, source)} className="light" target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </a>
      </Tooltip>
      <Tooltip title="LinkedIn">
        <a href={linkedInShare(title, url)} className="light" target="_blank" rel="noopener noreferrer">
          <Linkedin />
        </a>
      </Tooltip>
      <Tooltip title="Share">
        <span
          onClick={event => {
            event.preventDefault();
            navigatorShare(url, title);
          }}
          style={{ cursor: 'pointer' }}
          className="light"
          rel="noopener noreferrer"
        >
          <ShareIcon />
        </span>
      </Tooltip>
    </Space>
  );
};

export default Share;
export { ShareDark, TwitterIcon };
