import { create } from 'zustand';
import { getAvatarURL } from 'helpers/Account';

const useProfile = create((set, get) => ({
  profileAvatar: null,

  // async function getBase64(url) {
  //   let response = await axios.get(url, {
  //     responseType: 'arraybuffer',
  //   });
  //   return await Buffer.from(response.data, 'binary').toString('base64');
  // }

  getProfileAvatar: async () => {
    let avatarURL = null;
    // let avatarb64 = localStorage.getItem('localAvatar');
    // if (avatarb64) {
    //   console.log('returning base64 version of avatar from localstorage');
    //   return avatarb64;
    // }
    try {
      let res = await getAvatarURL();
      if (res.hasOwnProperty('error')) {
        // message.error({ content: res.error, duration: 5 });
        console.log('avatar error', res.error);
      } else {
        avatarURL = res[0].avatar;
      }
    } catch (e) {
      console.log(e.message);
    }

    // if (avatarURL) {
    //   try {
    //     let bi = await getBase64(avatarURL);
    //     localStorage.setItem('localAvatar', bi);
    //     console.log('base64', bi);
    //   } catch (e) {
    //     console.log('error fetching avatar');
    //   }
    // }

    return avatarURL;
  },

  // set user profile avatar
  setProfileAvatar: res => {
    set({ profileAvatar: res });
  },

  // get user profile avatar
  getUserProfileAvatar: async () => {
    const res = await get().getProfileAvatar();
    get().setProfileAvatar(res);
    return true;
  },
}));

export default useProfile;
