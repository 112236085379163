import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { List, Space, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getNews } from 'helpers/News';

import './PopoverLocation.css';

const { Paragraph } = Typography;

const LocationPopover = ({ city, state, country }) => {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [loadingWeather, setLoadingWeather] = useState(true);
  const [weather, setWeather] = useState({});

  // Set weatherURL
  let weatherUrl = 'https://api.openweathermap.org/data/2.5/weather?q=';
  if (country === 'USA') {
    weatherUrl += `${city},${state},US&appid=${process.env.REACT_APP_WEATHER_APP_ID}`;
  } else {
    weatherUrl += `${city},${state},${country}&appid=${process.env.REACT_APP_WEATHER_APP_ID}`;
  }

  // Set location
  let location = '';
  let searchUrl = [];
  let searchTopUrl = '';
  let searchLatestUrl = '';
  if (city) {
    location += city;
    searchUrl.push(`city_${city}`);
  }
  if (state) {
    location += location === '' ? state : `, ${state}`;
    searchUrl.push(`stateCode_${state}`);
  }
  if (country) {
    //location += location === '' ? country : `, ${country}`;
    searchUrl.push(`countryCode_${country}`);
  }
  searchTopUrl = `/companies/search?location=${searchUrl.join('~')}&sort=total_funding_usd`;
  searchLatestUrl = `/companies/search?location=${searchUrl.join('~')}&sort=last_funding_on`;

  // Adjust time
  const localTime = offset => {
    let time = new Date(new Date().getTime() + parseInt(offset) * 1000);
    let utcHours = time.getUTCHours();
    let rHours = utcHours % 12;
    let hours = rHours ? rHours : 12;
    let minutes = time
      .getUTCMinutes()
      .toString()
      .padStart(2, '0');
    let meridiem = time.getUTCHours() >= 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${meridiem}`;
  };

  useEffect(() => {
    getNews({ rssSearch: `loc:${location.replace(/,/g, '')}`, noSort: true })
      .then(data => {
        setNews(data.items.slice(0, 3));
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });

    fetch(weatherUrl)
      .then(response => response.json())
      .then(data => {
        if (data.cod !== 200) {
          throw new Error(data.message);
        }
        setWeather({
          temp: Math.round((data.main.temp - 273.15) * 1.8 + 32),
          description: data.weather[0].description,
          icon: data.weather[0].icon,
          localTime: localTime(data.timezone),
        });
        setLoadingWeather(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingWeather(false);
      });
  }, [location, weatherUrl]);

  return (
    <div style={{ width: '400px' }}>
      {(loading || loadingWeather) && <LoadingOutlined />}
      {!loading && !loadingWeather && (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <List
            loading={loading}
            size={'small'}
            dataSource={news}
            header={
              <h3>
                <Space size={'large'}>
                  <span>{`${location}`}</span>
                  {'localTime' in weather && weather.localTime}
                  {'temp' in weather && <span>{weather.temp}&#8457;</span>}
                  {'icon' in weather && (
                    <img src={`http://openweathermap.org/img/wn/${weather.icon}.png`} alt={weather.description} />
                  )}
                </Space>
              </h3>
            }
            renderItem={item => (
              <List.Item style={{ padding: '3px 0px 0px 0px', border: '0px' }}>
                <Paragraph ellipsis={{ rows: 1 }} style={{ padding: '0px' }}>
                  <a target="_blank" rel="noopener noreferrer" href={item.link}>
                    {item.title}
                  </a>
                </Paragraph>
              </List.Item>
            )}
            footer={
              <small>
                See more from this location:
                <br />
                <Link to={searchTopUrl}>Top Funded Companies</Link>
                <span style={{ margin: '0px 10px' }}>|</span>
                <Link to={searchLatestUrl}>Latest Funded Companies</Link>
              </small>
            }
          />
        </div>
      )}
    </div>
  );
};

export default LocationPopover;
