import React, { useState, useEffect } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { Button } from 'antd';
import Tour from 'reactour';

import { FeedSteps, NewDashboardFeedSteps, WatchlistPageFeedSteps } from './steps/Feed';
import { shallow } from 'zustand/shallow';
import useTours from 'store/Tours';

import sdk from 'sdk/Accounts';

import './UserTours.css';

// map url location to tour configuration
// key: unique key to be used for the tour
// state: the current state of the user (i.e. have they seen this tour yet)
const locationMap = {
  '/research/watchlists/updates': { key: 'home', state: 1 },
  '/research/watchlists/updates/': { key: 'home', state: 1 },
  '/research/feed': { key: 'feed', state: 1 },
  '/research/feed/': { key: 'feed', state: 1 },
  '/research/home': { key: 'newDashboardFeed', state: 1 },
  '/research/home/': { key: 'newDashboardFeed', state: 1 },
  '/research/watchlists/:watchlistId/:tab': { key: 'watchlistPage', state: 1 },
  '/research/watchlists/:watchlistId/:tab/': { key: 'watchlistPage', state: 1 },
};

// map tour configuration key to tour steps
// checklist is reserved for the dashboard TODO checklist for first time users
const pageSteps = {
  checklist: null,
  home: FeedSteps,
  feed: FeedSteps,
  newDashboardFeed: NewDashboardFeedSteps,
  watchlistPage: WatchlistPageFeedSteps,
};

const UserTours = () => {
  const { tours, loading, updateTours, setTourLoading } = useTours(
    state => ({
      tours: state.tours,
      loading: state.loading,
      updateTours: state.updateTours,
      setTourLoading: state.setTourLoading,
    }),
    shallow
  );
  const history = useHistory();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [steps, setSteps] = useState([]);
  const [tour, setTour] = useState(null);

  const historyListener = () => {
    // if we've received the user's tour state
    if (!loading) {
      const pathName = history.location.pathname;
      let tourConfig = locationMap[pathName];

      // checks for paths with :params
      if (!tourConfig) {
        const currentPageKey = Object.keys(locationMap).find(key => !!matchPath(pathName, { path: key, exact: true }));
        tourConfig = locationMap[currentPageKey];
      }
      // if a tour is defined for this page/window.location
      if (tourConfig) {
        const userTour = tours[tourConfig.key];
        // set tour if user has not already viewed and acknowledged it
        if (!userTour || userTour < tourConfig.state) {
          // set current tour config
          setTour(tourConfig);
          setSteps(pageSteps[tourConfig.key]);
          setIsTourOpen(true);
        } else {
          // if user navigate to another page by browser's forward or back button without completing tour of current page
          setIsTourOpen(false);
        }
      } else {
        // if user navigate to another page by browser's forward or back button without completing tour of current page
        setIsTourOpen(false);
      }
    }
  };

  const incrementTourState = async () => {
    setTourLoading(true);
    const userTour = tours[tour.key];
    let updatedTours = { ...tours, [tour.key]: userTour ? userTour + 1 : 1 };
    await sdk.updateTours(updatedTours);
    updateTours(updatedTours);
    setIsTourOpen(false);
  };

  useEffect(() => {
    // update state and check for tours with each history change
    const unlisten = history.listen(historyListener);
    historyListener();
    return () => {
      unlisten();
    };
    // eslint-disable-next-line
  }, [JSON.stringify(tours)]);

  return (
    <Tour
      key={history.location.key}
      accentColor="#00b48a"
      className="fuel-tour"
      steps={steps}
      isOpen={isTourOpen}
      maskSpace={0}
      onRequestClose={incrementTourState}
      rounded={4}
      scrollDuration={100}
      disableInteraction={true}
      onAfterOpen={target => (document.body.style.overflowY = 'hidden')}
      onBeforeClose={target => (document.body.style.overflowY = 'auto')}
      lastStepNextButton={
        <Button type="primary" loading={loading}>
          Got it!
        </Button>
      }
      closeWithMask={false}
    />
  );
};

export default UserTours;
export { locationMap };
