import React from 'react';

const Content = ({ maxWidth = '1400px', layoutPadding = true, children, style }) => {
  return (
    <div className={layoutPadding ? 'layout-padding' : 'layout-padding-none'} style={style ?? {}}>
      <div className="layout-max-width" style={{ maxWidth }}>
        {children}
      </div>
    </div>
  );
};

export default Content;
