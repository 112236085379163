import React, { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { getUsers } from 'pages/admin/helpers/Users';

let searchTimeout = null;

const SearchUsers = ({ onSelect, onDeselect, selected, options = [], placeholder = 'Search users...' }) => {
  const [result, setResult] = useState([]);
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  const searchAll = async searchText => {
    setLoading(true);
    let searchResult = await getUsers({ Name: 'email', Value: searchText });
    let users = searchResult.map(r => r.Attributes.reduce((a, v) => ({ ...a, [v.Name]: v.Value }), {}));
    setResult(users);
    setLoading(false);
  };

  const handleSearch = value => {
    if (value === '') {
      if (focus) {
        setValue(value);
      }
    } else {
      setValue(value);
    }
    // clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than 750ms
    clearTimeout(searchTimeout);
    // minimum character search
    if (value.length <= 2) {
      setResult([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    // make a new timeout set to go off in 750ms
    // this attempts to wait for the user to stop typing before running the search query
    searchTimeout = setTimeout(function() {
      searchAll(value);
    }, 400);
  };

  const handleChange = (v, opt) => {
    setValue('');
    setResult([]);
  };

  return (
    <Select
      loading={loading}
      prefix={<SearchOutlined />}
      placeholder={placeholder}
      showSearch
      suffixIcon={null}
      notFoundContent={null}
      size={'large'}
      popupMatchSelectWidth={true}
      onSearch={handleSearch}
      onChange={handleChange}
      onDeselect={onDeselect}
      onSelect={onSelect}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      open={focus}
      filterOption={false}
      searchValue={value}
      value={selected}
      className={'search-users-input'}
      popupClassName="search-users"
      ref={ref}
      mode="multiple"
      options={
        loading
          ? [
              {
                value: 'loader',
                disabled: true,
                label: (
                  <div className="loader">
                    <Spin />
                  </div>
                ),
              },
            ]
          : result.map(r => ({
              key: r.sub,
              value: r.sub,
              fullName: `${r.given_name} ${r.family_name}`,
              email: r.email,
              label: (
                <div>
                  <div>
                    {r.given_name} {r.family_name}
                  </div>
                  <div style={{ fontSize: '14px', color: 'rgba(0,0,0,.45)' }}>{r.email}</div>
                </div>
              ),
            }))
      }
    />
  );
};

export default SearchUsers;
