import React, { useEffect, useState } from 'react';
import { Space, Typography, Select, Tooltip, Button, message } from 'antd';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';
import { LoadingOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import AvatarFailover from 'components/AvatarFailover';
import { logoURL } from 'helpers/Organizations';
import Article from 'pages/news/Article';
import CategoryTags from 'pages/categories/CategoryTag';
import KeywordHistory from './History';
import ErrorBoundary from 'ErrorBoundary';
import AsideSection from 'components/layout/AsideSection';
import AuthWall from 'pages/marketPulse/NotLoggedInComponent';
import usePersonalAccount from 'store/PersonalAccount';
import TrendingKeywords from 'pages/keywords/Trending';
import SectionHeader from 'components/layout/SectionHeader';
import EmptyState from 'components/utility/EmptyState';
import MetaTags from 'react-meta-tags';
import sdk from 'sdk/Keywords';
import { API } from '@aws-amplify/api';

import './Articles.css';

const RedirectKeywords = () => {
  var { keyword } = useParams();
  return <Redirect to={`/topics?name=${keyword}`} />;
};

const KeywordWrapper = () => {
  return (
    <ErrorBoundary>
      <KeywordArticles />
    </ErrorBoundary>
  );
};

let searchTimeout = null;
const KeywordSearch = ({ search, selectedkeywords, setSelectedKeywords, searchingArticle }) => {
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [isLoadingKeywords, setIsLoadingKeywords] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const searchAllKeywords = async value => {
    setIsLoadingKeywords(true);
    const result = await API.get('fuelapi', '/api/keywords', { queryStringParameters: { query: value } });
    setIsLoadingKeywords(false);
    setKeywordOptions(pre => result);
  };

  const onSearchkeyword = value => {
    if (value === '') {
      setSearchValue(value);
    } else {
      setSearchValue(value);
    }

    clearTimeout(searchTimeout);

    if (value.length < 3) {
      setKeywordOptions([]);
      return;
    }

    setIsLoadingKeywords(true);
    searchTimeout = setTimeout(function() {
      searchAllKeywords(value);
    }, 400);
  };

  const onSelectKeyword = async (value, opt) => {
    setSelectedKeywords(pre => value);
    setSearchValue('');
  };

  const handleSearchByEnter = event => {
    setKeywordOptions([]);
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      search(selectedkeywords);
    }
  };

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Select
        loading={isLoadingKeywords}
        notFoundContent={null}
        suffixIcon={null}
        filterOption={false}
        mode={'multiple'}
        searchValue={searchValue}
        onSearch={onSearchkeyword}
        onChange={onSelectKeyword}
        onInputKeyDown={handleSearchByEnter}
        value={selectedkeywords}
        placeholder="Filter topics..."
        bordered={true}
        size="large"
        style={{ width: '100%', maxWidth: '350px' }}
        options={
          isLoadingKeywords
            ? [
                {
                  value: 'loading-topics',
                  disabled: true,
                  label: (
                    <div className="loader">
                      <LoadingOutlined />
                    </div>
                  ),
                },
              ]
            : keywordOptions.map(keyword => {
                return { value: keyword, label: <div>{keyword}</div> };
              })
        }
      ></Select>
      <Button
        type="primary"
        size="large"
        icon={<SearchOutlined />}
        loading={searchingArticle}
        onClick={() => search(selectedkeywords)}
      />
    </div>
  );
};

const KeywordArticles = () => {
  const isLoggedIn = usePersonalAccount(state => state.isLoggedIn);
  const [selectedkeywords, setSelectedKeywords] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState([]);
  const { search } = useLocation();
  const keyword = searchKeywords[0];
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchingResult, setSearchingResult] = useState(false);
  const [related, setRelated] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    message.config({ maxCount: 1 });
  }, []);

  //Initially prepopulating keywords from Query string into Filter
  useEffect(() => {
    const searchKeywords = search
      .slice(1)
      .split('&')
      .map(searchKeyword => searchKeyword.split('=')[1]);
    setSearchKeywords(pre => [...searchKeywords]);
  }, [search]);

  const postProcess = articles => {
    // keywords
    let updateRelated = {};
    for (const payload of articles) {
      const {
        article: { keywords },
      } = payload;
      for (const k of keywords) {
        updateRelated[k] = !!updateRelated[k] ? ++updateRelated[k] : 1;
      }
    }
    let entries = Object.entries(updateRelated);
    entries.sort((a, b) => b[1] - a[1]);
    setRelated(
      entries
        .slice(0, 9)
        .map(e => e[0])
        .filter(k => !searchKeywords.includes(k))
    );

    // companies
    let updateCompanies = {};
    let compMap = {};
    for (const payload of articles) {
      const { organization: org } = payload;
      compMap[org.id] = org;
      updateCompanies[org.id] = !!updateCompanies[org.id] ? ++updateCompanies[org.id] : 1;
    }
    let centries = Object.entries(updateCompanies);
    centries.sort((a, b) => b[1] - a[1]);
    setCompanies(centries.slice(0, 8).map(e => compMap[e[0]]));

    // markets
    let updateMarkets = {};
    let markMap = {};
    for (const payload of articles) {
      const { markets } = payload;

      for (const m of markets) {
        markMap[m.id] = m;
        updateMarkets[m.id] = !!updateMarkets[m.id] ? ++updateMarkets[m.id] : 1;
      }
    }
    let mentries = Object.entries(updateMarkets);
    mentries.sort((a, b) => b[1] - a[1]);
    setMarkets(mentries.slice(0, 8).map(e => markMap[e[0]]));
  };

  const fetchArticles = async searchKeywords => {
    setLoading(true);
    setDescription(`Recently published articles that contain the keyword:'${searchKeywords.join(' & ')}'`);
    let result = await sdk.getKeywordArticles(searchKeywords);
    postProcess(result);
    setArticles(result);
    setLoading(false);
    setSearchingResult(false);
  };

  const changeQuerySearchString = async keywords => {
    let queryString = '?';
    const newArr = keywords.map(k => 'name=' + k);
    queryString += newArr.join('&');
    if (window.history.pushState) {
      var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + queryString;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  const renderArticles = articleList => {
    if (articleList.length === 0) {
      return (
        <EmptyState
          title={'No Recent Articles'}
          description={`There are no recent articles for this topic. Check back later or browse the trending topics.`}
        />
      );
    }
    return articleList instanceof Array ? (
      articleList.map((article, i) => {
        return (
          <article key={`article-${i}`} className="article">
            <Article article={article} layout={'list'} images={true} descriptions={true} showCompany={true} />
          </article>
        );
      })
    ) : (
      <></>
    );
  };

  const getRelatedItems = () =>
    Object.keys(related).map((t, i) => ({
      '@type': 'ListItem',
      url: `https://fuel.york.ie/topics?name=${t}`,
      name: t,
    }));

  const getRelatedMarketItems = () =>
    markets.map((t, i) => ({
      '@type': 'ListItem',
      url: `https://fuel.york.ie/markets/${t.id.substring(4)}`,
      label: t,
    }));

  useEffect(() => {
    setSelectedKeywords(pre => [...searchKeywords]);
    if (searchKeywords.length) {
      fetchArticles(searchKeywords);
    } else if (searchingResult) {
      message.warning('You must provide at least one topic', 1);
    }
    // eslint-disable-next-line
  }, [searchKeywords]);

  const searchFilterHandler = selectedkeywordsArray => {
    changeQuerySearchString(selectedkeywordsArray);
    setSearchingResult(true);
    setSearchKeywords(pre => [...selectedkeywordsArray]);
  };

  const pageTitle = `News & Market Information`;
  return (
    <div className="keyword-wrapper">
      {keyword && keyword.length > 0 && (
        <MetaTags>
          <meta id="meta-title" name="title" property="og:title" content={pageTitle} />
          <meta id="meta-type" property="og:type" content="website" />
          <meta id="meta-description" name="description" content={description} />
          <meta id="meta-og-description" property="og:description" content={description} />
          <script id="page-jsonld" type="application/ld+json">
            {JSON.stringify([
              {
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                name: keyword,
                description: description,
                publisher: {
                  '@type': 'Organization',
                  name: 'York IE',
                  logo: {
                    '@type': 'ImageObject',
                    url: 'https://fuel.york.ie/apple-touch-icon.png',
                  },
                },
                breadcrumb: {
                  '@context': 'https://schema.org',
                  '@type': 'BreadcrumbList',
                  itemListElement: [
                    {
                      '@type': 'ListItem',
                      position: 1,
                      name: 'Fuel News',
                      item: 'https://fuel.york.ie/news',
                    },
                    {
                      '@type': 'ListItem',
                      position: 2,
                      name: keyword,
                    },
                  ],
                },
              },
              {
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                name: 'Related Topics',
                itemListElement: getRelatedItems(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                name: 'Related Markets',
                itemListElement: getRelatedMarketItems(),
              },
            ])}
          </script>
          <link
            id="meta-canonical"
            rel="canonical"
            href={`${window.location.origin}/topics?name=${searchKeywords.join('&')}`}
          />
        </MetaTags>
      )}
      <div className="keyword-header">
        <div className="filter-wrapper">
          <div className="filter-title">
            <SectionHeader
              id={'keywords-title'}
              title={isLoggedIn() && 'Explore Topics'}
              subtitle={
                <div>
                  {description}
                  {isLoggedIn() && (
                    <Tooltip title="Find articles by topics">
                      <InfoCircleOutlined style={{ marginLeft: '10px', color: 'rgba(0,0,0,.45)', fontSize: '14px' }} />
                    </Tooltip>
                  )}
                </div>
              }
              subTitleStyles={{ fontSize: '16px' }}
            />
          </div>
          {isLoggedIn() && (
            <KeywordSearch
              searchingArticle={searchingResult && loading}
              search={searchFilterHandler}
              selectedkeywords={selectedkeywords}
              setSelectedKeywords={setSelectedKeywords}
            />
          )}
        </div>
      </div>
      <div className="keyword-body">
        <div className="keyword-content">
          <Space
            className="category-list"
            direction="vertical"
            size="large"
            style={{ width: '100%', marginBottom: '50px' }}
          >
            {!loading && renderArticles(articles)}
            {loading && <LoadingOutlined />}
          </Space>
          {!loading && <AuthWall />}
        </div>
        <div className="keyword-aside">
          {!loading && articles.length === 0 && (
            <ErrorBoundary justText={true}>
              <TrendingKeywords />
            </ErrorBoundary>
          )}
          {!loading && articles.length > 0 && (
            <>
              <AsideSection title="7 Day History">
                <ErrorBoundary justText={true}>
                  <KeywordHistory keywords={searchKeywords} />
                </ErrorBoundary>
              </AsideSection>
              <AsideSection title="Related Topics">
                <div className="article-keywords" style={{ display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
                  {related.map(k => (
                    <Link
                      key={`keyword-${k}`}
                      to={`/topics?name=${k}`}
                      className="article-keyword"
                      style={{
                        backgroundColor: 'var(--charcoal-four)',
                        color: '#FFF',
                        fontSize: '15px',
                        padding: '2px 10px',
                        borderRadius: '50px',
                        fontWeight: '500',
                      }}
                    >
                      {k
                        .split(' ')
                        .map(s => (s.length < 4 ? s.toUpperCase() : s.capitalize()))
                        .join(' ')}
                    </Link>
                  ))}
                </div>
              </AsideSection>
              <AsideSection title="Top Companies">
                <div className="top-companies" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  {companies.map(c => (
                    <div
                      key={`top-${c.id}`}
                      style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '15px', alignItems: 'center' }}
                    >
                      <AvatarFailover
                        key={`avatar-${c.id}`}
                        src={logoURL(c.id)}
                        name={c.name}
                        shape="square"
                        size={'medium'}
                      />
                      <Typography.Text style={{ fontSize: '18px' }}>
                        <Link to={`/research/companies/${c.id}`} style={{ color: 'rgba(0,0,0,.65)' }}>
                          {c.name}
                        </Link>
                      </Typography.Text>
                    </div>
                  ))}
                </div>
              </AsideSection>
              <AsideSection title="Top Markets">
                <CategoryTags
                  tagStyle={{ fontSize: '15px', padding: '2px 10px', borderRadius: '50px', fontWeight: '500' }}
                  items={markets.map((t, i) => ({
                    link: `/markets/${t.id.substring(4)}`,
                    label: t.name,
                  }))}
                />
              </AsideSection>

              <ErrorBoundary justText={true}>
                <TrendingKeywords />
              </ErrorBoundary>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeywordWrapper;
export { RedirectKeywords };
