import { Button } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const ViewMoreButton = ({ style, max, total, setShowAll, showAll }) => {
  return total - max > 0 ? (
    <div
      style={{
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <Button
        onClick={() => setShowAll(!showAll)}
        type="text"
        style={{ fontWeight: '500', color: 'var(--secondary)', textTransform: 'uppercase' }}
      >
        {!showAll ? (
          <>
            {total - max} more <ArrowDownOutlined />
          </>
        ) : (
          <>
            HIDE <ArrowUpOutlined />
          </>
        )}
      </Button>
    </div>
  ) : null;
};

export default ViewMoreButton;
