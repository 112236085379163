import { LoadingOutlined } from '@ant-design/icons';

const LoadingSpinner = ({ style = {}, iconStyle = {}, className = '' }) => {
  return (
    <div
      className={className}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px', ...style }}
    >
      <LoadingOutlined style={{ fontSize: '25px', ...iconStyle }} />
    </div>
  );
};

export default LoadingSpinner;
