import React, { useState, useEffect } from 'react';
import { LoadingOutlined, EllipsisOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import ErrorBoundary from 'ErrorBoundary';
import Stat from 'components/layout/Stat';
import sdk from 'sdk/Keywords';

import './Articles.css';

const KeywordsBox = ({
  keywords = [],
  loading,
  minimum = 15,
  selectedTopics,
  topicSelectHandler,
  selectable = false,
}) => {
  const [more, setMore] = useState(false);
  let showKeywords = more ? keywords : keywords.slice(0, minimum);
  const styleObj = {
    color: 'var(--charcoal-four)',
    border: '1px solid var(--charcoal-four)',
    backgroundColor: '#FFF',
    fontSize: '15px',
    padding: '2px 10px',
    borderRadius: '50px',
    fontWeight: '500',
    cursor: 'pointer',
  };
  return (
    <ErrorBoundary justText={true}>
      {loading && <LoadingOutlined />}
      {!loading && keywords && keywords.length > 0 && (
        <div className="article-keywords" style={{ display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
          {selectable &&
            showKeywords
              .filter(k => !!k.keyword)
              .map(k => (
                <Typography.Text
                  key={`keyword-${k.keyword.split(' ').join('-')}`}
                  onClick={() => {
                    topicSelectHandler(k.keyword);
                  }}
                  className={`article-keyword ${selectedTopics?.includes(k.keyword) ? 'selected' : ''}`}
                  style={styleObj}
                >
                  {k.keyword
                    .split(' ')
                    .map(s => (s.length < 3 ? s.toUpperCase() : s.capitalize()))
                    .join(' ')}
                </Typography.Text>
              ))}

          {!selectable &&
            showKeywords
              .filter(k => !!k.keyword)
              .map(k => (
                <Link
                  key={`keyword-${k.keyword.split(' ').join('-')}`}
                  to={`/topics?name=${k.keyword}`}
                  className="article-keyword"
                  style={styleObj}
                >
                  {k.keyword
                    .split(' ')
                    .map(s => (s.length < 3 ? s.toUpperCase() : s.capitalize()))
                    .join(' ')}
                </Link>
              ))}
          {keywords.length > minimum && (
            <Button
              type="text"
              size="small"
              style={{ fontSize: '18px' }}
              icon={
                !more ? (
                  <EllipsisOutlined style={{ fontSize: '18px', color: 'rgba(0,0,0,.65)' }} />
                ) : (
                  <LeftOutlined style={{ fontSize: '18px', color: 'rgba(0,0,0,.65)' }} />
                )
              }
              onClick={() => setMore(!more)}
            />
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};

const TrendingKeywords = ({ title = 'Trending Topics', minimum }) => {
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchKeywords = async () => {
    setLoading(true);

    let ks = await sdk.getTrendingKeywords();
    console.log('ks', ks);
    if (!ks.error) {
      setKeywords(ks.keywords.map(k => ({ keyword: k })));
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchKeywords();
  }, []);

  return (
    <div className="trending-keywords">
      <Stat label={title}>
        <KeywordsBox keywords={keywords} loading={loading} minimum={minimum} />
      </Stat>
    </div>
  );
};

export default TrendingKeywords;
export { KeywordsBox };
