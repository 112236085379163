import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Tooltip, Avatar } from 'antd';
import { logoURL } from 'helpers/Organizations';
import Skeleton from 'components/utility/Skeleton';
import Logo from 'images/YorkIE_Fuel-logo-grey.svg';
import './AvatarFailover.css';

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  return [ref, loaded, onLoad];
};

const CompanyAvatar = ({ id, name, shape = 'circle', size = 'default' }) => {
  return <AvatarFailover key={`avatar-${id}`} src={logoURL(id)} name={name} shape={shape} size={size} />;
};

const CompanyCard = ({ id, name, domain, shape = 'circle', size = 'default', classes = '', styles = {} }) => {
  return (
    <div className={classes} style={{ display: 'inline-flex', gap: '8px', ...styles }}>
      <CompanyAvatar id={id} name={name} size={size} shape={shape} />

      <div>
        <div style={{ color: 'rgba(0,0,0,.85)', fontWeight: '500', lineHeight: '1.2' }}>{name}</div>
        {domain && <div style={{ color: 'rgba(0,0,0,.65)', fontSize: '14px', lineHeight: '1.2' }}>{domain}</div>}
      </div>
    </div>
  );
};

const AvatarFailover = ({ src, backupSrc, name, shape = 'circle', size = 'default', tooltip = true }) => {
  const [hide, setHide] = useState(src ? false : true);
  const [ref, loaded, onLoad] = useImageLoaded();
  const label = name && name.length > 0 ? name[0].toUpperCase() : 'C';

  const checkPlaceholder = url => {
    /*
    Crunchbase placeholder:
    https://crunchbase-production-res.cloudinary.com/image/upload/h_120,w_120,f_jpg/v1507847753/placeholder_zgpxrr.png
    */
    if (url && url.indexOf('crunchbase') > -1 && url.indexOf('placeholder') > -1) {
      return null;
    }
    return url;
  };

  const onError = () => {
    setHide(true);
    return true;
  };

  const ComponentRender = useCallback(
    ({ children }) => {
      if (tooltip) {
        return (
          <Tooltip title={name} placement="top">
            {children}
          </Tooltip>
        );
      } else {
        return children;
      }
    },
    [tooltip, name]
  );

  return (
    <ComponentRender>
      <span style={{ position: 'relative' }}>
        {!loaded && !hide && (
          <Skeleton style={{ width: '100%', height: '100%', position: 'absolute', left: '0', top: '0' }} />
        )}
        {!hide ? (
          <Avatar
            ref={ref}
            className="fuel-avatar"
            shape={shape}
            size={size}
            src={checkPlaceholder(src)}
            onError={onError}
            alt={name}
            style={{ backgroundColor: 'transparent', opacity: loaded ? '1' : '0' }}
            onLoad={onLoad}
          >
            <img src={Logo} alt={name} />
            {label}
          </Avatar>
        ) : (
          <Avatar
            className="fuel-avatar"
            shape={shape}
            size={size}
            src={checkPlaceholder(backupSrc)}
            alt={name}
            style={{ backgroundColor: 'transparent' }}
          >
            <img src={Logo} alt={name} />
            {label}
          </Avatar>
        )}
      </span>
    </ComponentRender>
  );
};

export default AvatarFailover;
export { CompanyAvatar, CompanyCard };
