import React, { useState, useEffect } from 'react';
import { Avatar, Button, Tooltip } from 'antd';
import { UsergroupAddOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import Auth from '@aws-amplify/auth';
import { accountLogoUrl } from 'utils';
import AWS from 'aws-sdk';
import sdk from 'sdk/Accounts';

const GetSignedURL = async url => {
  let imgURLKey = url.replace('http://', '').replace('https://', '');
  let fileKey = imgURLKey.split(/[/?#]/)[0];
  imgURLKey = imgURLKey.replace(fileKey, '').substring(1);
  const credentials = await Auth.currentCredentials();
  if (credentials.hasOwnProperty('error')) {
    console.error(credentials);
    return null;
  } else {
    const s3 = new AWS.S3({
      region: process.env.REACT_APP_REGION,
      credentials: Auth.essentialCredentials(credentials),
    });
    const params = { Bucket: process.env.REACT_APP_BUCKET_NAME, Key: imgURLKey };
    const avatarURL = s3.getSignedUrl('getObject', params);
    return avatarURL;
  }
};

const ShareAvatar = ({ id, url = '', size = 'default', shape = 'circle', isSigned = true }) => {
  const [loading, setLoading] = useState(false);
  const [signed, setSigned] = useState(url);

  const getSignedURL = async () => {
    let avatarURL = await GetSignedURL(url);
    if (avatarURL) {
      setSigned(avatarURL);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (url.includes('googleusercontent') || url === '') {
      setLoading(false);
      return;
    }
    if (!isSigned) getSignedURL();
    // eslint-disable-next-line
  }, [url]);

  return (
    <Tooltip title={id}>
      <Avatar
        style={{ backgroundColor: 'var(--tealish', borderRadius: '4px' }}
        icon={<UserOutlined />}
        shape={shape}
        src={loading ? <LoadingOutlined /> : signed}
        size={size}
        referrerPolicy="no-referrer"
      >
        {id && id[0]}
      </Avatar>
    </Tooltip>
  );
};

const Shares = ({ emails, addShareFn, showBtn = true }) => {
  const [urls, setUrls] = useState([]);
  const fetchAvatarUrls = async () => {
    if (emails.length > 0) {
      let unsigned = await sdk.getAvatarUrls(emails);
      setUrls(unsigned);
    }
  };

  useEffect(() => {
    fetchAvatarUrls();
    // eslint-disable-next-line
  }, [emails.length]);

  return (
    <Avatar.Group maxCount={5}>
      {urls.map(u => (
        <ShareAvatar key={u.id} id={u.id} url={u.avatar} />
      ))}
      {showBtn && (
        <Tooltip title="Manage User Access">
          <Button
            type="dashed"
            shape="circle"
            onClick={addShareFn}
            style={{ backgroundColor: 'transparent', marginLeft: '10px' }}
          >
            <UsergroupAddOutlined />
          </Button>
        </Tooltip>
      )}
    </Avatar.Group>
  );
};

const UserAvatar = ({ email, size, shape }) => {
  const [url, setUrl] = useState(null);

  const fetchAvatarUrl = async () => {
    if (email) {
      let unsigned = await sdk.getAvatarUrls([email]);
      if (unsigned.length > 0) {
        setUrl(unsigned[0]);
      }
    }
  };

  useEffect(() => {
    fetchAvatarUrl();
    // eslint-disable-next-line
  }, [email]);

  return url ? <ShareAvatar key={url.id} id={url.id} url={url.avatar} size={size} shape={shape} /> : null;
};

const OrgAvatar = ({ accountLogo, size, shape }) => {
  console.log('accountLogo', accountLogo);
  return (
    <Avatar
      style={{ cursor: 'pointer', backgroundColor: 'var(--tealish)', borderRadius: '3px' }}
      shape={shape}
      src={accountLogoUrl(accountLogo)}
      size={size}
    />
  );
};

export default Shares;
export { ShareAvatar, UserAvatar, OrgAvatar };
