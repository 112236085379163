import React, { useState } from 'react';
import { Avatar, Skeleton, Button, Dropdown, Menu, Upload, Tooltip, message } from 'antd';
import {
  LoadingOutlined,
  UserOutlined,
  PaperClipOutlined,
  SendOutlined,
  SettingOutlined,
  TeamOutlined,
  DeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Editor from 'rich-markdown-editor';
import showdown from 'showdown';
import DOMPurify from 'dompurify';
import useMessages from 'store/Messages';
import { shallow } from 'zustand/shallow';
import { EmptyStateSrc } from 'components/utility/EmptyState';
import { ShareAvatar } from 'components/users/ShareAvatars';
import { downloadBlob } from 'utils';
import ChannelMembers from './Members';
import sdk from 'sdk/Messages';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import axios from 'axios';
import './Home.css';
import './Content.css';
dayjs.extend(calendar);

// markdown setup
let converter;
showdown.extension('targetblank', function() {
  return [
    {
      type: 'html',
      regex: /(<a [^>]+?)(>.*<\/a>)/g,
      replace: '$1 target="_blank"$2',
    },
  ];
});
showdown.setOption('emoji', true);
showdown.setOption('simpleLineBreaks', true);
showdown.setOption('simplifiedAutoLink', true);
showdown.setOption('excludeTrailingPunctuationFromURLs', true);
showdown.setOption('encodeEmails', false);
showdown.setOption('tables', true);
showdown.setOption('strikethrough', true);
showdown.setOption('ghCodeBlocks', true);
converter = new showdown.Converter({
  extensions: ['targetblank'],
});

DOMPurify.addHook('afterSanitizeAttributes', function(node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

function dateFrom(str) {
  let d = new Date();
  let today = d.toISOString().split('T')[0];
  d.setDate(d.getDate() - 1);
  let yesterday = d.toISOString().split('T')[0];
  if (str === today) {
    return 'Today';
  } else if (str === yesterday) {
    return 'Yesterday';
  }
  return dayjs(str).format('dddd, MMMM Do');
}

function dateToFromNowDaily(myDate) {
  // get from-now for this date
  var fromNow = dayjs(myDate).calendar();

  return fromNow;
}

const ChannelOptions = ({ onDelete = () => {} }) => {
  return (
    <Menu
      onClick={onDelete}
      items={[
        {
          key: '1',
          label: 'Delete',
        },
      ]}
    />
  );
};

const MessagesContent = ({ channelId, quebicWrapper }) => {
  const {
    loadingMessages,
    activeChannel,
    messages,
    createMessage,
    deleteChannel,
    refreshData,
    channelUserMap,
    quebicID,
    deleteMessage,
    err,
  } = useMessages(
    state => ({
      loadingMessages: state.loadingMessages,
      activeChannel: state.activeChannel,
      messages: state.messages,
      createMessage: state.createMessage,
      deleteChannel: state.deleteChannel,
      refreshData: state.refreshData,
      channelUserMap: state.channelUserMap,
      quebicID: state.quebicID,
      deleteMessage: state.deleteMessage,
      err: state.err,
    }),
    shallow
  );
  const editorRef = React.useRef();
  const [attachments, setAttachments] = useState({});
  const [fileList, setFileList] = useState([]);
  const [putFileLoading, setPutFileLoading] = useState(false);
  const [filesDownloading, setFilesdownloading] = useState([]);
  const [showMembers, setShowMembers] = useState(false);

  const sendMessage = notify => {
    let markdown = editorRef.current.serializer.serialize(editorRef.current.view.state.doc);
    if (markdown.endsWith('\n\\\n')) {
      markdown = markdown.slice(0, markdown.length - 3);
    }
    markdown = markdown.replaceAll();
    if (markdown.length > 0) {
      createMessage(
        markdown,
        Object.keys(attachments).map(k => attachments[k]),
        notify
      );
      editorRef.current.view.dom.innerText = '';
      setAttachments({});
      setFileList([]);
    }
  };

  const handleKeyDown = keyboard => {
    if (keyboard.key === 'Enter') {
    }
  };

  const handleDelChannel = async () => {
    await deleteChannel(activeChannel.id);
    await refreshData();
  };

  const fetchURL = async file => {
    setPutFileLoading(true);
    return sdk
      .getPresignedURL('putObject', activeChannel.id, file.name, file.type)
      .then(data => {
        setAttachments(prevAtt => ({
          ...prevAtt,
          [file.uid]: {
            type: 'custom',
            url: data.objKey,
            description: '',
            file_name: file.name,
            size: file.size,
            percent: 0,
            uid: file.uid,
          },
        }));
        setPutFileLoading(false);
        return data.signedPutURL;
      })
      .catch(e => {
        message.error(`Failed to upload file: ${file.name}`);

        setAttachments(prevAtt => {
          let temp = { ...prevAtt };
          delete temp[file.uid];
          return { ...temp };
        });
        setPutFileLoading(false);
      });
  };

  const putFile = params => {
    if (params.action) {
      var options = {
        headers: {
          'Content-Type': params.file.type,
        },
      };

      axios
        .put(params.action, params.file, options)
        .then(params.onSuccess)
        .catch(onError)
        .finally(() => {});
    } else {
      message.error(`Failed to upload file: ${params.file.name}`);
      setAttachments(prevAtt => {
        let temp = { ...prevAtt };
        delete temp[params.file.uid];
        return { ...temp };
      });
    }
  };

  const onError = e => {
    message.error(`File upload failed.`);
  };

  const downloadAttachment = async embed => {
    setFilesdownloading([...filesDownloading, embed.url]);
    try {
      let { signedGetURL } = await sdk.getPresignedURL('getObject', activeChannel.id, embed.url);
      let result = await axios.get(signedGetURL, {
        responseType: 'blob',
        timeout: 30000,
      });
      downloadBlob(result.data, embed.file_name);
    } catch (e) {
      console.log('e', e);
      message.error('Error downloading attachment');
    }

    setFilesdownloading([...filesDownloading.filter(f => f.url !== embed.url)]);
  };

  const onFileChange = async payload => {
    // let currentFile = attachments.find(i => i.uid === payload.file.uid);
    // if (currentFile) {
    //   currentFile.percent = payload.file.percent;
    //   setAttachments([...attachments.filter(i => i.uid !== payload.file.uid), { ...currentFile }]);
    // }
    let updateAttachments = {};
    for (const file of payload.fileList) {
      updateAttachments[file.uid] = {
        type: 'custom',
        url: attachments?.[file.uid]?.url || '',
        description: '',
        file_name: file.name,
        size: file.size,
        percent: file.percent,
        uid: file.uid,
      };
    }
    setFileList([...payload.fileList]);
    setAttachments({ ...updateAttachments });
  };

  const deleteAttachment = async uid => {
    let temp = { ...attachments };
    delete temp[uid];
    setFileList(fileList.filter(f => f.uid !== uid));
    setAttachments({ ...temp });
  };

  return (
    <div className="admin-messages-content">
      {activeChannel?.id && <ChannelMembers show={showMembers} setShow={setShowMembers} channelID={activeChannel.id} />}
      <div className="admin-messages-content-header">
        <div className="header-context">
          {loadingMessages && <Skeleton.Input size="small" style={{ width: '200px' }} active={true} />}
          {!loadingMessages && activeChannel?.display_name}
        </div>
        <div className="header-search">
          {/* <Input prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} placeholder="Search messages..." /> */}
        </div>
        <div className="header-more">
          <Button type="text" size="large" onClick={() => setShowMembers(!showMembers)}>
            <TeamOutlined />
          </Button>
          <Dropdown
            overlay={<ChannelOptions onDelete={handleDelChannel} />}
            placement="bottomRight"
            trigger={['click']}
            overlayStyle={{ minWidth: '200px' }}
          >
            <Button type="text" size="large" loading={loadingMessages}>
              <SettingOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="admin-messages-content-wrapper">
        {!loadingMessages && !err && messages && Object.keys(messages).length === 0 && (
          <EmptyStateSrc
            imageName="no_messages.svg"
            title="You're all set"
            description="There aren't any notifications for you right now."
          />
        )}
        {!loadingMessages && err && (
          <EmptyStateSrc imageName="access_denied.svg" title="Something went wrong..." description={err} />
        )}
        {loadingMessages && (
          <div className="admin-messages-content-messages">
            <div className="loading-wrapper">
              <LoadingOutlined style={{ fontSize: '25px' }} />
            </div>
          </div>
        )}
        {!loadingMessages && messages && Object.keys(messages).length > 0 && (
          <div className="admin-messages-content-messages">
            {Object.keys(messages)
              .sort((a, b) => (a < b ? 1 : -1))
              .map(day => {
                let msgs = messages[day].sort((a, b) => b.timestamp - a.timestamp);

                // filter any duplicates that shouldn't exist
                const ids = msgs.map(({ id }) => id);
                msgs = msgs.filter(({ id }, index) => !ids.includes(id, index + 1));

                //channelUserMap
                return (
                  <div className="msg-day" key={`msg-day-${day}`}>
                    <div className="msg-day-wrapper">
                      <div className="msg-day-day">{dateFrom(day)}</div>
                    </div>
                    <div className="msg-day-messages">
                      {msgs.map(m => {
                        const converted_content = converter.makeHtml(m.content.replaceAll('\\', '<br/>'));
                        const sanitized_content = DOMPurify.sanitize(converted_content);
                        let userAvatar = (
                          <Avatar
                            size="large"
                            icon={<UserOutlined />}
                            shape="square"
                            style={{ borderRadius: '4px' }}
                            src={`https://cdn.quebic.com/api/v1/avatars/${m.owner.id}/${m.owner.avatar}`}
                          />
                        );
                        let userName = m.owner.nick;
                        let userMap = channelUserMap[m.owner.id];
                        if (userMap) {
                          userName = userMap.fullName;
                          userAvatar = (
                            <ShareAvatar id={userMap.owner} url={userMap.avatar} size="large" shape="square" />
                          );
                        }
                        return (
                          <div key={m.id} data-mid={m.id} className="msg-wrapper">
                            <div>{userAvatar}</div>
                            <div className="msg-content">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '10px',
                                  fontSize: '16px',
                                  fontWeight: '500',
                                }}
                              >
                                <span>{userName}</span>
                                <span
                                  style={{
                                    color: 'rgba(0,0,0,.45)',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    paddingTop: '1px',
                                  }}
                                >
                                  {dateToFromNowDaily(new Date(m.timestamp.toISOString()))}
                                </span>
                              </div>
                              <div dangerouslySetInnerHTML={{ __html: sanitized_content }}></div>
                              {m?.embeds && (
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '4px',
                                    padding: '0px',
                                    margin: '10px 0px 10px 4px',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {m.embeds.map(a => (
                                    <div
                                      key={`${m.id}-${a.file_name}`}
                                      style={{
                                        padding: '6px 10px',
                                        border: '1px solid rgba(0,0,0,.15)',
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => downloadAttachment(a)}
                                    >
                                      <Tooltip title="Click to download">
                                        {filesDownloading.includes(a.url) ? (
                                          <LoadingOutlined
                                            style={{ color: 'rgba(0, 0, 0, 0.25)', marginRight: '6px' }}
                                          />
                                        ) : (
                                          <PaperClipOutlined
                                            style={{ color: 'rgba(0, 0, 0, 0.25)', marginRight: '6px' }}
                                          />
                                        )}
                                        {a.file_name}
                                      </Tooltip>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {quebicID === m.owner.id && (
                                <div className="message-options">
                                  <Tooltip title="Delete message">
                                    <Button type="text" icon={<DeleteOutlined />} onClick={() => deleteMessage(m.id)} />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        <div className="admin-messages-content-create">
          <Editor
            ref={editorRef}
            className="message-editor"
            placeholder={`Send a message${activeChannel?.display_name ? ` to ${activeChannel?.display_name}` : '...'}`}
            onSave={() => sendMessage(false)}
            handleDOMEvents={{
              keydown: (event, keyboard) => {
                handleKeyDown(keyboard);
              },
            }}
          />
          <div style={{ display: 'flex', gap: '4px', padding: '0px 10px' }}>
            {Object.keys(attachments)
              .map(k => attachments[k])
              .map(a => (
                <div
                  style={{
                    margin: '10px 0px 10px 4px',
                    padding: '6px 10px',
                    border: `1px solid ${a.percent < 100 ? '#FFF' : 'rgba(0,0,0,.15)'}`,
                    backgroundColor: a.percent < 100 ? 'rgba(0,0,0,.05)' : '#FFF',
                    borderRadius: '10px',
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                  key={`file-attachment-${a.uid}`}
                >
                  {a.percent < 100 ? (
                    <LoadingOutlined style={{ color: 'rgba(0, 0, 0, 0.35)' }} />
                  ) : (
                    <PaperClipOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                  )}{' '}
                  <div>{a.file_name}</div>
                  <Tooltip title="Remove attachment">
                    <Button size="small" type="text" icon={<CloseOutlined />} onClick={() => deleteAttachment(a.uid)} />
                  </Tooltip>
                </div>
              ))}
          </div>
          <div className="create-options">
            <div style={{ display: 'flex', gap: '20px', fontSize: '18px', color: '#5B5B5B' }}>
              <Upload
                showUploadList={false}
                multiple={true}
                onChange={onFileChange}
                action={fetchURL}
                customRequest={putFile}
                fileList={fileList}
              >
                <Button
                  type="text"
                  disabled={putFileLoading}
                  icon={putFileLoading ? <LoadingOutlined /> : <PaperClipOutlined />}
                />
              </Upload>
            </div>
            <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
              <div className={`how-to-send show-help`}>
                <pre style={{ margin: '0px' }}>
                  <code>ctrl/cmd + enter</code>
                </pre>
              </div>
              <Dropdown.Button
                type="primary"
                size="small"
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 'send-notify',
                      label: 'Send & Email',
                    },
                  ],
                  onClick: ({ item, key }) => {
                    // send message to quebic and send email on successful post
                    if (key === 'send-notify') {
                      sendMessage(true);
                    }
                  },
                }}
                onClick={() => sendMessage(false)}
              >
                <SendOutlined style={{ padding: '0px 10px' }} />
              </Dropdown.Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesContent;
