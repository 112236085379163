import React from 'react';
import ErrorBoundary from 'ErrorBoundary';
import Sidebar from './Sidebar';
import Content from './Content';
import './Home.css';

const MessagesWrapper = ({ source = '' }) => {
  return (
    <ErrorBoundary>
      <MessagesHome source={source} />
    </ErrorBoundary>
  );
};

const MessagesHome = ({ source = '' }) => {
  return (
    <div className={`admin-messages-wrapper ${source}`}>
      <Sidebar source={source} />
      <ErrorBoundary justText={true}>
        <Content />
      </ErrorBoundary>
    </div>
  );
};

export default MessagesWrapper;
