import React, { useEffect } from 'react';
import { Redirect, withRouter, useParams, useHistory } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';

// Pages
import Authenticator from 'pages/user/Authenticator';

const AuthComponent = ({ authProps }) => {
  // check for referral
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          let redirect = new URLSearchParams(window.location.search).get('redirect');
          if (window.location.hash) {
            redirect += window.location.hash;
          }
          authProps.onUserSignIn(redirect ? redirect : undefined);
          break;
        case 'autoSignIn':
          //nothing here
          break;
        case 'autoSignIn_failure':
          history.replace('/login');
          break;
        case 'customOAuthState':
          console.log('event', event, 'data', data);
          break;
        default:
          break;
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        minHeight: '100vh',
        maxWidth: '100vw',
        backgroundColor: '#FFF',
        display: 'grid',
      }}
    >
      {!authProps.isLoggedIn && code && <Redirect to={`/signup?code=${code}&redirect=/referral/${code}`} />}
      {authProps.isLoggedIn && !code ? <Redirect to={authProps.redirect ?? `/`} /> : <Authenticator />}
    </div>
  );
};

export default withRouter(AuthComponent);
