import React, { useState } from 'react';
import useGlobal from 'store/Global';
import { Link } from 'react-router-dom';
import { Image, Typography, Space, Tooltip, Button, Skeleton, message } from 'antd';
import FuelSkelly from 'components/utility/Skeleton';
import { FilterFilled } from '@ant-design/icons';
import usePersonalAccount from 'store/PersonalAccount';
import { shallow } from 'zustand/shallow';
import AvatarFailover from 'components/AvatarFailover';
import { logoURL } from 'helpers/Organizations';
import Moment from 'react-moment';
import SocialShare from './SocialShare';
import { useWindowSize } from 'hooks';
import { calcReadTime } from './utils';

import sdk from 'sdk/News';
import UpdateArticle from './UpdateArticle';
import './Article.css';

const { Title, Text, Paragraph } = Typography;

const redirectFunction = article => {
  if (article.id.includes('news-')) {
    let newString = article.id.split('news-');
    return `/news/${article.mpn_category}/${newString[1]}`;
  } else {
    return `/news/${article.mpn_category}/${article.id}`;
  }
};

const fallback = () => {
  return `https://static.york.ie/images/stock/placeholder0${Math.ceil(Math.random() * 4)}.jpeg`;
};

const SitePreview = ({
  article,
  layout = 'list',
  images = true,
  descriptions = true,
  contentEditable = false,
  onHide,
}) => {
  const windowSize = useWindowSize();
  const maxWidth = 600;

  const width = layout === 'grid' || windowSize.width < maxWidth ? '100%' : 200;
  const direction = layout === 'grid' || windowSize.width < maxWidth ? 'vertical' : 'horizontal';
  const styles = layout === 'grid' || windowSize.width < maxWidth ? { width: '100%' } : {};
  const align = layout === 'grid' || windowSize.width < maxWidth ? '' : 'start';

  return (
    <Space contentEditable={contentEditable} size="large" align={align} direction={direction} style={styles}>
      {images && article.image && (
        <a className="dark" href={article.url} target="_blank" rel="noopener noreferrer">
          <Image
            style={layout !== 'grid' ? { maxHeight: '100px', objectFit: 'cover' } : {}}
            preview={false}
            width={width}
            src={article.image}
            fallback={fallback()}
            alt={article.title}
          />
        </a>
      )}
      {images && !article.image && (
        <Image
          style={layout !== 'grid' ? { maxHeight: '100px', objectFit: 'cover' } : {}}
          preview={false}
          width={width}
          src={fallback()}
          alt={article.title}
        />
      )}
      <Space direction="vertical" size={0} style={{ width: '100%' }}>
        <Title level={5} strong style={{ fontSize: '16px' }}>
          <a className="dark" href={article.url} target="_blank" rel="noopener noreferrer">
            {article.title}
          </a>
        </Title>
        {descriptions && <Paragraph ellipsis={{ rows: 3 }}>{article.description}</Paragraph>}
        <Text
          className="article-extra"
          style={{ textTransform: 'uppercase', color: '#8AB0AB', fontSize: '13px', fontWeight: '500' }}
        >
          <Space wrap>
            {article.hasOwnProperty('published_time') ? (
              <>
                <Moment format="MMM DD, YYYY">{article.published_time}</Moment>
                &middot;
              </>
            ) : (
              ''
            )}
            {article.site}
            &middot;
            <SocialShare title={article.title} url={article.url} />
            {onHide && <>&middot;</>}
            {onHide && (
              <Tooltip title="Remove Article">
                <Button style={{ float: 'right', padding: 0 }} type={'link'} onClick={() => onHide(article)}>
                  remove
                </Button>
              </Tooltip>
            )}
          </Space>
        </Text>
      </Space>
    </Space>
  );
};

const ArticleGrid = ({
  article: payload,
  layout = 'list',
  images = true,
  descriptions = true,
  maxHeight = '200px',
  onHide,
  queryEarned,
  companyId,
  showCompany = false,
}) => {
  const authProps = useGlobal(state => state.authProps);
  const [showUpdateArticle, setUpdateArticle] = useState(false);

  let article = payload['curated_article'] || payload['article'] || payload['earned_content'];
  let ogArticle = payload['article'] || payload['earned_content'] || payload['curated_article'];
  const { organization } = payload;
  const readTime = calcReadTime(ogArticle?.word_count);

  // Only for earned content
  const removeContent = async () => {
    let key = 'request-content-scrape';
    message.loading({ content: 'Requesting content...', key });
    let result = await sdk.deleteEarnedContent(article.id, companyId);
    if (result.error) {
      message.error({
        content: `${result.error}. Please contact support.`,
        key,
        duration: 4,
      });
    } else {
      message.success({
        content: 'Request submitted, this article will be deleted shortly.',
        key,
        duration: 4,
      });
    }
  };

  const imageStyles = {
    height: maxHeight,
    width: '100%',
    borderRadius: '3px',
    border: '1px solid #EFEFEF',
    objectFit: 'cover',
    marginBottom: '10px',
  };
  const articleLink = article.mpn_category
    ? redirectFunction(article)
    : `/news/${queryEarned ? 'earned' : 'article'}/${article?.id}`;

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '15px' }}>
      <div>
        <Space direction="vertical" size={0} style={{ width: '100%' }}>
          {showCompany && (
            <Space style={{ marginBottom: '10px' }}>
              <AvatarFailover
                key={`avatar-${organization.id || organization.uuid || companyId}`}
                src={logoURL(organization.id || organization.uuid || companyId)}
                name={organization.name}
                shape="square"
                size={'small'}
              />
              <Text style={{ fontSize: '15px', fontWeight: '500' }}>
                <Link
                  style={{ color: 'rgba(0,0,0,.65)' }}
                  to={`/research/companies/${organization.id || organization.uuid || companyId}`}
                >
                  {organization?.name}
                </Link>
              </Text>
            </Space>
          )}
          {images && article.image && (
            <Link className="dark fuel-news-image" to={articleLink}>
              <Image
                className="fuel-news-image"
                style={imageStyles}
                preview={false}
                src={article.image}
                placeholder={
                  <FuelSkelly className="grid-image-loader" style={{ height: '200px', width: '100%' }} active={true} />
                }
                fallback={fallback()}
                alt={article.title}
              />
            </Link>
          )}
          <Title level={5} strong style={{ fontSize: '18px' }} ellipsis={{ rows: 3 }}>
            <Link className="dark" to={articleLink} data-article-title={article.title}>
              {article.title}
            </Link>
          </Title>
          {images && !article.image && <Image style={imageStyles} preview={false} width={'100%'} src={fallback()} />}
          {descriptions && (
            <Paragraph
              ellipsis={{ rows: 2 }}
              style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: '.5em', fontSize: '15px' }}
            >
              {article.description}
            </Paragraph>
          )}

          <Text
            className="article-extra"
            style={{ textTransform: 'uppercase', color: '#8AB0AB', fontSize: '14px', fontWeight: '500' }}
          >
            <Space wrap>
              {article.hasOwnProperty('published_time') || article?.hasOwnProperty('content_date') ? (
                <>
                  <Moment format="MMM DD, YYYY">{article.published_time || article.content_date}</Moment>
                  &middot;
                </>
              ) : (
                ''
              )}
              {!!readTime ? (
                <>
                  <span>{`${readTime} min`}</span>
                  &middot;
                </>
              ) : (
                ''
              )}
              <SocialShare
                org={organization}
                organizationName={organization.name}
                title={article.title}
                url={ogArticle?.url}
              />
              {onHide && <>&middot;</>}
              {onHide && (
                <Tooltip title="Remove Article">
                  <Button style={{ float: 'right', padding: 0 }} type={'link'} onClick={() => onHide(article)}>
                    remove
                  </Button>
                </Tooltip>
              )}
              {authProps?.user?.groups?.includes('editors') && (
                <span
                  onClick={() => setUpdateArticle(true)}
                  style={{ cursor: 'pointer' }}
                  className="light"
                  rel="noopener noreferrer"
                >
                  <FilterFilled />
                </span>
              )}
              {queryEarned && (
                <Space style={{ textTransform: 'none' }}>
                  &middot;
                  <Tooltip title="Remove earned content">
                    <span style={{ cursor: 'pointer' }} onClick={removeContent}>
                      Remove
                    </span>
                  </Tooltip>
                </Space>
              )}
            </Space>
          </Text>
          {showUpdateArticle && (
            <UpdateArticle
              orgId={organization.id}
              articleId={article.id}
              article={article}
              show={showUpdateArticle}
              close={() => setUpdateArticle(false)}
            />
          )}
        </Space>
      </div>
    </div>
  );
};

const ESArticle = ({
  article: payload,
  descriptions = true,
  maxHeight = '125px',
  companyId,
  showCompany = false,
  showSitename = false,
}) => {
  const windowSize = useWindowSize();
  const maxWidth = 600;
  const hideImages = windowSize.width <= maxWidth;

  let article = payload['curated_article'] || payload['article'] || payload['earned_content'];
  let ogArticle = payload['article'] || payload['earned_content'] || payload['curated_article'];
  const { organization } = payload;
  const readTime = calcReadTime(ogArticle?.word_count);

  const articleLink = article.article_url;

  return (
    <div style={{ display: 'grid', gridTemplateColumns: hideImages ? '1fr' : '1fr auto', gap: '15px' }}>
      <div>
        <Space direction="vertical" size={0} style={{ width: '100%' }}>
          {showSitename && article?.site_name && <div>{article?.site_name}</div>}
          <Title level={5} strong style={{ fontSize: '18px' }} ellipsis={{ rows: 3 }}>
            <a
              target="_blank"
              className="dark"
              href={articleLink}
              style={{ color: 'rgba(0,0,0,.85)' }}
              data-article-title={article?.title}
              rel="noreferrer"
            >
              {article?.title}
            </a>
          </Title>
          {descriptions && (
            <Paragraph
              ellipsis={{ rows: 2 }}
              style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: '.5em', fontSize: '15px' }}
            >
              {article?.description ? article?.description : article?.summary}
            </Paragraph>
          )}

          <Text
            className="article-extra"
            style={{ textTransform: 'uppercase', color: '#8AB0AB', fontSize: '14px', fontWeight: '500' }}
          >
            <Space wrap>
              {showCompany && (
                <>
                  <Space>
                    <AvatarFailover
                      key={`avatar-${organization.id || organization.uuid || companyId}`}
                      src={logoURL(organization.id || organization.uuid || companyId)}
                      backupSrc={organization.logo_url}
                      name={organization.name}
                      shape="square"
                      size={'small'}
                    />
                    <Text style={{ fontWeight: '500' }}>
                      <Link
                        style={{ color: '#8AB0AB' }}
                        to={`/research/companies/${organization.id || organization.uuid || companyId}`}
                      >
                        {organization?.name}
                      </Link>
                    </Text>
                  </Space>
                  &middot;
                </>
              )}
              {article?.hasOwnProperty('published_time') ||
              article?.hasOwnProperty('content_date') ||
              article?.hasOwnProperty('published_at') ? (
                <>
                  <Moment format="MMM DD, YYYY">
                    {article?.published_time || article?.content_date || article?.published_at}
                  </Moment>
                </>
              ) : (
                ''
              )}
              {!!readTime ? (
                <>
                  <span>{`${readTime} min`}</span>
                  &middot;
                </>
              ) : (
                ''
              )}
            </Space>
          </Text>
        </Space>
      </div>
    </div>
  );
};

const Article = ({
  article: payload,
  layout = 'list',
  images = true,
  descriptions = true,
  maxHeight = '125px',
  onHide,
  queryEarned,
  companyId,
  showCompany = false,
  showMpn = false,
  showSitename = false,
}) => {
  const authProps = useGlobal(state => state.authProps);
  const [showUpdateArticle, setUpdateArticle] = useState(false);
  const windowSize = useWindowSize();
  const maxWidth = 600;
  const hideImages = windowSize.width <= maxWidth;

  let article = payload['curated_article'] || payload['article'] || payload['earned_content'];
  let ogArticle = payload['article'] || payload['earned_content'] || payload['curated_article'];
  const { organization } = payload;
  const readTime = calcReadTime(ogArticle?.word_count);
  // Only for earned content
  const removeContent = async () => {
    let key = 'request-content-scrape';
    message.loading({ content: 'Requesting content...', key });
    let result = await sdk.deleteEarnedContent(article.id, companyId);
    if (result.error) {
      message.error({
        content: `${result.error}. Please contact support.`,
        key,
        duration: 4,
      });
    } else {
      message.success({
        content: 'Request submitted, this article will be deleted shortly.',
        key,
        duration: 4,
      });
    }
  };

  const imageStyles = { height: maxHeight, borderRadius: '3px', border: '1px solid #EFEFEF', objectFit: 'cover' };
  const articleLink = article?.mpn_category
    ? redirectFunction(article)
    : `/news/${queryEarned ? 'earned' : 'article'}/${article?.id}`;

  return (
    <div style={{ display: 'grid', gridTemplateColumns: hideImages ? '1fr' : '1fr auto', gap: '15px' }}>
      <div>
        <Space direction="vertical" size={0} style={{ width: '100%' }}>
          {showCompany && (
            <Space style={{ marginBottom: '10px' }}>
              <AvatarFailover
                key={`avatar-${organization.id || organization.uuid || companyId}`}
                src={logoURL(organization.id || organization.uuid || companyId)}
                backupSrc={organization.logo_url}
                name={organization.name}
                shape="square"
                size={'small'}
              />
              <Text style={{ fontSize: '15px', fontWeight: '500' }}>
                <Link
                  style={{ color: 'rgba(0,0,0,.65)' }}
                  to={`/research/companies/${organization.id || organization.uuid || companyId}`}
                >
                  {organization?.name}
                </Link>
              </Text>
            </Space>
          )}
          {showSitename && article?.site_name && <div>{article?.site_name}</div>}
          <Title level={5} strong style={{ fontSize: '18px' }} ellipsis={{ rows: 3 }}>
            <Link
              className="dark"
              to={articleLink}
              style={{ color: 'rgba(0,0,0,.85)' }}
              data-article-title={article?.title}
            >
              {article?.title}
            </Link>
          </Title>
          {descriptions && (
            <Paragraph
              ellipsis={{ rows: 2 }}
              style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: '.5em', fontSize: '15px' }}
            >
              {article?.description ? article?.description : article?.summary}
            </Paragraph>
          )}

          <Text
            className="article-extra"
            style={{ textTransform: 'uppercase', color: '#8AB0AB', fontSize: '14px', fontWeight: '500' }}
          >
            <Space wrap>
              {article?.hasOwnProperty('published_time') || article?.hasOwnProperty('content_date') ? (
                <>
                  <Moment format="MMM DD, YYYY">{article?.published_time || article?.content_date}</Moment>
                  &middot;
                </>
              ) : (
                ''
              )}
              {!!readTime ? (
                <>
                  <span>{`${readTime} min`}</span>
                  &middot;
                </>
              ) : (
                ''
              )}
              <SocialShare
                org={organization}
                organizationName={organization.name}
                title={article?.title}
                url={ogArticle?.url}
              />
              {onHide && <>&middot;</>}
              {onHide && (
                <Tooltip title="Remove Article">
                  <Button style={{ float: 'right', padding: 0 }} type={'link'} onClick={() => onHide(article)}>
                    remove
                  </Button>
                </Tooltip>
              )}
              {authProps?.user?.groups?.includes('editors') && (
                <span
                  onClick={() => setUpdateArticle(true)}
                  style={{ cursor: 'pointer' }}
                  className="light"
                  rel="noopener noreferrer"
                >
                  <FilterFilled />
                </span>
              )}
              {showMpn && (
                <>
                  &middot;
                  <span>{article?.mpn_category}</span>
                </>
              )}
              {queryEarned && (
                <Space style={{ textTransform: 'none' }}>
                  &middot;
                  <Tooltip title="Remove earned content">
                    <span style={{ cursor: 'pointer' }} onClick={removeContent}>
                      Remove
                    </span>
                  </Tooltip>
                </Space>
              )}
            </Space>
          </Text>
          {showUpdateArticle && (
            <UpdateArticle
              orgId={organization.uuid}
              articleId={article?.id}
              article={article}
              show={showUpdateArticle}
              close={() => setUpdateArticle(false)}
            />
          )}
        </Space>
      </div>
      {!hideImages && (
        <>
          {images && article.image && (
            <Link className="dark" to={articleLink}>
              <Image
                style={imageStyles}
                preview={false}
                width={'200px'}
                height={'125px'}
                src={article.image}
                placeholder={<Skeleton.Input style={{ height: '125px', width: '200px' }} active={true} />}
                fallback={fallback()}
                alt={article?.title || 'article image'}
              />
            </Link>
          )}
          {images && !article.image && (
            <Link className="dark" to={articleLink}>
              <Image
                style={imageStyles}
                preview={false}
                width={'200px'}
                height={'125px'}
                src={fallback()}
                placeholder={<Skeleton.Input style={{ height: '125px', width: '200px' }} active={true} />}
                alt={article?.title || 'article image'}
              />
            </Link>
          )}
        </>
      )}
    </div>
  );
};

const RSSArticle = ({ title, link, pubDate, source, company, companyId, companyName, allowScrape = true }) => {
  const account = usePersonalAccount(state => ({ loading: state.loading, hasGroup: state.hasGroup }), shallow);
  const styles = { width: '100%', maxWidth: '100%' };

  const saveContent = async () => {
    let key = 'request-content-scrape';
    message.loading({ content: 'Requesting content...', key });
    let result = await sdk.scrapeEarnedContent(link, companyId, companyName, source);

    if (result.error) {
      message.error({
        content: `${result.error}. Please contact support.`,
        key,
        duration: 4,
      });
    } else {
      message.success({
        content: "Request submitted. We'll email you once this article is available",
        key,
        duration: 4,
      });
    }
  };

  return (
    <Space size="large" direction={'vertical'} style={styles}>
      <div>
        <Space direction="vertical" size={0} style={{ width: '100%' }}>
          <Title level={5} strong style={{ fontSize: '16px', marginBottom: '4px' }} ellipsis={{ rows: 2 }}>
            <a className="dark" href={link} style={{ color: 'rgba(0,0,0,.85)' }}>
              {title}
            </a>
          </Title>
          <Text
            className="article-extra"
            style={{ textTransform: 'uppercase', color: '#8AB0AB', fontSize: '13px', fontWeight: '500' }}
          >
            <Space wrap>
              {pubDate ? (
                <>
                  <Moment format="MMM DD, YYYY">{pubDate}</Moment>
                  &middot;
                </>
              ) : (
                ''
              )}

              {source && (
                <a style={{ color: 'rgb(138, 176, 171)' }} href={link}>
                  {source}
                </a>
              )}
              {!account.loading && account.hasGroup('internal') && allowScrape && (
                <Space style={{ textTransform: 'none' }}>
                  &middot;
                  <Tooltip title="Scrape and save earned content">
                    <span style={{ cursor: 'pointer' }} onClick={saveContent}>
                      Save
                    </span>
                  </Tooltip>
                </Space>
              )}
            </Space>
          </Text>
        </Space>
      </div>
    </Space>
  );
};

export default Article;
export { SitePreview, RSSArticle, ArticleGrid, ESArticle };
