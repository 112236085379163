// This file provides a common set of helpers to retrieve news.

import { API } from '@aws-amplify/api';
import Parser from 'rss-parser';

function parseISOString(s) {
  var b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

// Get RSS feed
const getRSS = async rssUrl => {
  let parser = new Parser({
    headers: { 'User-Agent': 'York IE Fuel', Origin: 'https://fuel.york.ie' },
  });
  let CORS_PROXY = 'https://cors-anywhere.herokuapp.com/';

  let feed = await parser.parseURL(CORS_PROXY + rssUrl);

  feed.items.sort((a, b) => parseISOString(b.isoDate) - parseISOString(a.isoDate));
  feed.items.forEach(function(value, i) {
    feed.items[i]['index'] = i;
  });

  return feed;
};

// Get news
const getNews = async (query, page, pageSize) => {
  var url = '/news?';
  if ('source' in query) url += `source=${query.source}&`;
  if ('companyId' in query) url += `uuid=${query.companyId}&`;
  else if ('listId' in query) url += `listId=${query.listId}&`;
  else if ('uuid' in query) url += `uuid=${query.uuid}&`;
  else if ('rssSearch' in query) url += `rssSearch=${query.rssSearch}&`;
  if ('yorkie' in query) url += `yorkie=${query.yorkie}&`;
  if ('queryEarned' in query) url += `queryEarned=${query.queryEarned}&`;
  if ('listIds' in query) {
    for (const id of query.listIds) {
      url += `listIds=${id}&`;
    }
  }

  url += `p=${page || 1}&r=${pageSize || 10}`;

  let result = await API.get('fuelapi', url)
    .then(async data => {
      if ('rssSearch' in query) {
        let parser = new Parser({
          customFields: {
            item: ['source'],
          },
        });

        data = await parser.parseString(data);

        if (!('noSort' in query)) {
          data.items.sort((a, b) => parseISOString(b.isoDate) - parseISOString(a.isoDate));
        }
      }

      data.items.forEach(function(value, i) {
        data.items[i]['index'] = i;
      });

      return data;
    })
    .catch(e => {
      // TODO: Display user error
      console.log('error retrieving data', e);
      return e;
    });
  return result;
};

export { getNews, getRSS };
