import React from 'react';
import { Typography, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Fade from 'components/utility/Fade';
import Dots from './Dots';

const { Title, Paragraph } = Typography;

const NewsIntro = ({ isVisible }) => {
  return (
    <div>
      <Fade animation="plain" isVisible={isVisible}>
        <Title level={4} className="mute-title fuel-title-text" style={{ color: 'var(--brand)', position: 'relative' }}>
          <div
            className="pattern"
            style={{ height: '260px', width: '300px', position: 'absolute', left: '-150px', top: '-100px' }}
          >
            <Dots />
          </div>
          Fuel News Introduction
        </Title>
      </Fade>
      <Space direction="vertical" size="large" style={{ maxWidth: '400px' }}>
        <Fade delay="delay-1" transformY="0" transformX="2" isVisible={isVisible}>
          <Space align="start" size="large">
            <img
              src="https://2c2hs846t9613xujn610ygkw-wpengine.netdna-ssl.com/wp-content/uploads/2021/04/jcs_475.jpg"
              alt="Colin Steele"
              style={{ width: '100px', borderRadius: '100px' }}
            />
            <Paragraph className="font-16" style={{ color: '#FFF' }}>
              <strong>Colin Steele</strong>
              <br />
              Editor-in-chief of Fuel News
            </Paragraph>
          </Space>
        </Fade>
        <Fade delay="delay-2" transformY="0" transformX="2" isVisible={isVisible}>
          <Paragraph className="font-16" style={{ color: '#FFF' }}>
            Colin personally curates the best news and insights from operators in key markets, so you can learn directly
            from your peers and industry leaders.
          </Paragraph>
        </Fade>
        <Fade delay="delay-3" transformY="0" transformX="2" isVisible={isVisible}>
          <Paragraph className="font-16" style={{ color: '#FFF' }}>
            Choose your Market Pulse emails <ArrowRightOutlined />
          </Paragraph>
        </Fade>
      </Space>
    </div>
  );
};

export default NewsIntro;
