import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Alert } from 'antd';
import Auth from '@aws-amplify/auth';
import LoadingSpinner from 'components/general/LoadingSpinner';

const ConfirmSignUp = ({ changeState, feedback, username }) => {
  const location = useLocation();
  const [msg, setMsg] = useState(feedback);
  const [email, setEmail] = useState(() => {
    let emailValue = '';
    try {
      emailValue = location.search
        .slice(1)
        .split('&')
        .filter(qParam => qParam.includes('email'))[0]
        .split('=')[1];
    } catch (e) {}
    return emailValue || username;
  });
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [mainLoading, setMainLoading] = useState(false);
  let code = '';
  if (window.location.href.includes('confirmationcode') && window.location.href.includes('/login')) {
    code = new URL(window.location.href).searchParams.get('confirmationcode') || '';
  }
  const resendConfirmationCode = async () => {
    setLoading(true);
    setErr('');
    try {
      await Auth.resendSignUp(email.toLocaleLowerCase().trim());
      setMsg('A new confirmation code has been sent.');
      setLoading(false);
    } catch (error) {
      console.log('error signing up:', error);
      setErr(error.message);
      setLoading(false);
    }
  };

  const handleConfirmSignUp = async e => {
    setLoading(true);
    setErr('');
    try {
      // set some metadata for Hubspot
      let meta = {
        fuel_signup_referral: document?.referrer ?? 'unknown',
        fuel_version_number: process.env.REACT_APP_VERSION,
      };
      await Auth.confirmSignUp(e.username.toLocaleLowerCase().trim(), e.code, { clientMetadata: meta });
      setLoading(false);
      setMainLoading(true);
      localStorage.removeItem('userName');
    } catch (error) {
      console.log('error signing up:', error);
      setErr(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (email !== '' && email !== null && code !== '') {
      const data = {
        username: email,
        code: code,
      };
      setMsg('');
      handleConfirmSignUp(data);
    } else if (code !== '') {
      setMsg('');
    }
    // eslint-disable-next-line
  }, []);

  return mainLoading ? (
    <LoadingSpinner style={{ height: '400px' }} />
  ) : (
    <Row>
      <Col xs={24} md={24} lg={16} xl={16} xxl={9} style={{ maxWidth: '600px' }}>
        <h4 className="mute-title" style={{ marginBottom: '30px' }}>
          Confirm Sign Up
        </h4>

        {msg && <Alert style={{ marginBottom: '10px' }} type="info" showIcon={false} message={msg} banner />}
        {err && <Alert style={{ marginBottom: '10px' }} type="error" showIcon={false} message={err} banner />}

        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="normal_signup"
          className="signup-form"
          onFinish={e => handleConfirmSignUp(e)}
        >
          <Form.Item
            initialValue={email}
            label="Email"
            name="username"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="xl"
              size="large"
              id="username"
              key="username"
              name="username"
              placeholder="Email"
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            label={
              <Row>
                <Col xs={12}>Code</Col>
                <Col xs={12} style={{ textAlign: 'end' }}>
                  <Button
                    type="link"
                    size="small"
                    onClick={resendConfirmationCode}
                    disabled={loading}
                    style={{ paddingRight: '0px' }}
                    tabIndex={4}
                  >
                    Resend Code
                  </Button>
                </Col>
              </Row>
            }
            initialValue={code}
            name="code"
            rules={[{ required: true, message: 'Please input your Confirmation Code!' }]}
          >
            <Input
              autoFocus
              value={code}
              className="xl"
              size="large"
              id="code"
              key="code"
              name="code"
              placeholder="Enter your confirmation code"
              disabled={loading}
              tabIndex={1}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
                <Button
                  type="link"
                  size="large"
                  block
                  onClick={() => changeState('signIn')}
                  disabled={loading}
                  tabIndex={6}
                >
                  Sign In
                </Button>
              </Col>
              <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
                <Button type="primary" size="large" htmlType="submit" block loading={loading} tabIndex={5}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ConfirmSignUp;
