import { create } from 'zustand';

const useGlobal = create((set, get) => ({
  authProps: null,
  setGlobalAuthProps: data => {
    set({ authProps: data });
  },
}));

export default useGlobal;
