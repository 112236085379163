import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Popover, Badge } from 'antd';
import { MessageFilled, MailFilled } from '@ant-design/icons';
import MessagesWrapper from 'pages/admin/messages/Home';
import usePersonalAccount from 'store/PersonalAccount';
import useMessages from 'store/Messages';
import { shallow } from 'zustand/shallow';
import './Messages.css';

const MessagesButton = ({ user }) => {
  const { initialStoreSetup, unreads } = useMessages(
    state => ({
      initialStoreSetup: state.initialStoreSetup,
      unreads: state.unreads,
    }),
    shallow
  );

  useEffect(() => {
    if (user) {
      initialStoreSetup(user);
    }
    // eslint-disable-next-line
  }, [user?.attributes?.email]);

  return (
    <Popover
      placement="bottomRight"
      overlayClassName="remove-padding"
      overlayInnerStyle={{ borderRadius: '8px', padding: '0px' }}
      title={null}
      content={<Messages />}
      trigger="click"
      arrow={true}
    >
      <Badge color={'var(--accent)'} dot={Object.keys(unreads).length > 0} offset={[-10, 10]}>
        <Button
          type="text"
          style={{ color: 'rgba(255, 255, 255, 0.85)' }}
          className="mail-btn"
          icon={<MailFilled />}
          size={'large'}
        />
      </Badge>
    </Popover>
  );
};

const Messages = () => {
  const hasMessengersGroup = usePersonalAccount(state => state.hasMessengersGroup);
  const history = useHistory();

  return (
    <div className="user-messages">
      <div className="user-messages-header">
        <span>Messages</span>
        {hasMessengersGroup() && (
          <Button type="text" size="large" onClick={() => history.push('/admin/messages')}>
            <MessageFilled style={{ color: '#FFF' }} />
          </Button>
        )}
      </div>
      <div className="user-messages-content">
        <MessagesWrapper source={'navbar'} />
      </div>
    </div>
  );
};

export default Messages;
export { MessagesButton };
