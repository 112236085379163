import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Alert } from 'antd';
import Auth from '@aws-amplify/auth';

const PasswordReset = ({ changeState, feedback }) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');

  const handleReset = async e => {
    setLoading(true);
    setErr('');
    try {
      await Auth.forgotPassword(e.username.toLocaleLowerCase().trim());
      setLoading(false);
      changeState('confirmReset', '', e.username.toLocaleLowerCase().trim());
    } catch (error) {
      console.log('error resetting password:', error);
      setErr(
        'This username/client ID was not found. Please verify what was entered is correct. If your account was created with Google credentials, please log in with Google. If you continue to receive this error, contact support via chat or email (support@york.ie)'
      );
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={24} md={24} lg={16} xl={16} xxl={9} style={{ maxWidth: '600px' }}>
        <h4 className="mute-title" style={{ marginBottom: '30px' }}>
          Reset your password
        </h4>

        {feedback && <Alert style={{ marginBottom: '10px' }} type="info" showIcon={false} message={feedback} banner />}
        {err && <Alert style={{ marginBottom: '10px' }} type="error" showIcon={false} message={err} banner />}

        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="normal_reset"
          className="reset-form"
          onFinish={e => handleReset(e)}
        >
          <Form.Item label="Email" name="username" rules={[{ required: true, message: 'Please input your Email!' }]}>
            <Input
              className="xl"
              size="large"
              id="username"
              key="username"
              name="username"
              placeholder="Email"
              disabled={loading}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
                <Button
                  type="link"
                  size="large"
                  block
                  onClick={() => changeState('signIn')}
                  disabled={loading}
                  tabIndex={6}
                >
                  Log In
                </Button>
              </Col>
              <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={loading}
                  tabIndex={5}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="fuel.user.resetPassword"
                >
                  Send Code
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default PasswordReset;
