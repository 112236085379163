import { API, graphqlOperation } from '@aws-amplify/api';
import {
  watchlistsByTeam as watchlistsByTeamGQL,
  watchlistsByAccountWithPreviewOnly as watchlistsByAccountGQL,
  watchlistsByOwner as watchlistsByOwnerGQL,
  watchlistsByOwnerAndAccount as watchlistsByOwnerAndAccountGQL,
  watchlistSharesByOwner as watchlistSharesByOwnerGQL,
  listSettingsByOwner as listSettingsByOwnerGQL,
  listSettingsByOwnerWithPreviewOnly as listSettingsByOwnerPreviewOnlyGQL,
  WatchlistsByAccountAndPersonalAccountPreviewOnly, //without items
  WatchlistsByAccountAndPersonalAccount as WatchlistsByAccountAndPersonalAccountGQL, //with items
} from 'graphql/customQueries';

const watchlistsByAccount = async accountID => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(
        graphqlOperation(watchlistsByAccountGQL, {
          nextToken,
          accountID,
          filter: { account_privacy: { ne: 'PRIVATE' } },
        })
      );
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.watchlistsByAccount.items)));
      nextToken = list.data.watchlistsByAccount.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by account'] };
  }
};

const watchlistsByAccountAndPersonalAccount = async (accountID, owner, previewOnly = false) => {
  try {
    let listOfLists = [];
    let isInitial = true;
    let byOwner_nextToken = null;
    let byAccount_nextToken = null;
    const gqlQuery = previewOnly
      ? WatchlistsByAccountAndPersonalAccountPreviewOnly
      : WatchlistsByAccountAndPersonalAccountGQL;

    while (isInitial || byAccount_nextToken || byOwner_nextToken) {
      if (isInitial || (byAccount_nextToken && byOwner_nextToken)) {
        isInitial = false;
        let combinedResult = await API.graphql(
          graphqlOperation(gqlQuery, {
            byAccount_nextToken: byAccount_nextToken,
            byOwner_nextToken: byOwner_nextToken,
            accountID,
            owner,
            byAccount_filter: { account_privacy: { ne: 'PRIVATE' } },
            byOwner_filter: { accountID: { attributeExists: false } },
          })
        );
        listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(combinedResult.data.watchlistsByAccount.items)));
        listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(combinedResult.data.watchlistsByOwner.items)));
        byAccount_nextToken = combinedResult.data.watchlistsByAccount.nextToken;
        byOwner_nextToken = combinedResult.data.watchlistsByOwner.nextToken;
        if (combinedResult.errors) {
          return combinedResult;
        }
      } else if (byAccount_nextToken) {
        let list = await API.graphql(
          graphqlOperation(watchlistsByAccountGQL, {
            nextToken: byAccount_nextToken,
            accountID,
            filter: { account_privacy: { ne: 'PRIVATE' } },
          })
        );
        listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.watchlistsByAccount.items)));
        byAccount_nextToken = list.data.watchlistsByAccount.nextToken;
        if (list.errors) {
          return list;
        }
      } else if (byOwner_nextToken) {
        let list = await API.graphql(
          graphqlOperation(watchlistsByOwnerGQL, {
            owner,
            nextToken: byOwner_nextToken,
            filter: { accountID: { attributeExists: false } },
          })
        );
        listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.watchlistsByOwner.items)));
        byOwner_nextToken = list.data.watchlistsByOwner.nextToken;
        if (list.errors) {
          return list;
        }
      }
    }

    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by account'] };
  }
};

const watchlistsByTeam = async teamID => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(
        graphqlOperation(watchlistsByTeamGQL, {
          nextToken,
          teamID,
        })
      );
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.watchlistsByTeam.items)));
      nextToken = list.data.watchlistsByTeam.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }

    return listOfLists
      .filter(l => !!l.watchlist)
      .filter(l => l.watchlist.account_privacy !== 'PRIVATE' && l.watchlist.accountID)
      .map(l => ({ ...l.watchlist }));
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by team'] };
  }
};

const watchlistsByOwnerAccountPrivate = async (owner, accountID) => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(
        graphqlOperation(watchlistsByOwnerAndAccountGQL, {
          nextToken,
          owner,
          accountID: { eq: accountID },
          filter: { account_privacy: { ne: 'PUBLIC' } },
        })
      );
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.watchlistsByOwnerAndAccount.items)));
      nextToken = list.data.watchlistsByOwnerAndAccount.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by account'] };
  }
};

const watchlistsByOwner = async owner => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(
        graphqlOperation(watchlistsByOwnerGQL, { owner, nextToken, filter: { accountID: { attributeExists: false } } })
      );
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.watchlistsByOwner.items)));
      nextToken = list.data.watchlistsByOwner.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by owner'] };
  }
};

const watchlistsByShared = async owner => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = {};
      try {
        list = await API.graphql(
          graphqlOperation(watchlistSharesByOwnerGQL, {
            owner,
            nextToken,
          })
        );
      } catch (e) {
        list = e;
      }
      if (list?.data) {
        let items = JSON.parse(JSON.stringify(list.data.watchlistSharesByOwner.items));
        let listFromRel = items.filter(s => !!s.list).map(s => s.list);
        listOfLists = listOfLists.concat(listFromRel);
        nextToken = list.data.watchlistSharesByOwner.nextToken;
        if (!nextToken) {
          nextPage = false;
        }
      } else {
        return { errors: list?.errors };
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by owner'] };
  }
};

const watchlistsBySettings = async owner => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = {};
      try {
        list = await API.graphql(
          graphqlOperation(listSettingsByOwnerPreviewOnlyGQL, { filter: { follow: { eq: true } }, owner, nextToken })
        );
      } catch (e) {
        list = e;
      }
      // if (list.errors) {
      //   return list;
      // }
      if (list?.data) {
        let items = JSON.parse(JSON.stringify(list.data.listSettingsByOwner.items));
        let listFromRel = items
          .filter(s => s.watchlist !== null)
          .map(s => ({
            ...s.watchlist,
            settings: {
              items: [
                { id: s.id, owner: s.owner, email: s.email, follow: s.follow, funding_updates: s.funding_updates },
              ],
            },
          }));
        listOfLists = listOfLists.concat(listFromRel);
        nextToken = list.data.listSettingsByOwner.nextToken;
        if (!nextToken) {
          nextPage = false;
        }
      } else {
        return { errors: ['Failed to fetch watchlists by owner, no data'] };
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by owner'] };
  }
};

const watchlistsBySettingsAll = async owner => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = {};
      try {
        list = await API.graphql(graphqlOperation(listSettingsByOwnerGQL, { owner, nextToken }));
      } catch (e) {
        list = e;
      }
      // if (list.errors) {
      //   return list;
      // }
      if (list?.data) {
        let items = JSON.parse(JSON.stringify(list.data.listSettingsByOwner.items));
        let listFromRel = items
          .filter(s => s.watchlist !== null)
          .map(s => ({
            ...s.watchlist,
            items: JSON.parse(s.watchlist.items),
            settings: {
              items: [
                { id: s.id, owner: s.owner, email: s.email, follow: s.follow, funding_updates: s.funding_updates },
              ],
            },
          }));
        listOfLists = listOfLists.concat(listFromRel);
        nextToken = list.data.listSettingsByOwner.nextToken;
        if (!nextToken) {
          nextPage = false;
        }
      } else {
        return { errors: ['Failed to fetch watchlists by owner, no data'] };
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by owner'] };
  }
};

const sdk = {
  watchlistsByAccount,
  watchlistsByTeam,
  watchlistsByOwnerAccountPrivate,
  watchlistsByOwner,
  watchlistsByShared,
  watchlistsBySettings,
  watchlistsBySettingsAll,
  watchlistsByAccountAndPersonalAccount,
};

export default sdk;
