import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { EllipsisOutlined, LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './CategoryTag.css';

const CategoryTag = ({ item, styles }) => {
  return (
    <Link className="fuel-tag-link" style={styles} to={item.link}>
      {item.label}
    </Link>
  );
};

const CategoryTags = ({ items, tagStyle, styles, minimum = 15 }) => {
  const [more, setMore] = useState(false);
  let showCats = more ? items : items.slice(0, minimum);

  return (
    <div className="fuel-tag-links" style={styles}>
      {showCats.map((i, idx) => (
        <CategoryTag key={`${idx}-category-tag`} styles={tagStyle} item={i} />
      ))}

      {items.length > minimum && (
        <Button
          type="text"
          size="small"
          style={{ fontSize: '18px' }}
          icon={
            !more ? (
              <EllipsisOutlined style={{ fontSize: '18px', color: 'rgba(0,0,0,.65)' }} />
            ) : (
              <LeftOutlined style={{ fontSize: '18px', color: 'rgba(0,0,0,.65)' }} />
            )
          }
          onClick={() => setMore(!more)}
        />
      )}
    </div>
  );
};

export default CategoryTags;
