import React from 'react';
import { Button } from 'antd';
import './GoogleSignin.css';

const GoogleSignin = ({ loading, onClick, disabled, style }) => {
  return (
    <Button
      block
      className="google-btn"
      size="large"
      disabled={disabled || loading}
      onClick={onClick}
      icon={
        <img
          src="/fs/images/google-signin-light.svg"
          alt="Sign in with Google"
          style={{ height: '20px', width: '20px', marginRight: '24px' }}
        />
      }
      style={style && style}
    >
      Sign in with Google
    </Button>
  );
};

export default GoogleSignin;
