import { API } from '@aws-amplify/api';

const rssNews = async (name, domain) => {
  let url = `/api-public/rss`;

  let queryText = domain.includes('.io') || domain.includes('.dev') ? domain : name;
  try {
    let result = await API.get('fuelapi', url, {
      queryStringParameters: { query: `"${queryText}"` },
    });
    if (result?.posts && result.posts.length === 0 && queryText === domain) {
      let result = await API.get('fuelapi', url, {
        queryStringParameters: { query: `"${name}"` },
      });
      return result;
    }
    return result;
  } catch (e) {
    console.log('error fetching company RSS news', e);
    return { error: 'Failed fetching company RSS news' };
  }
};

const rssBlogPosts = async (id, domain) => {
  let url = `/api-public/rss`;
  try {
    let result = await API.get('fuelapi', url, {
      queryStringParameters: { site: domain, companyId: id, query: '' },
    });

    return result;
  } catch (e) {
    console.log('error fetching company RSS blog', e);
    return { error: 'Failed fetching company RSS blog' };
  }
};

const otherNews = async (id, omitId) => {
  let url = `/api-public/companies/${id}/news`;

  try {
    let result = await API.get('fuelapi', url, { queryStringParameters: { omitId } });
    return result;
  } catch (e) {
    console.log('error fetching company news', e);
    return { error: 'Failed fetching company news' };
  }
};

const findCompanies = async (filters, offset, limit, sort) => {
  let url = `/api/companies/search`;

  try {
    let result = await API.post('fuelapi', url, { body: { filters, offset, limit, sort } });
    return result;
  } catch (e) {
    console.log('error searching companies', e);
    return { error: 'Failed to search for companies' };
  }
};

const searchCompaniesES = async (filters, offset, limit, sort) => {
  let url = `/api/search/organizations`;

  try {
    let result = await API.post('fuelapi', url, { body: { query: { filters, offset, limit, sort } } });
    return result;
  } catch (e) {
    console.log('error searching companies', e);
    return { error: 'Failed to search for companies' };
  }
};

const findCompaniesCount = async filters => {
  let url = `/api/companies/search/count`;

  try {
    let result = await API.post('fuelapi', url, { body: { filters } });
    return result;
  } catch (e) {
    console.log('error searching companies count', e);
    return { error: 'Failed to search for companies count' };
  }
};

const searchFundingRounds = async (filters, offset, limit, sort) => {
  let url = `/api/search/funding_rounds`;

  try {
    let result = await API.post('fuelapi', url, { body: { query: { filters, offset, limit, sort } } });
    return result;
  } catch (e) {
    console.log('error searching fundings', e);
    return { error: 'Failed to search for fundings' };
  }
};
const searchInvestors = async (filters, offset, limit, sort) => {
  let url = `/api/search/investors`;

  try {
    let result = await API.post('fuelapi', url, { body: { query: { filters, offset, limit, sort } } });
    return result;
  } catch (e) {
    console.log('error searching investors', e);
    return { error: 'Failed to search for investors' };
  }
};

const findFundingRounds = async (filters, offset, limit, sort) => {
  let url = `/api/funding/search`;

  try {
    let result = await API.post('fuelapi', url, { body: { filters, offset, limit, sort } });
    return result;
  } catch (e) {
    console.log('error searching fundings', e);
    return { error: 'Failed to search for fundings' };
  }
};

const findFundingRoundsCount = async filters => {
  let url = `/api/funding/search/count`;

  try {
    let result = await API.post('fuelapi', url, { body: { filters } });
    return result;
  } catch (e) {
    console.log('error searching fundings count', e);
    return { error: 'Failed to search for fundings count' };
  }
};

const searchCompanies = async (queryStr, isPublic = false) => {
  let url;
  if (isPublic) {
    url = `/api-public/companies/search`;
  } else {
    url = `/api/companies`;
  }
  try {
    let result = await API.get('fuelapi', url, { queryStringParameters: { query: queryStr } });
    return result;
  } catch (e) {
    console.log('error searching companies', e);
    return { error: 'Failed to search for companies' };
  }
};

const getCompanyURLMetrics = async (domain, companyId) => {
  let url = `/api/companies/url-metrics`;

  try {
    let result = await API.get('fuelapi', url, { queryStringParameters: { domain, companyId } });
    console.log('getCompanyURLMetrics', result);
    return result;
  } catch (e) {
    console.log('error retrieving company url metrics', e);
    return { error: 'Failed to fetch company url metrics' };
  }
};

const getCompanyOverview = async orgId => {
  let url = `/api/companies/${orgId}/overview`;
  let result;
  try {
    result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving company details', e);
    return { error: 'Failed to fetch company details', errMessage: e.message };
  }
};

const getCompanyDetails = async orgId => {
  let url = `/api/companies/${orgId}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving company details', e);
    return { error: 'Failed to fetch company details' };
  }
};

const getAdminCompanyOverlay = async orgId => {
  let url = `/api/admin/companies/${orgId}/overlay`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving company details', e);
    return { error: 'Failed to fetch company details' };
  }
};

const getMarketPatches = async companyId => {
  let url = `/api/admin/companies/${companyId}/markets`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    return { error: 'Failed to fetch company details' };
  }
};
const addMarketPatch = async companyObj => {
  let url = `/api/admin/companies/${companyObj.org_uuid}/markets`;

  try {
    let result = await API.post('fuelapi', url, { body: { item: companyObj } });
    return result;
  } catch (e) {
    return { error: 'Failed to add Market Patch' };
  }
};
const deleteMarketPatch = async companyObj => {
  let url = `/api/admin/companies/${companyObj.org_uuid}/markets`;

  try {
    let result = await API.del('fuelapi', url, { body: { item: companyObj } });
    return result;
  } catch (e) {
    return { error: 'Failed to delete market patch' };
  }
};

const updateAdminCompanyOverlay = async (orgId, item) => {
  let url = `/api/admin/companies/${orgId}/overlay`;

  try {
    let result = await API.put('fuelapi', url, { body: { item } });
    return result;
  } catch (e) {
    console.log('error updating company overlay', e);
    return { error: 'Failed to updating company overlay' };
  }
};

const getRelated = async orgId => {
  let url = `/api/companies/${orgId}/relatedCompaniesV2`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving related companies', e);
    return { error: 'Failed to fetch related companies' };
  }
};

const getCompanySocialHistory = async orgId => {
  let url = `/api/companies/${orgId}/socials`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving social history', e);
    return { error: 'Failed to fetch social history' };
  }
};

const getCompanyPortfolio = async orgId => {
  let url = `/api/companies/${orgId}/investments/portfolio`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving company portfolio', e);
    return { error: 'Failed to fetch company portfolio' };
  }
};

const getCompanyCoinvestors = async orgId => {
  let url = `/api/companies/${orgId}/investments/coinvestors`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving company coinvestors', e);
    return { error: 'Failed to fetch company coinvestors' };
  }
};

const getCompanyInvestments = async (orgId, limit = 10) => {
  let url = `/api/companies/${orgId}/investments`;

  try {
    let result = await API.get('fuelapi', url, {
      queryStringParameters: { _limit: limit },
    });
    return result;
  } catch (e) {
    console.log('error retrieving company data', e);
    return { error: 'Failed to fetch company data' };
  }
};

const getCompanyAcquisitions = async orgId => {
  let url = `/api/companies/${orgId}/acquisitions`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving company acquisitions', e);
    return { error: 'Failed to fetch company acquisitions' };
  }
};

const getCompanyFunding = async orgId => {
  let url = `/api/companies/${orgId}/funding`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving company data', e);
    return { error: 'Failed to fetch company data' };
  }
};

const getCompanyNews = async (orgId, limit = 10) => {
  let url = `/api/companies/${orgId}/news`;

  try {
    let result = await API.get('fuelapi', url, {
      queryStringParameters: { _limit: limit },
    });
    return result;
  } catch (e) {
    console.log('error retrieving company data', e);
    return { error: 'Failed to fetch company data' };
  }
};

const searchCompanyEdges = async ({
  companyId,
  edgeDirection = 'out',
  edge,
  offset,
  limit,
  filters,
  sort = 'published_time',
}) => {
  let url = `/api/companies/${companyId}/search`;

  try {
    let result = await API.post('fuelapi', url, {
      body: { edge, edgeDirection, filters, offset, limit, sort },
    });
    return result;
  } catch (e) {
    console.log('error searching company edge', e);
    return { error: 'Failed to fetch company edge data' };
  }
};

const groupCountCompanyEdges = async ({
  companyId,
  edgeDirection = 'out',
  edge,
  filters,
  groupCountBy = 'published_time',
}) => {
  let url = `/api/companies/${companyId}/count`;

  try {
    let result = await API.post('fuelapi', url, {
      body: { edge, edgeDirection, filters, groupCountBy },
    });
    return result;
  } catch (e) {
    console.log('error counting company edge', e);
    return { error: 'Failed to counting company edge data' };
  }
};

const getCompanyContent = async (orgId, limit = 6) => {
  let url = `/api/companies/${orgId}/content`;

  try {
    let result = await API.get('fuelapi', url, {
      queryStringParameters: { _limit: limit },
    });
    return result;
  } catch (e) {
    console.log('error retrieving company data', e);
    return { error: 'Failed to fetch company data' };
  }
};

const getCompanyScraperData = async orgId => {
  let url = `/api/companies/${orgId}/scraperData`;

  try {
    let result = await API.get('fuelapi', url, {});
    return result;
  } catch (e) {
    console.log('error retrieving company data', e);
    return { error: 'Failed to fetch company data' };
  }
};

const getCompanyPeople = async (orgId, limit = 10, active = true) => {
  let url = `/api/companies/${orgId}/people`;

  try {
    let result = await API.get('fuelapi', url, {
      queryStringParameters: { _limit: limit, active: active },
    });
    return result;
  } catch (e) {
    console.log('error retrieving company data', e);
    return { error: 'Failed to fetch company data' };
  }
};

const getCategorySOV = async categoryId => {
  let url = `/api/companies/sov/${categoryId}`;

  try {
    let result = await API.get('fuelapi', url);
    return { ...result, categoryId };
  } catch (e) {
    console.log('error retrieving strength of voice', e);
    return { error: 'Failed to fetch strength of voice for category' };
  }
};

// Get all article details
const getPodcasts = async orgId => {
  let url = `/query?podcastId=${orgId}&parseGremlin=true`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving podcasts', e);
    return { error: 'Failed to fetch podcasts' };
  }
};

const getInvestments = async orgId => {
  let url = `/query?uuid=${orgId}&companyPorfolio=true&parseGremlin=true`;
  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving podcasts', e);
    return { error: 'Failed to fetch company investments' };
  }
};

const getCompaniesOverview = async companies => {
  let url = `/api/companies/overview`;

  try {
    let result = await API.get('fuelapi', url, {
      queryStringParameters: { companies: companies },
    });
    return result;
  } catch (e) {
    console.log('error fetching Companies overview', e);
    return { error: 'Failed to fetch Companies overview' };
  }
};

const sdk = {
  getPodcasts,
  getInvestments,
  getCategorySOV,
  getRelated,
  getCompanyDetails,
  searchCompanies,
  getCompanyInvestments,
  getCompanyFunding,
  getCompanyAcquisitions,
  getCompanyNews,
  getCompanyContent,
  getCompanyPeople,
  getCompanyOverview,
  getCompanyScraperData,
  searchCompanyEdges,
  groupCountCompanyEdges,
  getAdminCompanyOverlay,
  getCompanySocialHistory,
  getCompaniesOverview,
  updateAdminCompanyOverlay,
  otherNews,
  rssNews,
  getMarketPatches,
  addMarketPatch,
  deleteMarketPatch,
  getCompanyURLMetrics,
  findCompanies,
  findCompaniesCount,
  findFundingRounds,
  findFundingRoundsCount,
  getCompanyPortfolio,
  getCompanyCoinvestors,
  rssBlogPosts,
  searchFundingRounds,
  searchCompaniesES,
  searchInvestors,
};

export default sdk;
