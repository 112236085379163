import './Search.css';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Space } from 'antd';
import { API } from '@aws-amplify/api';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AvatarFailover from 'components/AvatarFailover';
import { logoURL } from 'helpers/Organizations';

import { decodeGremlin } from 'data/gremlin';
import { getSearchResult } from 'helpers/Search';

let searchTimeout = null;

const typeCompany = 'company';
const typeMarket = 'market';

const Search = props => {
  let history = useHistory();
  let searchingContent = (
    <Space>
      <LoadingOutlined />
      Searching...
    </Space>
  );
  let nonFound = props.notFoundContent || <div>No results.</div>;
  const [result, setResult] = useState([]);
  const [value, setValue] = useState('');
  const [found, setFound] = useState();
  const [type] = useState(props.type || typeCompany);
  const dynamicProp = props.showSelectedValue ? {} : { value: props.searchValue ? props.searchValue : value };

  const reset = () => {
    setValue('');
    setResult([]);
    setFound();
  };

  useEffect(() => {
    if (props.doReset) {
      reset();
    }
  }, [props.doReset]);

  const handleSelect = (value, option) => {
    if (props.handleSelect) {
      if (props.resetOnSelection) reset();
      if (props.showSelectedValue) {
        props.handleSelect(value);
        setValue(value);
      } else {
        props.handleSelect(option);
      }
    } else {
      reset();
      history.push(option.link);
    }
  };

  const searchMarkets = searchText => {
    var splitArr = searchText.split(' ');
    var concatString = '';
    splitArr.map(data => {
      return (concatString += ` AND predicates.name.value:${data}~`);
    });
    let query = `$entity_type:tier3_category${concatString}$`;
    let url = `/query?_searchAll=${encodeURI(query)}`;

    API.get('fuelapi', url)
      .then(result => {
        // Catch lack of results
        if (typeof result === 'undefined') {
          result = [];
        } else if (!('result' in result)) {
          result = [];
        } else {
          result = decodeGremlin(result.result.data['@value']);
        }

        let results = result.map(o => ({
          value: props.showSelectedValue ? o.name : o.id,
          label: (
            <div className="search-result">
              <div className="t">{o.name}</div>
            </div>
          ),
          link: `/markets/${o.id.substring(4)}`,
        }));
        setResult(results);
        setFound(nonFound);
      })
      .catch(e => {
        setResult([]);
        setFound(nonFound);
      });
  };

  const searchCompanies = async searchText => {
    let formattedToDomain = searchText.trim();
    if (searchText.includes('://')) {
      formattedToDomain = searchText.split('://')[1];
    }
    if (formattedToDomain.includes('/')) {
      formattedToDomain = formattedToDomain.split('/')[0];
    }
    formattedToDomain = formattedToDomain.replace(/www./i, '');

    try {
      let result = await getSearchResult(formattedToDomain);
      let orgs = result.companies.map(c => ({ id: c?.id, uuid: c?.id, organization: { ...c } }));

      if (props.companies) {
        orgs = orgs.filter(org => props.companies.findIndex(company => company.uuid === org.uuid) < 0);
      }
      let results = orgs.map((o, i) => ({
        value: o.uuid,
        label: (
          <div className="search-result" data-value={o.organization.name} data-id={'company-list-' + i}>
            <div className="i">
              <AvatarFailover
                key={`avatar-${o.uuid}`}
                src={logoURL(o.uuid)}
                name={o.organization.name}
                shape="square"
                size={'small'}
              />
            </div>
            <div className="t" style={{ width: '100%' }}>
              {o.organization.name}
            </div>
            <div className="d">{o.organization.domain}</div>
          </div>
        ),
        link: `/research/companies/${o.uuid}`,
        data: { ...o },
      }));
      setResult(results);
      setFound(nonFound);
    } catch (e) {
      setResult([]);
      setFound(nonFound);
    }
  };

  const handleEnter = event => {
    // if the parent provided a handler, cancel the search and pass the text back to them
    event.preventDefault();
    if (props.handleEnter) {
      props.handleEnter(value);
      clearTimeout(searchTimeout);
      reset();
    }
  };

  const handleSearch = value => {
    setValue(value);
    if (props.handleSearch) {
      props.handleSearch(value);
    }
    // clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than 750ms
    clearTimeout(searchTimeout);
    setFound(searchingContent);

    // minimum character search
    if (value.length <= 2) {
      setResult([]);
      setFound();
      return;
    }

    // make a new timeout set to go off in 750ms
    // this attempts to wait for the user to stop typing before running the search query
    searchTimeout = setTimeout(function() {
      if (type === typeCompany) searchCompanies(value);
      else if (type === typeMarket) searchMarkets(value);
      else console.log(`'${type}' not supported`);
    }, 750);
  };

  return (
    <AutoComplete
      options={result.map(item => ({
        value: item.value,
        label: item.label,
        link: item.link,
        data: item.data,
      }))}
      style={props.style || { width: '50%' }}
      className={`search-bar ${props.className || ''}`}
      onSearch={handleSearch}
      onSelect={handleSelect}
      onBlur={props.doNotResetOnBlur ? null : reset}
      // value={props.searchValue ? props.searchValue : value}
      {...dynamicProp}
      notFoundContent={found}
      popupMatchSelectWidth={props?.style?.width || 500}
      disabled={props.disabled}
    >
      <Input
        data-id={props['data-id']}
        value={value}
        bordered={props.bordered || false}
        size={props.size || 'default'}
        placeholder={props.placeholder || 'Search...'}
        prefix={props.prefix ? <SearchOutlined /> : <></>}
        onPressEnter={handleEnter}
        style={props.inputStyle || {}}
      />
    </AutoComplete>
  );
};

export default Search;
