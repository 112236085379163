/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addWatchlist = /* GraphQL */ `
  mutation AddWatchlist($input: AddWatchlistInput!) {
    addWatchlist(input: $input) {
      id
      owner
      viewers
      admins
      company
      scope
      name
      description
      items
      accountID
      account_privacy
      columns
      metadata
      datasource
      updatingData
      lastDataUpdate
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teams {
        items {
          id
          accountID
          teamID
          watchlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          owner
          starred
          email
          follow
          funding_updates
          preferences
          watchlistSettingWatchlistId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const addAccount = /* GraphQL */ `
  mutation AddAccount($input: AddAccountInput!) {
    addAccount(input: $input) {
      id
      creator
      owners
      members
      name
      description
      user_seats
      logo
      limits
      referralID
      trialStart
      trialEnd
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      accountType
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      referral {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
    }
  }
`;
export const addTeam = /* GraphQL */ `
  mutation AddTeam($input: AddTeamInput!) {
    addTeam(input: $input) {
      id
      members
      name
      description
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teamMembers {
        items {
          id
          email
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const editAccount = /* GraphQL */ `
  mutation EditAccount($input: EditAccountInput!) {
    editAccount(input: $input) {
      id
      creator
      owners
      members
      name
      description
      user_seats
      logo
      limits
      referralID
      trialStart
      trialEnd
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      accountType
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      referral {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
    }
  }
`;
export const editTeam = /* GraphQL */ `
  mutation EditTeam($input: EditTeamInput!) {
    editTeam(input: $input) {
      id
      members
      name
      description
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teamMembers {
        items {
          id
          email
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const removeTeam = /* GraphQL */ `
  mutation RemoveTeam($input: RemoveTeamInput!) {
    removeTeam(input: $input) {
      id
      members
      name
      description
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teamMembers {
        items {
          id
          email
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCourseCategory = /* GraphQL */ `
  mutation CreateCourseCategory($input: CreateCourseCategoryInput!, $condition: ModelCourseCategoryConditionInput) {
    createCourseCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseCategory = /* GraphQL */ `
  mutation UpdateCourseCategory($input: UpdateCourseCategoryInput!, $condition: ModelCourseCategoryConditionInput) {
    updateCourseCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseCategory = /* GraphQL */ `
  mutation DeleteCourseCategory($input: DeleteCourseCategoryInput!, $condition: ModelCourseCategoryConditionInput) {
    deleteCourseCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createCourseCategoryConnection = /* GraphQL */ `
  mutation CreateCourseCategoryConnection(
    $input: CreateCourseCategoryConnectionInput!
    $condition: ModelCourseCategoryConnectionConditionInput
  ) {
    createCourseCategoryConnection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseCategoryConnection = /* GraphQL */ `
  mutation UpdateCourseCategoryConnection(
    $input: UpdateCourseCategoryConnectionInput!
    $condition: ModelCourseCategoryConnectionConditionInput
  ) {
    updateCourseCategoryConnection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseCategoryConnection = /* GraphQL */ `
  mutation DeleteCourseCategoryConnection(
    $input: DeleteCourseCategoryConnectionInput!
    $condition: ModelCourseCategoryConnectionConditionInput
  ) {
    deleteCourseCategoryConnection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createChannelInvites = /* GraphQL */ `
  mutation CreateChannelInvites($input: CreateChannelInvitesInput!, $condition: ModelChannelInvitesConditionInput) {
    createChannelInvites(input: $input, condition: $condition) {
      id
      owner
      channelID
      inviteID
      quebicUserID
      lastReadTime
      createdAt
      updatedAt
    }
  }
`;
export const updateChannelInvites = /* GraphQL */ `
  mutation UpdateChannelInvites($input: UpdateChannelInvitesInput!, $condition: ModelChannelInvitesConditionInput) {
    updateChannelInvites(input: $input, condition: $condition) {
      id
      owner
      channelID
      inviteID
      quebicUserID
      lastReadTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteChannelInvites = /* GraphQL */ `
  mutation DeleteChannelInvites($input: DeleteChannelInvitesInput!, $condition: ModelChannelInvitesConditionInput) {
    deleteChannelInvites(input: $input, condition: $condition) {
      id
      owner
      channelID
      inviteID
      quebicUserID
      lastReadTime
      createdAt
      updatedAt
    }
  }
`;
export const createUserPreferences = /* GraphQL */ `
  mutation CreateUserPreferences($input: CreateUserPreferencesInput!, $condition: ModelUserPreferencesConditionInput) {
    createUserPreferences(input: $input, condition: $condition) {
      id
      owner
      key
      value
      details
      createdAt
      updatedAt
    }
  }
`;
export const updateUserPreferences = /* GraphQL */ `
  mutation UpdateUserPreferences($input: UpdateUserPreferencesInput!, $condition: ModelUserPreferencesConditionInput) {
    updateUserPreferences(input: $input, condition: $condition) {
      id
      owner
      key
      value
      details
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserPreferences = /* GraphQL */ `
  mutation DeleteUserPreferences($input: DeleteUserPreferencesInput!, $condition: ModelUserPreferencesConditionInput) {
    deleteUserPreferences(input: $input, condition: $condition) {
      id
      owner
      key
      value
      details
      createdAt
      updatedAt
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan($input: CreatePlanInput!, $condition: ModelPlanConditionInput) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      planType
      createdAt
      updatedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan($input: UpdatePlanInput!, $condition: ModelPlanConditionInput) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      planType
      createdAt
      updatedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan($input: DeletePlanInput!, $condition: ModelPlanConditionInput) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      planType
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: CreateAccountInput!, $condition: ModelAccountConditionInput) {
    createAccount(input: $input, condition: $condition) {
      id
      creator
      owners
      members
      name
      description
      user_seats
      logo
      limits
      referralID
      trialStart
      trialEnd
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      accountType
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      referral {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!, $condition: ModelAccountConditionInput) {
    updateAccount(input: $input, condition: $condition) {
      id
      creator
      owners
      members
      name
      description
      user_seats
      logo
      limits
      referralID
      trialStart
      trialEnd
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      accountType
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      referral {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($input: DeleteAccountInput!, $condition: ModelAccountConditionInput) {
    deleteAccount(input: $input, condition: $condition) {
      id
      creator
      owners
      members
      name
      description
      user_seats
      logo
      limits
      referralID
      trialStart
      trialEnd
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      accountType
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      referral {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
    }
  }
`;
export const createReferral = /* GraphQL */ `
  mutation CreateReferral($input: CreateReferralInput!, $condition: ModelReferralConditionInput) {
    createReferral(input: $input, condition: $condition) {
      id
      referralType
      code
      description
      active
      accountID
      planID
      uses
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReferral = /* GraphQL */ `
  mutation UpdateReferral($input: UpdateReferralInput!, $condition: ModelReferralConditionInput) {
    updateReferral(input: $input, condition: $condition) {
      id
      referralType
      code
      description
      active
      accountID
      planID
      uses
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReferral = /* GraphQL */ `
  mutation DeleteReferral($input: DeleteReferralInput!, $condition: ModelReferralConditionInput) {
    deleteReferral(input: $input, condition: $condition) {
      id
      referralType
      code
      description
      active
      accountID
      planID
      uses
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createAccountMember = /* GraphQL */ `
  mutation CreateAccountMember($input: CreateAccountMemberInput!, $condition: ModelAccountMemberConditionInput) {
    createAccountMember(input: $input, condition: $condition) {
      id
      email
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateAccountMember = /* GraphQL */ `
  mutation UpdateAccountMember($input: UpdateAccountMemberInput!, $condition: ModelAccountMemberConditionInput) {
    updateAccountMember(input: $input, condition: $condition) {
      id
      email
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAccountMember = /* GraphQL */ `
  mutation DeleteAccountMember($input: DeleteAccountMemberInput!, $condition: ModelAccountMemberConditionInput) {
    deleteAccountMember(input: $input, condition: $condition) {
      id
      email
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput!, $condition: ModelTeamConditionInput) {
    createTeam(input: $input, condition: $condition) {
      id
      members
      name
      description
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teamMembers {
        items {
          id
          email
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput!, $condition: ModelTeamConditionInput) {
    updateTeam(input: $input, condition: $condition) {
      id
      members
      name
      description
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teamMembers {
        items {
          id
          email
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam($input: DeleteTeamInput!, $condition: ModelTeamConditionInput) {
    deleteTeam(input: $input, condition: $condition) {
      id
      members
      name
      description
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teamMembers {
        items {
          id
          email
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember($input: CreateTeamMemberInput!, $condition: ModelTeamMemberConditionInput) {
    createTeamMember(input: $input, condition: $condition) {
      id
      email
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember($input: UpdateTeamMemberInput!, $condition: ModelTeamMemberConditionInput) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      email
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember($input: DeleteTeamMemberInput!, $condition: ModelTeamMemberConditionInput) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      email
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
    }
  }
`;
export const createWatchlist = /* GraphQL */ `
  mutation CreateWatchlist($input: CreateWatchlistInput!, $condition: ModelWatchlistConditionInput) {
    createWatchlist(input: $input, condition: $condition) {
      id
      owner
      viewers
      admins
      company
      scope
      name
      description
      items
      accountID
      account_privacy
      columns
      metadata
      datasource
      updatingData
      lastDataUpdate
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teams {
        items {
          id
          accountID
          teamID
          watchlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          owner
          starred
          email
          follow
          funding_updates
          preferences
          watchlistSettingWatchlistId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateWatchlist = /* GraphQL */ `
  mutation UpdateWatchlist($input: UpdateWatchlistInput!, $condition: ModelWatchlistConditionInput) {
    updateWatchlist(input: $input, condition: $condition) {
      id
      owner
      viewers
      admins
      company
      scope
      name
      description
      items
      accountID
      account_privacy
      columns
      metadata
      datasource
      updatingData
      lastDataUpdate
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teams {
        items {
          id
          accountID
          teamID
          watchlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          owner
          starred
          email
          follow
          funding_updates
          preferences
          watchlistSettingWatchlistId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteWatchlist = /* GraphQL */ `
  mutation DeleteWatchlist($input: DeleteWatchlistInput!, $condition: ModelWatchlistConditionInput) {
    deleteWatchlist(input: $input, condition: $condition) {
      id
      owner
      viewers
      admins
      company
      scope
      name
      description
      items
      accountID
      account_privacy
      columns
      metadata
      datasource
      updatingData
      lastDataUpdate
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teams {
        items {
          id
          accountID
          teamID
          watchlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          owner
          starred
          email
          follow
          funding_updates
          preferences
          watchlistSettingWatchlistId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createWatchlistTeam = /* GraphQL */ `
  mutation CreateWatchlistTeam($input: CreateWatchlistTeamInput!, $condition: ModelWatchlistTeamConditionInput) {
    createWatchlistTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      watchlistID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const updateWatchlistTeam = /* GraphQL */ `
  mutation UpdateWatchlistTeam($input: UpdateWatchlistTeamInput!, $condition: ModelWatchlistTeamConditionInput) {
    updateWatchlistTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      watchlistID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const deleteWatchlistTeam = /* GraphQL */ `
  mutation DeleteWatchlistTeam($input: DeleteWatchlistTeamInput!, $condition: ModelWatchlistTeamConditionInput) {
    deleteWatchlistTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      watchlistID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const createWatchlistUser = /* GraphQL */ `
  mutation CreateWatchlistUser($input: CreateWatchlistUserInput!, $condition: ModelWatchlistUserConditionInput) {
    createWatchlistUser(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      list {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const updateWatchlistUser = /* GraphQL */ `
  mutation UpdateWatchlistUser($input: UpdateWatchlistUserInput!, $condition: ModelWatchlistUserConditionInput) {
    updateWatchlistUser(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      list {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const deleteWatchlistUser = /* GraphQL */ `
  mutation DeleteWatchlistUser($input: DeleteWatchlistUserInput!, $condition: ModelWatchlistUserConditionInput) {
    deleteWatchlistUser(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      list {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const createWatchlistSetting = /* GraphQL */ `
  mutation CreateWatchlistSetting(
    $input: CreateWatchlistSettingInput!
    $condition: ModelWatchlistSettingConditionInput
  ) {
    createWatchlistSetting(input: $input, condition: $condition) {
      id
      owner
      starred
      email
      follow
      funding_updates
      preferences
      watchlistSettingWatchlistId
      createdAt
      updatedAt
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const updateWatchlistSetting = /* GraphQL */ `
  mutation UpdateWatchlistSetting(
    $input: UpdateWatchlistSettingInput!
    $condition: ModelWatchlistSettingConditionInput
  ) {
    updateWatchlistSetting(input: $input, condition: $condition) {
      id
      owner
      starred
      email
      follow
      funding_updates
      preferences
      watchlistSettingWatchlistId
      createdAt
      updatedAt
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const deleteWatchlistSetting = /* GraphQL */ `
  mutation DeleteWatchlistSetting(
    $input: DeleteWatchlistSettingInput!
    $condition: ModelWatchlistSettingConditionInput
  ) {
    deleteWatchlistSetting(input: $input, condition: $condition) {
      id
      owner
      starred
      email
      follow
      funding_updates
      preferences
      watchlistSettingWatchlistId
      createdAt
      updatedAt
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const createAdvisoryJourney = /* GraphQL */ `
  mutation CreateAdvisoryJourney($input: CreateAdvisoryJourneyInput!, $condition: ModelAdvisoryJourneyConditionInput) {
    createAdvisoryJourney(input: $input, condition: $condition) {
      id
      title
      slug
      subtitle
      order
      description
      playbookSectionTitle
      playbooks
      createdAt
      updatedAt
    }
  }
`;
export const updateAdvisoryJourney = /* GraphQL */ `
  mutation UpdateAdvisoryJourney($input: UpdateAdvisoryJourneyInput!, $condition: ModelAdvisoryJourneyConditionInput) {
    updateAdvisoryJourney(input: $input, condition: $condition) {
      id
      title
      slug
      subtitle
      order
      description
      playbookSectionTitle
      playbooks
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdvisoryJourney = /* GraphQL */ `
  mutation DeleteAdvisoryJourney($input: DeleteAdvisoryJourneyInput!, $condition: ModelAdvisoryJourneyConditionInput) {
    deleteAdvisoryJourney(input: $input, condition: $condition) {
      id
      title
      slug
      subtitle
      order
      description
      playbookSectionTitle
      playbooks
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse($input: CreateCourseInput!, $condition: ModelCourseConditionInput) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      category
      featured
      bizAssessmentCategory
      slug
      short_description
      description
      content
      featuredimage
      order
      published
      createdAt
      updatedAt
      attachments {
        items {
          id
          courseId
          title
          description
          filetype
          order
          attachmentType
          fuelTool
          createdAt
          updatedAt
          name
          identityId
        }
        nextToken
      }
      videos {
        items {
          id
          courseId
          title
          description
          duration
          previewimage
          order
          createdAt
          updatedAt
          url
        }
        nextToken
      }
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse($input: UpdateCourseInput!, $condition: ModelCourseConditionInput) {
    updateCourse(input: $input, condition: $condition) {
      id
      name
      category
      featured
      bizAssessmentCategory
      slug
      short_description
      description
      content
      featuredimage
      order
      published
      createdAt
      updatedAt
      attachments {
        items {
          id
          courseId
          title
          description
          filetype
          order
          attachmentType
          fuelTool
          createdAt
          updatedAt
          name
          identityId
        }
        nextToken
      }
      videos {
        items {
          id
          courseId
          title
          description
          duration
          previewimage
          order
          createdAt
          updatedAt
          url
        }
        nextToken
      }
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse($input: DeleteCourseInput!, $condition: ModelCourseConditionInput) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name
      category
      featured
      bizAssessmentCategory
      slug
      short_description
      description
      content
      featuredimage
      order
      published
      createdAt
      updatedAt
      attachments {
        items {
          id
          courseId
          title
          description
          filetype
          order
          attachmentType
          fuelTool
          createdAt
          updatedAt
          name
          identityId
        }
        nextToken
      }
      videos {
        items {
          id
          courseId
          title
          description
          duration
          previewimage
          order
          createdAt
          updatedAt
          url
        }
        nextToken
      }
    }
  }
`;
export const createCourseAttachment = /* GraphQL */ `
  mutation CreateCourseAttachment(
    $input: CreateCourseAttachmentInput!
    $condition: ModelCourseAttachmentConditionInput
  ) {
    createCourseAttachment(input: $input, condition: $condition) {
      id
      courseId
      title
      description
      filetype
      order
      attachmentType
      fuelTool
      createdAt
      updatedAt
      name
      identityId
    }
  }
`;
export const updateCourseAttachment = /* GraphQL */ `
  mutation UpdateCourseAttachment(
    $input: UpdateCourseAttachmentInput!
    $condition: ModelCourseAttachmentConditionInput
  ) {
    updateCourseAttachment(input: $input, condition: $condition) {
      id
      courseId
      title
      description
      filetype
      order
      attachmentType
      fuelTool
      createdAt
      updatedAt
      name
      identityId
    }
  }
`;
export const deleteCourseAttachment = /* GraphQL */ `
  mutation DeleteCourseAttachment(
    $input: DeleteCourseAttachmentInput!
    $condition: ModelCourseAttachmentConditionInput
  ) {
    deleteCourseAttachment(input: $input, condition: $condition) {
      id
      courseId
      title
      description
      filetype
      order
      attachmentType
      fuelTool
      createdAt
      updatedAt
      name
      identityId
    }
  }
`;
export const createCourseVideo = /* GraphQL */ `
  mutation CreateCourseVideo($input: CreateCourseVideoInput!, $condition: ModelCourseVideoConditionInput) {
    createCourseVideo(input: $input, condition: $condition) {
      id
      courseId
      title
      description
      duration
      previewimage
      order
      createdAt
      updatedAt
      url
    }
  }
`;
export const updateCourseVideo = /* GraphQL */ `
  mutation UpdateCourseVideo($input: UpdateCourseVideoInput!, $condition: ModelCourseVideoConditionInput) {
    updateCourseVideo(input: $input, condition: $condition) {
      id
      courseId
      title
      description
      duration
      previewimage
      order
      createdAt
      updatedAt
      url
    }
  }
`;
export const deleteCourseVideo = /* GraphQL */ `
  mutation DeleteCourseVideo($input: DeleteCourseVideoInput!, $condition: ModelCourseVideoConditionInput) {
    deleteCourseVideo(input: $input, condition: $condition) {
      id
      courseId
      title
      description
      duration
      previewimage
      order
      createdAt
      updatedAt
      url
    }
  }
`;
export const createGenericJob = /* GraphQL */ `
  mutation CreateGenericJob($input: CreateGenericJobInput!, $condition: ModelGenericJobConditionInput) {
    createGenericJob(input: $input, condition: $condition) {
      id
      owner
      cognitoId
      jobType
      input
      output
      metadata
      content
      configuration
      status
      deleted
      documentID
      createdAt
      updatedAt
    }
  }
`;
export const updateGenericJob = /* GraphQL */ `
  mutation UpdateGenericJob($input: UpdateGenericJobInput!, $condition: ModelGenericJobConditionInput) {
    updateGenericJob(input: $input, condition: $condition) {
      id
      owner
      cognitoId
      jobType
      input
      output
      metadata
      content
      configuration
      status
      deleted
      documentID
      createdAt
      updatedAt
    }
  }
`;
export const deleteGenericJob = /* GraphQL */ `
  mutation DeleteGenericJob($input: DeleteGenericJobInput!, $condition: ModelGenericJobConditionInput) {
    deleteGenericJob(input: $input, condition: $condition) {
      id
      owner
      cognitoId
      jobType
      input
      output
      metadata
      content
      configuration
      status
      deleted
      documentID
      createdAt
      updatedAt
    }
  }
`;
export const createSavedSearch = /* GraphQL */ `
  mutation CreateSavedSearch($input: CreateSavedSearchInput!, $condition: ModelSavedSearchConditionInput) {
    createSavedSearch(input: $input, condition: $condition) {
      id
      jobID
      accountID
      owner
      name
      description
      search_index
      filters
      dashboard_config
      createdAt
      updatedAt
      job {
        id
        owner
        cognitoId
        jobType
        input
        output
        metadata
        content
        configuration
        status
        deleted
        documentID
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSavedSearch = /* GraphQL */ `
  mutation UpdateSavedSearch($input: UpdateSavedSearchInput!, $condition: ModelSavedSearchConditionInput) {
    updateSavedSearch(input: $input, condition: $condition) {
      id
      jobID
      accountID
      owner
      name
      description
      search_index
      filters
      dashboard_config
      createdAt
      updatedAt
      job {
        id
        owner
        cognitoId
        jobType
        input
        output
        metadata
        content
        configuration
        status
        deleted
        documentID
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSavedSearch = /* GraphQL */ `
  mutation DeleteSavedSearch($input: DeleteSavedSearchInput!, $condition: ModelSavedSearchConditionInput) {
    deleteSavedSearch(input: $input, condition: $condition) {
      id
      jobID
      accountID
      owner
      name
      description
      search_index
      filters
      dashboard_config
      createdAt
      updatedAt
      job {
        id
        owner
        cognitoId
        jobType
        input
        output
        metadata
        content
        configuration
        status
        deleted
        documentID
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUserThread = /* GraphQL */ `
  mutation CreateUserThread($input: CreateUserThreadInput!, $condition: ModelUserThreadConditionInput) {
    createUserThread(input: $input, condition: $condition) {
      id
      owner
      threadID
      name
      label
      createdAt
      updatedAt
    }
  }
`;
export const updateUserThread = /* GraphQL */ `
  mutation UpdateUserThread($input: UpdateUserThreadInput!, $condition: ModelUserThreadConditionInput) {
    updateUserThread(input: $input, condition: $condition) {
      id
      owner
      threadID
      name
      label
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserThread = /* GraphQL */ `
  mutation DeleteUserThread($input: DeleteUserThreadInput!, $condition: ModelUserThreadConditionInput) {
    deleteUserThread(input: $input, condition: $condition) {
      id
      owner
      threadID
      name
      label
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($input: CreateDocumentInput!, $condition: ModelDocumentConditionInput) {
    createDocument(input: $input, condition: $condition) {
      id
      owner
      partition
      partitionData
      doctype
      name
      input
      data
      content
      configuration
      status
      text
      role
      locked
      companyID
      createdAt
      updatedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument($input: UpdateDocumentInput!, $condition: ModelDocumentConditionInput) {
    updateDocument(input: $input, condition: $condition) {
      id
      owner
      partition
      partitionData
      doctype
      name
      input
      data
      content
      configuration
      status
      text
      role
      locked
      companyID
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($input: DeleteDocumentInput!, $condition: ModelDocumentConditionInput) {
    deleteDocument(input: $input, condition: $condition) {
      id
      owner
      partition
      partitionData
      doctype
      name
      input
      data
      content
      configuration
      status
      text
      role
      locked
      companyID
      createdAt
      updatedAt
    }
  }
`;
export const createNotebookPrompt = /* GraphQL */ `
  mutation CreateNotebookPrompt($input: CreateNotebookPromptInput!, $condition: ModelNotebookPromptConditionInput) {
    createNotebookPrompt(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateNotebookPrompt = /* GraphQL */ `
  mutation UpdateNotebookPrompt($input: UpdateNotebookPromptInput!, $condition: ModelNotebookPromptConditionInput) {
    updateNotebookPrompt(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotebookPrompt = /* GraphQL */ `
  mutation DeleteNotebookPrompt($input: DeleteNotebookPromptInput!, $condition: ModelNotebookPromptConditionInput) {
    deleteNotebookPrompt(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessAssessmentResult = /* GraphQL */ `
  mutation CreateBusinessAssessmentResult(
    $input: CreateBusinessAssessmentResultInput!
    $condition: ModelBusinessAssessmentResultConditionInput
  ) {
    createBusinessAssessmentResult(input: $input, condition: $condition) {
      id
      email
      userData
      result
      questions
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessAssessmentResult = /* GraphQL */ `
  mutation UpdateBusinessAssessmentResult(
    $input: UpdateBusinessAssessmentResultInput!
    $condition: ModelBusinessAssessmentResultConditionInput
  ) {
    updateBusinessAssessmentResult(input: $input, condition: $condition) {
      id
      email
      userData
      result
      questions
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessAssessmentResult = /* GraphQL */ `
  mutation DeleteBusinessAssessmentResult(
    $input: DeleteBusinessAssessmentResultInput!
    $condition: ModelBusinessAssessmentResultConditionInput
  ) {
    deleteBusinessAssessmentResult(input: $input, condition: $condition) {
      id
      email
      userData
      result
      questions
      createdAt
      updatedAt
    }
  }
`;
