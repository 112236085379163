import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import App from './App';
import 'antd/dist/reset.css';
import './index.less';

initializeFaro({
  url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/5ea8392e5dddc43771ee589915ebb743',
  app: {
    name: 'York IE Fuel',
    version: process.env.REACT_APP_VERSION || '0.0.0',
    environment: process.env.NODE_ENV,
  },
  instrumentations: [
    // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
    ...getWebInstrumentations(),
  ],
});

const AppWrapper = () => {
  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          colorPrimary: '#00b48a',
          colorLink: '#008C6C',
          fontFamily: `'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
        },
      }}
    >
      <App />
    </ConfigProvider>
  );
};

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
  <Router>
    <AppWrapper />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
