import React, { useEffect } from 'react';
import { register as registerServiceWorker } from './serviceWorkerRegistration.js';

const Updater = props => {
  // redux state

  useEffect(() => {
    // Register service worker and add onUpdate callback
    registerServiceWorker({
      onUpdate: onWorkerUpdate,
      onSuccess: registration => {
        console.log('onSuccess', registration);
      },
    });
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   throw new Error('sdf')
  // }, [])

  const onWorkerUpdate = registration => {
    console.log('onWorkerUpdate', registration);
    if (registration.waiting) {
      // update redux state
      console.log('Sending message to update pwa');
      props.updateAvailable(true);
      // We send the SKIP_WAITING message to tell the Service Worker
      // to update its cache and flush the old one
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      registration.waiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          console.log('Service worker activated, reload the page to update');
        }
        console.log('target state', e.target.state);
      });
    }
  };

  return <></>;
};

export default Updater;
