import { API, graphqlOperation } from '@aws-amplify/api';
import {
  listWatchlists,
  listWatchlistDetails,
  watchlistsByAccount,
  getWatchlistItems,
  updateWatchlistMinimal,
} from 'graphql/customQueries';
import {
  updateWatchlist,
  updateWatchlistSetting,
  deleteWatchlistSetting,
  deleteWatchlistTeam,
  createWatchlistTeam,
} from 'graphql/mutations';
import { notification, Button } from 'antd';

// Get summary of followed watchlists
const getListsSummary = async query => {
  let url = '/api/watchlists/summary';

  let result = await API.get('fuelapi', url, { queryStringParameters: query })
    .then(data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
    });

  return result;
};

// Get events of followed watchlists (funding and acquisitions)
const getListsEvents = async query => {
  let url = '/api/watchlists/dashboard/events';
  try {
    let result = await API.get('fuelapi', url, { queryStringParameters: query });
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    // assumption since we can't read the status code or the response body on this error
    return { error: 'Failed to fetch events' };
  }
};

// Get updates of followed watchlists
const getListsUpdates = async query => {
  let url = '/api/watchlists/dashboard/updates';

  let result = await API.get('fuelapi', url, { queryStringParameters: query })
    .then(data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
    });

  return result;
};

const listCompanies = async id => {
  let url = `/api/watchlists/${id}/companies`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving list companies', e);
    return { companies: [] };
  }
};

const listUpdates = async (id, query) => {
  let url = `/api/watchlists/${id}/updates`;
  let payload = {
    headers: {},
    response: false,
    queryStringParameters: query,
  };

  try {
    let result = await API.put('fuelapi', url, payload);
    return result;
  } catch (e) {
    console.log('error retrieving list companies', e);
    return { companies: [] };
  }
};

const createList = async listInput => {
  let url = `/api/watchlists`;
  if (!listInput.name) {
    listInput.name = 'Untitled List';
  }
  if (!listInput.items) {
    listInput.items = [];
  }
  try {
    let result = await API.post('fuelapi', url, { body: listInput });
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    console.log('response', e.response);
    if (e.response.status === 403) {
      return e.response.data;
    }
    // assumption since we can't read the status code or the response body on this error
    return { error: 'Failed to create Watchlist' };
  }
};

const createNewWatchlist = async (name, items, accountID) => {
  let url = `/api/watchlists`;
  try {
    let result = await API.post('fuelapi', url, { body: { name, items, accountID } });
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    console.log('response', e.response);
    if (e.response.status === 403) {
      return e.response.data;
    }
    // assumption since we can't read the status code or the response body on this error
    return { error: 'Failed to create Watchlist' };
  }
};

const getList = async id => {
  try {
    let response = await API.graphql(graphqlOperation(getWatchlistItems, { id }));
    let watchlist = response.data.getWatchlist;
    return { ...watchlist, items: JSON.parse(watchlist.items) };
  } catch (e) {
    console.log(e);
    let watchlist = e?.data?.getWatchlist;
    if (watchlist) {
      return { ...watchlist, items: JSON.parse(watchlist.items) };
    }
  }
  return { error: 'Failed to fetch Watchlist' };
};

const checkCompaniesPerWatchlistLimit = (activeWorkspaceAccount, groups, personalAccount, companiesLength) => {
  if (groups.includes('unlimited')) {
    return false;
  }
  let limitExceeded = false;
  if (activeWorkspaceAccount) {
    const companiesLimit = JSON.parse(activeWorkspaceAccount?.account?.limits)?.companiesPerWatchlist ?? 100;
    if (companiesLength > companiesLimit) {
      limitExceeded = true;
    }
  } else {
    //default limit if no plan or Account exists
    let companiesLimit = 10;
    if (personalAccount?.limits) {
      companiesLimit = JSON.parse(personalAccount.limits)?.companiesPerWatchlist ?? 100;
    }
    if (companiesLength > companiesLimit) {
      limitExceeded = true;
    }
  }
  if (limitExceeded) {
    notification.warning({
      message: `Limits Exceeded`,
      description: (
        <div>
          Your watchlist exceeded the limits of maximum companies per watchlist. Upgrade your account.
          <br />
          <br />
          <Button>
            <a href={`${window.location.origin}${activeWorkspaceAccount ? '/accounts/upgrade' : '/account#billing'}`}>
              {activeWorkspaceAccount ? 'Upgrade' : 'Manage Plans'}
            </a>
          </Button>
        </div>
      ),
      placement: 'topRight',
      duration: 20,
    });
    return limitExceeded;
  }
};

const updateListItems = async (id, items) => {
  try {
    let response = await API.graphql(
      graphqlOperation(updateWatchlistMinimal, {
        input: { id, items: JSON.stringify(items) },
      })
    );
    return response;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to update watchlist' };
  }
};

const updateList = async list => {
  try {
    let response = await API.graphql(
      graphqlOperation(updateWatchlist, {
        input: { ...list, items: JSON.stringify(list.items) },
      })
    );
    return response;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to update watchlist' };
  }
};

const updateListMeta = async (id, name, accountID, account_privacy, company, scope) => {
  let u = { id, name, accountID, company, scope };
  if (account_privacy !== null) {
    u['account_privacy'] = account_privacy;
  }
  try {
    let response = await API.graphql(
      graphqlOperation(updateWatchlist, {
        input: u,
      })
    );
    return response;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to update watchlist' };
  }
};

const addWatchlistTeam = async (teamID, accountID, watchlistID) => {
  try {
    let response = await API.graphql(
      graphqlOperation(createWatchlistTeam, {
        input: { teamID, accountID, watchlistID },
      })
    );
    return response;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to create watchlist team' };
  }
};

const removeWatchlistTeam = async id => {
  try {
    let response = await API.graphql(
      graphqlOperation(deleteWatchlistTeam, {
        input: { id },
      })
    );
    return response;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to update watchlist' };
  }
};

const updateSetting = async (settingId, follow) => {
  try {
    let response = await API.graphql(
      graphqlOperation(updateWatchlistSetting, {
        input: { id: settingId, follow: follow },
      })
    );
    return response;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to update watchlist' };
  }
};

const deleteSetting = async settingId => {
  try {
    let response = await API.graphql(
      graphqlOperation(deleteWatchlistSetting, {
        input: { id: settingId },
      })
    );
    return response;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to delete watchlist' };
  }
};

const getListsWithLimit = async (limit = 10) => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(graphqlOperation(listWatchlists, { nextToken }));
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.listWatchlists.items)));
      nextToken = list.data.listWatchlists.nextToken;
      if (!nextToken || listOfLists.length === limit) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists'] };
  }
};

const getLists = async () => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(graphqlOperation(listWatchlists, { nextToken }));
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.listWatchlists.items)));
      nextToken = list.data.listWatchlists.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists'] };
  }
};

const getListsDetails = async () => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(graphqlOperation(listWatchlistDetails, { nextToken }));
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.listWatchlists.items)));
      nextToken = list.data.listWatchlists.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists'] };
  }
};

const getListsByAccount = async accountID => {
  try {
    let listOfLists = [];
    let nextPage = true;
    let nextToken = null;

    while (nextPage) {
      let list = await API.graphql(graphqlOperation(watchlistsByAccount, { nextToken, accountID }));
      if (list.errors) {
        return list;
      }
      listOfLists = listOfLists.concat(JSON.parse(JSON.stringify(list.data.watchlistsByAccount.items)));
      nextToken = list.data.watchlistsByAccount.nextToken;
      if (!nextToken) {
        nextPage = false;
      }
    }
    return listOfLists;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed to fetch watchlists by account'] };
  }
};

const sendWatchlistInvites = async (listId, email, message, sendEmail) => {
  let url = `/api/watchlists/${listId}/share`;
  await API.post('fuelapi', url, { body: { email, message, sendEmail } })
    .then(data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: 'Failed to send invite emails' };
    });
};

const revokeWatchlistShare = async (listId, email) => {
  let url = `/api/watchlists/${listId}/share?email=${email}`;
  await API.del('fuelapi', url)
    .then(data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: 'Failed to delete shared setting' };
    });
};

// GET SINGLE LIST
const getWatchlistUpdates = async (listId, query) => {
  let url = '/api/watchlists/' + listId;
  let payload = {
    headers: {},
    response: false,
    queryStringParameters: query,
  };

  // we probably need to return the data in a friendlier way from the function
  let result = await API.get('fuelapi', url, payload)
    .then(data => {
      //Format category_summary if exists
      if ('stats' in data) {
        if ('category_summary' in data.stats) {
          data.stats.category_summary = Object.keys(data.stats.category_summary)
            .map(key => {
              return { category: key, value: data.stats.category_summary[key] };
            })
            .sort(function(a, b) {
              return a.value - b.value;
            })
            .slice(-5);
        }
      }

      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
    });

  return result;
};

// MEREGE LIST/ COPY LIST
const mergeOrcopyWatchList = async (action, list, accountId) => {
  const path = '/api/watchlists/' + action;

  return await API.post('fuelapi', path, { body: { watchlistIds: list, accountID: accountId } })
    .then(data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      console.log('response', e.response);
      if (e?.response?.status === 403) {
        return e.response.data;
      }
      return { error: `Failed to ${action} watchlist` };
    });
};

// DELETE watchlists
const deleteWatchList = async list => {
  const path = `/api/watchlists?listIds=${list}`;
  let result = await API.del('fuelapi', path, {})
    .then(data => {
      return data;
    })
    .catch(e => {
      console.log('error retrieving data', e);
      return { error: `Failed to delete watchlist` };
    });
  return result;
};

const sdk = {
  getWatchlistUpdates,
  listUpdates,
  sendWatchlistInvites,
  revokeWatchlistShare,
  getListsSummary,
  getLists,
  updateList,
  mergeOrcopyWatchList,
  deleteWatchList,
  getListsDetails,
  getListsWithLimit,
  createNewWatchlist,
  getListsByAccount,
  createList,
  getListsEvents,
  getListsUpdates,
  updateSetting,
  deleteSetting,
  listCompanies,
  updateListMeta,
  addWatchlistTeam,
  removeWatchlistTeam,
  getList,
  updateListItems,
  checkCompaniesPerWatchlistLimit,
};

export default sdk;
