import React from 'react';
import { Link } from 'react-router-dom';
import searchIcon from 'images/searchIcon.svg';
import ErrorBoundary from 'ErrorBoundary';
import usePersonalAccount from 'store/PersonalAccount';
import useAccounts from 'store/Accounts';
import './NotLoggedInComponent.css';

const AuthWall = ({ children }) => {
  const isLoggedIn = usePersonalAccount(state => state.isLoggedIn);

  const renderItem = () => {
    const authn = isLoggedIn();
    if (authn) {
      return children ? children : null;
    }
    return <NotLoggedInComponent />;
  };

  return <ErrorBoundary>{renderItem()}</ErrorBoundary>;
};

const UpgradeCallout = ({ title = 'Upgrade to view all Company Investments' }) => {
  const getAccountUpgradeLink = useAccounts(state => state.getAccountUpgradeLink);
  return (
    <div className="authwall-card">
      <div>
        <h4 className="mute-title" style={{ marginBottom: '10px', fontSize: '28px' }}>
          {title}
        </h4>
        <p>
          Or create a new <Link to="/accounts/new">Team</Link>. Follow, track, and research markets and companies like
          this in Fuel.
        </p>
        <div>
          <Link
            to={getAccountUpgradeLink()}
            className="ant-btn ant-btn-primary ant-btn-lg"
            style={{ marginRight: 10, fontWeight: '500' }}
          >
            Upgrade
          </Link>
          <Link to="/accounts/new" className="ant-btn ant-btn-primary ant-btn-lg" style={{ fontWeight: '500' }}>
            Create a Team
          </Link>
        </div>
      </div>
      <div>
        <img src={searchIcon} alt="Sign up free" />
      </div>
    </div>
  );
};

const NotLoggedInComponent = () => {
  return (
    <div className="authwall-card">
      <div>
        <h4 className="mute-title" style={{ marginBottom: '10px', fontSize: '28px' }}>
          Sign in to York IE Fuel
        </h4>
        <p>Or create a free Fuel Account below. Follow, track, and research markets and companies like this in Fuel.</p>
        <div>
          <a
            href="/login"
            className="ant-btn ant-btn-primary ant-btn-lg"
            style={{ marginRight: 10, fontWeight: '500' }}
          >
            Login
          </a>
          <a href="/signup?plan=free" className="ant-btn ant-btn-primary ant-btn-lg" style={{ fontWeight: '500' }}>
            Get a Free Account
          </a>
        </div>
      </div>
      <div>
        <img src={searchIcon} alt="Sign up free" />
      </div>
    </div>
  );
};

export default AuthWall;
export { NotLoggedInComponent, UpgradeCallout };
