import React, { useState } from 'react';
import { Space, Avatar, Typography, Button, Tooltip } from 'antd';
import {
  CloseOutlined,
  UserOutlined,
  ShopOutlined,
  EllipsisOutlined,
  LeftOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import AvatarFailover from 'components/AvatarFailover';
import { AuthenticatedCompanyPopover } from 'components/company/PopoverOverview';
import { logoURL } from 'helpers/Organizations';
import ErrorBoundary from 'ErrorBoundary';

const { Text } = Typography;

const CompanyTag = ({ id, url, name, dismissable, onDismiss, showLogo = true, loading }) => {
  return (
    <Tag
      img={
        loading ? (
          <span style={{ paddingLeft: '4px' }}>
            <LoadingOutlined />
          </span>
        ) : id ? (
          <AvatarFailover key={`avatar-${id}`} src={logoURL(id)} name={name} shape="circle" size={'small'} />
        ) : (
          <Avatar size="small" icon={<ShopOutlined />} />
        )
      }
      text={name}
      content={
        <AuthenticatedCompanyPopover key={`ctag-popover-${id}`} uuid={id}>
          <span style={{ color: 'rgba(0,0,0,.75)', fontWeight: '500' }}>{name}</span>
        </AuthenticatedCompanyPopover>
      }
      dismissable={dismissable}
      onDismiss={onDismiss}
      showImg={showLogo}
    />
  );
};

const TagGroup = ({ items, minimum = 10 }) => {
  const [more, setMore] = useState(false);
  let showItems = more ? items : items.slice(0, minimum);

  return (
    <ErrorBoundary justText={true}>
      {items && items.length > 0 && (
        <div className="some-tags" style={{ display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
          {showItems
            .filter(tag => !!tag.label)
            .map(tag => (
              <Tag showImg={false} key={`keyword-${tag.label}`} content={tag.label} />
            ))}
          {items.length > minimum && (
            <Button
              type="text"
              size="small"
              style={{ fontSize: '18px' }}
              icon={
                !more ? (
                  <EllipsisOutlined style={{ fontSize: '18px', color: 'rgba(0,0,0,.65)' }} />
                ) : (
                  <LeftOutlined style={{ fontSize: '18px', color: 'rgba(0,0,0,.65)' }} />
                )
              }
              onClick={() => setMore(!more)}
            />
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};

const Tag = ({ img, text, dismissable, onDismiss, showImg = true, content }) => {
  if (!content) {
    content = text;
  }
  return (
    <div
      style={{
        backgroundColor: '#FFF',
        border: '1px solid #EEE',
        borderRadius: '100px',
        padding: dismissable ? '2px' : '2px 9px 2px 2px',
        paddingLeft: showImg ? '2px' : '9px',
      }}
    >
      <Space>
        {showImg && <div style={{ padding: '1px' }}>{img ? img : <Avatar size="small" icon={<UserOutlined />} />}</div>}
        <Text
          style={{
            cursor: 'default',
            whiteSpace: 'nowrap',
            maxWidth: '175px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
          }}
        >
          {content}
        </Text>
        {dismissable && (
          <Tooltip title={`Remove ${text}`}>
            <Button
              size="small"
              type="text"
              style={{ color: 'rgba(0, 0, 0, 0.45)', borderRadius: '100px' }}
              icon={<CloseOutlined />}
              onClick={onDismiss}
            />
          </Tooltip>
        )}
      </Space>
    </div>
  );
};

export default Tag;
export { CompanyTag, TagGroup };
