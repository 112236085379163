/*
Wrapper for recently visited companies. Currently storing data in localstorage,
but can be changed to query an API (or anything else).
*/

class DataClient {
  localKey(KEY) {
    return {
      // get retrieves all 'recent' links
      get: function() {
        let items = localStorage.getItem(KEY);

        if (items === '' || items === null) {
          items = {};
        } else {
          items = JSON.parse(items);
        }
        console.log('items: ', items);
        return items;
      },

      // update updates the list of recents
      update: function(items) {
        let existingItems = this.get();

        for (const key in items) {
          // remove if already exists so it adds it again to top of array
          delete existingItems[key];

          if (items.hasOwnProperty(key)) {
            const element = items[key];
            existingItems[key] = element;
          }
        }

        this.save(existingItems);
      },

      // save saves all items
      save: function(items) {
        localStorage.setItem(KEY, JSON.stringify(items));
      },

      // clear all items
      clear: function() {
        localStorage.removeItem(KEY);
      },
    };
  }

  recentLinks() {
    const KEY = 'recents';
    return this.localKey(KEY);
  }
}

const c = new DataClient();
export default c;
