// This file provides a common set of helpers to manage a user's account.

import { API } from '@aws-amplify/api';

/**
 * @typedef {Object} Account
 * @property {string} id - The UUID of the user account
 * @property {string} email - User email address
 * @property {string} username - Account username
 * @property {string} avatar - S3 URL of user's avatar (not functional yet)
 * @property {boolean} weekly_update_enabled - Boolean value indicating if this user has weekly emails enabled
 * @example
 {
   "id": "c1b60ad3-ae05-4ae9-807d-739396dfaa97",
   "email": "nick@york.ie",
   "username": "Nick Sjostrom",
   "avatar": "s3path",
   "weekly_update_enabled": false
 }
 */

/**
 * Returns the requesting user's account information:
 *
 * @param {boolean} weekly_update_enabled User will recieve weekly email updates.
 * @return {Account} The user account object.
 */
const getAccount = async () => {
  const path = `/api/account`;
  try {
    var result = await API.get('fuelapi', path);
    return result;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to retrieve the User Account.' };
  }
};

/** get URL for Avatar
 * @param  {isUpload} boolean
 * @return {object} user profile avatar.
 *
 */

const getAvatarURL = async () => {
  const path = `/api/account/avatar`;
  try {
    var url = await API.get('fuelapi', path);
    return url;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to retrieve the User Avatar.' };
  }
};

/**
 * Updates the requesting user's account with the provided parameters
 *
 * @param {boolean} weekly_update_enabled User will recieve weekly email updates.
 * @return {object} query update result.
 */
const updateAccount = async param => {
  const path = `/api/account`;
  try {
    var result = await API.put('fuelapi', path, { body: param });
    return result;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to update the User Account.' };
  }
};

export { getAccount, getAvatarURL, updateAccount };
