import React, { useEffect, useState } from 'react';
import { Link, useHistory, matchPath } from 'react-router-dom';
import { Dropdown, Avatar, Space, Typography } from 'antd';
import {
  UserOutlined,
  SettingOutlined,
  BookOutlined,
  ReconciliationOutlined,
  BulbOutlined,
  ToolOutlined,
  RiseOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  RobotOutlined,
} from '@ant-design/icons';
import useProfile from 'store/Profile';
import { logoURL } from 'helpers/Organizations';
import Auth from '@aws-amplify/auth';
import AvatarFailover from 'components/AvatarFailover';
import usePersonalAccount from 'store/PersonalAccount';
import useAccounts from 'store/Accounts';
import useTours from 'store/Tours';
import { shallow } from 'zustand/shallow';

import './UserDropdown.css';
import UpdateArticle from 'pages/news/UpdateArticle';
import ErrorBoundary from 'ErrorBoundary';
import { locationMap } from 'components/utility/tours/UserTours';

const PAGE_ACTIONS = {
  CLICK: 'click',
  SHOW_MESSAGE: 'show-message',
};

const { Text } = Typography;
const menuItems = (authProps, userOrg, account, addCurate, activeAccount, startTour, hasPaid) => {
  let items = [];
  if (userOrg?.org_id) {
    items.push(
      {
        key: 'sub1',
        label: 'My Company',
        type: 'group',
        children: [
          {
            key: '4-my-company',
            label: (
              <Link to={`/research/companies/${userOrg.org_id}`}>
                <ErrorBoundary justText={true}>
                  <Space>
                    <AvatarFailover
                      key={`avatar-${userOrg.org_id}`}
                      src={userOrg.logo_url}
                      backupSrc={logoURL(userOrg.org_id)}
                      name={Array.isArray(userOrg.org_name) ? userOrg.org_name[0] : userOrg.org_name}
                      shape="square"
                      size={'small'}
                    />
                    {Array.isArray(userOrg.org_name) ? userOrg.org_name[0] : userOrg.org_name}
                  </Space>
                </ErrorBoundary>
              </Link>
            ),
          },
        ],
      },
      {
        type: 'divider',
      }
    );
  }

  items = items.concat([
    {
      key: '0-settings',
      label: (
        <Link to="/accounts/personal">
          <Space>
            <SettingOutlined style={{ color: 'rgba(0,0,0,.25)' }} /> Profile Settings
          </Space>
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '7-manage-workspaces',
      label: (
        <Link to={`/accounts${activeAccount ? '/' + activeAccount?.account?.id : ''}`}>
          <Text>
            <Space>
              <ReconciliationOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              Account
            </Space>
          </Text>
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '7-billing',
      label: (
        <Link to={`/accounts?next=billing`}>
          <Text>
            <Space>
              <CreditCardOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              Billing
            </Space>
          </Text>
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'tour-options',
      label: (
        <Text>
          <Space>
            <RiseOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            Start Tour
          </Space>
        </Text>
      ),
      children: [
        {
          key: 'tour-1',
          label: (
            <Text onClick={() => startTour('/research/home')}>
              <Space>HomePage</Space>
            </Text>
          ),
        },
        {
          key: 'tour-2',
          label: (
            <Text onClick={() => startTour('/research/watchlists/updates')}>
              <Space>Watchlist Updates</Space>
            </Text>
          ),
        },
        {
          key: 'tour-3',
          label: (
            <Text onClick={() => startTour(`/research/watchlists/:watchlistId/:tab`)}>
              <Space>Watchlist Page</Space>
            </Text>
          ),
        },
      ],
    },
  ]);

  if (hasPaid) {
    items.push(
      {
        type: 'divider',
      },
      {
        key: 'notebooks',
        label: (
          <Link to={`/notebooks`}>
            <Text>
              <Space>
                <BookOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                Notebooks
              </Space>
            </Text>
          </Link>
        ),
      }
    );
  }

  if (account.hasIdeaGenGroup()) {
    items.push(
      {
        type: 'divider',
      },
      {
        key: 'content-generator',
        label: (
          <Link to={`/tools/content`}>
            <Text>
              <Space>
                <BulbOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                Content Playground
              </Space>
            </Text>
          </Link>
        ),
      }
    );
  }

  if (account.hasGroup('assistant-chat')) {
    items.push(
      {
        type: 'divider',
      },
      {
        key: 'assistant',
        label: (
          <Link to={`/assistant`}>
            <Text>
              <Space>
                <RobotOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                Assistant
              </Space>
            </Text>
          </Link>
        ),
      }
    );
  }

  if (account.hasGroup('editors')) {
    items.push(
      {
        type: 'divider',
      },
      {
        key: 'content-curation',
        label: (
          <Link to={`/advisory/admin`}>
            <Text>
              <Space>
                <BookOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                Advisory Playbooks
              </Space>
            </Text>
          </Link>
        ),
      }
    );
  }
  if (account.hasAdminGroup()) {
    items.push(
      {
        type: 'divider',
      },
      {
        key: 'admin',
        label: (
          <Link to={`/admin`}>
            <Text>
              <Space>
                <ToolOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                Admin Tools
              </Space>
            </Text>
          </Link>
        ),
      }
    );
  }
  items.push(
    {
      type: 'divider',
    },
    {
      key: 'logout',
      onClick: authProps.onUserSignOut,
      label: (
        <Text id="logout-btn">
          <Space>
            <LogoutOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            Logout
          </Space>
        </Text>
      ),
    }
  );
  return items;
};

const UserDropdown = ({ authProps, backgroundColor = 'var(--tealish)' }) => {
  const [user, setUser] = useState();
  const [showCurate, setShowCurate] = useState(false);
  const account = usePersonalAccount(state => ({
    hasIdeaGenGroup: state.hasIdeaGenGroup,
    hasGroup: state.hasGroup,
    hasAdminGroup: state.hasAdminGroup,
    checkAccountPaid: state.hasPaidContent,
  }));
  const getUserProfileAvatar = useProfile(state => state.getUserProfileAvatar);
  const avatar = useProfile(state => state.profileAvatar);
  const { activeAccount, watchlists, hasActiveOrTrialAcct } = useAccounts(state => ({
    activeAccount: state.activeAccount,
    watchlists: state.watchlists,
    hasActiveOrTrialAcct: state.hasActiveOrTrialAcct,
    getAccountUpgradeLink: state.getAccountUpgradeLink,
  }));
  const hasPaid = account?.checkAccountPaid() || hasActiveOrTrialAcct();
  const history = useHistory();
  const { tours, updateTours } = useTours(
    state => ({
      tours: state.tours,
      updateTours: state.updateTours,
    }),
    shallow
  );

  const startTour = path => {
    const tourConfig = locationMap?.[path];
    if (tourConfig) {
      updateTours({ ...tours, [tourConfig.key]: 0 });
      //watchlist page tour
      if (path.includes(':watchlistId')) {
        const obj = matchPath(history.location.pathname, {
          path: '/research/watchlists/:watchlistId/:tab',
          exact: true,
        });
        const watchlistId = obj?.params?.watchlistId || watchlists?.[0]?.id;
        if (watchlistId) {
          history.push(`/research/watchlists/${watchlistId}`);
        } else {
          history.push('/research/watchlists', {
            pageActions: [
              {
                name: PAGE_ACTIONS.CLICK,
                data: {
                  selector: '[data-id="watchlist-create-btn"]',
                },
              },
              {
                name: PAGE_ACTIONS.SHOW_MESSAGE,
                data: {
                  text: 'Create watchlist and start tour.',
                  type: 'warning',
                  duration: 5,
                },
              },
            ],
          });
        }
        return;
      } else {
        // other pages tour
        history.push(path);
      }
    }
  };

  const addCurate = () => {
    setShowCurate(true);
  };

  useEffect(() => {
    let isMounted = true;
    if (!avatar) {
      console.log('fetch avatar', avatar);
      getUserProfileAvatar();
    }

    const getOrg = async () => {
      let u = await Auth.currentUserInfo();
      if (isMounted) {
        setUser(u);
      }
    };

    if (!user) {
      getOrg();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const displayName =
    authProps && authProps.user?.attributes?.given_name
      ? authProps.user?.attributes?.given_name
      : authProps.user?.attributes?.email;
  const userOrg = user?.attributes && user?.attributes['custom:org'] ? JSON.parse(user.attributes['custom:org']) : null;

  return (
    <>
      <Dropdown
        menu={{ items: menuItems(authProps, userOrg, account, addCurate, activeAccount, startTour, hasPaid) }}
        trigger={['click']}
        placement={'bottomLeft'}
        overlayStyle={{ width: '220px' }}
      >
        <div id="user-dropdown" className="user-dropdown">
          {avatar ? (
            <img
              referrerPolicy="no-referrer"
              style={{
                cursor: 'pointer',
                borderRadius: '2px',
                backgroundColor: backgroundColor,
                width: '32px',
                height: '32px',
              }}
              src={avatar}
              alt="user avatar"
            />
          ) : (
            <Avatar
              style={{ cursor: 'pointer', backgroundColor: backgroundColor }}
              shape="square"
              icon={<UserOutlined />}
            ></Avatar>
          )}
          <span className="username">{displayName}</span>
        </div>
      </Dropdown>
      {showCurate && <UpdateArticle show={showCurate} isCreate={true} close={() => setShowCurate(false)} />}
    </>
  );
};

export default UserDropdown;
