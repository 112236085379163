import React, { Suspense, lazy, useEffect, Fragment } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import AuthComponent from 'Auth';
import useGlobal from 'store/Global';

import Search from 'pages/search/Search';
import ErrorBoundary from 'ErrorBoundary';
import Splash from 'components/Splash';
import MainLayout from 'containers/Main';
import Content from 'containers/Content';
import PlatformNotifications from 'components/utility/PlatformNotifications';

import useAccounts from 'store/Accounts';
import usePersonalAccount from 'store/PersonalAccount';

// Tours
import Tours from 'components/utility/tours/UserTours';
import { SlackAuth } from 'components/SlackAuth';
import useTours from 'store/Tours';

import { RedirectKeywords } from 'pages/keywords/Articles';

import Debugger from 'Debugger';

const EmptyPage = lazy(() => import('pages/Empty'));

const ExpDashboard = lazy(() => import('pages/experimental/layouts/dashboardPage/Dashboard'));
const NewDashboard = lazy(() => import('pages/experimental/layouts/dashboardPage/NewDashboard'));
const AdvisoryJourney = lazy(() => import('pages/experimental/layouts/dashboardPage/AdvisoryJourney'));
const Support = lazy(() => import('pages/support/Support'));
const AdminWrapper = lazy(() => import('pages/adminTools/Admin'));
const AdminCompany = lazy(() => import('pages/admin/company/Company'));
const NewsWrapper = lazy(() => import('containers/News'));
const InvestorInquiry = lazy(() => import('pages/forms/InvestorInquiry'));
const PartnershipInquiry = lazy(() => import('pages/forms/PartnershipInquiry.js'));
const PostSubmissionPage = lazy(() => import('pages/forms/PostSubmissionPage'));
const BusinessAssessment = lazy(() => import('pages/forms/bizAssessment/BusinessAssessment'));
const BizOutput = lazy(() => import('pages/forms/bizAssessment/BizOutput'));
const DailyUpdates = lazy(() => import('pages/companies/landing/DailyUpdate'));
const WeeklyUpdates = lazy(() => import('pages/companies/landing/WeeklyUpdate'));
const DailyWatchlistsUpdate = lazy(() => import('pages/companies/landing/DailyWatchlistsUpdate'));
const WeeklyWatchlistsUpdate = lazy(() => import('pages/companies/landing/WeeklyWatchlistsUpdate'));

const AdvisoryHome = lazy(() => import('pages/advisory/Home'));
const AdvisoryCourse = lazy(() => import('pages/advisory/Course'));
const AdvisoryChat = lazy(() => import('pages/advisory/Chat'));
const AdminMessages = lazy(() => import('pages/admin/messages/Home'));

// Advisory Admin
const AdvisoryAdmin = lazy(() => import('pages/advisory/admin/List'));
const AdvisoryEdit = lazy(() => import('pages/advisory/admin/Edit'));

// News Admin
const NewsAdmin = lazy(() => import('pages/marketPulse/admin/List'));
const KeywordArticles = lazy(() => import('pages/keywords/Articles'));

// Marketing pages
const NewLanding = lazy(() => import('pages/marketing/NewLanding'));
const Plans = lazy(() => import('pages/marketing/Plans'));

// Lazy load these components (code split)
const Person = lazy(() => import('pages/people/Person'));
const ResearchReports = lazy(() => import('pages/advisory/Research'));
const Companies = lazy(() => import('pages/companies/landing/Landing'));
const ExpCompany = lazy(() => import('pages/experimental/demo/companies/Company'));
const Markets = lazy(() => import('pages/markets/Landing'));
const KeywordWeekly = lazy(() => import('pages/keywords/Weekly'));
const CompaniesSearchPage = lazy(() => import('pages/companies/search/SearchPage'));

const Category = lazy(() => import('pages/categories/Category'));
const CategoryOwnedContent = lazy(() => import('pages/categories/details/OwnedContent'));
const CategoryEarnedContent = lazy(() => import('pages/categories/details/EarnedContent'));
const CategoryFundingHistory = lazy(() => import('pages/categories/details/FundingHistory'));
const CategoryRecentFunding = lazy(() => import('pages/categories/details/RecentFunding'));
const CategoryTopInvestors = lazy(() => import('pages/categories/details/Investors'));

const PodcastSearch = lazy(() => import('pages/tools/Podcasts'));
const InvestorES = lazy(() => import('pages/tools/InvestorsES'));
const SocialIdeas = lazy(() => import('pages/tools/Social'));
const Blog = lazy(() => import('pages/tools/blog/Blog'));
const Ebook = lazy(() => import('pages/tools/ebook/Ebook'));
const PRFAQ = lazy(() => import('pages/tools/prfaq/PRFAQ'));
const Messaging = lazy(() => import('pages/tools/messaging/MessagingHierarchy'));
const StrategicGrowth = lazy(() => import('pages/tools/strategicGrowth/StrategicGrowth'));
const PitchAnalyzer = lazy(() => import('pages/tools/pitchAnalyzer/PitchAnalyzer'));
const DocSummarizer = lazy(() => import('pages/tools/docSummarizer/DocSummarizer'));

const PromptScienceList = lazy(() => import('pages/promptScience/List'));

const ExpWatchlist = lazy(() => import('pages/experimental/demo/watchlists/Watchlist'));
const WatchlistsList = lazy(() => import('pages/watchlists/ListView'));
const Profile = lazy(() => import('pages/profile/Profile'));
const Checkout = lazy(() => import('pages/profile/Checkout'));
const NewAccount = lazy(() => import('pages/accounts/New'));
const ListAccounts = lazy(() => import('pages/accounts/List'));
const AccountDetail = lazy(() => import('pages/accounts/Detail'));
const AddCompany = lazy(() => import('pages/companies/AddCompany'));

// Assistant
const Assistant = lazy(() => import('pages/assistant'));

// Reports
const FundedOrgs = lazy(() => import('pages/reports/FundedOrgs'));

// data snapshots
const ListSnapshots = lazy(() => import('pages/snapshots/List'));
const ExploreSnapshot = lazy(() => import('pages/snapshots/Explore'));

// Generated Ideas
const ContentGenerator = lazy(() => import('pages/ideas/ContentGenerator'));

// Documents
const ListDocuments = lazy(() => import('pages/documents/List'));
const Document = lazy(() => import('pages/documents/Document'));

// Experimental pages and components
const Experimental = lazy(() => import('pages/experimental/Root'));

const PageNotFound = lazy(() => import('components/general/NotFoundPage'));
const Tools = lazy(() => import('pages/tools/ToolsLanding'));
const SavedSearches = lazy(() => import('pages/companies/search/SavedSearches'));
const SavedSearchUpdates = lazy(() => import('pages/companies/search/SavedSearchUpdates'));

function ScrollToTop({ children }) {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(event => {
      window.initHubspotChat();
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line
  }, []);

  return <Fragment>{children}</Fragment>;
}

const ProtectedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route
    {...rest}
    render={rProps =>
      childProps.isLoggedIn ? (
        <C {...rProps} authProps={childProps} />
      ) : (
        <Redirect to={`/login/?redirect=${encodeURIComponent(rProps.location.pathname + rProps.location.search)}`} />
      )
    }
  />
);

const App = props => {
  const { isLoggedIn } = props.authProps;
  const setGlobalAuthProps = useGlobal(state => state.setGlobalAuthProps);
  const accountsStoreInitialSetup = useAccounts(state => state.initialStoreSetup);
  const personalAccountStoreInitialSetup = usePersonalAccount(state => state.initialStoreSetup);
  const toursStoreInitialSetup = useTours(state => state.initialStoreSetup);

  useEffect(() => {
    setGlobalAuthProps(props.authProps);
  }, [setGlobalAuthProps, props.authProps]);

  //Initialization of useAccounts & usePersonalAccount zustand store
  useEffect(() => {
    const userEmail = props.authProps?.user?.attributes?.email;
    accountsStoreInitialSetup(userEmail);
    personalAccountStoreInitialSetup(props.authProps?.user);
    // eslint-disable-next-line
  }, [props.authProps?.user]);

  useEffect(() => {
    toursStoreInitialSetup(props.authProps.isLoggedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authProps.isLoggedIn]);

  const renderRoutes = () => {
    return (
      <>
        {isLoggedIn && <Tours />}
        <ScrollToTop>
          <Switch>
            {/* {isLoggedIn && <Redirect from="/" to="/research/dashboard" exact />} */}
            <Route exact path="/login/" key={new Date()} render={() => <AuthComponent {...props} />} />
            <Route exact path="/signup/" render={() => <AuthComponent {...props} />} />
            <Route exact path="/referral/:code" render={() => <AuthComponent {...props} />} />
            <Route
              path="/slackauth"
              render={({ location }) => {
                return isLoggedIn ? (
                  <SlackAuth />
                ) : (
                  <Redirect to={`/login/?redirect=${encodeURIComponent(location.pathname + location.search)}`} />
                );
              }}
            />

            {!isLoggedIn && <Route path="/" render={() => <MarketingApp {...props} />} />}

            {isLoggedIn && (
              <ProtectedRoute path="/" render={props => <ProtectedApp {...props} />} props={props.authProps} />
            )}
          </Switch>
        </ScrollToTop>
      </>
    );
  };

  return renderRoutes();
};

const NewsRoutes = props => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Splash styles={{ height: '100%' }} />}>
        <NewsWrapper {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

const MarketingApp = ({ authProps }) => {
  const location = useLocation();
  const redirectURL = encodeURIComponent(`${location.pathname}${location.search}`);
  const renderNext = () => {
    return (
      <MainLayout authProps={authProps}>
        <ErrorBoundary>
          <Suspense fallback={<Splash styles={{ height: '100%' }} />}>
            <Switch>
              <Route path="/" exact render={props => <NewLanding />} />
              <Route path="/plans" exact render={props => <Plans {...props} />} />

              <Route path="/research/reports" exact render={props => <ResearchReports {...props} />} />
              <Route path="/research/reports/:courseSlug" exact render={props => <AdvisoryCourse {...props} />} />

              <Route path="/advisory" exact render={props => <AdvisoryHome {...props} />} />
              <Route path="/advisory/library/:categorySlug" exact render={props => <AdvisoryHome {...props} />} />
              <Route
                path="/advisory/library/:categorySlug/:courseSlug"
                exact
                render={props => <AdvisoryCourse {...props} />}
              />
              <Route
                exact
                path="/forms/business-assessment"
                render={props => <BusinessAssessment isLoggedIn={authProps.isLoggedIn} />}
              />
              <Route
                exact
                path="/forms/business-assessment/result"
                render={props => <BizOutput {...props} />}
                isLoggedIn={authProps.isLoggedIn}
              />

              <Route path="/markets" exact render={props => <Markets {...props} />} />
              <Route path="/markets/:categoryId" exact render={props => <Category {...props} />} />

              <Route
                path="/forms/investor-inquiry"
                exact
                render={() => <InvestorInquiry isLoggedIn={authProps.isLoggedIn} />}
              />
              <Route
                path="/forms/partnership-inquiry"
                exact
                render={() => <PartnershipInquiry isLoggedIn={authProps.isLoggedIn} />}
              />
              <Route path="/form/signup" exact render={() => <PostSubmissionPage />} />

              <Route path="/support" exact render={props => <Support {...props} {...authProps} />} />
              <Route exact path="/page-not-found" render={() => <PageNotFound />} />
              <Redirect to={`/login/?redirect=${redirectURL}`} />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </MainLayout>
    );
  };

  return renderNext();
};

const ProtectedApp = ({ authProps }) => {
  const hasPaidContent = usePersonalAccount(state => state.hasPaidContent);
  const hasActiveOrTrialAcct = useAccounts(state => state.hasActiveOrTrialAcct);
  const hasPaid = hasPaidContent() || hasActiveOrTrialAcct();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let redirect = searchParams.get('redirect') || '/research/home';
  // check for state from checkout, redirect appropriately
  let data = null;
  const storageKey = '_signup_settings';
  let notifications = JSON.parse(localStorage.getItem(storageKey));
  if (notifications && notifications.plan) {
    if (notifications.plan === 'news') {
      redirect = '/account#notifications';
    } else if (notifications.plan === 'free') {
      localStorage.removeItem(storageKey);
    } else if (notifications.plan === 'team' || notifications.plan === 'plus') {
      localStorage.removeItem(storageKey);
      redirect = '/accounts/new';
      data = { accountDetails: { name: 'My Account' }, step: 2 };
    } else if (notifications.plan.startsWith('individual')) {
      redirect = '/account#billing';
    }
  }

  const renderNext = () => {
    return (
      <>
        <PlatformNotifications />
        <MainLayout authProps={authProps}>
          <ErrorBoundary>
            <Suspense fallback={<Splash styles={{ height: '100%' }} />}>
              <Debugger />
              <Switch>
                <Route path="/assistant" exact render={() => <Assistant />} />
                <Route path="/empty" exact render={() => <EmptyPage />} />
                <Route path="/news" render={props => <NewsRoutes authProps={authProps} {...props} />} />
                <Route path="/research/home/advisory-journey/:slug" render={() => <AdvisoryJourney />} />
                <Route path="/research/home" render={() => <NewDashboard />} />

                <Route path="/people/:personId" render={props => <Person />} />

                <Route path="/research/companies/saved-searches" exact render={() => <SavedSearches />} />
                <Route
                  path="/research/companies/saved-searches/:searchId"
                  exact
                  render={() => <SavedSearchUpdates />}
                />
                <Route path="/companies/search" render={() => <CompaniesSearchPage />} />

                <Route path="/research/companies" exact render={() => <Companies />} />
                <Route path="/research/companies/:companyId" render={props => <ExpCompany />} />
                <Route path="/companies/:companyId" render={() => <ExpCompany />} />
                <Route path="/research/markets" exact render={props => <Markets {...props} />} />
                <Route path="/research/markets/:marketId" render={props => <Category {...props} />} />
                <Route path="/data" exact render={() => <ListSnapshots />} />
                <Route path="/data/:jobId" render={() => <ExploreSnapshot />} />
                <Route path="/ideas/content" render={() => <ContentGenerator />} />
                <Route path="/notebooks" exact render={() => <ListDocuments />} />
                <Route path="/notebooks/:docId" render={() => <Document />} />
                <Route path="/markets" exact render={props => <Markets {...props} />} />
                <Route path="/markets/:categoryId" exact render={props => <Category {...props} />} />
                <Route
                  path="/markets/:categoryId/owned-content"
                  exact
                  render={props => <CategoryOwnedContent {...props} />}
                />
                <Route
                  path="/markets/:categoryId/earned-content"
                  exact
                  render={props => <CategoryEarnedContent {...props} />}
                />
                <Route
                  path="/markets/:categoryId/funding-history"
                  exact
                  render={props => <CategoryFundingHistory {...props} />}
                />
                <Route
                  path="/markets/:categoryId/recent-funding"
                  exact
                  render={props => <CategoryRecentFunding {...props} />}
                />
                <Route
                  path="/markets/:categoryId/investors"
                  exact
                  render={props => <CategoryTopInvestors {...props} />}
                />
                <Route path="/research/reports" exact render={props => <ResearchReports {...props} />} />
                <Route path="/research/reports/:courseSlug" exact render={props => <AdvisoryCourse {...props} />} />

                <Route path="/updates/day/:day" exact render={() => <DailyUpdates />} />
                <Route path="/watchlists/updates/day/:day" exact render={() => <DailyWatchlistsUpdate />} />
                <Route path="/updates/week/:startDate" exact render={() => <WeeklyUpdates />} />
                <Route path="/watchlists/updates/week/:startDate" exact render={() => <WeeklyWatchlistsUpdate />} />

                <Route
                  path="/research/watchlists"
                  exact
                  render={props => <WatchlistsList {...props} {...authProps} />}
                />
                <Route path="/research/watchlists/updates" render={() => <ExpDashboard />} />
                <Route path="/research/watchlists/:watchlistId" render={() => <ExpWatchlist />} />
                <Route path="/watchlists/:watchlistId" render={() => <ExpWatchlist />} />

                <Route path="/topics" exact render={props => <KeywordArticles {...props} />} />
                <Route path="/topics-weekly" exact render={props => <KeywordWeekly {...props} />} />
                <Route path="/topics/:keyword" exact render={() => <RedirectKeywords />} />

                <Route
                  path="/forms/investor-inquiry"
                  exact
                  render={() => <InvestorInquiry isLoggedIn={authProps.isLoggedIn} />}
                />
                <Route
                  path="/forms/partnership-inquiry"
                  exact
                  render={() => <PartnershipInquiry isLoggedIn={authProps.isLoggedIn} />}
                />
                <Route
                  path="/forms/business-assessment"
                  exact
                  render={props => <BusinessAssessment isLoggedIn={authProps.isLoggedIn} />}
                />
                <Route
                  exact
                  path="/forms/business-assessment/result"
                  render={props => <BizOutput {...props} isLoggedIn={authProps.isLoggedIn} />}
                />
                <Route
                  path="/search"
                  render={props => (
                    <Content>
                      <Search {...props} />
                    </Content>
                  )}
                />

                <Route
                  path="/add-company"
                  exact
                  render={props => (
                    <Content>
                      <AddCompany {...props} />
                    </Content>
                  )}
                />

                <Route path="/workspaces/personal" exact render={props => <Profile {...props} {...authProps} />} />
                <Route path="/accounts/personal" exact render={props => <Profile {...props} {...authProps} />} />

                <Route path="/account" exact render={props => <Profile {...props} {...authProps} />} />

                <Route path="/account/checkout" exact render={props => <Checkout {...props} {...authProps} />} />

                {(authProps.user.groups.includes('messengers') || authProps.user.groups.includes('admin')) && (
                  <Route path="/admin/messages" exact render={props => <AdminMessages />} />
                )}
                {authProps.user.groups.includes('admin') && (
                  <Route path="/admin/company/:companyId" exact render={() => <AdminCompany />} />
                )}

                {authProps.user.groups.includes('admin') && (
                  <Route path="/admin" render={props => <AdminWrapper {...props} />} />
                )}

                {(authProps.user.groups.includes('admin') || authProps.user.groups.includes('internal')) && (
                  <Route path="/experimental" render={props => <Experimental {...authProps} />} />
                )}

                {authProps.user.groups.includes('internal') && (
                  <Route path="/internal/reports/fundedOrgs" exact render={props => <FundedOrgs {...props} />} />
                )}

                {(authProps.user.groups.includes('admin') ||
                  authProps.user.groups.includes('internal') ||
                  authProps.user.groups.includes('idea-gen')) && (
                  <Route path="/tools/content" render={props => <ContentGenerator />} />
                )}
                <Route path="/tools" exact render={props => <Tools {...props} />} />
                <Route path="/tools/podcasts" render={props => <PodcastSearch {...props} />} />
                <Route path="/tools/investors" render={props => <InvestorES {...props} />} />
                <Route path="/tools/social" render={props => <SocialIdeas {...props} />} />
                <Route path="/tools/blog" render={() => <Blog />} />
                <Route path="/tools/ebook" render={() => <Ebook />} />
                <Route path="/tools/messaging" render={() => <Messaging />} />
                <Route path="/tools/prfaq" render={() => <PRFAQ />} />
                <Route path="/tools/strategic-growth" render={() => <StrategicGrowth />} />
                <Route path="/tools/pitch" render={() => <PitchAnalyzer />} />
                <Route path="/tools/doc-summarizer" render={() => <DocSummarizer />} />

                <Route path="/prompts" exact render={() => <PromptScienceList />} />

                {authProps.user.groups.includes('editors') && (
                  <Route path="/advisory/admin" exact render={props => <AdvisoryAdmin />} />
                )}

                {authProps.user.groups.includes('editors') && (
                  <Route path="/editors/admin" exact render={props => <NewsAdmin />} />
                )}

                {authProps.user.groups.includes('editors') && (
                  <Route
                    path="/advisory/admin/edit/:courseId"
                    exact
                    render={props => (
                      <Content>
                        <AdvisoryEdit />
                      </Content>
                    )}
                  />
                )}

                <Route
                  path="/accounts/new"
                  exact
                  render={props => (
                    <Content>
                      <NewAccount />
                    </Content>
                  )}
                />
                <Route path="/accounts/upgrade" exact render={props => <ListAccounts />} />
                <Route path="/workspaces/:accountId" render={props => <AccountDetail />} />
                <Route path="/accounts/:accountId" render={props => <AccountDetail />} />

                <Route path="/accounts" exact render={props => <ListAccounts />} />
                <Route path="/workspaces" exact render={props => <ListAccounts />} />
                <Route path="/plans" exact render={props => <Plans {...props} />} />

                <Route path="/advisory" exact render={props => <AdvisoryHome {...props} />} />

                {hasPaid && <Route path="/advisory/chat" render={props => <AdvisoryChat {...props} />} />}

                <Route path="/advisory/library/:categorySlug" exact render={props => <AdvisoryHome {...props} />} />

                <Route
                  path="/advisory/library/:categorySlug/:courseSlug"
                  exact
                  render={props => <AdvisoryCourse {...props} />}
                />

                <Route path="/support" exact render={props => <Support {...props} {...authProps} />} />
                <Route exact path="/page-not-found" render={() => <PageNotFound />} />
                <Redirect
                  to={{
                    pathname: redirect,
                    state: data ? { data } : null,
                  }}
                />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </MainLayout>
      </>
    );
  };

  return renderNext();
};

export default App;
