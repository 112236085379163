import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Layout, Space, Button, Drawer, message, Typography } from 'antd';
import {
  SearchOutlined,
  ArrowLeftOutlined,
  MenuOutlined,
  QuestionCircleOutlined as QuestionCircleFilled,
  EditOutlined as EditFilled,
  AudioOutlined as AudioFilled,
  BookOutlined as BookFilled,
  MessageOutlined as MessageFilled,
  NotificationOutlined as NotificationFilled,
  DollarCircleOutlined as DollarCircleFilled,
  ReadOutlined as ReadFilled,
  BulbOutlined as BulbFilled,
  LineChartOutlined as SignalFilled,
  SoundOutlined as SoundFilled,
  ScheduleOutlined as ScheduleFilled,
  FileProtectOutlined,
  WechatOutlined,
  RightOutlined,
} from '@ant-design/icons';

import sdk from 'sdk/OpenNews';
import Footer from 'components/layout/Footer';
import UserDropdown from './UserDropdown';
import Logo from 'images/YorkIE_Fuel-light-mint.svg';
import Search from 'components/SearchAll';
import FuelMenu from './SideNav';
import { MessagesButton } from 'components/messages/Messages';
import usePersonalAccount from 'store/PersonalAccount';
import { useWindowSize } from 'hooks';

import './Main.less';

const { Header, Content } = Layout;
const { Text } = Typography;

function getAvailableSpace(element) {
  const rect = element?.getBoundingClientRect();
  const availableLeftSpace = rect?.right;
  const availableRightSpace = window.innerWidth - rect?.left;

  return { leftSpace: availableLeftSpace, rightSpace: availableRightSpace };
}

const DropdownItem = ({ dropdownItem, idx }) => {
  return (
    <Link
      to={dropdownItem?.url}
      {...dropdownItem?.props}
      className={`dropdown-item ${dropdownItem?.children ? 'has-children' : ''}`}
      key={dropdownItem?.key}
      style={{ gridTemplateColumns: dropdownItem?.icon ? '32px 1fr' : '1fr' }}
    >
      {dropdownItem.icon && (
        <div className="dropdown-icon">
          <div className="blob" style={{ transform: `rotate(${idx * 30}deg)` }}></div>
          <div style={{ position: 'relative' }}>{dropdownItem.icon}</div>
        </div>
      )}
      <div class="dropdown-text">
        <div>
          {dropdownItem.label}
          {dropdownItem?.children ? <RightOutlined style={{ float: 'right' }} /> : ''}
        </div>
        {dropdownItem.description && <p>{dropdownItem.description}</p>}
      </div>
      {dropdownItem?.children && (
        <div className={`${dropdownItem?.key} nested-dropdown`} id={dropdownItem?.key}>
          <div className="nested-dropdown-wrap" style={dropdownItem?.style ? dropdownItem?.style : {}}>
            {dropdownItem.children
              .filter(nestedItem => !nestedItem.disabled)
              .map(nestedItem => (
                <Link
                  to={nestedItem?.url}
                  {...nestedItem?.props}
                  className={`nested-dropdown-item`}
                  key={nestedItem?.key}
                >
                  {nestedItem.label}
                </Link>
              ))}
          </div>
        </div>
      )}
    </Link>
  );
};

const ItemsWithDropdown = [
  {
    id: 'list_item-strategy',
    dropdownKey: 'key_strategy',
  },
  {
    id: 'list_item-research',
    dropdownKey: 'key_research',
  },
  {
    id: 'list_item-tools',
    dropdownKey: 'key_tools',
  },
];

const FuelHeader = ({ isLoggedIn }) => {
  const windowSize = useWindowSize();
  useLayoutEffect(() => {
    ItemsWithDropdown.forEach(item => {
      const listItemEl = document.getElementById(item.id);
      if (!listItemEl) {
        return;
      }
      const obj = getAvailableSpace(listItemEl);
      const dropdownEl = document.getElementById(item.dropdownKey);
      if (obj.rightSpace >= obj.leftSpace) {
        if (dropdownEl.classList.contains('dir_left')) {
          dropdownEl.classList.remove('dir_left');
        }
        dropdownEl?.classList?.add('dir_right');
      } else {
        if (dropdownEl.classList.contains('dir_right')) {
          dropdownEl.classList.remove('dir_right');
        }
        dropdownEl?.classList?.add('dir_left');
      }
    });
  }, [windowSize]);

  const strategyPlaybookOptions = [
    {
      key: '/advisory/library/market',
      label: 'Product',
      description: 'Discover the keys to building a SaaS product and kickstart your product’s growth',
      icon: <BulbFilled />,
      url: '/advisory/library/product',
    },
    {
      key: '/advisory/library/finance',
      label: 'Finance',
      description: 'Develop a unified business strategy and bring it to life with a sound financial model',
      icon: <DollarCircleFilled />,
      url: '/advisory/library/finance',
    },
    {
      key: '/advisory/library/sales',
      label: 'Sales',
      description: 'Speak to the right person at the right time in the right way',
      icon: <SignalFilled />,
      url: '/advisory/library/sales',
    },
    {
      key: '/advisory/library/marketing',
      label: 'Marketing',
      description: 'Increase your brand awareness and build an engaged audience',
      icon: <SoundFilled />,
      url: '/advisory/library/marketing',
    },
  ];

  const advisoryChatOption = {
    key: '/advisory/chat/live',
    label: 'Advisory Chat',
    description: 'Speak to a Growth Coach in real time and get the help you need',
    icon: <WechatOutlined />,
    url: '/advisory/chat/live',
  };

  const strategyOption = {
    key: 'key_strategy',
    url: '/advisory',
    label: (
      <Link data-id="strategy-menu" to="/advisory" id="list_item-strategy">
        Strategy
      </Link>
    ),
    customDropdown: (
      <div className={`strategy dropdown`} id={'key_strategy'}>
        <div className="strategy dropdown-wrap">
          <div className="playbook-section">
            <div className="section-title">Advisory Playbook</div>
            <div className="playbook-category-container">
              {strategyPlaybookOptions
                .filter(child => !child.disabled)
                .map((dropdownItem, idx) => (
                  <DropdownItem dropdownItem={dropdownItem} idx={idx} />
                ))}
            </div>
          </div>
          <div className="chat-section">
            <div className="section-title">Advisory Chat</div>
            <DropdownItem dropdownItem={advisoryChatOption} idx={4} />
          </div>
        </div>
      </div>
    ),
  };

  const strategyOptionUnauth = {
    key: 'key_strategy',
    url: '/advisory',
    label: (
      <Link data-id="strategy-menu" to="/advisory" id="list_item-strategy">
        Strategy
      </Link>
    ),
    customDropdown: (
      <div className={`strategy dropdown`} id={'key_strategy'}>
        <div className="strategy dropdown-wrap">
          <div className="playbook-section">
            <div className="section-title">Advisory Playbook</div>
            <div className="playbook-category-container">
              {strategyPlaybookOptions
                .filter(child => !child.disabled)
                .map((dropdownItem, idx) => (
                  <DropdownItem dropdownItem={dropdownItem} idx={idx} />
                ))}
            </div>
          </div>
        </div>
      </div>
    ),
  };

  const marketsOption = {
    key: `key_markets`,
    theme: 'light',
    style: { width: '150px' },
    props: { 'data-id': 'market-link' },
    url: '/markets',
    label: 'Markets',
  };

  const reportsOption = {
    key: 'research_report',
    label: 'Reports',
    props: { 'data-id': 'market_reports-link' },
    url: '/research/reports',
  };

  const watchlistOption = {
    key: `key_watchlists`,
    url: `/research/watchlists`,
    props: { 'data-id': 'watchlists-link' },
    label: 'Watchlists',
  };
  const savedSearchOption = {
    key: `key_savedSearches`,
    url: `/research/Companies/saved-searches`,
    props: { 'data-id': 'savedSearches-link' },
    label: 'Saved Searches',
  };
  const companiesOption = {
    key: `key_companies`,
    url: `/research/companies`,
    props: { 'data-id': 'companies-link' },
    label: 'Companies',
  };
  const items = useMemo(() => {
    if (isLoggedIn) {
      let arr = [
        {
          key: 'key_research',
          label: (
            <Link to="/research/watchlists/updates" data-id="research-link" id="list_item-research">
              Research
            </Link>
          ),
          children: [watchlistOption, savedSearchOption, companiesOption, marketsOption, reportsOption],
        },
        strategyOption,
        {
          key: 'key_tools',
          label: (
            <Link data-id="companies-link" to={`/tools`} id="list_item-tools">
              Execution
            </Link>
          ),
          theme: 'light',
          style: {
            //dropdown styles
            width: '600px',
            display: 'grid',
            gridGap: '2px',
            gridTemplateColumns: 'repeat(2, 280px)',
          },
          children: [
            {
              key: '/tools/blog',
              label: 'Blog Draft',
              description: 'Get started on your next blog post',
              icon: <EditFilled />,
              url: '/tools/blog',
            },
            {
              key: '/tools/ebook',
              label: 'Ebook Draft',
              description: 'Create a draft ebook based on your company and market',
              icon: <BookFilled />,
              url: '/tools/ebook',
            },
            {
              key: '/tools/messaging',
              label: 'Messaging Hierarchy',
              description: 'Create a new messaging hierarchy for your company',
              icon: <MessageFilled />,
              url: '/tools/messaging',
            },
            {
              key: '/tools/prfaq',
              label: 'PR/FAQ',
              description: 'Create a customer-centric document for your new product',
              icon: <ReadFilled />,
              url: '/tools/prfaq',
            },
            {
              key: '/tools/growth',
              label: 'Strategic Growth Plan',
              description: 'Create a strategic growth plan for your company',
              icon: <ScheduleFilled />,
              url: '/tools/strategic-growth',
            },
            {
              key: '/tools/pitch',
              label: 'Pitch Analyzer',
              description: 'Get feedback on your elevator pitch',
              icon: <FileProtectOutlined />,
              url: '/tools/pitch',
            },
            {
              key: '/tools/doc-summarizer',
              label: 'Document Summarizer',
              description: 'Summarize your document',
              icon: <FileProtectOutlined />,
              url: '/tools/doc-summarizer',
            },
            {
              key: '/tools/social',
              label: 'Social Posts',
              description: 'Generate some ideas to post on social media',
              icon: <NotificationFilled />,
              url: '/tools/social',
            },
            {
              key: '/tools/investors',
              label: 'Investors Research',
              description: 'Find top investors relevant to you and your market',
              icon: <DollarCircleFilled />,
              url: '/tools/investors',
            },
            {
              key: '/tools/podcasts',
              label: 'Podcasts Research',
              description: 'Find Podcasts based on your company and market',
              icon: <AudioFilled />,
              url: '/tools/podcasts',
            },
          ],
        },
      ];
      return arr;
    } else {
      let arr = [
        {
          key: 'key_research',
          label: <Text>Research</Text>,
          children: [marketsOption, reportsOption],
        },
        { ...strategyOptionUnauth },
        {
          key: `key_pricing`,
          label: (
            <Link to={`/plans`} id="list_item-pricing">
              Pricing
            </Link>
          ),
          theme: 'light',
          style: { width: '150px' },
        },
      ];
      return arr;
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <nav className={`fuel-header-nav`}>
      <ul className="nav-list">
        {items.map(item => (
          <li className={`nav-item`} key={item?.key}>
            {item.label}
            {item?.customDropdown ? (
              item?.customDropdown
            ) : item.children ? (
              <div className={`${item?.key} dropdown`} id={item?.key}>
                <div className="dropdown-wrap" style={item?.style ? item?.style : {}}>
                  {item.children
                    .filter(child => !child.disabled)
                    .map((dropdownItem, idx) => (
                      <DropdownItem dropdownItem={dropdownItem} idx={idx} />
                    ))}
                </div>
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  );
};

const MainLayout = ({ authProps, children }) => {
  const history = useHistory();
  const [lists, setLists] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const hasGroup = usePersonalAccount(state => state.hasGroup);
  const noFooter = window.location.pathname === '/assistant';

  useEffect(() => {
    async function fetchData() {
      let result = await sdk.getConfig();
      if (result.hasOwnProperty('error')) {
        message.error(result.error, 4);
      } else {
        let items = result.items
          .filter(i => i.navitem === true)
          .sort((a, b) => a.data.order - b.data.order)
          .map(i => {
            return { ...i.data, id: i.id };
          });
        setLists(items);
      }
    }
    fetchData();
  }, []);

  const renderLayout = () => {
    return (
      <Layout className={`home fuel-layout company-details ${noFooter ? 'no-footer' : ''}`}>
        <Header className="fuel-header">
          {showSearch && (
            <div className="full-width-search">
              <Button
                type="text"
                style={{ color: '#FFF' }}
                className="search-back-btn"
                icon={<ArrowLeftOutlined />}
                onClick={() => setShowSearch(false)}
                size={'large'}
              />
              <Search
                handleEnter={value => {
                  history.push(`/companies/search?name=${encodeURI(value)}`);
                  setShowSearch(false);
                }}
                handleSelect={option => {
                  history.push(option.link);
                  setShowSearch(false);
                }}
                size="large"
                style={{ width: '100%', backgroundColor: 'rgba(255,255,255,.2)', color: '#FFF', borderRadius: '5px' }}
                className="full-search-all"
                autoFocus={true}
              />
            </div>
          )}
          {!showSearch && (
            <div className="header-wrapper">
              <div style={{ height: '100%' }}>
                <Link to={`/`}>
                  <img
                    className="fuel-logo"
                    alt="York IE Fuel - Market Research and Competitive Intelligence"
                    src={Logo}
                    style={{ padding: '8px 0px', height: '100%', marginLeft: '-5px' }}
                  />
                </Link>
              </div>
              <div className="fuel-menu-tour">
                <div className="trigger">
                  <Button
                    type="text"
                    style={{ color: '#FFF' }}
                    icon={<MenuOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    size={'large'}
                  />
                </div>
                <FuelHeader isLoggedIn={authProps.isLoggedIn} />
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }} className="search-all-tour">
                {authProps.isLoggedIn && (
                  <>
                    <Search
                      handleEnter={value => {
                        history.push(`/companies/search?name=${encodeURI(value)}`);
                      }}
                      size="large"
                      style={{
                        width: '100%',
                        backgroundColor: 'rgba(255,255,255,.2)',
                        color: '#FFF',
                        borderRadius: '5px',
                      }}
                    />
                    <Button
                      type="text"
                      style={{ color: '#FFF' }}
                      className="search-btn"
                      icon={<SearchOutlined />}
                      onClick={() => setShowSearch(true)}
                      size={'large'}
                    />
                  </>
                )}
              </div>

              <div
                style={{
                  float: 'right',
                  color: 'rgba(255,255,255,.85)',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                {authProps.isLoggedIn && (
                  <Space size={0} className="fuel-header-icons-end">
                    <Button
                      type="text"
                      style={{ color: 'rgba(255, 255, 255, 0.85)' }}
                      className="support-btn"
                      icon={<QuestionCircleFilled />}
                      onClick={() => history.push('/support')}
                      size={'large'}
                    />
                    <MessagesButton user={authProps?.user} />
                    <div style={{ padding: '0px 0px 0px 15px' }}>
                      <UserDropdown authProps={authProps} />
                    </div>
                  </Space>
                )}
                {!authProps.isLoggedIn && (
                  <Space>
                    <Button
                      id="login-btn"
                      type="primary"
                      style={{ fontWeight: '600' }}
                      onClick={() => history.push('/login')}
                    >
                      Log in
                    </Button>
                  </Space>
                )}
              </div>
            </div>
          )}
        </Header>

        <Content>{children}</Content>
        {/*commented to remove space bottom*/}
        {/*<Content className="fuel-content layout2">{children}</Content>*/}
        {!noFooter && <Footer className="fuel-footer" />}

        <Drawer
          width={305}
          rootClassName="fuel-sider"
          title={
            <Link to="/">
              <div className="logo">
                <img alt="large fuel logo" src={Logo} width="196px" />
              </div>
            </Link>
          }
          placement={'left'}
          closable={false}
          onClose={() => setCollapsed(!collapsed)}
          open={!collapsed}
          key={'nav-drawer-left'}
        >
          <FuelMenu
            navClick={() => setCollapsed(!collapsed)}
            lists={lists}
            isLoggedIn={authProps.isLoggedIn}
            showNotebooks={hasGroup('notebooks')}
          />
        </Drawer>
      </Layout>
    );
  };

  return renderLayout();
};

export default MainLayout;
