import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Badge } from 'antd';
import QueryString from 'query-string';
import SearchCompanies from 'components/company/SearchCompanies';
import SearchMarkets from 'pages/markets/SearchMarkets';

const { TabPane } = Tabs;

const badgeStyles = {
  backgroundColor: '#fff',
  color: '#444',
  boxShadow: '0 0 0 1px #d9d9d9 inset',
};

const Search = ({ location }) => {
  const [companyCount, setCompanyCount] = useState(0);
  const [marketCount, setMarketCount] = useState(0);

  let parsedQuery = QueryString.parse(location.search);

  useEffect(() => {
    document.title = `${parsedQuery?.query || ''} ${process.env.REACT_APP_SITE_TITLE_SUFFIX}`;
  });

  return (
    <>
      <div className="page-content">
        <Tabs defaultActiveKey="company-results">
          <TabPane
            tab={
              <span>
                Companies <Badge style={badgeStyles} count={companyCount} />
              </span>
            }
            key="company-results"
          >
            {parsedQuery.query && (
              <SearchCompanies
                search={parsedQuery.query}
                sf={parsedQuery.sf}
                filterable
                countCallback={setCompanyCount}
              />
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                Markets <Badge style={badgeStyles} count={marketCount} />
              </span>
            }
            key="market-results"
            forceRender={true}
          >
            {parsedQuery.query && <SearchMarkets search={parsedQuery.query} countCallback={setMarketCount} />}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default withRouter(Search);
