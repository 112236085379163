import React, { useEffect, useState } from 'react';
import sdk from 'sdk/Keywords';
import { dateFrom } from 'utils';
import './History.css';

const KeywordHistory = ({ keywords }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [max, setMax] = useState(0);

  const fetchHistory = async () => {
    setLoading(true);
    let result = await sdk.getKeywordHistory(keywords);

    // get dates we want, last 7 days
    let weeksHistory = {};
    let week = new Date();
    week.setDate(week.getDate() - 7);
    for (const i of [1, 1, 1, 1, 1, 1, 1]) {
      week.setDate(week.getDate() + i);
      weeksHistory[week.toISOString().split('T')[0]] = 0;
    }

    let findMax = 0;
    if (result?.value) {
      Object.keys(result.value).map(k => {
        let v = result.value[k];
        if (v > findMax) {
          findMax = v;
        }
        weeksHistory[k] = v;
        return { [k]: v };
      });
    }
    let arrResults = Object.keys(weeksHistory)
      .map(k => {
        return { day: k, value: weeksHistory[k] };
      })
      .sort((a, b) => (a.day < b.day ? -1 : 1));

    console.log('arrResults', arrResults);
    setMax(findMax);
    setHistory(arrResults);
    setLoading(false);
  };

  useEffect(() => {
    if (!keywords.length) {
      return;
    }
    fetchHistory();
    // eslint-disable-next-line
  }, [keywords]);

  return (
    <div
      className={`keyword-history-wrapper ${loading ? 'loading-history' : ''}`}
      style={{ gridTemplateColumns: `repeat(${history.length}, minmax(0, 1fr))` }}
    >
      <div className="vertical-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {history.map((h, i) => {
        return (
          <div key={h.day} className={`history-item pos-${i}`}>
            <div
              className="history-datum"
              style={{ height: h.value > 0 ? `${(h.value / max) * 100}%` : 'initial' }}
            ></div>
            <div className="datum-label">
              <div
                style={{
                  backgroundColor: '#FFF',
                  borderRadius: '2px',
                  padding: '10px 15px',
                  boxShadow: '1px 1px 5px rgba(0,0,0,.3)',
                  border: '1px solid #fbfbfb',
                  fontFamily: 'Nunito',
                  width: '200px',
                }}
              >
                <div style={{ textTransform: 'uppercase', fontSize: '12px', color: '#999', fontWeight: '700' }}>
                  {dateFrom(h.day)}
                </div>
                <div style={{ fontWeight: '700', fontSize: '16px' }}>
                  {h.value} article{h.value > 1 ? 's' : ''}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default KeywordHistory;
