import React from 'react';

class GoogleTagManager extends React.Component {
  constructor(props) {
    super(props);

    this._ref = React.createRef();
  }

  componentDidMount() {
    let script = document.createElement('script');
    script.innerHTML =
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
      this.props.gtmId +
      "');";
    document.getElementsByTagName('head')[0].appendChild(script);

    let iframe = document.createElement('iframe');
    iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + this.props.gtmId;
    iframe.style = 'display:none;visibility:hidden';
    document.getElementById('gtm').appendChild(iframe);
  }

  render() {
    let tag = <noscript id="gtm"></noscript>;

    return tag;
  }
}

export default GoogleTagManager;
