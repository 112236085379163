import React, { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined, EnvironmentOutlined, LinkOutlined } from '@ant-design/icons';
import { Typography, Popover, Space } from 'antd';
import { SocialPercentage } from 'components/company/SocialOverview';
import CategoryTags from 'pages/categories/CategoryTag';
import AvatarFailover from 'components/AvatarFailover';
import { logoURL } from 'helpers/Organizations';
import usePersonalAccount from 'store/PersonalAccount';
import WatchlistSelection from 'components/company/WatchlistSelection';
import ErrorBoundary from 'ErrorBoundary';
import Stat from 'components/layout/Stat';
import sdk from 'sdk/Companies';
import ViewMoreButton from 'components/general/ViewMoreButton';

import './PopoverOverview.css';

const { Paragraph, Title, Text } = Typography;

const CompanyPopoverOverview = ({ uuid }) => {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});

  const fetchCompanyOverview = async () => {
    setLoading(true);
    let result = await sdk.getCompanyOverview(uuid);
    setCompany(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchCompanyOverview();
    // eslint-disable-next-line
  }, [uuid]);

  const { organization, markets, socials } = company?.overview ?? {};
  return (
    <div style={{ width: '500px' }} className="company-gridview">
      <ErrorBoundary justText={true}>
        {loading && <LoadingOutlined />}
        {!loading && !!organization && (
          <div className="company-wrapper">
            <div>
              <div className="company-summary">
                <div className="company-bullets">
                  <div style={{ alignSelf: 'start' }}>
                    <AvatarFailover
                      key={`avatar-${organization.id}`}
                      src={logoURL(organization.id)}
                      backupSrc={organization.logo_url}
                      name={organization.name}
                      shape="square"
                      size={'large'}
                    />
                  </div>
                  <div>
                    <Title level={4} style={{ margin: '0px', fontWeight: '400', fontSize: '24px' }}>
                      <Link style={{ color: 'rgba(0,0,0,.85)' }} to={`/research/companies/${organization.id}`}>
                        {organization?.name}
                      </Link>
                    </Title>
                    <div className="company-items">
                      <div className="company-item">
                        <EnvironmentOutlined style={{ fontSize: '14px', color: 'rgba(0,0,0,.45)' }} />
                        <Text>
                          {organization?.city || organization?.state_code || organization?.country_code ? (
                            <>
                              {' '}
                              {organization?.city ? `${organization?.city}, ` : ''}
                              {organization?.state_code
                                ? `${organization?.state_code} `
                                : organization?.state_code}{' '}
                              {organization.country_code}
                            </>
                          ) : (
                            '--'
                          )}
                        </Text>
                      </div>
                      <div className="company-item">
                        <LinkOutlined style={{ fontSize: '14px', color: 'rgba(0,0,0,.45)' }} />
                        <Text>
                          {organization?.homepage_url ? (
                            <a href={organization?.homepage_url} rel="noopener noreferrer" target="_blank">
                              {organization?.homepage_url}
                            </a>
                          ) : (
                            '--'
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <Paragraph style={{ fontSize: '16px', color: 'rgba(0,0,0,.65)', fontFamily: 'Nunito' }}>
                  {organization?.short_description}
                </Paragraph>

                <div>
                  <CategoryTags
                    minimum={2}
                    items={markets.map((t, i) => ({
                      link: `/markets/${t.id}`,
                      label: t.name,
                    }))}
                  />
                </div>

                {socials && socials.length > 0 && (
                  <div style={{ marginTop: '20px' }}>
                    <SocialPercentage
                      accounts={socials
                        .filter(acct => acct.hasOwnProperty('followers_count'))
                        .sort((a, b) => {
                          return a.followers_count < b.followers_count ? 1 : -1;
                        })}
                    />
                  </div>
                )}

                <div style={{ marginTop: '20px' }}>
                  <Stat label="Your Watchlists">
                    <WatchlistSelection companyId={organization?.id} companyName={organization?.name} />
                  </Stat>
                </div>
              </div>
            </div>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};

const AuthenticatedCompanyPopover = ({ uuid, children }) => {
  const isLoggedIn = usePersonalAccount(state => state.isLoggedIn);

  if (isLoggedIn()) {
    return (
      <Popover mouseEnterDelay={0.5} content={<CompanyPopoverOverview uuid={uuid} />}>
        {children}
      </Popover>
    );
  } else {
    return children;
  }
};

const CompanyFlash = memo(
  ({
    id,
    name,
    domain,
    description,
    stats,
    align = 'center',
    textStyles = {},
    size = 'medium',
    extra = {},
    gap = '12px',
    titleSize = '16px',
  }) => {
    return (
      <AuthenticatedCompanyPopover uuid={id}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap, alignItems: align }}>
          <AvatarFailover key={`CompanyFlash-avatar-${id}`} src={logoURL(id)} name={name} shape="square" size={size} />
          <Space style={{ width: '100%', ...textStyles }} size={2} direction="vertical">
            <Text style={{ fontSize: titleSize, fontWeight: '500' }}>
              <Link to={`/research/companies/${id}`} style={{ color: 'rgba(0,0,0,.65)' }}>
                {name}
              </Link>
            </Text>
            {description && (
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                {description}
              </Paragraph>
            )}
            {Object.keys(extra).includes('markets') && (
              <div style={{ margin: '10px 0px' }}>
                <CategoryTags
                  minimum={2}
                  items={extra['markets'].map((t, i) => ({
                    link: `/markets/${t.id}`,
                    label: t.name,
                  }))}
                />
              </div>
            )}
            {domain && (
              <Text style={{ fontSize: '14px' }} type="secondary">
                {domain}
              </Text>
            )}
            {stats && stats.length > 0 && (
              <Text style={{ textTransform: 'uppercase', color: '#8AB0AB', fontSize: '14px', fontWeight: '500' }}>
                <Space wrap>
                  {stats.map((s, i) => (
                    <Space key={`cf-${id}-${i}`}>
                      {s}
                      {i < stats.length - 1 ? <>&middot;</> : null}
                    </Space>
                  ))}
                </Space>
              </Text>
            )}
          </Space>
        </div>
      </AuthenticatedCompanyPopover>
    );
  }
);

const CompanyFlashLimitedHover = memo(
  ({
    id,
    name,
    domain,
    description,
    stats,
    align = 'center',
    textStyles = {},
    size = 'medium',
    extra = {},
    gap = '12px',
    titleSize = '16px',
  }) => {
    const SIZES = {
      small: 24,
      medium: 32,
      large: 40,
    };
    return (
      <Space direction="vertical" className="w-100" style={{ minWidth: '300px' }}>
        <AuthenticatedCompanyPopover uuid={id}>
          <Space>
            <AvatarFailover
              key={`CompanyFlash-avatar-${id}`}
              src={logoURL(id)}
              name={name}
              shape="square"
              size={size}
            />
            <Text style={{ fontSize: titleSize, fontWeight: '500' }}>
              <Link to={`/research/companies/${id}`} style={{ color: 'rgba(0,0,0,.65)' }}>
                {name}
              </Link>
            </Text>
          </Space>
        </AuthenticatedCompanyPopover>
        <Space>
          <div style={{ width: SIZES[size], height: SIZES[size] }}></div>
          <Space style={{ ...textStyles, justifyContent: 'stretch' }} size={2} direction="vertical">
            {description && (
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                {description}
              </Paragraph>
            )}
            {Object.keys(extra).includes('markets') && (
              <div style={{ margin: '10px 0px' }}>
                <CategoryTags
                  minimum={2}
                  items={extra['markets'].map((t, i) => ({
                    link: `/markets/${t.id}`,
                    label: t.name,
                  }))}
                />
              </div>
            )}
            {domain && (
              <Text style={{ fontSize: '14px' }} type="secondary">
                {domain}
              </Text>
            )}
            {stats && stats.length > 0 && (
              <Text style={{ textTransform: 'uppercase', color: '#8AB0AB', fontSize: '14px', fontWeight: '500' }}>
                <Space wrap>
                  {stats.map((s, i) => (
                    <Space key={`cf-${id}-${i}`}>
                      {s}
                      {i < stats.length - 1 ? <>&middot;</> : null}
                    </Space>
                  ))}
                </Space>
              </Text>
            )}
          </Space>
        </Space>
      </Space>
    );
  }
);

const CompanyList = ({ companies, max = 8, descriptions = false }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <div>
      <div className="top-companies" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {companies.slice(0, showAll ? companies.length : max).map(c => (
          <AuthenticatedCompanyPopover key={`top-${c.id}`} uuid={c.id}>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '15px', alignItems: 'center' }}>
              <AvatarFailover key={`avatar-${c.id}`} src={logoURL(c.id)} name={c.name} shape="square" size={'medium'} />
              <div>
                <Typography.Text style={{ fontSize: '16px', fontWeight: '500' }}>
                  <Link to={`/research/companies/${c.id}`} style={{ color: 'rgba(0,0,0,.65)' }}>
                    {c.name}
                  </Link>
                </Typography.Text>
                {descriptions && (
                  <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                    {c.description}
                  </Paragraph>
                )}
              </div>
            </div>
          </AuthenticatedCompanyPopover>
        ))}
      </div>
      {!!companies.length && (
        <ViewMoreButton total={companies.length} max={max} setShowAll={setShowAll} showAll={showAll} />
      )}
    </div>
  );
};

export default CompanyPopoverOverview;
export { AuthenticatedCompanyPopover, CompanyFlash, CompanyList, CompanyFlashLimitedHover };
