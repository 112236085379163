import React from 'react';
import { Typography } from 'antd';
import './SectionHeader.css';

const { Text } = Typography;

const SectionHeader = ({ id, title, subtitle, extra, subTitleStyles = { fontSize: '16px' } }) => {
  return (
    <div className="section-header">
      <div className="title-area">
        <div>
          <h4 id={id} style={{ marginBottom: '0px' }}>
            {title}
          </h4>
          <Text style={subTitleStyles} type="secondary">
            {subtitle}
          </Text>
        </div>
        {extra && <div className="title-extra">{extra}</div>}
      </div>
    </div>
  );
};

export default SectionHeader;
