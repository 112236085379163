import { API } from '@aws-amplify/api';

// Get Organization Data
const customGremlinQuery = async qry => {
  let url = `/query?_query=${encodeURIComponent(qry)}&_queryType=Gremlin`;
  let data = await API.get('fuelapi', url);
  data = decodeGremlin(data.result.data);
  return data;
};

let decodeGremlin = obj => {
  var result = null;

  if (typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    result = [];

    for (let k = 0; k < obj.length; k++) {
      result.push(decodeGremlin(obj[k]));
    }

    if (result.length === 1 && typeof result[0] !== 'object') {
      result = result[0];
    }

    return result;
  }

  if (['g:Map'].includes(obj['@type'])) {
    result = {};
    let key,
      val = null;

    for (let i = 0; i < obj['@value'].length / 2; i++) {
      key = decodeGremlin(obj['@value'][i * 2]);
      val = decodeGremlin(obj['@value'][i * 2 + 1]);
      result[key] = val;
    }

    return result;
  }

  if (obj['@type'] === 'g:List') {
    result = [];

    for (let j = 0; j < obj['@value'].length; j++) {
      result.push(decodeGremlin(obj['@value'][j]));
    }

    if (result.length === 1 && typeof result[0] !== 'object') {
      result = result[0];
    }

    return result;
  }

  // Straight Values
  let straight_val_types = ['g:T', 'g:Date', 'g:Int64', 'g:Int32', 'g:Vertex'];
  if (straight_val_types.includes(obj['@type'])) {
    return decodeGremlin(obj['@value']);
  }

  console.log('Possible gremlin decoder errored on the following property:', obj);
  return obj;
};
export { decodeGremlin, customGremlinQuery };
