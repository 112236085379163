import './Search.css';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Space, Avatar } from 'antd';
import { API } from '@aws-amplify/api';
import React, { useState } from 'react';

import { decodeGremlin } from 'data/gremlin';

let searchTimeout = null;

const typeCompany = 'company';
const typeMarket = 'market';

const SearchCompany = props => {
  let searchingContent = (
    <Space>
      <LoadingOutlined />
      Searching...
    </Space>
  );
  let nonFound = <div>No results.</div>;
  const [result, setResult] = useState([]);
  const [value, setValue] = useState('');
  const [found, setFound] = useState();
  const [type] = useState(props.type || typeCompany);

  const reset = () => {
    setValue('');
    setResult([]);
    setFound();
  };

  const handleSelect = (value, option) => {
    setValue(option.label.props.children[1].props.children);
    props.handleSelect(option);
  };

  const searchMarkets = searchText => {
    var splitArr = searchText.split(' ');
    var concatString = '';
    splitArr.map(data => {
      return (concatString += ` AND predicates.name.value:${data}~`);
    });
    let query = `$entity_type:category${concatString}$`;
    let url = `/query?_searchAll=${encodeURI(query)}`;

    API.get('fuelapi', url)
      .then(result => {
        // Catch lack of results
        if (typeof result === 'undefined') {
          result = [];
        } else if (!('result' in result)) {
          result = [];
        } else {
          result = decodeGremlin(result.result.data['@value']);
        }

        let results = result.map(o => ({
          value: o.id,
          label: (
            <div className="search-result">
              <div className="t">{o.name}</div>
            </div>
          ),
          link: `/markets/${o.id}`,
        }));
        setResult(results);
        setFound(nonFound);
      })
      .catch(e => {
        setResult([]);
        setFound(nonFound);
      });
  };

  const searchCompanies = searchText => {
    let url = `/query?_quickSearch=${encodeURI(searchText)}`;

    API.get('fuelapi', url)
      .then(result => {
        let orgs = result?.organization;
        if (props.companies) {
          orgs = orgs.filter(org => props.companies.findIndex(company => company.uuid === org.uuid) < 0);
        }
        let results = orgs.map(o => ({
          value: o.uuid,
          label: (
            <div className="search-result">
              <div className="i">
                <Avatar
                  size={24}
                  shape="square"
                  style={{ marginRight: '8px', borderRadius: '4px' }}
                  src={o.logo_url}
                  alt={o.name}
                >
                  {o.name[0]}
                </Avatar>
              </div>
              <div className="t">{o.name}</div>
            </div>
          ),
          link: `/research/companies/${o.uuid}`,
        }));
        setResult(results);
        setFound(nonFound);
      })
      .catch(e => {
        setResult([]);
        setFound(nonFound);
      });
  };

  const handleEnter = event => {
    // if the parent provided a handler, cancel the search and pass the text back to them
    event.preventDefault();
    if (props.handleEnter) {
      props.handleEnter(value);
      clearTimeout(searchTimeout);
      reset();
    }
  };

  const handleSearch = value => {
    setValue(value);
    if (props.handleSearch) {
      props.handleSearch(value);
    }
    // clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than 750ms
    clearTimeout(searchTimeout);
    setFound(searchingContent);

    // minimum character search
    if (value.length <= 2) {
      setResult([]);
      setFound();
      return;
    }

    // make a new timeout set to go off in 750ms
    // this attempts to wait for the user to stop typing before running the search query
    searchTimeout = setTimeout(function() {
      if (type === typeCompany) searchCompanies(value);
      else if (type === typeMarket) searchMarkets(value);
      else console.log(`'${type}' not supported`);
    }, 750);
  };

  return (
    <AutoComplete
      options={result.map(item => ({
        value: item.value,
        label: item.label,
        link: item.link,
      }))}
      style={props.style || { width: '50%' }}
      className={`search-bar ${props.className || ''}`}
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={value}
      notFoundContent={found}
      popupMatchSelectWidth={500}
      disabled={props.disabled}
      allowClear={true}
    >
      <Input
        value={value}
        bordered={props.bordered || false}
        size={props.size || 'default'}
        placeholder={props.placeholder || 'Search...'}
        prefix={props.prefix ? <SearchOutlined /> : <></>}
        onPressEnter={handleEnter}
        style={props.inputStyle || {}}
      />
    </AutoComplete>
  );
};

export default SearchCompany;
