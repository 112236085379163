import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Skeleton } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Fade from 'components/utility/Fade';
import sdk from 'sdk/Checkout';
import accountSdk from 'sdk/Accounts';
import { shallow } from 'zustand/shallow';
import useAccounts from 'store/Accounts';
import { loadStripe } from '@stripe/stripe-js';
import { getStripePK } from 'utils';

const stripePromise = loadStripe(getStripePK());

const ShowRedirector = ({ text = 'Redirecting to your dashboard...' }) => {
  const history = useHistory();

  useState(() => {
    setTimeout(() => {
      history.push('/');
    }, 3000);
  }, []);

  return (
    <Fade transformX={'2'} transformY={'0'}>
      <br />
      <br />
      <br />

      <div>
        <LoadingOutlined /> {text}
      </div>
    </Fade>
  );
};

const ProvisionFreeAccount = ({ referral }) => {
  const { accountReferralQualified, loading: acctLoading } = useAccounts(
    state => ({ accountReferralQualified: state.accountReferralQualified, loading: state.loading }),
    shallow
  );
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateAccountAndRedirect = async () => {
    try {
      // load org accounts that the user created and that don't have the same referral id
      let accountID = await accountReferralQualified(referral?.id);
      // update account with referralID set to referral?.account.?id
      let result = await accountSdk.updateAccount({ id: accountID, referralID: referral?.id });
      if (!!result.errors) {
        setErr({
          msg: (
            <div>
              Failed to update account with referral.
              <br />
              Please <Link to="/support">contact support.</Link>
            </div>
          ),
        });
      }
    } catch (e) {
      console.log('error getting account referral qualified', e);
      setErr({
        msg: (
          <div>
            Failed to get qualified account.
            <br />
            Please <Link to="/support">contact support.</Link>
          </div>
        ),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!acctLoading) {
      updateAccountAndRedirect();
    }
    // eslint-disable-next-line
  }, [acctLoading]);

  return (
    <div>
      {loading && (
        <div>
          <Fade transformX={'2'} transformY={'0'}>
            <h2 style={{ fontFamily: 'Nunito', fontSize: '22px' }}>Provisioning your account...</h2>
          </Fade>
          <LoadingOutlined style={{ fontSize: '22px' }} />
        </div>
      )}
      {!loading && err && (
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <Fade transformX={'2'} transformY={'0'}>
            <ExclamationCircleOutlined style={{ color: 'var(--yellow)', fontSize: '24px' }} />
          </Fade>
          <Fade>
            <h2 style={{ margin: '0px', fontFamily: 'Nunito', fontSize: '22px' }}>{err.msg}</h2>
          </Fade>
        </div>
      )}
      {!loading && <ShowRedirector />}
    </div>
  );
};

const ProvisionPaidAccount = ({ referral, fuelPlan, billingPeriod }) => {
  const { accountReferralQualified, loading: acctLoading } = useAccounts(
    state => ({ accountReferralQualified: state.accountReferralQualified, loading: state.loading }),
    shallow
  );
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchSessionAndRedirect = async stripeID => {
    // load org accounts that the user created and that don't have the same referral id
    let accountID = await accountReferralQualified(referral?.id);
    // load user's account and call getOrgCheckoutSession with ID
    let result = await sdk.getOrgCheckoutSession(stripeID, fuelPlan, 1, accountID, referral?.id, referral?.accountID);

    if (result.type === 'checkout' && result.sessionId) {
      let stripe = await stripePromise;
      stripe
        .redirectToCheckout({
          sessionId: result.sessionId,
        })
        .then(d => setLoading(false))
        .catch(e => {
          setErr({
            msg: (
              <div>
                Failed to update plan information.
                <br />
                Please <Link to="/support">contact support.</Link>
              </div>
            ),
          });
          setLoading(false);
        });
    } else {
      setErr({
        msg: (
          <div>
            Failed to update plan information.
            <br />
            Please <Link to="/support">contact support.</Link>
          </div>
        ),
      });
    }
    setLoading(false);
  };

  const fetchPlans = async () => {
    setLoading(true);
    let result = await sdk.getVisiblePlans();
    console.log('result', result);
    let stripeID = null;

    if (result.items && result.items.length > 0) {
      let currentPlan = result.items.find(r => r.id === fuelPlan);
      if (currentPlan?.stripePriceID && currentPlan?.stripePriceID.length > 0) {
        // set first by default
        stripeID = currentPlan?.stripePriceID[0];

        if (currentPlan?.stripePriceID.length > 1 && !!billingPeriod) {
          try {
            let periodPlans = JSON.parse(currentPlan?.priceValues);
            let selectedID = Object.keys(periodPlans).find(key => periodPlans[key].billing_period === billingPeriod);
            if (!!selectedID) {
              stripeID = selectedID;
            }
          } catch (e) {
            console.log('error parsing plans');
          }
        }
      }
    }

    if (!referral?.id) {
      setErr({
        msg: (
          <div>
            Invalid referral: {referral?.id}
            <br />
            Please <Link to="/support">contact support.</Link>
          </div>
        ),
      });
      setLoading(false);
      return;
    }

    if (!stripeID) {
      setErr({
        msg: (
          <div>
            Invalid plan: {fuelPlan}
            <br />
            Please <Link to="/support">contact support.</Link>
          </div>
        ),
      });
      setLoading(false);
      return;
    }
    fetchSessionAndRedirect(stripeID);
  };

  useEffect(() => {
    if (!acctLoading) {
      fetchPlans();
    }
    // eslint-disable-next-line
  }, [fuelPlan, acctLoading]);

  return (
    <div>
      {loading && (
        <div>
          <Fade transformX={'2'} transformY={'0'}>
            <h2 style={{ fontFamily: 'Nunito', fontSize: '22px' }}>Provisioning your account...</h2>
          </Fade>
          <LoadingOutlined style={{ fontSize: '22px' }} />
        </div>
      )}
      {!loading && err && (
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <Fade transformX={'2'} transformY={'0'}>
            <ExclamationCircleOutlined style={{ color: 'var(--yellow)', fontSize: '24px' }} />
          </Fade>
          <Fade>
            <h2 style={{ margin: '0px', fontFamily: 'Nunito', fontSize: '22px' }}>{err.msg}</h2>
          </Fade>
        </div>
      )}
      {!loading && !err && <ShowRedirector text={'Redirect to Stripe checkout...'} />}
    </div>
  );
};

const ReferralAccount = ({ referral }) => {
  const fuelPlan = referral?.account?.limits?.referrals?.fuel_plan || 'fuel-free';
  const billingPeriod = referral?.account?.limits?.referrals?.billing_period;
  // need yearly or monthly config
  return (
    <div>
      {!referral && (
        <div>
          <Skeleton active={true} />
          <Skeleton active={true} />
        </div>
      )}
      {referral && fuelPlan === 'fuel-teams' && (
        <ProvisionPaidAccount referral={referral} fuelPlan={fuelPlan} billingPeriod={billingPeriod} />
      )}
      {referral && fuelPlan === 'fuel-free' && <ProvisionFreeAccount referral={referral} />}
    </div>
  );
};

export default ReferralAccount;
