import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Space, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import sdk from 'sdk/OpenNews';

const NewsSettings = ({ changeState, changeWorkflow, updateSettings }) => {
  const [pulses, setPulses] = useState([]);
  const [loading, setLoading] = useState(true);

  const next = () => {
    changeState('signUp');
    changeWorkflow('free');
  };

  const onChange = (e, p) => {
    let value = e.target.checked;
    if (p) {
      value = { enabled: e.target.checked, label: p.data.label, id: p.id };
    }
    updateSettings(e.target.name, value);
  };

  const fetchPulses = async () => {
    try {
      let result = await sdk.getConfig();
      setPulses(result.items);
    } catch (e) {
      message.error('Failed to retrieve Market Pulses. Please try again later or continue to Sign Up.');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPulses();
  }, []);

  return (
    <Space direction="vertical" size="large">
      <div>
        <h4 className="mute-title" style={{ marginTop: '30px' }}>
          Market Pulse Emails
        </h4>
        {loading && <LoadingOutlined />}
        {!loading && (
          <Space direction="vertical">
            {pulses.map(p => (
              <Checkbox key={p.id} name={p.data.slug} onChange={e => onChange(e, { ...p })}>
                {p.data.label}
              </Checkbox>
            ))}
          </Space>
        )}
      </div>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button type="link" size="large" onClick={next}>
          Skip for now
        </Button>
        <Button type="primary" size="large" onClick={next}>
          Continue
        </Button>
      </Space>
    </Space>
  );
};

export default NewsSettings;
