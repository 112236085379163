import React from 'react';
import './Fade.css';

const Fade = ({ children, animation, delay, transformY, transformX, isVisible = true }) => {
  const domRef = React.useRef();

  const [localVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;
    const observer = new IntersectionObserver(entries => {
      if (isMounted) {
        // In your case there's only one element to observe:
        if (entries[0].isIntersecting) {
          // Not possible to set it back to false like this:
          setVisible(true);

          // No need to keep observing:
          observer.unobserve(domRef.current);
        }
      }
    });

    observer.observe(domRef.current);
    const currentRef = domRef.current;

    return () => {
      isMounted = false;
      currentRef && observer.unobserve(currentRef);
    };
  }, []);

  return (
    <section
      ref={domRef}
      className={`fade ${animation || ''} ${delay || ''} ${
        transformY ? `transform-y-${transformY}` : 'transform-y-10'
      } ${transformX ? `transform-x-${transformX}` : ''} ${localVisible && isVisible ? ' is-visible' : ''} ${
        !isVisible ? 'outro' : ''
      }`}
    >
      {children}
    </section>
  );
};

export default Fade;
