import { API } from '@aws-amplify/api';

const getCompaniesByCategories = async categoryIds => {
  let url = `/api/categories/companies`;

  try {
    let result = await API.post('fuelapi', url, { body: { categoryIds } });
    return result;
  } catch (e) {
    console.log('error searching categories', e);
    return { error: 'Failed to search for categories' };
  }
};

const searchCategories = async queryStr => {
  let url = `/api/categories`;

  try {
    let result = await API.get('fuelapi', url, { queryStringParameters: { query: queryStr } });
    return result;
  } catch (e) {
    console.log('error searching categories', e);
    return { error: 'Failed to search for categories' };
  }
};

const getCategory = async categoryId => {
  let url = `/api/categories/${categoryId}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving tier 3 category', e);
    return { error: 'Failed to fetch tier 3 market category' };
  }
};

const getUpdates = async categoryId => {
  let url = `/api/categories/${categoryId}/updates`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving tier 3 category updates', e);
    return { error: 'Failed to fetch tier 3 market category updates' };
  }
};

const getEarned = async categoryId => {
  let url = `/api/categories/${categoryId}/earnedContent`;

  let d = new Date();
  d.setDate(d.getDate() - 30);
  let gteStr = d.toISOString().split('T')[0] + 'T00:00:00';

  try {
    let result = await API.get('fuelapi', url, { queryStringParameters: { gte: gteStr } });
    return result;
  } catch (e) {
    console.log('error retrieving tier 3 category earned content', e);
    return { error: 'Failed to fetch tier 3 market category earned content' };
  }
};

const getFundingHistory = async categoryId => {
  let url = `/api/categories/${categoryId}/fundingHistory`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving tier 3 category funding history', e);
    return { error: 'Failed to fetch tier 3 market funding history' };
  }
};

const getRecentFunding = async categoryId => {
  let url = `/api/categories/${categoryId}/recentFunding`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving tier 3 category recent funding', e);
    return { error: 'Failed to fetch tier 3 market recent funding' };
  }
};

const getTopInvestors = async categoryId => {
  let url = `/api/categories/${categoryId}/topInvestors`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving tier 3 category investors', e);
    return { error: 'Failed to fetch tier 3 market investors' };
  }
};

const getTopCompanies = async (
  categoryId,
  limit = 20,
  offset = 0,
  query,
  location,
  month,
  employee,
  funding,
  foundedDateExpression
) => {
  let url = `/api/categories/${categoryId}/topCompanies`;
  let queryStringParameters = { _limit: limit, _offset: offset };

  if (!!query) {
    queryStringParameters['_query'] = query;
  }
  if (!!location) {
    Object.keys(location).forEach(key => {
      if (location[key]) {
        queryStringParameters[key] = location[key];
      }
    });
  }

  if (!!month) {
    queryStringParameters['monthFilter'] = month;
    queryStringParameters['foundedDateExpression'] = foundedDateExpression;
  }

  if (!!employee && employee.length > 0) {
    queryStringParameters['employees'] = employee;
  }

  if (!!funding) {
    queryStringParameters['fundingValue'] = funding.value;
    queryStringParameters['fundingExpression'] = funding.expression;
  }

  try {
    let result = await API.get('fuelapi', url, { queryStringParameters });
    return result;
  } catch (e) {
    console.log('error retrieving tier 3 category top companies', e);
    return { error: 'Failed to fetch tier 3 market top companies' };
  }
};

const sdk = {
  getCategory,
  getUpdates,
  getFundingHistory,
  getTopCompanies,
  getEarned,
  getRecentFunding,
  getTopInvestors,
  searchCategories,
  getCompaniesByCategories,
};

export default sdk;
