// This file provides a common set of helpers for global search.
import { API } from '@aws-amplify/api';
const GLOBAL_API_KEY = process.env.REACT_APP_PUBLIC_API_KEY;

// Get search result
const getSearchResult = async searchText => {
  if (!searchText) {
    // don't run search if text is empty
    return {};
  }
  let formattedToDomain = searchText.trim();
  if (searchText.includes('://')) {
    formattedToDomain = searchText.split('://')[1];
  }
  if (formattedToDomain.includes('/')) {
    formattedToDomain = formattedToDomain.split('/')[0];
  }
  formattedToDomain = formattedToDomain.replace(/www./i, '');
  let result = {};
  try {
    result = await API.get('fuelapi', `/api/companies/_search`, {
      queryStringParameters: { query: formattedToDomain },
    });
  } catch (e) {
    console.log('error retrieving data', e);
  }

  console.log('search result', result);
  return result;
};

const getSearchResultUnAuth = async (index, query) => {
  if (!index || !query) return {};
  console.log('frontend-GLOBAL_API_KEY: ', GLOBAL_API_KEY);
  let result;
  try {
    result = await API.post('fuelapi', `/api-public/search/${index}`, {
      body: { query: query, Authorization: `Bearer ${GLOBAL_API_KEY}` },
      Authorization: `Bearer ${GLOBAL_API_KEY}`,
      headers: {
        Authorization: `Bearer ${GLOBAL_API_KEY}`,
      },
    });
  } catch (e) {
    console.log('error retrieving data', e);
  }
  return result;
};
export { getSearchResult, getSearchResultUnAuth };
