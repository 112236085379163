import React, { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SearchUsers from './SearchUsers';
import { ShareAvatar } from 'components/users/ShareAvatars';
import useMessages from 'store/Messages';
import Skeleton from 'components/utility/Skeleton';
import sdk from 'sdk/Messages';
import accounts from 'sdk/Accounts';

const ChannelMembers = ({ show, setShow, channelID }) => {
  const createInvite = useMessages(state => state.createInvite);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(true);
  const [invite, setInvite] = useState(null);

  const fetchMembers = async () => {
    setAdding(true);
    let result = await sdk.fetchChannelMembers(channelID);
    let existingInvite = null;
    for (const r of result) {
      if (r.inviteID) {
        existingInvite = r.inviteID;
        break;
      }
    }
    let users = await accounts.getUsersByIDs(result.map(r => r.owner));
    setInvite(existingInvite);
    setMembers(users);
    setAdding(false);
    setLoading(false);
  };

  const addMember = async (v, opt) => {
    let eInvite = invite;

    // create invite if we don't have an existing one
    if (!eInvite) {
      eInvite = await createInvite(channelID);
      console.log('created invite', eInvite);
      eInvite = eInvite.id;
    }
    if (eInvite) {
      console.log('add user', opt.value);
      await sdk.createChannelInvites(opt.value, channelID, eInvite);
      fetchMembers();
    } else {
      console.log('no invite');
    }
  };

  const onClose = async () => {
    setShow(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchMembers();
    // eslint-disable-next-line
  }, [channelID]);

  return (
    <Drawer
      title={<div style={{ color: '#FFF' }}>Channel Members</div>}
      placement={'right'}
      closable={false}
      onClose={onClose}
      open={show}
      key={`right-channel-members-${channelID}`}
      headerStyle={{ backgroundColor: 'var(--charcoal-four)', textTransform: 'uppercase' }}
      width={'768px'}
      extra={
        <Button type="primary" onClick={onClose}>
          Done
        </Button>
      }
      destroyOnClose={true}
    >
      <div>
        <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
          <SearchUsers placeholder="Add channel members" size="large" onSelect={addMember} />
        </div>
        {loading && <LoadingOutlined />}
        {!loading && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {adding && (
              <div style={{ display: 'flex', gap: '8px' }}>
                <Skeleton active={true} style={{ height: '40px', width: '40px', borderRadius: '4px' }} />
                <Skeleton
                  active={true}
                  style={{ height: '18px', width: '100px', borderRadius: '4px', marginTop: '2px' }}
                />
              </div>
            )}
            {members.map(m => (
              <div
                key={`channel-member-${channelID}-${m.id}`}
                style={{ display: 'grid', gridTemplateColumns: '40px minmax(0, 1fr)', gap: '8px' }}
              >
                <ShareAvatar id={m.id} url={m.avatar} size="large" shape="square" />
                <div style={{ borderBottom: '1px solid rgba(0,0,0,.05)', padding: '2px 0px 15px' }}>
                  <div style={{ fontWeight: '500', fontSize: '16px', lineHeight: '16px' }}>{m.fullName}</div>
                  <div style={{ fontSize: '14px', color: 'rgba(0,0,0,.65)' }}>{m.email}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default ChannelMembers;
