import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const FeedSteps = [
  {
    selector: '.dne',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Welcome to your Watchlist Feed
        </Title>
        <Paragraph>This shows the updates from all of your favorite watchlists.</Paragraph>
      </div>
    ),
  },
  {
    selector: '.feed-workspace-selector',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Accounts
        </Title>
        <Paragraph>Switch accounts to view different activity feeds based on your favorited Watchlists.</Paragraph>
      </div>
    ),
  },
  {
    selector: '.feed-favorites-list',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Favorite Watchlists
        </Title>
        <Paragraph>
          Your favorite Watchlists will be listed here, you can favorite or un-favorite a Watchlist from the Watchlist
          page.
        </Paragraph>
      </div>
    ),
  },
  {
    selector: '.feed-activity-feed-list',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Watchlist Activity
        </Title>
        <Paragraph>Funding rounds, blog posts, and other news will appear front and center.</Paragraph>
      </div>
    ),
  },
  {
    selector: '.feed-acq-list',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Recent Acquisitons
        </Title>
        <Paragraph>Recent acquisitions, based on your favorite Watchlists, will be shown in the side bar. </Paragraph>
      </div>
    ),
  },
  {
    selector: '.feed-topics-list',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Topics
        </Title>
        <Paragraph>Explore topics based on your Watchlist updates and other popular articles.</Paragraph>
      </div>
    ),
  },
  {
    selector: '.feed-explore-list',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Explore Fuel
        </Title>
        <Paragraph>
          Browse York IE Fuel for new companies and markets that are relevant to you. Be sure to check out our Advisory
          Playbooks as well.
        </Paragraph>
      </div>
    ),
  },
  {
    selector: '.feed-advanced-tools-list',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Advanced Tools
        </Title>
        <Paragraph>Need something more specific? Use Fuel's advanced tools to find Investors or Podcasts.</Paragraph>
      </div>
    ),
  },
];

const NewDashboardFeedSteps = [
  {
    selector: '.dne',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Welcome to Fuel!
        </Title>
        <Paragraph>Before you get started, let us show you around!</Paragraph>
      </div>
    ),
  },
  {
    selector: '.fuel-menu-tour',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Navigation
        </Title>
        <Paragraph>
          Your navigation is up here. You can research Markets and Companies, create Watchlists to follow your
          competitors, review our Advisory for strategy templates and best practices documents, and use our Tools to
          help with execution.
        </Paragraph>
      </div>
    ),
    style: {
      minWidth: '500px',
    },
  },
  {
    selector: '.search-all-tour',
    position: 'bottom',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Search
        </Title>
        <Paragraph>
          The search bar is your primary tool across the platform to find companies, markets, articles, and recently
          viewed items.
        </Paragraph>
      </div>
    ),
  },
  {
    selector: '.dashboard_journeys',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Advisory Journeys
        </Title>
        <Paragraph>
          We have built Advisory Journeys to help you solve common problems that all startups face. Click on a journey
          to see our step-by-step recommendations that will help you succeed.
        </Paragraph>
      </div>
    ),
    style: {
      minWidth: '500px',
    },
  },
  {
    selector: '.dashboard_discover',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Discover
        </Title>
        <Paragraph>
          Gain valuable insights and use Fuel's data to help you make informed decisions. Research companies, market
          landscapes, funding, investment opportunities and even run a self check to evaluate your current business.
        </Paragraph>
      </div>
    ),
    style: {
      minWidth: '500px',
    },
  },
  {
    selector: '.user-dropdown',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Settings Menu
        </Title>
        <Paragraph>
          Explore the various features of the settings menu available in the Fuel platform. This menu includes Profile
          Settings, Account Settings, Billing Info, Data Snapshots, and Fuel Notebooks.
        </Paragraph>
      </div>
    ),
    style: {
      minWidth: '500px',
    },
  },
  {
    selector: '.support-btn',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Support & Documentation
        </Title>
        <Paragraph>Get the most out of Fuel with our documentation and in depth guides.</Paragraph>
      </div>
    ),
  },
  {
    selector: '.dne',
    content: args => {
      return (
        <div>
          <Title className="fuel-title" level={3}>
            Where do I start?
          </Title>
          <Paragraph>
            Building a successful business takes a lot of hard work. Our{' '}
            <Link
              to="/advisory/library/featured/founders-checklist"
              onClick={() => {
                document.body.style.overflowY = 'auto';
                args.close();
              }}
              className="link-bold"
            >
              Founder’s checklist
            </Link>{' '}
            will help keep you on track.
          </Paragraph>
        </div>
      );
    },
    style: {
      minWidth: '420px',
    },
  },
];
const WatchlistPageFeedSteps = [
  {
    selector: '.nav-items',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Company Details
        </Title>
        <Paragraph>View company details and updates for the companies of this Watchlist.</Paragraph>
      </div>
    ),
  },
  {
    selector: '#watchlistPage-options',
    content: () => (
      <div>
        <Title className="fuel-title" level={3}>
          Watchlist Settings
        </Title>
        <Paragraph>
          Options to follow the Watchlist, configure notifications, download Watchlist data, manage users, and edit.
        </Paragraph>
      </div>
    ),
  },
];

// feed-workspace-selector

export { FeedSteps, NewDashboardFeedSteps, WatchlistPageFeedSteps };
