import React from 'react';
import { Button, Dropdown, Typography, message, Tooltip } from 'antd';
import Icon, { ShareAltOutlined, FacebookOutlined, LinkedinOutlined, CopyOutlined } from '@ant-design/icons';

import './NavigatorShare.css';
import TwitterLogoGray from 'images/twitter_bg_white_color_gray.svg';

const TwitterGrayIcon = () => {
  return <img src={TwitterLogoGray} alt="X (formerly Twitter)" style={{ width: '12px' }} />;
};

function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

const SocialShareDropdown = ({ title, link, orgName, buttonText = false }) => {
  const twitterLink =
    'https://twitter.com/intent/tweet?text=' +
    encodeURI(title + ' ' + link + (orgName ? ' By ' + orgName : '') + ' via @YorkGrowth');
  const linkedInLink =
    'https://www.linkedin.com/cws/share?url=' +
    encodeURI(link) +
    '&title=' +
    encodeURI(title) +
    '&summary=' +
    encodeURI(title) +
    '&source=' +
    encodeURI(link);
  const facebookLink = 'https://facebook.com/sharer/sharer.php?u=' + encodeURI(link);

  return (
    <Tooltip title="Share">
      <Dropdown
        menu={{
          items: [
            {
              key: '0',
              icon: <Icon component={TwitterGrayIcon} />,
              label: (
                <a href={twitterLink} target="_blank" rel="noreferrer noopener">
                  X (formerly Twitter)
                </a>
              ),
            },
            {
              key: '1',
              icon: <LinkedinOutlined />,
              label: (
                <a href={linkedInLink} target="_blank" rel="noreferrer noopener">
                  LinkedIn
                </a>
              ),
            },
            {
              key: '2',
              icon: <FacebookOutlined />,
              label: (
                <a href={facebookLink} target="_blank" rel="noreferrer noopener">
                  Facebook
                </a>
              ),
            },
            {
              key: '3',
              icon: <CopyOutlined />,
              label: (
                <Typography.Text
                  onClick={() => {
                    if (copyToClipboard(link)) {
                      message.success('URL copied to clipboard');
                    } else {
                      message.error('Something went wrong!');
                    }
                  }}
                >
                  Copy Link
                </Typography.Text>
              ),
            },
          ],
        }}
        trigger={['click']}
        placement={'bottomRight'}
      >
        <Button type="text" shape="circle" icon={<ShareAltOutlined />}>
          {buttonText ? 'Share' : ''}
        </Button>
      </Dropdown>
    </Tooltip>
  );
};

export const navigatorShare = async (url, title, text) => {
  if (navigator.canShare) {
    const shareData = {
      title,
      text,
      url,
    };

    await navigator.share(shareData);
    return;
  }
  if (copyToClipboard(url)) {
    message.success('URL copied to clipboard');
  } else {
    message.error('Something went wrong!');
  }
};

const NavigatorShare = ({ title, text, url = window.location.href }) => {
  return (
    <Tooltip title="Share">
      <Button shape="circle" icon={<ShareAltOutlined />} onClick={() => navigatorShare(url, title, text)} />
    </Tooltip>
  );
};

const NavigatorShareFallback = ({ link = window.location.href, title, text }) => {
  return navigator.canShare ? (
    <NavigatorShare title={title} text={text} url={link} />
  ) : (
    <SocialShareDropdown title={title} link={link} />
  );
};

export default NavigatorShare;
export { SocialShareDropdown, NavigatorShareFallback, copyToClipboard };
