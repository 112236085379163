import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import useRecentlyViewed from 'store/RecentlyViewed';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { Typography, Select, Spin } from 'antd';
import { abbreviate_number } from 'utils';

import { getSearchResult } from 'helpers/Search';
import { logoURL } from 'helpers/Organizations';
import AvatarFailover from 'components/AvatarFailover';
import './SearchAll.css';
import { CompanyHeader } from 'components/company/Overview';

let searchTimeout = null;

const { Option, OptGroup } = Select;
const { REACT_APP_SITE_ROLLUPS_URL } = process.env;

const ADVANCED_SEARCHES = [
  { title: 'Companies', url: '/companies/search' },
  { title: 'Funding Rounds', url: '/companies/search/funding' },
  { title: 'Acquisitions', url: '/companies/search/acquisition' },
  { title: 'Articles', url: '/companies/search/article' },
];

const AdvancedSearchesTiles = () => {
  return (
    <div className="advancedSearchTiles-wrapper">
      {ADVANCED_SEARCHES.map(searchDetail => (
        <Link to={searchDetail.url} className="filter-link">
          <span>{searchDetail.title}</span>
        </Link>
      ))}
    </div>
  );
};

const SearchAll = props => {
  let history = useHistory();
  const [result, setResult] = useState({});
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [markets, setMarkets] = useState([]);
  const ref = useRef(null);
  const recents = useRecentlyViewed(state => state.recentlyViewedCompanies, shallow);

  useEffect(() => {
    fetchMarkets();
    // eslint-disable-next-line
  }, []);

  const reset = () => {
    setValue('');
    setFocus(false);
    setResult([]);
    if (ref.current) {
      ref.current.blur();
    }
  };

  const fetchMarkets = async () => {
    try {
      const resp = await axios.get(`${REACT_APP_SITE_ROLLUPS_URL}fuel-market-rollup.json`);
      resp.data.sort((a, b) => (b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1));
      let items = resp.data.filter(d => d.count > 0);
      setMarkets(items);
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  const searchMarkets = async searchText => {
    return markets.filter(m => m.name.toLowerCase().includes(searchText.toLowerCase()));
  };

  const handleSelect = (key, option) => {
    if (option.linktype !== 'article') {
      if (props.handleEnter && loading) {
        reset();
        props.handleEnter(value);
      } else if (props.handleSelect) {
        reset();
        props.handleSelect(option);
      } else {
        reset();
        history.push(option.link);
      }
    } else {
      window.open(option.link, '_blank');
    }
  };

  const searchAll = async searchText => {
    setLoading(true);
    let [companies, markets] = await Promise.all([await getSearchResult(searchText), await searchMarkets(searchText)]);
    setResult({ companies: companies?.companies || [], markets: markets || [] });
    setLoading(false);
  };

  const handleSearch = value => {
    if (value === '') {
      if (focus) {
        setValue(value);
        setLoading(false);
      }
    } else {
      setValue(value);
    }
    if (props.handleSearch) {
      props.handleSearch(value);
    }
    // clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than 750ms
    clearTimeout(searchTimeout);
    // minimum character search
    if (value.length <= 2) {
      setResult([]);
      return;
    }

    setLoading(true);
    // make a new timeout set to go off in 750ms
    // this attempts to wait for the user to stop typing before running the search query
    searchTimeout = setTimeout(function() {
      searchAll(value);
    }, 400);
  };

  const searchResults = () => {
    const rKeys = Object.keys(recents);
    let results = [];

    if (result && result.companies) {
      results.push({
        label: (
          <span>
            Companies
            <Link onClick={reset} style={{ float: 'right' }} to={`/companies/search?name=${value}`}>
              <FilterOutlined /> Filters
            </Link>
          </span>
        ),
        options: result.companies.slice(0, 10).map(org => {
          return {
            value: `org-${org.id}`,
            link: `/research/companies/${org.id}`,
            linkType: 'organization',
            label: <CompanyHeader org={org} showDomain={true} />,
          };
        }),
      });
    }

    if (result.markets && result.markets.length > 0) {
      console.log('result.markets', result.markets);
      results.push({
        label: (
          <span>
            Markets
            <Link onClick={reset} style={{ float: 'right' }} to={`/markets?search=${value}`}>
              more
            </Link>
          </span>
        ),
        options: result.markets.slice(0, 5).map(m => {
          let funding = 0;
          for (const key of Object.keys(m.funding_summary)) {
            funding += m.funding_summary[key].total_usd;
          }
          return {
            value: `market-${m.id}`,
            link: `/markets/${m.id.replaceAll('tc3-', '')}`,
            linkType: 'category',
            label: (
              <Typography.Text style={{ fontSize: '16px' }}>
                <Link
                  to={`/markets/${m.id.replaceAll('tc3-', '')}`}
                  style={{
                    color: '#000',
                    display: 'grid',
                    gridTemplateColumns: 'minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)',
                    alignItems: 'center',
                  }}
                >
                  {m.name}
                  <span style={{ fontSize: '14px', color: 'rgba(0,0,0,.65)', width: '100%', textAlign: 'right' }}>
                    {m.count.toLocaleString()} companies
                  </span>
                  <span style={{ fontSize: '14px', color: 'rgba(0,0,0,.65)', width: '100%', textAlign: 'right' }}>
                    $ {abbreviate_number(funding)}
                  </span>
                </Link>
              </Typography.Text>
            ),
          };
        }),
      });
    }

    // If empty, let them know
    const noResult = value !== '' && value.length > 2 && !loading && results.length === 0;

    results.push({
      label: (
        <span>
          Recently Viewed Companies
          <Link style={{ float: 'right' }} to="/research/companies">
            more
          </Link>
        </span>
      ),
      options: rKeys
        .reverse()
        .slice(0, 5)
        .map(function(keyName, keyIndex) {
          const org = recents[keyName];
          return {
            value: `recents-${org.id}`,
            link: `/research/companies/${org.id}`,
            label: (
              <Link to={`/research/companies/${org.id}`}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: '10px',
                  }}
                >
                  <div style={{ marginRight: '8px', borderRadius: '4px' }}>
                    <AvatarFailover
                      key={`avatar-${org.id}`}
                      src={logoURL(org.id)}
                      backupSrc={org.logo}
                      name={org.name}
                      shape="square"
                      size={'medium'}
                    />
                  </div>
                  <div style={{ overflow: 'hidden' }}>
                    <div style={{ width: '100%' }}>
                      <Typography.Text style={{ fontSize: '16px' }}>
                        <Link to={`/research/companies/${org.uuid}`} style={{ color: '#000' }}>
                          {org.name}
                        </Link>
                      </Typography.Text>
                      {org.domain && (
                        <Typography.Text type="secondary" style={{ fontSize: '95%', float: 'right' }}>
                          {org.domain}
                        </Typography.Text>
                      )}
                    </div>
                    <Typography.Paragraph
                      style={{ margin: 0 }}
                      type="secondary"
                      ellipsis={{ rows: 1, expandable: false }}
                      size={18}
                    >
                      {org.description}
                    </Typography.Paragraph>
                  </div>
                </div>
              </Link>
            ),
          };
        }),
    });

    return { results, noResult };
  };

  const { results, noResult } = searchResults();
  return (
    <Select
      data-id="page-header-search"
      loading={loading}
      bordered={false}
      prefix={props.prefix ? <SearchOutlined /> : <></>}
      placeholder={value === '' && !focus && (props.placeholder || 'Search...')}
      showSearch
      suffixIcon={null}
      notFoundContent={null}
      style={props.style || { width: '50%' }}
      size={props.size || 'default'}
      popupMatchSelectWidth={750}
      listHeight={window.innerHeight - 100}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      open={focus}
      filterOption={false}
      searchValue={value}
      value={focus && value ? value : null}
      className={props.className || 'search-all-input'}
      popupClassName="search-all"
      autoFocus={props.autoFocus}
      ref={ref}
    >
      {loading && (
        <Option disabled className="loader-option">
          <div className="loader">
            <Spin />
          </div>
        </Option>
      )}
      {!loading && noResult && focus && (
        <Option className="noresult-option">
          <div>
            No Results Found - <Link to={`/add-company`}>Suggest a company we should add</Link>
          </div>
        </Option>
      )}
      <OptGroup key={`group-filters`} label={'Advanced Search'}>
        <Option key="hello" value="filters-options" style={{ backgroundColor: 'white', marginBottom: '5px' }}>
          <AdvancedSearchesTiles />
        </Option>
      </OptGroup>
      {results.map((r, i) => (
        <OptGroup key={`group-${i}`} label={r.label}>
          {r.options.map(opt => (
            <Option key={`option-${i}-${opt.value}`} value={opt.value} link={opt.link} linktype={opt.linkType}>
              {opt.label}
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  );
};

export default SearchAll;
