import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Drawer, Button, Input, Space, Divider, message, Select, Spin, Form, Checkbox, DatePicker, Upload } from 'antd';
import { API } from '@aws-amplify/api';
import Splash from 'components/Splash';
import sdk from 'sdk/News';
import newsSdk from 'sdk/OpenNews';
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import MarkdownIt from 'markdown-it';
import SearchCompany from 'components/SearchCompany';
import { Storage } from '@aws-amplify/storage';
import awsmobile from 'aws-exports';

import 'react-markdown-editor-lite/lib/index.css';
import slugify from 'slugify';

// lazy load this lib
const MdEditor = lazy(() => import('react-markdown-editor-lite'));

const { TextArea } = Input;
const { Option } = Select;
const imageTypes = ['image/jpeg', 'image/png'];

const UpdateArticle = ({ show, close, article, orgId, articleId, ...props }) => {
  const [curateResult, setCurateResult] = useState({});
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [imageurl, setImageurl] = useState('');
  const [key_point, setKeypoint] = useState([]);
  const [publishedDate, setPublishedDate] = useState(dayjs());
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [loadingImg, setLoadingImg] = useState(false);
  const [featured, setFeatured] = useState('');
  const [marketpulse, setMarketpulse] = useState('');
  const [isArticleUpdating, setIsArticleUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState([]);
  const [error, setError] = useState();
  const [isArticleDeleting, setIsArticleDeleting] = useState(false);

  const [form] = Form.useForm();
  const mdEditor = React.useRef(null);

  // Initialize a markdown parser
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  const setCompany = item => {
    setCompanyId(item.value);
    setCompanyName(item.label.props.children[1].props.children);
    form.setFieldsValue({
      company: item.label.props.children[1].props.children,
    });
  };

  useEffect(() => {
    const fetchCurate = async () => {
      try {
        setLoading(true);
        let url = `/editors/article/${articleId}`;
        let result = await API.get('fuelapi', url);
        setLoading(false);
        setIsArticleUpdating(false);
        if (result.length > 0) {
          const data = result[0];
          setCurateResult(data);
          setFeatured(data.featured);
          setPublishedDate(dayjs(data.content_date, 'YYYY-MM-DDTHH:mm:ss'));
          setImageurl(data.image);
          setTitle(data.title);
          setSlug(articleId);
          setMarketpulse(data.mpn_category);
          setDescription(data?.content ? data.description : '');
          setContent(data?.content ? data.content : data.description);

          let key_point_arr = [];

          if (data.summary) {
            key_point_arr = data.summary;
            setKeypoint(data.summary);
          }

          form.setFieldsValue({
            featured: data.featured,
            publishedDate: dayjs(data.content_date, 'YYYY-MM-DDTHH:mm:ss'),
            imageurl: data.image,
            title: data.title,
            marketpulse: data.mpn_category,
            keypoints: key_point_arr,
            description: data?.content ? data.description : '',
          });
        } else {
          fetchArticle();
          setTitle(article?.title ? article.title : '');
          setSlug(slugify(article?.title, { replacement: '-', remove: /[*+~.()'"!:@?]/g, lower: true, trim: false }));
          setImageurl(article?.image ? article.image : '');
          setPublishedDate(dayjs());

          form.setFieldsValue({
            title: article?.title ? article.title : '',
            imageurl: article?.image ? article.image : '',
            publishedDate: dayjs(),
          });
        }
        return result;
      } catch (e) {
        setIsArticleUpdating(false);
        return { error: 'Failed to update news article' };
      }
    };
    const fetchArticle = async () => {
      setLoading(true);
      const result = await sdk.getArticleDetails(articleId);
      setLoading(false);
      if (result.article) {
        const data = result.article;
        let key_point_arr = [];

        if (data.summary) {
          key_point_arr = data.summary;
          setKeypoint(data.summary);
        }
        setDescription(data?.content ? data.description : '');
        setContent(data?.content ? data.content : data.description);
        form.setFieldsValue({
          keypoints: key_point_arr,
          description: data?.content ? data.description : '',
        });
      }
    };
    const fetchData = async () => {
      let result = await newsSdk.getConfig();

      if (result.hasOwnProperty('error')) {
        message.error(result.error, 4);
      } else {
        let items = result.items
          .sort((a, b) => a.data.order - b.data.order)
          .map(i => {
            return i.data;
          });
        setLists(items);
      }
    };
    fetchData();
    if (articleId && !props.isCreate) {
      fetchCurate();
    } else if (!props.isCreate) {
      message.error('Missing Article ID');
    }
  }, [articleId, article, form, props.isCreate]);

  const deleteArticle = async () => {
    let url = `/editors/article/${articleId}`;
    setIsArticleDeleting(true);
    await API.del('fuelapi', url)
      .then(data => {
        setIsArticleDeleting(false);
        close();
        message.success('Article Deleted succesfully', 4);
      })
      .catch(e => {
        setIsArticleDeleting(false);
        close();
        message.error('Failed to delete Article. Please try again.');
        return;
      });
  };

  const updateArticle = async () => {
    await form.validateFields();
    const articleObj = {
      title: title,
      description: description,
      content: content,
      image: imageurl,
      published_time: publishedDate,
      featured: featured,
      article_id: articleId,
      org_id: props.isCreate ? companyId : orgId,
      marketpulse_category: marketpulse,
      summary: form.getFieldValue('keypoints'),
      id: 'news-' + slug,
    };
    setIsArticleUpdating(true);
    if (Object.keys(curateResult).length > 0) {
      let url = `/editors/article`;
      try {
        let result = await API.put('fuelapi', url, { body: articleObj });
        message.success('Article Updated', 4);
        setIsArticleUpdating(false);
        close();
        return result;
      } catch (e) {
        setIsArticleUpdating(false);
        setError('CuratedNewsArticleExists');
        message.error('BadRequestError: CuratedNewsArticleExists', 4);
        return;
      }
    } else {
      let url = `/editors/article`;
      try {
        let resultadd = await API.post('fuelapi', url, { body: articleObj });
        message.success('Article Added', 4);
        setIsArticleUpdating(false);
        close();
        return resultadd;
      } catch (e) {
        setIsArticleUpdating(false);
        setError('CuratedNewsArticleExists');
        message.error('BadRequestError: CuratedNewsArticleExists', 4);
        return;
      }
    }
  };

  const handleContentEditorChange = ({ html, text }) => {
    setContent(text);
  };

  const beforeUpload = file => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!imageTypes.includes(file.type)) {
      message.error(`Allowed file types: ${imageTypes.toString()}`);
    }
    if (imageTypes.includes(file.type)) {
      // setImageurl(file);
      handleChangeCreateImage(file);
    }
    return false;
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChangeCreateImage = async info => {
    const file = info;
    const newFilename = new Date().valueOf();
    try {
      setLoadingImg(true);
      const uploadedFileKeyImg = await Storage.put(
        `${file.name.replace(/\.[^/.]+$/, '')}_${newFilename}.${file.name.replace(/(.*)\./g, '')}`,
        file,
        {
          level: 'public-read',
          contentType: file.type,
        }
      );
      setLoadingImg(false);

      let imgUrl =
        'https://' +
        awsmobile.aws_user_files_s3_bucket +
        '.s3.' +
        awsmobile.aws_user_files_s3_bucket_region +
        '.amazonaws.com/public/' +
        encodeURI(uploadedFileKeyImg.key);
      setImageurl(imgUrl);
      form.setFields({
        imageurl: imgUrl,
      });
    } catch (error) {
      message.error(`Uploading file: ${error}`);
    }
  };

  return (
    <Drawer
      title="Curate"
      placement={'right'}
      style={{ zIndex: 1040 }}
      onClose={close}
      open={show}
      key={`notebook-share-drawer`}
      width="800px"
      bodyStyle={{ padding: '20px' }}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        {loading && <Spin />}
        {error && <h5 style={{ color: 'red' }}>{error}</h5>}
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            title: title,
            description: description,
            content: content,
            keypoints: key_point,
            imageurl: imageurl,
            publishedDate: publishedDate,
            featured: featured,
            marketpulse: marketpulse,
            lists: lists,
            company: companyName,
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter title.',
              },
            ]}
          >
            <Input
              placeholder="Enter Title..."
              size="large"
              value={title}
              type="text"
              onChange={event => setTitle(event.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="URL Slug"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter URL slug.',
              },
            ]}
            extra={slug ? <code className="fuel-code">/news/article/news-{slug}</code> : 'e.g. unique-course-slug'}
          >
            <Input
              placeholder="Human readable URL slug; e.g. unique-course-slug"
              size="large"
              value={slug}
              onChange={e => {
                setSlug(
                  slugify(e.target.value, { replacement: '-', remove: /[*+~.()'"!:@?]/g, lower: true, trim: false })
                );
              }}
            />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please add description.',
              },
            ]}
          >
            <TextArea
              rows={5}
              style={{ marginTop: '5px' }}
              placeholder="Enter Summary..."
              size="large"
              value={description}
              type="text"
              onChange={event => setDescription(event.target.value)}
            />
          </Form.Item>

          <Form.Item label="Key points" required={true}>
            <Form.List
              name="keypoints"
              rules={[
                {
                  validator: async (_, names) => {
                    if (names.length < 1) {
                      return Promise.reject(new Error('Please enter key point value'));
                    }
                  },
                },
              ]}
            >
              {(key_point, { add, remove }, { errors }) => {
                return (
                  <>
                    {key_point.map((field, index) => (
                      <Form.Item required={false} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please enter key point value.',
                              },
                            ]}
                            noStyle
                          >
                            <TextArea
                              rows={5}
                              placeholder="Enter Key Points"
                              style={{ width: '96%', marginRight: '15px', height: '45px' }}
                            />
                          </Form.Item>
                          {key_point.length > 0 ? (
                            <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                          ) : null}
                        </div>
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} style={{ width: '60%' }} icon={<PlusOutlined />}>
                        Add Key Point
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Form.Item>

          {!props.isCreate && (
            <Form.Item
              label="Image"
              name="imageurl"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please add image url.',
                },
              ]}
            >
              <Input
                style={{ marginTop: '5px' }}
                placeholder="Enter Image URL..."
                size="large"
                value={imageurl}
                type="text"
                onChange={event => setImageurl(event.target.value)}
              />
            </Form.Item>
          )}

          <Space direction="vertical" style={{ width: '100%', marginBottom: '24px' }}>
            <label for="md-editor" class="ant-form-item" title="Content">
              Content
            </label>
            <Suspense fallback={<Splash styles={{ height: '100%' }} />}>
              <MdEditor
                ref={mdEditor}
                id="md-editor"
                style={{ height: '200px' }}
                value={content}
                renderHTML={text => mdParser.render(text)}
                onChange={handleContentEditorChange}
              />
            </Suspense>
          </Space>

          {props.isCreate && (
            <>
              <Form.Item
                label="Company"
                name="company"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please select company.',
                  },
                ]}
              >
                <SearchCompany
                  handleSelect={option => setCompany(option)}
                  searchValue={companyName}
                  prefix={true}
                  bordered={true}
                  type="company"
                  placeholder="Add company"
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                label="Image"
                name="imageurl"
                rules={[
                  {
                    required: true,
                    message: 'Please upload image.',
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept={imageTypes}
                >
                  {imageurl && !loadingImg ? (
                    <img src={imageurl} alt="avatar" style={{ height: '100%', width: '100%' }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </>
          )}

          <Form.Item label="Published Date" name="publishedDate">
            <DatePicker
              name="publishedDate"
              // defaultValue={publishedDate}
              inputReadOnly={true}
              showTime={{ defaultValue: publishedDate }}
              format={'YYYY-MM-DD HH:mm:ss'}
              style={{ width: '100%', height: '45px' }}
              onChange={(date, dateString) => {
                setPublishedDate(dayjs(dateString));
              }}
            />
          </Form.Item>

          <Form.Item label="Featured" name="featured" valuePropName="checked">
            <Checkbox
              style={{ marginTop: '5px' }}
              checked={featured}
              onChange={e => setFeatured(e.target.checked)}
            ></Checkbox>
          </Form.Item>

          <Form.Item label="Market Pulse category" name="marketpulse">
            <Select
              style={{ width: '100%' }}
              name="marketpulse"
              placeholder="Please Select  Market Pulse"
              value={marketpulse}
              onChange={value => setMarketpulse(value)}
              defaultValue={marketpulse[0]?.slug}
            >
              {lists.map((item, index) => {
                return (
                  <Option key={index} value={item.slug}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>

        <Space style={{ float: 'right' }}>
          {Object.keys(curateResult).length > 0 && (
            <Button type="primary" onClick={deleteArticle} loading={isArticleDeleting}>
              Delete
            </Button>
          )}
          <Button type="primary" onClick={updateArticle} loading={isArticleUpdating}>
            Save
          </Button>
        </Space>
      </Space>
      <Divider />
    </Drawer>
  );
};

export default UpdateArticle;
