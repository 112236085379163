import { API, graphqlOperation } from '@aws-amplify/api';
import {
  byAccountMember,
  teamsByAccount,
  teamsByAccountAndMember,
  teamsByAccountAndMemberDetail,
  createReferral as createReferralGQL,
  updateReferral as updateReferralGQL,
  accountReferrels,
  getAccount as getAccountGQL,
  updateAccount as updateAccountGQL,
  accountsByReferrer,
} from 'graphql/customQueries';
import { accountsByCreator } from 'graphql/queries';
import {
  addAccount as addAccountGQL,
  addTeam,
  editAccount as editAccountGQL,
  editTeam as editTeamGQL,
  removeTeam as removeTeamGQL,
} from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';

const getAccountReferrals = async accountID => {
  try {
    let referrals = await API.graphql(graphqlOperation(accountReferrels, { accountID }));
    if (referrals.data.referralsByAccount) {
      return referrals.data.referralsByAccount;
    }
    return referrals;
  } catch (e) {
    console.log('errors', e);
    if (e.data.referralsByAccount) {
      return e.data.referralsByAccount;
    }
    return { errors: ['Failed to fetch referrals'] };
  }
};

const updateReferralCode = async updateInput => {
  try {
    let updatedReferral = await API.graphql(
      graphqlOperation(updateReferralGQL, {
        input: updateInput,
      })
    );

    if (updatedReferral.errors) {
      return updatedReferral;
    }
    return updatedReferral.data.updateReferral;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed updating referral'] };
  }
};

const createReferralCode = async (code, referralType, description, accountID, active) => {
  try {
    let newReferral = await API.graphql(
      graphqlOperation(createReferralGQL, {
        input: {
          id: uuidv4(),
          code,
          referralType,
          description,
          accountID,
          active,
          uses: 0,
        },
      })
    );

    if (newReferral.errors) {
      return newReferral;
    }
    return newReferral.data.createReferral;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed creating referral'] };
  }
};

const editTeam = async editTeamInput => {
  try {
    let updatedTeam = await API.graphql(
      graphqlOperation(editTeamGQL, {
        input: editTeamInput,
      })
    );

    if (updatedTeam.errors) {
      return updatedTeam;
    }
    return updatedTeam.data.editTeam;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed updating team'] };
  }
};

const getTeamsByMember = async (email, accountID) => {
  try {
    let teams = await API.graphql(graphqlOperation(teamsByAccountAndMember, { email, accountID }));
    if (teams.errors) {
      return teams;
    }
    return teams.data.byTeamMember;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch teams'] };
  }
};

const getTeamsDetailByMember = async (email, accountID) => {
  try {
    let teams = await API.graphql(graphqlOperation(teamsByAccountAndMemberDetail, { email, accountID }));
    if (teams.data.byTeamMember) {
      return teams.data.byTeamMember;
    }
    return teams;
  } catch (e) {
    console.log('errors', e);
    if (e.data.byTeamMember) {
      return e.data.byTeamMember;
    }
    return { errors: ['Failed to fetch teams'] };
  }
};

const getTeams = async accountID => {
  try {
    let account = await API.graphql(graphqlOperation(teamsByAccount, { accountID }));
    if (account.errors) {
      return account;
    }
    return account.data.teamsByAccount;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch teams'] };
  }
};

const createTeam = async (accountID, name, description, members = []) => {
  try {
    let newTeam = await API.graphql(
      graphqlOperation(addTeam, {
        input: {
          accountID,
          name,
          description,
          members,
        },
      })
    );

    if (newTeam.errors) {
      return newTeam;
    }
    return newTeam.data.addTeam;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed creating team'] };
  }
};

const removeTeam = async (teamID, accountID) => {
  try {
    let teamDel = await API.graphql(
      graphqlOperation(removeTeamGQL, {
        input: { id: teamID, accountID: accountID },
      })
    );

    if (teamDel.errors) {
      return teamDel;
    }
    return teamDel.data.removeTeam;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed deleting team'] };
  }
};

const getAccount = async id => {
  try {
    let account = await API.graphql(graphqlOperation(getAccountGQL, { id: id }));
    if (account.errors) {
      return account;
    }
    return account.data.getAccount;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch account'] };
  }
};

const getOrgAccounts = async email => {
  try {
    let nextToken = null;
    let accounts = { items: [] };
    do {
      let result = await API.graphql(graphqlOperation(byAccountMember, { email: email, nextToken: nextToken }));
      if (result.errors) {
        return result;
      }
      accounts.items = accounts.items.concat(result.data.byAccountMember.items);
      nextToken = result.data.byAccountMember.nextToken;
    } while (nextToken);
    return accounts;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch org accounts'] };
  }
};

const getPersonalAccount = async creator => {
  try {
    let account = await API.graphql(graphqlOperation(accountsByCreator, { creator, accountType: { eq: 'PERSONAL' } }));
    if (account.errors) {
      return account;
    }
    return account.data.accountsByCreator;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch personal account'] };
  }
};

// THIS IS DIFFERENT THAN updateAccount; this function calls
// a custom lambda to add/remove account members
const editAccount = async editAccountInput => {
  try {
    let updatedAccount = await API.graphql(
      graphqlOperation(editAccountGQL, {
        input: editAccountInput,
      })
    );

    if (updatedAccount.errors) {
      return updatedAccount;
    }
    return updatedAccount.data.editAccount;
  } catch (e) {
    console.log(e);
    if (e.errors && e.errors.length > 0) {
      return { errors: [e.errors[0].message] };
    }
    return { errors: ['Failed updating account'] };
  }
};

// THIS IS DIFFERENT THAN editAccount; this function ONLY
// updates the object in AppSync, it will not update AccountMembers
const updateAccount = async updateAccountInput => {
  try {
    let updatedAccount = await API.graphql(
      graphqlOperation(updateAccountGQL, {
        input: updateAccountInput,
      })
    );

    if (updatedAccount.errors) {
      return updatedAccount;
    }
    return updatedAccount.data.updateAccount;
  } catch (e) {
    console.log(e);
    if (e.errors && e.errors.length > 0) {
      return { errors: [e.errors[0].message] };
    }
    return { errors: ['Failed updating account'] };
  }
};

// only delete on stripe failure
// const removeAccount = async accountID => {};

const addAccount = async ({ name, description, members, owners, planID }) => {
  try {
    let cleanMembers = members ? members.map(m => m.toLowerCase()) : [];
    let cleanOwners = owners ? owners.map(m => m.toLowerCase()) : [];

    let newAccount = await API.graphql(
      graphqlOperation(addAccountGQL, {
        input: {
          name: name || 'New Account',
          description: description || '',
          members: cleanMembers,
          owners: cleanOwners,
          planID,
        },
      })
    );

    if (newAccount.errors) {
      return newAccount;
    }
    return newAccount.data.addAccount;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed creating account'] };
  }
};

// Save user tours
const updateTours = async tours => {
  const path = '/api/account/tours';
  try {
    var result = await API.put('fuelapi', path, { body: tours });
    return result;
  } catch (e) {
    console.log(e);
    return { error: 'Failed updating tours' };
  }
};

// Fetch user tours from dynamo
const getTours = async () => {
  const path = '/api/account/tours';
  try {
    var tours = await API.get('fuelapi', path);
    return tours;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to retrieve user tours.' };
  }
};

const getAvatarUrls = async emails => {
  const path = `/api/account/avatar?emails=${emails.join(',')}`;
  try {
    var url = await API.get('fuelapi', path);
    return url;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to retrieve the User Avatar.' };
  }
};
const getUsersByIDs = async ids => {
  const path = `/api/account/avatar?ids=${ids.join(',')}`;
  try {
    var url = await API.get('fuelapi', path);
    return url;
  } catch (e) {
    console.log(e);
    return { error: 'Failed to retrieve the User Avatar.' };
  }
};

const getReferralByCode = async code => {
  let url = `/api-public/referrals/${code}`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving referral', e);
    return { error: 'Failed to fetch referral' };
  }
};

// TODO
const endAccountTrial = async accountID => {
  let url = `/api/accounts/${accountID}/endTrial`;

  try {
    let result = await API.put('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error ending trial', e);
    return { error: 'Failed to end trial' };
  }
};

const getAccountsReferred = async (accountId, referralId) => {
  let url = `/api/accounts/${accountId}/referrals/${referralId}/accounts`;

  try {
    let result = await API.get('fuelapi', url);
    return result;
  } catch (e) {
    console.log('error retrieving referral', e);
    return { error: 'Failed to fetch referral' };
  }
};
const updateAccountUserSeats = async (accountId, userSeats) => {
  let url = `/api/accounts/${accountId}/editUserSeats`;

  try {
    let result = await API.post('fuelapi', url, { body: { user_seats: userSeats } });
    return result;
  } catch (e) {
    console.log('error updating account userSeats', e);
    return { error: 'Failed to update account userSeats' };
  }
};

const getAccountsByReferrer = async referralId => {
  let nextToken = null;
  let list = [];

  try {
    do {
      let response = await API.graphql(graphqlOperation(accountsByReferrer, { referralID: referralId, nextToken }));
      if (response.data.accountsByReferrer) {
        list = list.concat(response.data.accountsByReferrer.items);
      }
      nextToken = response.data.accountsByReferrer.nextToken;
    } while (nextToken);

    return list;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to fetch referredAccounts'] };
  }
};

const sdk = {
  getAvatarUrls,
  getTours,
  updateTours,
  getPersonalAccount,
  addAccount,
  getOrgAccounts,
  getAccount,
  getTeams,
  createTeam,
  editAccount,
  updateAccount,
  editTeam,
  getTeamsByMember,
  getTeamsDetailByMember,
  removeTeam,
  createReferralCode,
  updateReferralCode,
  getAccountReferrals,
  getReferralByCode,
  getAccountsReferred,
  getUsersByIDs,
  getAccountsByReferrer,
  endAccountTrial,
  updateAccountUserSeats,
};
export default sdk;
