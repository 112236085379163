import React from 'react';
import ErrorBoundary from 'ErrorBoundary';
import Skeleton from 'components/utility/Skeleton';
import './Stat.css';

const Placeholder = ({ height = '150px' }) => {
  return <Skeleton style={{ width: '100%', height: height }} />;
};

const Stat = ({ label, children, extra, demo = false, height, style, className = '' }) => {
  return (
    <div className={`stat-section ${className}`} style={style}>
      <div className="stat-header">
        <div>{label}</div>
        {extra && <div>{extra}</div>}
      </div>
      <ErrorBoundary justText={true}>
        {demo && <Placeholder height={height} />}
        {!demo && children}
      </ErrorBoundary>
    </div>
  );
};

const GridStats = ({ stats = [], showZero = false, keyPrefix }) => {
  return (
    <div className="grid-stats">
      {stats
        .filter(s => showZero || !!s.value)
        .map((stat, i) => (
          <div key={`gridstat-${keyPrefix || stat.label}-${i}`} className="grid-stat">
            <div className="grid-stat-label">{stat.label}</div>
            <div className="grid-stat-value">{stat.value}</div>
            <div className="grid-stat-extra">{stat.extra}</div>
          </div>
        ))}
    </div>
  );
};

export default Stat;
export { GridStats };
