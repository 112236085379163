import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Tooltip, message } from 'antd';
import { PlusOutlined, CloseOutlined, LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import NewList from 'pages/watchlists/New';
import sdk from 'sdk/WatchlistsList';
import listSdk from 'sdk/Watchlists';
import usePersonalAccount from 'store/PersonalAccount';
import { shallow } from 'zustand/shallow';
import useAccounts from 'store/Accounts';
import './WatchlistSelection.css';

const { Option, OptGroup } = Select;

const List = ({ list, onRemoveFromList }) => {
  const [deleting, setDeleting] = useState(false);
  const history = useHistory();

  const onDelete = () => {
    setDeleting(true);
    onRemoveFromList(list.id);
  };

  return (
    <div key={`existing-list-tag-${list.id}`} className="list-tag">
      <Tooltip title={list.name}>
        <span className="list-tag-text" onClick={() => history.push(`/research/watchlists/${list?.id}`)}>
          {list.name}
        </span>
      </Tooltip>
      <div className="remove-list" onClick={() => onDelete()}>
        {deleting ? <LoadingOutlined /> : <CloseOutlined />}
      </div>
    </div>
  );
};

const AddCreate = ({
  accounts,
  companyId,
  companyName,
  selected,
  onAddToList,
  onRemoveFromList,
  loading,
  center,
  setOpenWatchlistSelection,
}) => {
  const [active, setActive] = useState(false);
  const [base] = useState(accounts);
  const [showNew, setShowNew] = useState(false);
  const [name, setName] = useState('');
  const [filtered, setFiltered] = useState(accounts);

  const onSearch = value => {
    let srch = value.toLowerCase();
    let updated = { ...base };
    for (const acct of Object.keys(updated)) {
      updated[acct] = updated[acct].filter(l => l.name.toLowerCase().includes(srch));
    }
    setName(value);
    setFiltered(updated);
  };

  const onSelect = (value, opt) => {
    if (opt.value) {
      if (selected.includes(value)) {
        // remove company from watchlist
        onRemoveFromList(value);
      } else {
        // add company to watchlist
        onAddToList(value);
      }
    } else {
      // create new list
      setName(value);
      setShowNew(true);
      setActive(false);
      setOpenWatchlistSelection(false);
    }
  };

  return (
    <div>
      <div
        className={`list-tag-new ${center ? 'centered' : ''} ${active ? 'active' : ''}`}
        onClick={() => setActive(true)}
      >
        {!active && (
          <>
            <PlusOutlined /> <span>Add</span>
          </>
        )}
        {active && (
          <Select
            showSearch
            loading={loading}
            mode="tags"
            bordered={false}
            filterOption={false}
            placeholder={<>{loading ? <LoadingOutlined /> : null} Search or create Watchlist...</>}
            onSelect={onSelect}
            onSearch={onSearch}
            autoFocus={true}
            notFoundContent={null}
            value={[]}
            style={{ width: '100%' }}
            onBlur={() => {
              setActive(false);
              setFiltered(base);
            }}
          >
            {filtered &&
              Object.keys(filtered).map(acct => (
                <OptGroup key={`opt-group-${acct}`} label={acct}>
                  {filtered[acct].map((a, i) => (
                    <Option
                      key={`opt-${a.id}-${i}`}
                      value={a.id}
                      style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
                    >
                      {selected.includes(a.id) ? (
                        <CheckOutlined
                          style={{
                            position: 'absolute',
                            left: '5px',
                            top: '8px',
                            color: 'var(--brand)',
                            fontSize: '15px',
                          }}
                        />
                      ) : null}
                      {a.name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
          </Select>
        )}
      </div>
      <NewList show={showNew} setShow={setShowNew} name={name} companies={[{ uuid: companyId, name: companyName }]} />
    </div>
  );
};

const WatchlistSelection = ({
  setOpenWatchlistSelection,
  companyId,
  companyName,
  hideLists = false,
  center = false,
  setAccounts = () => {},
}) => {
  const [byAccount, setByAccount] = useState({});
  const [withCompany, setWithCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const { cognito, groups, account: personalAccount } = usePersonalAccount(
    state => ({
      cognito: state.cognito,
      groups: state.groups,
      account: state.account,
    }),
    shallow
  );
  const activeAccount = useAccounts(state => state.activeAccount);

  const fetchLists = async owner => {
    setLoading(true);
    let result = await sdk.watchlistsBySettingsAll(owner);
    let ics = {};
    let accounts = {};
    for (const list of result) {
      if (list.items.map(l => l.uuid).includes(companyId)) {
        ics[list.id] = { ...list };
      }

      let acctName = 'Personal';
      if (list.accountID && list?.account?.name) {
        acctName = list.account.name;
      }
      if (!accounts?.[acctName]) {
        accounts[acctName] = [];
      }
      accounts[acctName].push({ ...list });
    }

    setAccounts(accounts);
    setByAccount(accounts);
    setWithCompany(ics);
    setLoading(false);
  };

  const addToWatchlist = async listId => {
    setUpdating(true);

    // fetch list (for latest items)
    let result = await listSdk.getList(listId);
    if (result.error || !result.items) {
      message.error('Failed to update Watchlist');
      setUpdating(false);
      return;
    }

    //limit check
    const limitExceeded = listSdk.checkCompaniesPerWatchlistLimit(
      activeAccount,
      groups,
      personalAccount,
      result.items.length + 1
    );
    if (limitExceeded) {
      setUpdating(false);
      return;
    }

    // add to list items and save
    result.items.push({ uuid: companyId, name: companyName, added: new Date().toISOString() });
    let updated = await listSdk.updateListItems(listId, result.items);
    if (updated.error) {
      message.error('Failed to update Watchlist');
      setUpdating(false);
      return;
    }

    // update local state on success
    let updatedWithCompany = { ...withCompany };
    for (const acct of Object.keys(byAccount)) {
      for (const list of byAccount[acct]) {
        if (list.id === listId) {
          updatedWithCompany[listId] = { ...list };
        }
      }
    }
    setWithCompany(updatedWithCompany);
    setUpdating(false);
  };

  const removeFromWatchlist = async listId => {
    setUpdating(true);

    // fetch list (for latest items)
    let result = await listSdk.getList(listId);
    if (result.error || !result.items) {
      message.error('Failed to update Watchlist');
      setUpdating(false);
      return;
    }

    // remove from list items and save
    result.items = result.items.filter(i => i.uuid !== companyId);
    let updated = await listSdk.updateListItems(listId, result.items);
    if (updated.error) {
      message.error('Failed to update Watchlist');
      setUpdating(false);
      return;
    }

    // update local state on success
    let updatedWithCompany = { ...withCompany };
    delete updatedWithCompany[listId];
    setWithCompany(updatedWithCompany);
    setUpdating(false);
  };

  useEffect(() => {
    if (cognito?.attributes?.email) {
      fetchLists(cognito?.attributes?.email);
    }
    // eslint-disable-next-line
  }, [cognito?.attributes?.email]);

  return (
    <div className="list-tags-wrapper">
      {loading && (
        <div style={{ height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingOutlined />
        </div>
      )}
      {!loading && withCompany && !hideLists && (
        <div className="list-tags">
          {Object.keys(withCompany).map(l => (
            <List key={`tag-list-${withCompany[l].id}`} list={withCompany[l]} onRemoveFromList={removeFromWatchlist} />
          ))}
        </div>
      )}
      {!loading && (
        <AddCreate
          accounts={byAccount}
          selected={Object.keys(withCompany)}
          companyId={companyId}
          companyName={companyName}
          onAddToList={addToWatchlist}
          onRemoveFromList={removeFromWatchlist}
          loading={updating}
          center={center}
          setOpenWatchlistSelection={setOpenWatchlistSelection}
        />
      )}
    </div>
  );
};

export default WatchlistSelection;
export { AddCreate };
