import React, { useMemo, useState } from 'react';
import { Form, Input, Row, Col, Alert, Button } from 'antd';
import Auth from '@aws-amplify/auth';

const PasswordReset = ({ changeState, feedback, username }) => {
  const [msg, setMsg] = useState(feedback);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const initialConfirmationCode = useMemo(() => {
    return new URL(window.location.href).searchParams.get('confirmationcode') || '';
  }, []);

  const resendConfirmationCode = async () => {
    setLoading(true);
    setErr('');
    try {
      await Auth.forgotPassword(username.toLocaleLowerCase().trim());
      setMsg('A new confirmation code has been sent.');
      setLoading(false);
    } catch (error) {
      console.log('error resetting password:', error);
      setErr(error.message);
      setLoading(false);
    }
  };

  const confirmReset = async e => {
    setLoading(true);
    setErr('');
    try {
      await Auth.forgotPasswordSubmit(username.toLocaleLowerCase().trim(), e.code, e.password);
      setLoading(false);
      changeState('signIn', 'Sign in with new password', '');
    } catch (error) {
      console.log('error resetting password:', error);
      setErr(error.message);
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={24} md={24} lg={16} xl={16} xxl={9} style={{ maxWidth: '600px' }}>
        <h4 className="mute-title" style={{ marginBottom: '30px' }}>
          Reset your password
        </h4>

        {msg && <Alert style={{ marginBottom: '10px' }} type="info" showIcon={false} message={msg} banner />}
        {err && <Alert style={{ marginBottom: '10px' }} type="error" showIcon={false} message={err} banner />}

        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="normal_reset"
          className="reset-form"
          onFinish={e => confirmReset(e)}
        >
          <Form.Item
            labelCol={{ span: 24 }}
            label="Code"
            name="code"
            initialValue={initialConfirmationCode}
            rules={[{ required: true, message: 'Please input your Confirmation Code!' }]}
          >
            <Input
              autoFocus
              className="xl"
              size="large"
              id="code"
              key="code"
              name="code"
              placeholder="Enter your Confirmation Code"
              disabled={loading}
              tabIndex={1}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (!value?.trim()) {
                    return callback('Please input your Password!');
                  }
                  if (value !== confirmPassword) {
                    return callback('Password should be same as confirm password!');
                  }
                  callback();
                },
              },
            ]}
          >
            <Input.Password
              className="xl"
              onChange={event => setPassword(event.target.value)}
              size="large"
              id="password"
              key="password"
              name="password"
              placeholder="Password"
              disabled={loading}
              tabIndex={2}
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (!value?.trim()) {
                    return callback('Confirm Password is required!');
                  }
                  if (value !== password) {
                    return callback('Confirm Password should be same as password!');
                  }
                  callback();
                },
              },
            ]}
          >
            <Input.Password
              className="xl"
              size="large"
              id="password"
              key="password"
              onChange={event => setConfirmPassword(event.target.value)}
              name="password"
              placeholder="Confirm Password"
              disabled={loading}
              tabIndex={2}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
                <Button
                  type="link"
                  size="large"
                  block
                  onClick={resendConfirmationCode}
                  disabled={loading}
                  tabIndex={6}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="fuel.user.resetPasswordResend"
                >
                  Resend Code
                </Button>
              </Col>
              <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={loading}
                  tabIndex={5}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="fuel.user.resetPasswordComplete"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default PasswordReset;
