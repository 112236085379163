import React from 'react';
import { Tooltip, Space } from 'antd';
import Icon, {
  MediumSquareFilled,
  GithubOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
} from '@ant-design/icons';
import { socialColors } from 'data/social';
import TwitterLogo from 'images/twitter_bg_gray_color_black.svg';

const TwitterIcon = () => {
  return <img src={TwitterLogo} alt="X (formerly Twitter)" style={{ width: '12px' }} />;
};

const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const socials = {
  twitter: {
    followers: 'Followers',
    icon: (c = '#1DA1F2') => <Icon component={TwitterIcon} style={{ color: c }} />,
  },
  medium: {
    followers: 'Followers',
    icon: (c = '#000') => <MediumSquareFilled style={{ color: c }} />,
  },
  facebook: {
    followers: 'Followers',
    icon: (c = '#4267B2') => <FacebookFilled style={{ color: c }} />,
  },
  github: {
    followers: 'Followers',
    icon: (c = '#000') => <GithubOutlined style={{ color: c }} />,
  },
  instagram: {
    followers: 'Followers',
    icon: (c = '#cc2366') => <InstagramFilled style={{ color: c }} />,
  },
  linkedin: {
    followers: 'Followers',
    icon: (c = '#2867B2') => <LinkedinFilled style={{ color: c }} />,
  },
};

const socialFormat = social => {
  let format = socials[social] || { followers: 'Followers' };
  return format;
};

const SocialPercentageStacked = ({ socials, showTotal = true }) => {
  const max = Math.max(...socials.map(s => s.followers_count || 0));

  const scale = val => {
    if (!val || val <= 1) return 0; // log is undefined for 0, log(1) = 0
    return (100 * Math.log(val)) / Math.log(max);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="social-parts" style={{ display: 'grid', gridAutoRows: '25px', gap: '3px' }}>
        {socials.map((social, ai) => {
          let p = scale(social.followers_count);
          const socialText = socialFormat(social.social);
          return (
            <Tooltip
              key={`social-part-${ai}`}
              title={`${capitalize(social.social.replaceTwitterWithX())} - ${Number(
                social?.followers_count || 0
              ).toLocaleString()}`}
            >
              <div
                className="social-part"
                style={{
                  backgroundColor: socialColors[social.social],
                  height: '100%',
                  float: 'left',
                  borderRadius: '2px',
                  opacity: '0.9',
                  padding: '0px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: `${p}%`,
                }}
              >
                {socialText.icon('rgba(255,255,255,.7)')}
              </div>
            </Tooltip>
          );
        })}
      </div>
    </Space>
  );
};

const SocialPercentage = ({ accounts, showTotal = true, max = null, showValue = false }) => {
  const filtered = accounts.filter(a => a.hasOwnProperty('followers_count'));
  const total = max ? max : filtered.reduce((a, c) => a + c.followers_count, 0);
  const gridTemplateColumns = filtered
    .map(i => {
      let p = (i.followers_count / total) * 100;
      return `minmax(2px, calc(${p}%))`;
    })
    .join(' ');
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {showTotal && (
        <div>
          {total <= 0 ? (
            <Tooltip title="Unknown social following">
              <div>
                <span style={{ pointerEvents: 'none' }}>--</span>
              </div>
            </Tooltip>
          ) : (
            <>
              <strong>{total.toLocaleString()}</strong> <i>Total Followers</i>
            </>
          )}
        </div>
      )}
      {total > 0 && (
        <div className="social-parts" style={{ height: '20px', display: 'grid', gridTemplateColumns, gap: '2px' }}>
          {filtered.map((account, ai) => {
            let p = (account.followers_count / total) * 100;
            const socialText = socialFormat(account.social);
            return (
              <Tooltip
                key={`social-part-${ai}`}
                title={`${capitalize(account.social)} - ${
                  showValue ? Number(account.followers_count).toLocaleString() : p.toFixed(1) + '%'
                }`}
              >
                <div
                  className="social-part"
                  style={{
                    backgroundColor: socialColors[account.social],
                    height: '100%',
                    float: 'left',
                    borderRadius: '2px',
                    opacity: '0.9',
                    padding: p > 5 ? '0 4px' : '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {socialText.icon('rgba(255,255,255,.7)')}
                </div>
              </Tooltip>
            );
          })}
        </div>
      )}
    </Space>
  );
};

export { SocialPercentage, SocialPercentageStacked };
