/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFeaturedCourseMaterial = /* GraphQL */ `
  query GetFeaturedCourseMaterial($id: ID!, $courseId: ID!, $materialType: String!) {
    getFeaturedCourseMaterial(id: $id, courseId: $courseId, materialType: $materialType)
  }
`;
export const getCourseCategory = /* GraphQL */ `
  query GetCourseCategory($id: ID!) {
    getCourseCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCourseCategorys = /* GraphQL */ `
  query ListCourseCategorys($filter: ModelCourseCategoryFilterInput, $limit: Int, $nextToken: String) {
    listCourseCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseCategoryConnection = /* GraphQL */ `
  query GetCourseCategoryConnection($id: ID!) {
    getCourseCategoryConnection(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listCourseCategoryConnections = /* GraphQL */ `
  query ListCourseCategoryConnections(
    $filter: ModelCourseCategoryConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseCategoryConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChannelInvites = /* GraphQL */ `
  query GetChannelInvites($id: ID!) {
    getChannelInvites(id: $id) {
      id
      owner
      channelID
      inviteID
      quebicUserID
      lastReadTime
      createdAt
      updatedAt
    }
  }
`;
export const listChannelInvitess = /* GraphQL */ `
  query ListChannelInvitess($filter: ModelChannelInvitesFilterInput, $limit: Int, $nextToken: String) {
    listChannelInvitess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        channelID
        inviteID
        quebicUserID
        lastReadTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const channelsByUser = /* GraphQL */ `
  query ChannelsByUser(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelChannelInvitesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    channelsByUser(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        channelID
        inviteID
        quebicUserID
        lastReadTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const channelMembers = /* GraphQL */ `
  query ChannelMembers(
    $channelID: String
    $sortDirection: ModelSortDirection
    $filter: ModelChannelInvitesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    channelMembers(
      channelID: $channelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        channelID
        inviteID
        quebicUserID
        lastReadTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPreferences = /* GraphQL */ `
  query GetUserPreferences($id: ID!) {
    getUserPreferences(id: $id) {
      id
      owner
      key
      value
      details
      createdAt
      updatedAt
    }
  }
`;
export const listUserPreferencess = /* GraphQL */ `
  query ListUserPreferencess($filter: ModelUserPreferencesFilterInput, $limit: Int, $nextToken: String) {
    listUserPreferencess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        key
        value
        details
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preferencesByUser = /* GraphQL */ `
  query PreferencesByUser(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByUser(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        key
        value
        details
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preferencesByKey = /* GraphQL */ `
  query PreferencesByKey(
    $key: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByKey(key: $key, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        key
        value
        details
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      planType
      createdAt
      updatedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans($filter: ModelPlanFilterInput, $limit: Int, $nextToken: String) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const accountsByCreator = /* GraphQL */ `
  query AccountsByCreator(
    $creator: String
    $accountType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByCreator(
      creator: $creator
      accountType: $accountType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const accountsByStripeCustomer = /* GraphQL */ `
  query AccountsByStripeCustomer(
    $stripeCustomerID: String
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByStripeCustomer(
      stripeCustomerID: $stripeCustomerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const accountsByReferrer = /* GraphQL */ `
  query AccountsByReferrer(
    $referralID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByReferrer(
      referralID: $referralID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      creator
      owners
      members
      name
      description
      user_seats
      logo
      limits
      referralID
      trialStart
      trialEnd
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      accountType
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      referral {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts($filter: ModelAccountFilterInput, $limit: Int, $nextToken: String) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReferral = /* GraphQL */ `
  query GetReferral($id: ID!) {
    getReferral(id: $id) {
      id
      referralType
      code
      description
      active
      accountID
      planID
      uses
      createdAt
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        planType
        createdAt
        updatedAt
      }
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReferrals = /* GraphQL */ `
  query ListReferrals($filter: ModelReferralFilterInput, $limit: Int, $nextToken: String) {
    listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
      nextToken
    }
  }
`;
export const referralsByAccount = /* GraphQL */ `
  query ReferralsByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    referralsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
      nextToken
    }
  }
`;
export const referralByCode = /* GraphQL */ `
  query ReferralByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    referralByCode(code: $code, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        referralType
        code
        description
        active
        accountID
        planID
        uses
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
      nextToken
    }
  }
`;
export const getAccountMember = /* GraphQL */ `
  query GetAccountMember($id: ID!) {
    getAccountMember(id: $id) {
      id
      email
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAccountMembers = /* GraphQL */ `
  query ListAccountMembers($filter: ModelAccountMemberFilterInput, $limit: Int, $nextToken: String) {
    listAccountMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
      nextToken
    }
  }
`;
export const byAccountMember = /* GraphQL */ `
  query ByAccountMember(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelAccountMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccountMember(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
      nextToken
    }
  }
`;
export const byAccount = /* GraphQL */ `
  query ByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAccountMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      members
      name
      description
      accountID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teamMembers {
        items {
          id
          email
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams($filter: ModelTeamFilterInput, $limit: Int, $nextToken: String) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const teamsByAccount = /* GraphQL */ `
  query TeamsByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      email
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers($filter: ModelTeamMemberFilterInput, $limit: Int, $nextToken: String) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        accountID
        teamID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        team {
          id
          members
          name
          description
          accountID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const byTeamMember = /* GraphQL */ `
  query ByTeamMember(
    $email: String
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTeamMember(
      email: $email
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        accountID
        teamID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        team {
          id
          members
          name
          description
          accountID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const byTeam = /* GraphQL */ `
  query ByTeam(
    $teamID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTeam(
      teamID: $teamID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        accountID
        teamID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        team {
          id
          members
          name
          description
          accountID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWatchlist = /* GraphQL */ `
  query GetWatchlist($id: ID!) {
    getWatchlist(id: $id) {
      id
      owner
      viewers
      admins
      company
      scope
      name
      description
      items
      accountID
      account_privacy
      columns
      metadata
      datasource
      updatingData
      lastDataUpdate
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      teams {
        items {
          id
          accountID
          teamID
          watchlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          owner
          starred
          email
          follow
          funding_updates
          preferences
          watchlistSettingWatchlistId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listWatchlists = /* GraphQL */ `
  query ListWatchlists($filter: ModelWatchlistFilterInput, $limit: Int, $nextToken: String) {
    listWatchlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByOwner = /* GraphQL */ `
  query WatchlistsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByOwnerAndAccount = /* GraphQL */ `
  query WatchlistsByOwnerAndAccount(
    $owner: String
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByOwnerAndAccount(
      owner: $owner
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByAccount = /* GraphQL */ `
  query WatchlistsByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByAccountPrivacy = /* GraphQL */ `
  query WatchlistsByAccountPrivacy(
    $accountID: ID
    $account_privacy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByAccountPrivacy(
      accountID: $accountID
      account_privacy: $account_privacy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getWatchlistTeam = /* GraphQL */ `
  query GetWatchlistTeam($id: ID!) {
    getWatchlistTeam(id: $id) {
      id
      accountID
      teamID
      watchlistID
      createdAt
      updatedAt
      account {
        id
        creator
        owners
        members
        name
        description
        user_seats
        logo
        limits
        referralID
        trialStart
        trialEnd
        createdAt
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          planType
          createdAt
          updatedAt
        }
        accountType
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        referral {
          id
          referralType
          code
          description
          active
          accountID
          planID
          uses
          createdAt
          updatedAt
        }
      }
      team {
        id
        members
        name
        description
        accountID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teamMembers {
          nextToken
        }
      }
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const listWatchlistTeams = /* GraphQL */ `
  query ListWatchlistTeams($filter: ModelWatchlistTeamFilterInput, $limit: Int, $nextToken: String) {
    listWatchlistTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        teamID
        watchlistID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        team {
          id
          members
          name
          description
          accountID
          createdAt
          updatedAt
        }
        watchlist {
          id
          owner
          viewers
          admins
          company
          scope
          name
          description
          items
          accountID
          account_privacy
          columns
          metadata
          datasource
          updatingData
          lastDataUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const watchlistsByTeam = /* GraphQL */ `
  query WatchlistsByTeam(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistsByTeam(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        teamID
        watchlistID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        team {
          id
          members
          name
          description
          accountID
          createdAt
          updatedAt
        }
        watchlist {
          id
          owner
          viewers
          admins
          company
          scope
          name
          description
          items
          accountID
          account_privacy
          columns
          metadata
          datasource
          updatingData
          lastDataUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const teamsByWatchlist = /* GraphQL */ `
  query TeamsByWatchlist(
    $watchlistID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamsByWatchlist(
      watchlistID: $watchlistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        teamID
        watchlistID
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        team {
          id
          members
          name
          description
          accountID
          createdAt
          updatedAt
        }
        watchlist {
          id
          owner
          viewers
          admins
          company
          scope
          name
          description
          items
          accountID
          account_privacy
          columns
          metadata
          datasource
          updatingData
          lastDataUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWatchlistUser = /* GraphQL */ `
  query GetWatchlistUser($id: ID!) {
    getWatchlistUser(id: $id) {
      id
      owner
      createdAt
      updatedAt
      list {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const listWatchlistUsers = /* GraphQL */ `
  query ListWatchlistUsers($filter: ModelWatchlistUserFilterInput, $limit: Int, $nextToken: String) {
    listWatchlistUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        list {
          id
          owner
          viewers
          admins
          company
          scope
          name
          description
          items
          accountID
          account_privacy
          columns
          metadata
          datasource
          updatingData
          lastDataUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const watchlistSharesByOwner = /* GraphQL */ `
  query WatchlistSharesByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchlistSharesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        list {
          id
          owner
          viewers
          admins
          company
          scope
          name
          description
          items
          accountID
          account_privacy
          columns
          metadata
          datasource
          updatingData
          lastDataUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWatchlistSetting = /* GraphQL */ `
  query GetWatchlistSetting($id: ID!) {
    getWatchlistSetting(id: $id) {
      id
      owner
      starred
      email
      follow
      funding_updates
      preferences
      watchlistSettingWatchlistId
      createdAt
      updatedAt
      watchlist {
        id
        owner
        viewers
        admins
        company
        scope
        name
        description
        items
        accountID
        account_privacy
        columns
        metadata
        datasource
        updatingData
        lastDataUpdate
        createdAt
        updatedAt
        account {
          id
          creator
          owners
          members
          name
          description
          user_seats
          logo
          limits
          referralID
          trialStart
          trialEnd
          createdAt
          updatedAt
          accountType
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
        }
        teams {
          nextToken
        }
        shares {
          nextToken
        }
        settings {
          nextToken
        }
      }
    }
  }
`;
export const listWatchlistSettings = /* GraphQL */ `
  query ListWatchlistSettings($filter: ModelWatchlistSettingFilterInput, $limit: Int, $nextToken: String) {
    listWatchlistSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        starred
        email
        follow
        funding_updates
        preferences
        watchlistSettingWatchlistId
        createdAt
        updatedAt
        watchlist {
          id
          owner
          viewers
          admins
          company
          scope
          name
          description
          items
          accountID
          account_privacy
          columns
          metadata
          datasource
          updatingData
          lastDataUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSettingsByOwner = /* GraphQL */ `
  query ListSettingsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWatchlistSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        starred
        email
        follow
        funding_updates
        preferences
        watchlistSettingWatchlistId
        createdAt
        updatedAt
        watchlist {
          id
          owner
          viewers
          admins
          company
          scope
          name
          description
          items
          accountID
          account_privacy
          columns
          metadata
          datasource
          updatingData
          lastDataUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAdvisoryJourney = /* GraphQL */ `
  query GetAdvisoryJourney($id: ID!) {
    getAdvisoryJourney(id: $id) {
      id
      title
      slug
      subtitle
      order
      description
      playbookSectionTitle
      playbooks
      createdAt
      updatedAt
    }
  }
`;
export const listAdvisoryJourneys = /* GraphQL */ `
  query ListAdvisoryJourneys($filter: ModelAdvisoryJourneyFilterInput, $limit: Int, $nextToken: String) {
    listAdvisoryJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        subtitle
        order
        description
        playbookSectionTitle
        playbooks
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const advisoryJourneyBySlug = /* GraphQL */ `
  query AdvisoryJourneyBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelAdvisoryJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advisoryJourneyBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        subtitle
        order
        description
        playbookSectionTitle
        playbooks
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      category
      featured
      bizAssessmentCategory
      slug
      short_description
      description
      content
      featuredimage
      order
      published
      createdAt
      updatedAt
      attachments {
        items {
          id
          courseId
          title
          description
          filetype
          order
          attachmentType
          fuelTool
          createdAt
          updatedAt
          name
          identityId
        }
        nextToken
      }
      videos {
        items {
          id
          courseId
          title
          description
          duration
          previewimage
          order
          createdAt
          updatedAt
          url
        }
        nextToken
      }
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category
        featured
        bizAssessmentCategory
        slug
        short_description
        description
        content
        featuredimage
        order
        published
        createdAt
        updatedAt
        attachments {
          nextToken
        }
        videos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const courseBySlug = /* GraphQL */ `
  query CourseBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseBySlug(slug: $slug, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category
        featured
        bizAssessmentCategory
        slug
        short_description
        description
        content
        featuredimage
        order
        published
        createdAt
        updatedAt
        attachments {
          nextToken
        }
        videos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const coursesByCategory = /* GraphQL */ `
  query CoursesByCategory(
    $category: COURSE_CATEGORY
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByCategory(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        featured
        bizAssessmentCategory
        slug
        short_description
        description
        content
        featuredimage
        order
        published
        createdAt
        updatedAt
        attachments {
          nextToken
        }
        videos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const coursesByFeatured = /* GraphQL */ `
  query CoursesByFeatured(
    $featured: COURSE_FEATURED_STATE
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByFeatured(
      featured: $featured
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        featured
        bizAssessmentCategory
        slug
        short_description
        description
        content
        featuredimage
        order
        published
        createdAt
        updatedAt
        attachments {
          nextToken
        }
        videos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCourseAttachment = /* GraphQL */ `
  query GetCourseAttachment($id: ID!) {
    getCourseAttachment(id: $id) {
      id
      courseId
      title
      description
      filetype
      order
      attachmentType
      fuelTool
      createdAt
      updatedAt
      name
      identityId
    }
  }
`;
export const listCourseAttachments = /* GraphQL */ `
  query ListCourseAttachments($filter: ModelCourseAttachmentFilterInput, $limit: Int, $nextToken: String) {
    listCourseAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        title
        description
        filetype
        order
        attachmentType
        fuelTool
        createdAt
        updatedAt
        name
        identityId
      }
      nextToken
    }
  }
`;
export const getCourseVideo = /* GraphQL */ `
  query GetCourseVideo($id: ID!) {
    getCourseVideo(id: $id) {
      id
      courseId
      title
      description
      duration
      previewimage
      order
      createdAt
      updatedAt
      url
    }
  }
`;
export const listCourseVideos = /* GraphQL */ `
  query ListCourseVideos($filter: ModelCourseVideoFilterInput, $limit: Int, $nextToken: String) {
    listCourseVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        title
        description
        duration
        previewimage
        order
        createdAt
        updatedAt
        url
      }
      nextToken
    }
  }
`;
export const getGenericJob = /* GraphQL */ `
  query GetGenericJob($id: ID!) {
    getGenericJob(id: $id) {
      id
      owner
      cognitoId
      jobType
      input
      output
      metadata
      content
      configuration
      status
      deleted
      documentID
      createdAt
      updatedAt
    }
  }
`;
export const listGenericJobs = /* GraphQL */ `
  query ListGenericJobs($filter: ModelGenericJobFilterInput, $limit: Int, $nextToken: String) {
    listGenericJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        cognitoId
        jobType
        input
        output
        metadata
        content
        configuration
        status
        deleted
        documentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByOwner = /* GraphQL */ `
  query JobsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelGenericJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        cognitoId
        jobType
        input
        output
        metadata
        content
        configuration
        status
        deleted
        documentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByDocument = /* GraphQL */ `
  query JobsByDocument(
    $documentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGenericJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByDocument(
      documentID: $documentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        cognitoId
        jobType
        input
        output
        metadata
        content
        configuration
        status
        deleted
        documentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSavedSearch = /* GraphQL */ `
  query GetSavedSearch($id: ID!) {
    getSavedSearch(id: $id) {
      id
      jobID
      accountID
      owner
      name
      description
      search_index
      filters
      dashboard_config
      createdAt
      updatedAt
      job {
        id
        owner
        cognitoId
        jobType
        input
        output
        metadata
        content
        configuration
        status
        deleted
        documentID
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSavedSearchs = /* GraphQL */ `
  query ListSavedSearchs($filter: ModelSavedSearchFilterInput, $limit: Int, $nextToken: String) {
    listSavedSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobID
        accountID
        owner
        name
        description
        search_index
        filters
        dashboard_config
        createdAt
        updatedAt
        job {
          id
          owner
          cognitoId
          jobType
          input
          output
          metadata
          content
          configuration
          status
          deleted
          documentID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const savedSearchesByAccount = /* GraphQL */ `
  query SavedSearchesByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSavedSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savedSearchesByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobID
        accountID
        owner
        name
        description
        search_index
        filters
        dashboard_config
        createdAt
        updatedAt
        job {
          id
          owner
          cognitoId
          jobType
          input
          output
          metadata
          content
          configuration
          status
          deleted
          documentID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const savedSearchesByOwner = /* GraphQL */ `
  query SavedSearchesByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelSavedSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savedSearchesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobID
        accountID
        owner
        name
        description
        search_index
        filters
        dashboard_config
        createdAt
        updatedAt
        job {
          id
          owner
          cognitoId
          jobType
          input
          output
          metadata
          content
          configuration
          status
          deleted
          documentID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserThread = /* GraphQL */ `
  query GetUserThread($id: ID!) {
    getUserThread(id: $id) {
      id
      owner
      threadID
      name
      label
      createdAt
      updatedAt
    }
  }
`;
export const listUserThreads = /* GraphQL */ `
  query ListUserThreads($filter: ModelUserThreadFilterInput, $limit: Int, $nextToken: String) {
    listUserThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        threadID
        name
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const threadsByOwner = /* GraphQL */ `
  query ThreadsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threadsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        threadID
        name
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      owner
      partition
      partitionData
      doctype
      name
      input
      data
      content
      configuration
      status
      text
      role
      locked
      companyID
      createdAt
      updatedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments($filter: ModelDocumentFilterInput, $limit: Int, $nextToken: String) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        partition
        partitionData
        doctype
        name
        input
        data
        content
        configuration
        status
        text
        role
        locked
        companyID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const docsByOwner = /* GraphQL */ `
  query DocsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    docsByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        partition
        partitionData
        doctype
        name
        input
        data
        content
        configuration
        status
        text
        role
        locked
        companyID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const docsByOwnerPart = /* GraphQL */ `
  query DocsByOwnerPart(
    $owner: String
    $partition: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    docsByOwnerPart(
      owner: $owner
      partition: $partition
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        partition
        partitionData
        doctype
        name
        input
        data
        content
        configuration
        status
        text
        role
        locked
        companyID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotebookPrompt = /* GraphQL */ `
  query GetNotebookPrompt($id: ID!) {
    getNotebookPrompt(id: $id) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const listNotebookPrompts = /* GraphQL */ `
  query ListNotebookPrompts($filter: ModelNotebookPromptFilterInput, $limit: Int, $nextToken: String) {
    listNotebookPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessAssessmentResult = /* GraphQL */ `
  query GetBusinessAssessmentResult($id: ID!) {
    getBusinessAssessmentResult(id: $id) {
      id
      email
      userData
      result
      questions
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessAssessmentResults = /* GraphQL */ `
  query ListBusinessAssessmentResults(
    $filter: ModelBusinessAssessmentResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessAssessmentResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        userData
        result
        questions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
