import { useEffect } from 'react';

// let intervalId = null;
const Debugger = () => {
  // Selectively send user console logs to lambda for worst-case debugging
  // const log_levels = ['log', 'error', 'warn', 'debug'];

  //   const initLogger = () => {
  //     //  if (console.everything === undefined) {
  //     //    console.everything = [];
  //     //    console.defaultLog = console.log.bind(console);
  //     //    console.log = function() {
  //     //      if (log_levels.includes('log'))
  //     //        console.everything.push({ type: 'log', datetime: Date().toLocaleString(), value: Array.from(arguments) });
  //     //      console.defaultLog.apply(console, arguments);
  //     //    };
  //     //    console.defaultError = console.error.bind(console);
  //     //    console.error = function() {
  //     //      if (log_levels.includes('error'))
  //     //        console.everything.push({ type: 'error', datetime: Date().toLocaleString(), value: Array.from(arguments) });
  //     //      console.defaultError.apply(console, arguments);
  //     //    };
  //     //    console.defaultWarn = console.warn.bind(console);
  //     //    console.warn = function() {
  //     //      if (log_levels.includes('warn'))
  //     //        console.everything.push({ type: 'warn', datetime: Date().toLocaleString(), value: Array.from(arguments) });
  //     //      console.defaultWarn.apply(console, arguments);
  //     //    };
  //     //    console.defaultDebug = console.debug.bind(console);
  //     //    console.debug = function() {
  //     //      if (log_levels.includes('debug'))
  //     //        console.everything.push({ type: 'debug', datetime: Date().toLocaleString(), value: Array.from(arguments) });
  //     //      console.defaultDebug.apply(console, arguments);
  //     //    };
  //     //  }
  //   };

  //   const ship = () => {
  //     //  console.log(`shipping client logs: ${console.everything.length}`);
  //     //  if (console.everything.length > 0) {
  //     //    console.everything.length = 0;
  //     //  }
  //   };

  useEffect(() => {
    //  initLogger();
    //  intervalId = setInterval(() => {
    //    ship();
    //  }, 1000 * 60);
    //  return () => {
    //    clearInterval(intervalId);
    //  };
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Debugger;
