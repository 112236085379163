import { create } from 'zustand';
import sdk from 'sdk/Accounts';

const useTours = create((set, get) => ({
  tours: null,
  loading: true,
  initialStoreSetup: async isLoggedIn => {
    if (isLoggedIn && get().tours === null) {
      let result = await sdk.getTours();
      if (result.hasOwnProperty('error')) {
        console.log('tours error', result.error);
      } else {
        get().updateTours(result.item);
      }
    }
  },
  updateTours: tours => {
    set({ tours: tours, loading: false });
  },
  setTourLoading: value => {
    set({ loading: value });
  },
}));

export default useTours;
