import React from 'react';
import { Typography } from 'antd';
import img_empty from 'images/empty.svg';

import './EmptyState.css';

const { Title, Text } = Typography;
const imageBase = process.env.REACT_APP_IMAGE_URL;

const EmptyStateSrc = ({ imageName, title, description, cta }) => {
  return (
    <EmptyState
      image={<img style={{ width: '128px' }} alt={imageName} src={`${imageBase}/${imageName}`} />}
      title={title}
      description={description}
      cta={cta}
      style={{ backgroundColor: '#FFF' }}
    />
  );
};

const EmptyState = ({ image, title, description, cta, style }) => {
  return (
    <div className="empty-state" style={style}>
      {image && image}
      {title && (
        <Title level={5} className="mute-title" type="secondary">
          {title}
        </Title>
      )}
      <Text type="secondary">{description}</Text>

      {cta && cta}
    </div>
  );
};

const EmptyData = ({ text = 'No data available!' }) => {
  return (
    <div className="empty-data-wrapper">
      <div className="empty-img">
        <img src={img_empty} alt="No Data" />
      </div>
      <div className="empty-text">{text}</div>
    </div>
  );
};

export default EmptyState;
export { EmptyStateSrc, EmptyData };
