import { create } from 'zustand';
import sdk from 'sdk/Accounts';
import lists from 'sdk/Watchlists';
import prefs from 'sdk/Preferences';
import usePersonalAccount from './PersonalAccount';
import sdk2 from 'sdk/WatchlistsList';

const updatePreference = usePersonalAccount.getState().updatePreference;
const PREFERENCES = usePersonalAccount.getState().PREFERENCES;
const useAccounts = create((set, get) => ({
  loading: true,
  accounts: [],
  teams: [],
  teamObjects: [],
  teamsLoading: true,
  err: null,
  activeAccount: null,
  isCreator: false,
  isOwner: false,
  loadingData: true,
  watchlists: [],
  userEmail: null,
  preferences: null,
  savedSearchPrefAccountId: null,
  setSavedSearchPrefAccountId: acctId => set({ savedSearchPrefAccountId: acctId }),
  initialStoreSetup: userEmail => {
    if (userEmail) {
      set({ userEmail: userEmail });
      get().fetchAccounts(userEmail);
    } else {
      set({ accounts: [] });
    }
  },
  fetchAccounts: async email => {
    set({ loading: true });
    let accts = [];
    let result = await sdk.getOrgAccounts(email);
    let presult = await prefs.getUserPreferences(email);
    let items = presult.items
      ? presult.items.reduce((obj, item) => {
          obj[item.key] = item;
          return obj;
        }, {})
      : {};
    if (!presult.errors) set({ preferences: items });
    if (!result.errors) {
      set({ accounts: result.items });
      accts = result.items;
    }
    set({ err: result.errors });
    let active = items[prefs.PREF_ACTIVE_ACCOUNT];
    let acct = accts.filter(i => i.accountID === active?.value);
    await get()._setActive(acct, accts);
    set({ loading: false });
  },
  fetchAccountTeamsForUser: async acctId => {
    set({ loadingTeams: true });
    let result = await sdk.getTeamsByMember(get().userEmail, acctId);
    if (!result.errors) {
      set({ teams: result?.items?.map(t => t.teamID), teamObjects: result.items?.map(t => t.team) });
    }
    set({ loadingTeams: false });
  },
  fetchWatchlists: async (acctId, accts, ownsAccount) => {
    set({ loadingData: true });
    try {
      let items = {};
      if (get().isCreator) {
        items = await sdk2.watchlistsByAccountAndPersonalAccount(acctId, get().userEmail);
      } else {
        items = await lists.getListsByAccount(acctId);
      }
      if (!items.errors) {
        set({ watchlists: items });
      } else {
        console.log('Failed to fetch Watchlists');
      }
    } catch (e) {
      console.log('Failed to fetch Watchlists');
    }
    set({ loadingData: false });
  },

  _setActive: async (acct, accts) => {
    set({ loading: true });

    // pick one if none is active
    if (!acct) {
      if (accts.length > 0) {
        acct = accts[0];
      }
    }

    let ownsAccount = acct[0].account.owners.includes(get().userEmail);
    const isAccountCreator = acct[0]?.account.creator === get().userEmail;
    set({ activeAccount: acct[0], isOwner: ownsAccount, isCreator: isAccountCreator });
    get().fetchAccountTeamsForUser(acct[0].accountID);

    const currentPagePath = window.location.href.replace(window.location.origin, '');
    if (currentPagePath === '/research/watchlists/updates' || currentPagePath === '/research/watchlists') {
      await get().fetchWatchlists(acct[0].accountID, accts, ownsAccount);
    } else {
      get().fetchWatchlists(acct[0].accountID, accts, ownsAccount);
    }
    set({ loading: false, loadingData: false });
  },
  hasActiveOrgAccount: () => {
    let hasActiveAccount = get().accounts.filter(({ account }) => account?.accountStatus === 'ACTIVE');
    return hasActiveAccount.length > 0;
  },
  hasActiveOrTrialAcct: () => {
    let accts = get().accounts?.filter(
      account => account.account.accountStatus === 'TRIAL' || account.account.accountStatus === 'ACTIVE'
    );
    return accts.length > 0;
  },
  getAccountUpgradeLink: () => {
    const trialAccount = get().accounts?.find(account => account.account.accountStatus === 'TRIAL');
    const freeAccount = get().accounts?.find(account => account.account.accountStatus === 'FREE');
    return trialAccount || freeAccount
      ? `/accounts/${trialAccount?.accountID || freeAccount?.accountID}#billing`
      : null;
  },
  setActiveAccount: id => {
    const accounts = get().accounts;
    let acct = accounts.filter(i => i.accountID === id);
    get()._setActive(acct, accounts);
  },
  reload: async () => {
    await get().fetchAccounts(get().userEmail);
  },
  refreshWatchlists: async () => {
    const activeAccount = get().activeAccount;
    const accounts = get().accounts;
    const isOwner = get().isOwner;
    await get().fetchWatchlists(activeAccount?.accountID || null, accounts, isOwner);
  },
  saveActiveAccount: async id => {
    let currentPref = get().preferences[prefs.PREF_ACTIVE_ACCOUNT] || {
      owner: get().userEmail,
      key: prefs.PREF_ACTIVE_ACCOUNT,
    };
    delete currentPref['createdAt'];
    delete currentPref['updatedAt'];
    currentPref['value'] = id;
    try {
      const value = id ? 'workspace-account' : 'personal-mine';
      const currentPagePath = window.location.href.replace(window.location.origin, '');
      prefs.upsertPreference(currentPref); //no need to wait
      if (currentPagePath === '/research/watchlists/updates') {
        updatePreference(PREFERENCES.PREF_WATCHLIST_PAGE, value); //dashboard page (no need to wait for this api call)
      }
    } catch (e) {
      console.log('failed to update preference', e);
    }
    // update regardless of error so user can still work
    get().setActiveAccount(id);
  },
  accountReferralQualified: async referralId => {
    let email = get().userEmail;
    let accounts = get().accounts.filter(a => a.account.creator === email && a.account.referralID !== referralId);
    if (accounts.length > 0) {
      return accounts[0].account.id;
    }
    return null;
  },
  trialExpired: async () => {
    let trialAccount = get().accounts.find(a => a.account.accountStatus === 'TRIAL');
    if (
      trialAccount &&
      trialAccount.account.accountStatus === 'TRIAL' &&
      new Date().toISOString() > trialAccount.account.trialEnd
    ) {
      // The account is on trial if accountStatus is TRIAL AND
      // the current date is between trialStart and trialEnd
      // update accountStatus to free
      console.log('ending trial...');
      try {
        let result = await sdk.endAccountTrial(trialAccount.account.id);
        if (!result.error) {
          console.log('ended trial', result);
          window.location.reload();
        } else {
          // get().reload();
        }
      } catch (e) {
        console.log('failed to end trial', e);
      }
    }
    return false;
  },
  upgradeNotification: () => {
    let trialAccount = get().accounts.find(a => a.account.accountStatus === 'TRIAL');
    let freeAccount = get().accounts.find(a => a.account.accountStatus === 'FREE');
    let ActiveAccount = get().accounts.find(a => a.account.accountStatus === 'ACTIVE');

    if (!!!ActiveAccount) {
      if (trialAccount) {
        // The active account is on trial if accountStatus is TRIAL AND
        // the current date is between trialStart and trialEnd
        const currentDate = new Date().toISOString().split('T')[0];
        const today = new Date(currentDate);
        const end = new Date(trialAccount.account.trialEnd);

        //difference in milliseconds
        const diff_milliseconds = end - today;

        // difference in days
        const days = diff_milliseconds / (1000 * 60 * 60 * 24);
        const onTrial =
          today.toISOString() >= trialAccount.account.trialStart &&
          today.toISOString() <= trialAccount.account.trialEnd;

        // update account status if trial has expired
        get().trialExpired();
        if (onTrial) {
          return { type: 'UPGRADE_TRIAL_ACCT', data: { days: days < 1 ? 1 : days, account: trialAccount } };
        } else {
          //as Trial has expired, accountStatus will be updated to FREE
          return { type: 'UPGRADE_FREE_ACCT', data: { account: trialAccount } };
        }
      } else if (freeAccount) {
        return { type: 'UPGRADE_FREE_ACCT', data: { account: freeAccount } };
      }
    }

    return { type: 'NONE', data: null };
  },
}));

export default useAccounts;
