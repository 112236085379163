import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input, Button, Row, Col, Alert, Checkbox } from 'antd';
import Auth from '@aws-amplify/auth';
import { EyeInvisibleOutlined, EyeTwoTone, ArrowLeftOutlined } from '@ant-design/icons';
import GoogleSignin from './GoogleSignin';

const SignUp = ({ changeState, changeWorkflow }) => {
  const [loading, setLoading] = useState(false);
  const [toc, setToc] = useState(false);
  const [err, setErr] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const redirect = params.get('redirect');

  const handleSignUp = async e => {
    setLoading(true);
    setErr('');
    try {
      await Auth.signUp({
        username: e.username.toLocaleLowerCase().trim(),
        password: e.password,
        attributes: {
          email: e.username.toLocaleLowerCase().trim(),
          given_name: e.given_name.trim(),
          family_name: e.family_name.trim(),
        },
        autoSignIn: {
          enabled: true,
        },
      });

      //storing redirect state into localstorage
      if (redirect) {
        let redirectUrl = redirect;
        if (window.location.hash) {
          redirectUrl = redirectUrl + window.location.hash;
        }
        localStorage.setItem('redirectUrl', JSON.stringify(redirectUrl));
      }

      localStorage.setItem('userName', JSON.stringify(e.username));
      setLoading(false);
      changeState('confirmSignUp', null, e.username.toLocaleLowerCase().trim());
    } catch (error) {
      console.log('error signing up:', error);
      let message = error.message.replace('PreSignUp failed with error', '');
      setErr(message);
      setLoading(false);
    }
  };

  function signInWithGoogle() {
    setLoading(true);
    Auth.federatedSignIn({ provider: 'Google' });
  }

  const next = () => {
    changeState('signIn');
    changeWorkflow('welcome');
    history.push(
      `/login${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''}${
        window.location.hash ? window.location.hash : ''
      }`
    );
  };

  return (
    <Row>
      <Col xs={24} md={24} lg={16} xl={16} xxl={9} style={{ maxWidth: '600px' }}>
        <h4 className="mute-title" style={{ marginBottom: '30px' }}>
          Create a new account
        </h4>

        {err && <Alert style={{ marginBottom: '10px' }} type="error" showIcon={false} message={err} banner />}

        <Form
          labelCol={{ xs: 24 }}
          layout="vertical"
          name="normal_signup"
          className="signup-form"
          onFinish={e => handleSignUp(e)}
        >
          <Form.Item label="Email" name="username" rules={[{ required: true, message: 'Please input your Email!' }]}>
            <Input
              autoFocus
              className="xl"
              size="large"
              id="username"
              key="username"
              name="username"
              placeholder="Email"
              disabled={loading}
              tabIndex={1}
            />
          </Form.Item>

          <Form.Item
            label="First Name"
            name="given_name"
            rules={[
              { required: true, message: 'Please input your First Name!' },
              { min: 2, message: 'Must be min of length 2' },
              {
                pattern: new RegExp('^[a-zA-Z._^+*&%$#!~@, -]+$'),
                message: 'Only alphabet is allowed!',
              },
            ]}
          >
            <Input
              className="xl"
              size="large"
              id="given_name"
              key="given_name"
              name="given_name"
              placeholder="First name"
              disabled={loading}
              tabIndex={2}
            />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="family_name"
            rules={[
              { required: true, message: 'Please input your Last Name!' },
              { min: 2, message: 'Must be min of length 2' },
              {
                pattern: new RegExp('^[a-zA-Z._^+*&%$#!~@, -]+$'),
                message: 'Only alphabet is allowed!',
              },
            ]}
          >
            <Input
              className="xl"
              size="large"
              id="family_name"
              key="family_name"
              name="family_name"
              placeholder="Last name"
              disabled={loading}
              tabIndex={3}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password
              className="xl"
              size="large"
              id="password"
              key="password"
              name="password"
              type="password"
              placeholder="Password"
              disabled={loading}
              onCopy={event => {
                event.preventDefault();
                return false;
              }}
              onChange={event => setPassword(event.target.value)}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              tabIndex={4}
            />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Confirm Password is required!' },
              {
                validator(rule, value, callback) {
                  if (value !== password) {
                    return callback('Confirm Password should be same as password!');
                  }

                  return callback();
                },
              },
            ]}
          >
            <Input.Password
              className="xl"
              size="large"
              id="confirmPassword"
              key="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              disabled={loading}
              onCopy={event => {
                event.preventDefault();
                return false;
              }}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              tabIndex={5}
            />
          </Form.Item>

          <Form.Item>
            <Checkbox onChange={e => setToc(e.target.checked)}>
              I agree to the{' '}
              <a href="https://york.ie/legal/terms-conditions/" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" block loading={loading} tabIndex={6} disabled={!toc}>
              Create Account with Email
            </Button>
          </Form.Item>
        </Form>
        <GoogleSignin loading={loading} onClick={signInWithGoogle} disabled={!toc} />
        <Divider plain />
        <Button type="link" size="large" block onClick={next} disabled={loading} tabIndex={7}>
          <ArrowLeftOutlined /> Back to Log In
        </Button>
      </Col>
    </Row>
  );
};

export default SignUp;
