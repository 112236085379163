import { API, graphqlOperation } from '@aws-amplify/api';
import { preferencesByUser } from 'graphql/queries';
import { updateUserPreferences, createUserPreferences } from 'graphql/mutations';

const PREFERENCES = {
  PREF_ACTIVE_ACCOUNT: 'active_account',
  PREF_DASHBOARD_SETTINGS: 'dashboard_settings',
  PREF_COMPANY_POPOVER: 'company_popover',
};

const upsertPreference = async editPreferenceInput => {
  // load the preference by key and owner, if doesn't exist, create it
  // otherwise use existing id to update
  const existingPreference = await getUserPreferences(editPreferenceInput.owner);
  if (existingPreference.errors) {
    return existingPreference;
  }
  // find the preference by key
  const existingPreferenceByKey = existingPreference.items.find(
    preference => preference.key === editPreferenceInput.key
  );
  // if found, update the id
  if (existingPreferenceByKey) {
    editPreferenceInput.id = existingPreferenceByKey.id;
  }
  // if not found, create a new preference
  else {
    return await createPreference(editPreferenceInput);
  }

  // update the preference
  try {
    let updatedPreference = await API.graphql(
      graphqlOperation(updateUserPreferences, {
        input: editPreferenceInput,
      })
    );

    if (updatedPreference.errors) {
      return updatedPreference;
    }
    return updatedPreference.data.updateUserPreferences;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed updating user preference'] };
  }
};

const createPreference = async createPreferenceInput => {
  try {
    let updatedPreference = await API.graphql(
      graphqlOperation(createUserPreferences, {
        input: createPreferenceInput,
      })
    );

    if (updatedPreference.errors) {
      return updatedPreference;
    }
    return updatedPreference.data.createUserPreferences;
  } catch (e) {
    console.log(e);
    return { errors: ['Failed creating user preference'] };
  }
};

const getUserPreferences = async email => {
  try {
    let preferences = await API.graphql(graphqlOperation(preferencesByUser, { owner: email }));
    if (preferences.errors) {
      return preferences;
    }
    return preferences.data.preferencesByUser;
  } catch (e) {
    console.log('errors', e);
    return { errors: ['Failed to user preferences'] };
  }
};

const sdk = { getUserPreferences, createPreference, upsertPreference, ...PREFERENCES };
export default sdk;
